import { FileAddOutlined } from "@ant-design/icons";
import { ReviewStatusesQueryProvider } from "__legacy/queries/enums/ReviewStatusesQueryProvider";
import { Alert, Col, Form, Input, Row, Select, Space } from "antd";
import { FormInstance } from "antd/lib/form";
import Dragger from "antd/lib/upload/Dragger";
import { compact, find, isString } from "lodash";
import { Fragment, useCallback } from "react";

import { ReviewQuestions } from "./ReviewQuestions";

type ReviewFormType = {
  action: string;
  form: FormInstance<any>;
  handler: (values: any) => void;
  values: any;
  errors: string[];
  codes?: any[];
  questions?: any[];
};

const fields = [
  ["status", "status"],
  ["content", "content"],
  ["attachment", "attachment"],
];

export const onError =
  (form: any, setErrors: any) =>
  ({ graphQLErrors }: any) => {
    const fieldsErrors: any[] = [];
    const errors: string[] = [];
    graphQLErrors.forEach((graphQLError: any) => {
      let found = false;
      fields.forEach(([fieldName, errorFieldName]) => {
        if (graphQLError.name === errorFieldName) {
          fieldsErrors.push({ name: fieldName, errors: (graphQLError as any).messages });
          found = true;
        }
      });
      if (!found) {
        errors.push(graphQLError.message);
      }
    });
    form.setFields(fieldsErrors);
    setErrors(errors);
  };

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const input = {
    ...values,
    ...params,
    attachment: values.attachment?.file ?? null,
    questions: compact(
      compact(values.questions).map((question: any) =>
        question.reply
          ? question.reply === true
            ? { ask: question.ask, reply: "yes" }
            : { ask: question.ask, reply: question.reply }
          : null,
      ),
    ),
  };

  mutate({ variables: { ...variables, input } });
};

export const ReviewForm: React.FC<ReviewFormType> = (props) => {
  const { form, handler, values, errors, codes, questions } = props;

  const onValuesChange = useCallback(
    (changedValues, allValues) => {
      const fieldValues: any[] = [];
      if (changedValues.attachment && changedValues.attachment.file.status === "removed") {
        fieldValues.push({ name: ["attachment"], value: null });
      }
      if (changedValues.rejectedClassifications) {
        const rejecteds = changedValues.rejectedClassifications;
        const accepteds = codes?.filter((code) => !rejecteds.includes(code.id));
        const acceptedString = accepteds?.length
          ? `ACCEPTED CODES:\n${accepteds.map((code: any) => code.label).join("\n")}\n\n`
          : "";
        const rejectedString = rejecteds.length
          ? `REJECTED CODES:\n${rejecteds.map((code: any) => find(codes, { id: code }).label).join("\n")}\n\n`
          : "";
        fieldValues.push({
          name: "content",
          value: `${acceptedString}${rejectedString}`,
        });
        if (rejecteds.length === codes?.length) fieldValues.push({ name: "status", value: null });
      }

      form.setFields(fieldValues);
    },
    [form, codes],
  );

  const initialValues = {
    ...values,
    attachment: values.attachment ? [{ url: values.attachment, name: "Download" }] : [],
    content: codes ? `ACCEPTED CODES:\n${codes.map((code: any) => code.label).join("\n")}\n\n` : "",
  };

  const uploadProps = {
    maxCount: 1,
    accept: ".pdf, .doc, .xls, .xlsx",
    beforeUpload: () => {
      return false;
    },
  };

  return (
    <Fragment>
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Form
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
        onFinish={handler}
      >
        <Row gutter={[24, 0]}>
          {codes?.length && (
            <Col span={24}>
              <Form.Item name="rejectedClassifications" label="Rejected Codes">
                <Select
                  mode="multiple"
                  showSearch
                  optionLabelProp="label"
                  filterOption={(input, option) => {
                    let itemText = "";
                    if (option?.label && isString(option.label)) itemText = option.label;
                    itemText = itemText.toLowerCase();
                    const searchValue = input.toLowerCase();
                    return itemText.indexOf(searchValue) > -1;
                  }}
                >
                  {codes.map((code: any) => (
                    <Select.Option key={code.id} value={code.id} label={code.label}>
                      {code.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <ReviewStatusesQueryProvider>
              {({ reviewStatuses }) => (
                <Form.Item dependencies={["rejectedClassifications"]}>
                  {(form: FormInstance) => (
                    <Form.Item name="status" label="Status" rules={[{ required: true, message: "is required" }]}>
                      <Select
                        showSearch
                        optionLabelProp="label"
                        filterOption={(input, option) => {
                          let label = "";
                          if (option?.label && isString(option.label)) label = option.label;
                          let value = option?.value;
                          return (
                            label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                            value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {reviewStatuses?.enumValues?.map((reviewStatus) => {
                          if (reviewStatus.name === "for_review") return null;
                          if (
                            codes &&
                            form.getFieldValue("rejectedClassifications")?.length === codes?.length &&
                            reviewStatus.name === "approved"
                          )
                            return null;
                          return (
                            <Select.Option
                              key={reviewStatus.name}
                              value={reviewStatus.name}
                              label={reviewStatus.description}
                            >
                              {reviewStatus.description}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  )}
                </Form.Item>
              )}
            </ReviewStatusesQueryProvider>
          </Col>
          <Col span={24}>
            <ReviewQuestions questions={questions} />
          </Col>
          <Col span={24}>
            <Form.Item name="content" label="Remarks" rules={[{ required: true, message: "is required" }]}>
              <Input.TextArea rows={codes ? 10 : 3} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="attachment" label="Attachment" valuePropName={"defaultFileList"}>
              <Dragger {...uploadProps}>
                <FileAddOutlined />
                Click or drop here
              </Dragger>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};
