import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  UpdateCandidacyMutation as UpdateCandidacyMutationType,
  UpdateCandidacyMutationVariables,
} from "./__generated__/UpdateCandidacyMutation";

const UpdateCandidacyMutation = loader("./UpdateCandidacyMutation.gql");

type UpdateCandidacyMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted: (data: UpdateCandidacyMutationType) => void;
}) => ReactElement;

export const UpdateCandidacyMutationProvider: UpdateCandidacyMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<UpdateCandidacyMutationType, UpdateCandidacyMutationVariables>(
    UpdateCandidacyMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
