import { Button, Grid, Icon, Link, Stack } from "@chakra-ui/react";
import React from "react";
import { RiFileDownloadLine } from "react-icons/ri";

import { Card } from "../ui/Card";
import { CardField } from "../ui/CardField";

export const Transfer: React.FC<{}> = () => {
  return (
    <Card
      title="Transfer"
      actions={
        <Button variant="outline" aria-label="Edit" colorScheme="blue">
          Edit
        </Button>
      }
    >
      <Stack>
        <Grid templateColumns="repeat(2, 1fr)" rowGap="2">
          <CardField label="Existing Cb" value="AJA Registrars Limited" />
          <CardField label="Existing Accreditation" value="ANAB" />
          <CardField label="Cerfiticate Expiry Date" value="12/12/2018" />
          <CardField label="Original Cerfiticate Date" value="12/12/2019" />
          <CardField
            label="Cert/Schedule"
            value={
              <Link color="blue.500">
                Download <Icon as={RiFileDownloadLine} />
              </Link>
            }
          />
          <CardField
            label="Previous CB Report"
            value={
              <Link color="blue.500">
                Download <Icon as={RiFileDownloadLine} />
              </Link>
            }
          />
        </Grid>
        <CardField
          label="Transfer Reason"
          value="The customer is an Italy AJA customer. They want to maintain the same CB"
        />
      </Stack>
    </Card>
  );
};
