import { CreateActivityItemMutationProvider } from "__legacy/queries/activities/CreateActivityItemMutationProvider";
import { Button, Form, Layout, PageHeader } from "antd";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ItemForm, onError, onFinish } from "./ItemForm";

export const New: React.FC<{}> = () => {
  const { agencyId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <CreateActivityItemMutationProvider onError={onError(form, setErrors)} onCompleted={() => navigate("..")}>
      {(loading, mutate) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title={`New Item`}
            extra={
              <Fragment>
                <Button onClick={() => navigate("..")}>Back to list</Button>
                <Button type="primary" loading={loading} onClick={() => form.submit()}>
                  Save
                </Button>
              </Fragment>
            }
          />
          <Layout.Content className="content">
            <ItemForm form={form} handler={onFinish(mutate, { agencyId }, {})} values={{}} errors={errors} />
          </Layout.Content>
        </Fragment>
      )}
    </CreateActivityItemMutationProvider>
  );
};
