import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  CreateEmploymentMutation as CreateEmploymentMutationType,
  CreateEmploymentMutationVariables,
} from "./__generated__/CreateEmploymentMutation";

const CreateEmploymentMutation = loader("./CreateEmploymentMutation.gql");

type CreateEmploymentMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted: (data: CreateEmploymentMutationType) => void;
}) => ReactElement;

export const CreateEmploymentMutationProvider: CreateEmploymentMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<CreateEmploymentMutationType, CreateEmploymentMutationVariables>(
    CreateEmploymentMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
      refetchQueries: ["GetContextQuery"],
      awaitRefetchQueries: true,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
