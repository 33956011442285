import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  UpdateAbsenceMutation as UpdateAbsenceMutationType,
  UpdateAbsenceMutationVariables,
} from "./__generated__/UpdateAbsenceMutation";

const UpdateAbsenceMutation = loader("./UpdateAbsenceMutation.gql");

type UpdateAbsenceMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError?: (error: ApolloError) => void;
  onCompleted?: (data: UpdateAbsenceMutationType) => void;
}) => ReactElement;

export const UpdateAbsenceMutationProvider: UpdateAbsenceMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<UpdateAbsenceMutationType, UpdateAbsenceMutationVariables>(
    UpdateAbsenceMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
