import { AccreditationQueryProvider } from "__legacy/queries/accreditations/AccreditationQueryProvider";
import { UpdateAccreditationMutationProvider } from "__legacy/queries/accreditations/UpdateAccreditationMutationProvider";
import { Button, Form, Layout, PageHeader } from "antd";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AccreditationForm, onError, onFinish } from "./AccreditationForm";

export const Edit: React.FC<{}> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <AccreditationQueryProvider variables={{ id: id }}>
      {({ accreditation }) => (
        <UpdateAccreditationMutationProvider onError={onError(form, setErrors)} onCompleted={() => navigate("../../")}>
          {(loading, mutate) => (
            <Fragment>
              <PageHeader
                ghost={true}
                title={`Edit accreditation of ${accreditation.authority.name} for scheme ${accreditation.scheme.name}`}
                extra={
                  <Fragment>
                    <Button onClick={() => navigate("../../../../")}>Back to list</Button>
                    <Button onClick={() => navigate("../../")}>Back to accreditations</Button>
                    <Button type="primary" loading={loading} onClick={() => form.submit()}>
                      Save
                    </Button>
                  </Fragment>
                }
              />
              <Layout.Content className="content">
                <AccreditationForm
                  action="update"
                  form={form}
                  handler={onFinish(mutate, {}, { id })}
                  values={{
                    ...accreditation,
                    authorityId: accreditation.authority.id,
                    accreditationClassifications: accreditation.accreditationClassifications.map(
                      (accreditationClassification) => ({
                        riskLevel: accreditationClassification.riskLevel,
                        classificationId: accreditationClassification.classification.id,
                      }),
                    ),
                  }}
                  errors={errors}
                />
              </Layout.Content>
            </Fragment>
          )}
        </UpdateAccreditationMutationProvider>
      )}
    </AccreditationQueryProvider>
  );
};
