import { DeleteOutlined, EditOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { AgenciesQueryProvider } from "__legacy/queries/agencies/AgenciesQueryProvider";
import { Button, Input, Layout, notification, PageHeader, Popconfirm, Row, Space, Table, Tooltip } from "antd";
import { Fragment, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

const deleteAgencyMutation = gql`
  mutation($id: ID!) {
    deleteAgency(id: $id) {
      agency {
        id
        name
      }
    }
  }
`;

export const List: React.FC<{}> = () => {
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const handleSearch = (value: string) => setSearchQuery(value.trim());

  const [execDelete] = useMutation(deleteAgencyMutation, {
    errorPolicy: "none",
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
    onCompleted: ({ deleteAgency: { agency } }) => {
      notification.success({
        message: `Agency "${agency.name}" deleted`,
      });
    },
    refetchQueries: ["AgenciesQuery"],
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "agencies.name",
      render: (value: string, resource: any) => <NavLink to={resource.id}>{value}</NavLink>,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "agencies.country",
      render: (value: string, resource: any) => <NavLink to={resource.id}>{value}</NavLink>,
    },
    {
      width: "80px",
      title: "Actions",
      dataIndex: "id",
      render: (id: string) => (
        <Row justify="end">
          <Space>
            <Tooltip title="Edit" placement="bottom">
              <Button onClick={() => navigate(`${id}/edit`)} icon={<EditOutlined />}></Button>
            </Tooltip>
            <Popconfirm
              title="Are you sure?"
              icon={<QuestionCircleOutlined />}
              onConfirm={() => execDelete({ variables: { id } })}
            >
              <Tooltip title="Delete" placement="bottom">
                <Button danger icon={<DeleteOutlined />}></Button>
              </Tooltip>
            </Popconfirm>
          </Space>
        </Row>
      ),
    },
  ];

  return (
    <AgenciesQueryProvider variables={{ search: searchQuery }}>
      {({ agencies }) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title="Agencies"
            extra={
              <Fragment>
                <Input.Search
                  allowClear
                  placeholder="search"
                  onSearch={handleSearch}
                  defaultValue={searchQuery ?? ""}
                  style={{ width: 500 }}
                />
                <Button onClick={() => navigate("new")} type="primary">
                  <PlusOutlined />
                  New
                </Button>
              </Fragment>
            }
          />
          <Layout.Content className="content">
            <Table rowKey="id" columns={columns} dataSource={agencies} pagination={false} />
          </Layout.Content>
        </Fragment>
      )}
    </AgenciesQueryProvider>
  );
};
