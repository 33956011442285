import { useQuery } from "@apollo/client";
import { CenterResult } from "__legacy/components/CenterResult";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import { AreaQuery as AreaQueryType, AreaQueryVariables } from "./__generated__/AreaQuery";

const AreaQuery = loader("./AreaQuery.gql");

type AreaQueryProviderType = (props: {
  children: (data: AreaQueryType) => ReactNode;
  variables: AreaQueryVariables;
}) => ReactElement;

export const AreaQueryProvider: AreaQueryProviderType = (props) => {
  const { children, variables } = props;

  const { error, loading, data } = useQuery<AreaQueryType, AreaQueryVariables>(AreaQuery, {
    variables,
    fetchPolicy: "cache-and-network",
  });

  if (loading) return <CenterSpinner size="large" />;
  if (error || !data)
    return <CenterResult status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;

  return <Fragment>{children(data)}</Fragment>;
};
