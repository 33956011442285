import { Layout } from "antd";
import { Outlet } from "react-router-dom";

export const AuthLayout: React.FC<{}> = () => {
  return (
    <Layout className="layout-auth">
      <Outlet />
    </Layout>
  );
};
