import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { ProjectStatus } from "__legacy/queries/__generated__/globalTypes";
import { ProjectStatusesQuery_projectStatuses_enumValues } from "__legacy/queries/enums/__generated__/ProjectStatusesQuery";
import { ProjectStatusesQueryProvider } from "__legacy/queries/enums/ProjectStatusesQueryProvider";
import { VisitsQueryProvider } from "__legacy/queries/projects/VisitsQueryProvider";
import { Button, Input, Layout, PageHeader, Table } from "antd";
import { find } from "lodash";
import moment from "moment";
import { Fragment, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

export const List: React.FC<{ agency?: true }> = (props) => {
  const { agency } = props;
  const { kind, status } = useParams();
  const navigate = useNavigate();
  const [activePane] = useState<ProjectStatus>(status as ProjectStatus);

  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const handleSearch = (value: string) => setSearchQuery(value.trim());

  const actionLink = (resource: any) => {
    return !agency && ["pending"].includes(resource.status) ? `${resource.id}/review` : "";
  };

  const columns = (projectStatus: ProjectStatusesQuery_projectStatuses_enumValues[]) => {
    const columns: any[] = [
      {
        title: "Status",
        dataIndex: "status",
        render: (value: string, resource: any) => find(projectStatus, ({ name }) => name === value)?.description,
      },
      {
        title: "Project",
        render: (value: string, resource: any) => (
          <NavLink to={actionLink(resource)}>{resource.audit.project.code}</NavLink>
        ),
      },
      {
        title: "Site",
        render: (value: string, resource: any) => resource.site.name,
      },
      {
        title: "Mandays",
        dataIndex: "mandays",
      },
      {
        title: "Lead Auditor",
        render: (value: string, resource: any) => resource.leadAuditor?.companyName,
      },
      {
        title: "Due Date",
        render: (value: string, resource: any) =>
          resource.dueDate ? moment(resource.dueDate).format("MM-DD-YYYY") : null,
      },
      {
        width: "80px",
        title: "Actions",
        dataIndex: "id",
        render: (id: string, resource: any) => (
          <Button onClick={() => navigate(actionLink(resource))}>
            <EditOutlined />
          </Button>
        ),
      },
    ];

    return columns;
  };
   ;
  return (
    <VisitsQueryProvider variables={{ status: activePane, filterAuthorities: true }}>
      {({ visits }) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title={(kind ? (kind === "main" ? "Assessment" : "Surveillance") : "") + ` Packages Visits`}
            extra={
              <Fragment>
                <Input.Search
                  allowClear
                  placeholder="search"
                  onSearch={handleSearch}
                  defaultValue={searchQuery ?? ""}
                  style={{ width: 500 }}
                />
                {agency && (
                  <Button onClick={() => navigate("new")} type="primary">
                    <PlusOutlined />
                    New
                  </Button>
                )}
              </Fragment>
            }
            // footer={
            //   <Tabs
            //     activeKey={activePane}
            //     onChange={(activeKey: any) => setActivePane(activeKey !== "all" ? activeKey : undefined)}
            //   >
            //     <Tabs.TabPane tab="All" key="all" />
            //     <Tabs.TabPane tab="Redaction" key="redaction" />
            //     <Tabs.TabPane tab="Pending" key="pending" />
            //     <Tabs.TabPane tab="Approved" key="approved" />
            //   </Tabs>
            // }
          />
          <Layout.Content className="content">
            <ProjectStatusesQueryProvider>
              {({ projectStatuses }) => (
                <Table
                  rowKey="id"
                  columns={columns(projectStatuses?.enumValues ?? [])}
                  dataSource={kind ? visits.filter((visit: any) => visit.audit.kind === kind) : visits}
                  pagination={false}
                />
              )}
            </ProjectStatusesQueryProvider>
          </Layout.Content>
        </Fragment>
      )}
    </VisitsQueryProvider>
  );
};
