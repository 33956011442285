import { Grid, Stack } from "@chakra-ui/react";

import { Card } from "../ui/Card";
import { CardField } from "../ui/CardField";

export const Experience: React.FC<{}> = () => {
  return (
    <Card title="Desktop Support Technician">
      <Stack w="full" spacing="4">
        <Stack>
          <Grid templateColumns="repeat(2, 1fr)" rowGap="2">
            <CardField label="Institution" value="Eastern Company" />
            <CardField label="Qualification" value="Desktop Support Technician" />
            <CardField label="Start year" value="1998" />
            <CardField label="End year" value="2000" />
          </Grid>
          <CardField
            label="Details"
            value="Nullam molestie nibh in lectus. Pellentesque at nulla. Suspendisse potenti. Cras in purus eu magna vulputate luctus.\x"
          />
        </Stack>
      </Stack>
    </Card>
  );
};
