import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  CreateSchemeMutation as CreateSchemeMutationType,
  CreateSchemeMutationVariables,
} from "./__generated__/CreateSchemeMutation";

const CreateSchemeMutation = loader("./CreateSchemeMutation.gql");

type CreateSchemeMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted: (data: CreateSchemeMutationType) => void;
}) => ReactElement;

export const CreateSchemeMutationProvider: CreateSchemeMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<CreateSchemeMutationType, CreateSchemeMutationVariables>(
    CreateSchemeMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
