import { Box, Heading, HStack, Icon, Stack, Tab, TabList, Tabs, Text, VStack } from "@chakra-ui/react";
import { HiBan } from "react-icons/hi";

export const Summary: React.FC<{}> = () => {
  return (
    <VStack bg="white" boxShadow="sm">
      <Stack w="full" p="6" pb="2">
        <HStack w="full" justify="space-between">
          <Box>
            <Heading size="lg">Alberto Corra</Heading>
          </Box>
          <Box>
            <Heading size="md" color="blue.500">
              CFQBGP00C62H647Z
            </Heading>
          </Box>
        </HStack>
        <HStack w="full" justify="space-between">
          <Box>
            <Text>Via Agnello</Text>
          </Box>
          <Box>
            <Text>Fiscal Code: LBRCRR12R23E874A</Text>
          </Box>
        </HStack>
        <HStack w="full" justify="space-between">
          <Box>
            <Text>Ancona 68016, IT</Text>
          </Box>
          <Box>
            <Text>Vat Code: 12312312312311</Text>
          </Box>
        </HStack>
      </Stack>
      <Stack w="full" px="6">
        <Tabs variant="unstyled">
          <TabList>
            <Tab
              fontSize="sm"
              fontWeight="bold"
              p="2"
              mx="px"
              borderBottom="1px"
              borderBottomColor="transparent"
              color="coolGray.400"
              _selected={{ color: "coolGray.700", borderBottomColor: "coolGray.700" }}
              _hover={{ color: "coolGray.600", borderBottomColor: "coolGray.600" }}
            >
              CUSTOMER
            </Tab>
            <Tab
              fontSize="xs"
              fontWeight="bold"
              p="1"
              mx="px"
              borderBottom="1px"
              borderBottomColor="transparent"
              color="coolGray.400"
              isDisabled
            >
              <Icon as={HiBan} boxSize="3" mr="1" />
              SUPPLIER
            </Tab>
            <Tab
              fontSize="xs"
              fontWeight="bold"
              p="2"
              mx="px"
              borderBottom="1px"
              borderBottomColor="transparent"
              color="coolGray.400"
              _selected={{ color: "coolGray.700", borderBottomColor: "coolGray.700" }}
              _hover={{ color: "coolGray.600", borderBottomColor: "coolGray.600" }}
            >
              COLLABORATOR
            </Tab>
          </TabList>
        </Tabs>
      </Stack>
    </VStack>
  );
};
