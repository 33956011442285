import { Box, Button, Stack } from "@chakra-ui/react";

type CardButtonType = {
  colorScheme?: string;
  leftIcon?: any;
  rightIcon?: any;
  title?: string;
  variant?: string;
};

export const CardButton: React.FC<CardButtonType> = (props) => {
  const { colorScheme, leftIcon, rightIcon, title, variant } = props;
  return (
    <Stack bg="white" spacing="0" boxShadow="sm">
      <Stack p="3" px="4">
        <Box h="8" lineHeight="8">
          <Button
            colorScheme={colorScheme}
            aria-label={title}
            w="full"
            variant={variant}
            leftIcon={leftIcon}
            rightIcon={rightIcon}
          >
            {title}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};
