import { DeleteOutlined, EditOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { IndustryQueryProvider } from "__legacy/queries/industries/IndustryQueryProvider";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Input,
  Layout,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const deleteClassificationMutation = gql`
  mutation($id: ID!) {
    deleteClassification(id: $id) {
      classification {
        id
        name
      }
    }
  }
`;

export const Show: React.FC<{}> = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const handleSearch = (value: string) => setSearchQuery(value.trim());

  const [execDelete] = useMutation(deleteClassificationMutation, {
    errorPolicy: "none",
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
    onCompleted: ({ deleteClassification: { classification } }) => {
      notification.success({
        message: `Classification "${classification.name}" deleted`,
      });
    },
    refetchQueries: ["IndustryQuery"],
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "classifications.name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "classifications.description",
    },
    {
      width: "50px",
      title: "Actions",
      dataIndex: "id",
      render: (id: string) => (
        <Row justify="end">
          <Space>
            <Tooltip title="Edit" placement="bottom">
              <Button onClick={() => navigate(`./codes/${id}/edit`)} icon={<EditOutlined />}></Button>
            </Tooltip>
            <Popconfirm
              title="Are you sure?"
              icon={<QuestionCircleOutlined />}
              onConfirm={() => execDelete({ variables: { id } })}
            >
              <Tooltip title="Delete" placement="bottom">
                <Button danger icon={<DeleteOutlined />}></Button>
              </Tooltip>
            </Popconfirm>
          </Space>
        </Row>
      ),
    },
  ];

  return (
    <IndustryQueryProvider variables={{ id, search: searchQuery }}>
      {({ industry }) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title={`Industry ${industry.name}`}
            extra={
              <Fragment>
                <Button onClick={() => navigate("../")}>Back to list</Button>
                <Button type="primary" onClick={() => navigate("./edit")}>
                  Edit
                </Button>
              </Fragment>
            }
          />
          <Layout.Content className="content">
            <Card>
              <Row gutter={[16, 16]} justify="space-between">
                <Col>
                  <Typography.Title level={2}>{industry.name}</Typography.Title>
                  <Typography.Title level={5}>Kind: {industry.kind}</Typography.Title>
                  <Checkbox disabled checked={industry.isEnabled}>
                    Enabled
                  </Checkbox>
                </Col>
              </Row>
              <Divider />
              <Row gutter={[16, 16]} justify="space-between">
                <Col>
                  <Typography.Title level={3}>Classifications</Typography.Title>
                </Col>
                <Col>
                  <Input.Search
                    allowClear
                    placeholder="search"
                    onSearch={handleSearch}
                    defaultValue={searchQuery ?? ""}
                    style={{ width: 400, marginRight: 12 }}
                  />
                  <Button onClick={() => navigate("./codes/new")} type="primary">
                    <PlusOutlined />
                    New
                  </Button>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Table
                    size="small"
                    rowKey="id"
                    columns={columns}
                    dataSource={industry.classifications}
                    pagination={false}
                  />
                </Col>
              </Row>
            </Card>
          </Layout.Content>
        </Fragment>
      )}
    </IndustryQueryProvider>
  );
};
