import frames from "__legacy/frames";
import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { useApplicationContext } from "./ApplicationContext";
import { AuthLayout } from "./AuthLayout";
import { AuthRedirector } from "./AuthRedirector";
import { MainLayout } from "./MainLayout";
import { MainRedirector } from "./MainRedirector";

import "__legacy/styles/theme.less";

export const Application: React.FC<{}> = () => {
  const applicationContext = useApplicationContext();
  const location = useLocation();
  const { i18n } = useTranslation();

  const helmet = (
    <Helmet>
      <html lang={i18n.language} />
      <meta charSet="utf-8" />
      <link rel="icon" type="image/png" href="/favicon.png" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <title>Aja Europe</title>
      <body />
    </Helmet>
  );

  let application = <Fragment></Fragment>;

  if (!applicationContext.authenticated) {
    console.info("Not authenticated!");

    application = (
      <Routes>
        <Route path="/" element={<AuthRedirector />} />
        <Route element={<AuthLayout />}>
          <Route path="/auth/sign-in" element={<frames.auth.SignIn />} />
          <Route path="/auth/password-reset/:token" element={<frames.auth.CompletePasswordReset />} />
          <Route path="/auth/password-reset" element={<frames.auth.RequestPasswordReset />} />
        </Route>
        <Route path="*" element={<AuthRedirector />} />
      </Routes>
    );
  } else if (location.state && (location.state as any).landingUrl) {
    application = <Navigate to={(location.state as any).landingUrl} replace />;
  } else {
    const employments = applicationContext.session.currentUser.employments;

    application = (
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<MainRedirector />} />
          <Route path="/users">
            <Route path="/new" element={<frames.globals.users.New />} />
            <Route path="/:id/edit" element={<frames.globals.users.Edit />} />
            <Route path="/:userId/employments">
              <Route path="/new" element={<frames.globals.users.employments.New />} />
              <Route path="/:id/edit" element={<frames.globals.users.employments.Edit />} />
            </Route>
            <Route path="/:id" element={<frames.globals.users.Show />} />
            <Route path="/" element={<frames.globals.users.List />} />
          </Route>
          <Route path="/roles">
            <Route path="/new" element={<frames.shareds.roles.New />} />
            <Route path="/:id/edit" element={<frames.shareds.roles.Edit />} />
            <Route path="/:id" element={<frames.shareds.roles.Show />} />
            <Route path="/" element={<frames.shareds.roles.List />} />
          </Route>
          <Route path="/agencies">
            <Route path="/new" element={<frames.globals.agencies.New />} />
            <Route path="/:id/edit" element={<frames.globals.agencies.Edit />} />
            <Route path="/:agencyId/users">
              <Route path="/new" element={<frames.globals.agencies.users.New />} />
              <Route path="/:id/edit" element={<frames.globals.agencies.users.Edit />} />
            </Route>
            <Route path="/:id" element={<frames.globals.agencies.Show />} />
            <Route path="/" element={<frames.globals.agencies.List />} />
          </Route>
          <Route path="/classifications">
            <Route path="/new" element={<frames.globals.classifications.New />} />
            <Route path="/:id/edit" element={<frames.globals.classifications.Edit />} />
            <Route path="/:groupId/codes">
              <Route path="/new" element={<frames.globals.classifications.codes.New />} />
              <Route path="/:id/edit" element={<frames.globals.classifications.codes.Edit />} />
            </Route>
            <Route path="/:id" element={<frames.globals.classifications.Show />} />
            <Route path="/" element={<frames.globals.classifications.List />} />
          </Route>
          <Route path="/certifications">
            <Route path="/bodies">
              <Route path="/new" element={<frames.globals.certifications.bodies.New />} />
              <Route path="/:id/edit" element={<frames.globals.certifications.bodies.Edit />} />
              <Route path="/:id" element={<frames.globals.certifications.bodies.Show />} />
              <Route path="/" element={<frames.globals.certifications.bodies.List />} />
            </Route>
            <Route path="/schemes">
              <Route path="/new" element={<frames.globals.certifications.schemes.New />} />
              <Route path="/:id/edit" element={<frames.globals.certifications.schemes.Edit />} />
              <Route
                path="/:schemeId/technicalAreas/new"
                element={<frames.globals.certifications.schemes.technicalAreas.New />}
              />
              <Route
                path="/:schemeId/technicalAreas/:id/edit"
                element={<frames.globals.certifications.schemes.technicalAreas.Edit />}
              />
              <Route
                path="/:schemeId/accreditations/new"
                element={<frames.globals.certifications.schemes.accreditations.New />}
              />
              <Route
                path="/:schemeId/accreditations/:id/edit"
                element={<frames.globals.certifications.schemes.accreditations.Edit />}
              />
              <Route path="/:schemeId" element={<frames.globals.certifications.schemes.Show />}>
                <Route path="/technicalAreas" element={<frames.globals.certifications.schemes.technicalAreas.List />} />
                <Route path="/accreditations" element={<frames.globals.certifications.schemes.accreditations.List />} />
                <Route path="/" element={<frames.globals.certifications.schemes.info.Show />} />
              </Route>
              <Route path="/" element={<frames.globals.certifications.schemes.List />} />
            </Route>
          </Route>
          <Route path="/auditors">
            <Route path="/schemeApplications">
              <Route path="/:abilityId/review" element={<frames.globals.collaborators.schemeApplications.Review />} />
              <Route path="/" element={<frames.globals.collaborators.schemeApplications.List />} />
            </Route>
            <Route path="/codeApplications">
              <Route path="/:candidacyId/review" element={<frames.globals.collaborators.codeApplications.Review />} />
              <Route path="/" element={<frames.globals.collaborators.codeApplications.List />} />
            </Route>
            <Route path="/dashboard" element={<frames.globals.collaborators.Dash />} />
            <Route path="/allAuditors">
              <Route path="/:agencyId/:stakeholderId" element={<frames.shareds.collaborators.Show />}>
                <Route path="/experiences" element={<frames.shareds.collaborators.experiences.List />} />
                <Route path="/trainings" element={<frames.shareds.collaborators.trainings.List />} />
                <Route path="/abilities" element={<frames.shareds.collaborators.abilities.List />} />
                <Route path="/" element={<frames.shareds.collaborators.stakeholder.Show />} />
              </Route>
              <Route path="/" element={<frames.shareds.collaborators.List />} />
            </Route>
          </Route>
          <Route path="/job-orders">
            <Route path="/system">
              <Route path="/dashboard" element={<frames.globals.projects.Dash />} />
              <Route path="/:step" element={<frames.shareds.projects.List />} />
              <Route path="/:step/:status" element={<frames.shareds.projects.List />} />
              <Route path="/:step/:status/:id" element={<frames.shareds.projects.Show />} />
              <Route path="/:step/:status/:projectId/review" element={<frames.globals.projects.Review />} />
              <Route path="/visits" element={<frames.globals.visits.List />} />
              <Route path="/visits/:kind" element={<frames.globals.visits.List />} />
              <Route path="/visits/:kind/:status" element={<frames.globals.visits.List />} />
              <Route path="/visits/:kind/:status/:visitId/review" element={<frames.globals.visits.Review />} />
            </Route>
            <Route path="/product" element={<Fragment>GLOBAL Product</Fragment>} />
            <Route path="/personnel" element={<Fragment>GLOBAL Personnel</Fragment>} />
          </Route>

          <Route path="/:agencyId">
            <Route path="/users">
              <Route path="/:id/edit" element={<frames.agencies.users.Edit />} />
              <Route path="/" element={<frames.agencies.users.List />} />
            </Route>
            <Route path="/roles">
              <Route path="/new" element={<frames.shareds.roles.New agency />} />
              <Route path="/:id/edit" element={<frames.shareds.roles.Edit agency />} />
              <Route path="/:id" element={<frames.shareds.roles.Show agency />} />
              <Route path="/" element={<frames.shareds.roles.List agency />} />
            </Route>
            <Route path="/stakeholders">
              <Route path="/new" element={<frames.agencies.stakeholders.New />} />
              <Route path="/:id/edit" element={<frames.agencies.stakeholders.Edit />} />
              <Route path="/:stakeholderId/contacts/new" element={<frames.agencies.stakeholders.contacts.New />} />
              <Route
                path="/:stakeholderId/contacts/:id/edit"
                element={<frames.agencies.stakeholders.contacts.Edit />}
              />
              <Route path="/:stakeholderId/sites/new" element={<frames.agencies.stakeholders.sites.New />} />
              <Route path="/:stakeholderId/sites/:id/edit" element={<frames.agencies.stakeholders.sites.Edit />} />
              <Route path="/:id" element={<frames.agencies.stakeholders.Show />} />
              <Route path="/" element={<frames.agencies.stakeholders.List />} />
            </Route>
            <Route path="/auditors">
              <Route
                path="/:stakeholderId/experiences/new"
                element={<frames.agencies.collaborators.experiences.New />}
              />
              <Route
                path="/:stakeholderId/experiences/:id/edit"
                element={<frames.agencies.collaborators.experiences.Edit />}
              />
              <Route path="/:stakeholderId/trainings/new" element={<frames.agencies.collaborators.trainings.New />} />
              <Route
                path="/:stakeholderId/trainings/:id/edit"
                element={<frames.agencies.collaborators.trainings.Edit />}
              />
              <Route path="/:stakeholderId/abilities/new" element={<frames.agencies.collaborators.abilities.New />} />
              <Route
                path="/:stakeholderId/abilities/:id/edit"
                element={<frames.agencies.collaborators.abilities.Edit />}
              />
              <Route path="/:stakeholderId" element={<frames.shareds.collaborators.Show agency />}>
                <Route path="/experiences" element={<frames.shareds.collaborators.experiences.List agency />} />
                <Route path="/trainings" element={<frames.shareds.collaborators.trainings.List agency />} />
                <Route path="/abilities">
                  <Route path="/:abilityId/candidacies">
                    <Route path="/new" element={<frames.agencies.collaborators.abilities.candidacies.New />} />
                    <Route path="/:id/edit" element={<frames.agencies.collaborators.abilities.candidacies.Edit />} />
                    <Route
                      path="/:candidacyId"
                      element={<frames.agencies.collaborators.abilities.candidacies.Show />}
                    />
                  </Route>
                  <Route path="/" element={<frames.shareds.collaborators.abilities.List agency />} />
                </Route>
                <Route path="/" element={<frames.shareds.collaborators.stakeholder.Show agency />} />
              </Route>
              <Route path="/" element={<frames.shareds.collaborators.List agency />} />
            </Route>
            <Route path="/job-orders">
              <Route path="/system">
                <Route path="/dashboard" element={<frames.agencies.projects.Dash />} />
                <Route path="/:step" element={<frames.shareds.projects.List agency />} />
                <Route path="/:step/:status" element={<frames.shareds.projects.List agency />} />
                <Route path="/:step/:status/:id" element={<frames.shareds.projects.Show agency />} />
                <Route path="/visits/:kind/:status" element={<frames.agencies.visits.List agency />} />
                <Route path="/new" element={<frames.agencies.projects.New />} />
                <Route path="/" element={<frames.shareds.projects.List agency />} />
              </Route>
              <Route path="/product">
                <Route path="/new" element={<frames.agencies.jobOrders.product.ProductForm />} />
                <Route path="/:projectId/edit" element={<frames.agencies.jobOrders.product.ProductForm />} />
              </Route>
              <Route path="/personnel" element={<Fragment>GLOBAL Personnel</Fragment>} />
            </Route>
            <Route path="/activities">
              <Route path="/" element={<frames.shareds.activities.Dash />} />
              <Route path="/items">
                <Route path="/" element={<frames.shareds.activities.items.List />} />
                <Route path="/new" element={<frames.shareds.activities.items.New />} />
                <Route path="/:id/edit" element={<frames.shareds.activities.items.Edit />} />
              </Route>
              <Route path="/stages">
                <Route path="/" element={<frames.shareds.activities.stages.List />} />
                <Route path="/new" element={<frames.shareds.activities.stages.New />} />
                <Route path="/:id/edit" element={<frames.shareds.activities.stages.Edit />} />
              </Route>
              <Route path="/templates">
                <Route path="/" element={<frames.shareds.activities.templates.List />} />
                <Route path="/new" element={<frames.shareds.activities.templates.New />} />
                <Route path="/:id/edit" element={<frames.shareds.activities.templates.Edit />} />
              </Route>
            </Route>
            <Route path="/timesheet">
              <Route path="/" element={<frames.agencies.timesheet.List />} />
              <Route path="/:date" element={<frames.agencies.timesheet.Show />} />
            </Route>
          </Route>
        </Route>

        <Route path="/auth/*" element={<MainRedirector />} />
        {employments
          .filter((e: any) => e.kind === "agency")
          .map((employment: any) => (
            <Route key={employment.agency.id} path={`/${employment.agency.id}/*`} element={<MainRedirector />} />
          ))}
        <Route path="*" element={<MainRedirector />} />
      </Routes>
    );
  }

  return (
    <Fragment>
      {helmet}
      {application}
    </Fragment>
  );
};
