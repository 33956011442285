import { Reviews } from "__legacy/frames/shareds/collaborators/Reviews";
import { AbilityQueryProvider } from "__legacy/queries/abilities/AbilityQueryProvider";
import { UpdateAbilityMutationProvider } from "__legacy/queries/abilities/UpdateAbilityMutationProvider";
import { Button, Divider, Form, Layout, PageHeader } from "antd";
import moment from "moment";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AbilityForm, onError, onFinish } from "./AbilityForm";

export const Edit: React.FC<{}> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <AbilityQueryProvider variables={{ id: id }}>
      {({ ability }) => (
        <UpdateAbilityMutationProvider onError={onError(form, setErrors)} onCompleted={() => navigate("../../")}>
          {(loading, mutate) => (
            <Fragment>
              <PageHeader
                ghost={true}
                title={`Edit ability for ${ability.stakeholder.companyName}`}
                extra={
                  <Fragment>
                    <Button onClick={() => navigate("../../")}>Back to experiences</Button>
                    <Button type="primary" loading={loading} onClick={() => form.submit()}>
                      Save
                    </Button>
                  </Fragment>
                }
              />
              <Layout.Content className="content">
                <AbilityForm
                  action="update"
                  form={form}
                  handler={onFinish(mutate, {}, { id })}
                  values={{
                    ...ability,
                    schemeId: ability.scheme.id,
                    journeyDate: moment(ability.journeyDate, "YYYY-MM-DD"),
                    startDate: moment(ability.startDate, "YYYY-MM-DD"),
                    endDate: moment(ability.endDate, "YYYY-MM-DD"),
                  }}
                  errors={errors}
                />
                <Divider />
                <Reviews reviews={ability.abilityReviews} />
              </Layout.Content>
            </Fragment>
          )}
        </UpdateAbilityMutationProvider>
      )}
    </AbilityQueryProvider>
  );
};
