import { QuestionCircleOutlined } from "@ant-design/icons";
import { useApplicationContext } from "__legacy/components/Application";
import { CreateAbsenceMutationProvider } from "__legacy/queries/absences/CreateAbsenceMutationProvider";
import { DeleteAbsenceMutationProvider } from "__legacy/queries/absences/DeleteAbsenceMutationProvider";
import { UpdateAbsenceMutationProvider } from "__legacy/queries/absences/UpdateAbsenceMutationProvider";
import { Button, Col, Drawer, Form, InputNumber, Popconfirm, Row, Select, TimePicker } from "antd";
import { isString } from "lodash";
import moment from "moment";

export const AbsenceForm: React.FC<{
  employment: any;
  date: any;
  absence: any;
  visible: any;
  onClose: () => void;
  onCompleted: () => void;
}> = (props) => {
  const { visible, onClose, onCompleted, absence, employment, date } = props;
  const { getEnumOptions } = useApplicationContext();
  const [form] = Form.useForm();

  const [Mutation, params, variables] = absence
    ? [UpdateAbsenceMutationProvider, {}, { id: absence.id }]
    : [CreateAbsenceMutationProvider, { employmentId: employment.id }, {}];

  const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
    const input = {
      ...values,
      doneTime: undefined,
      doneOn: !absence ? moment(date + moment(values.doneTime).format("HH:mm"), "MM-DD-YYYY HH:mm") : undefined,
      ...params,
    };
    mutate({ variables: { ...variables, input } });
  };

  return (
    <Mutation onCompleted={onCompleted}>
      {(loading, mutate) => (
        <Drawer
          title={(absence ? "Edit" : "Add") + " Authorized Absence"}
          width={680}
          onClose={onClose}
          visible={visible}
          keyboard={false}
          maskClosable={false}
          footer={
            <Row>
              <Col flex={1}>
                {absence && (
                  <DeleteAbsenceMutationProvider
                    onCompleted={() => {
                      onClose();
                      window.location.reload();
                    }}
                  >
                    {(loading, mutate) => (
                      <Popconfirm
                        title="Are you sure?"
                        placement="top"
                        icon={<QuestionCircleOutlined />}
                        onConfirm={() => mutate({ variables: { id: absence.id } })}
                      >
                        <Button danger loading={loading} style={{ marginRight: 8 }}>
                          Delete
                        </Button>
                      </Popconfirm>
                    )}
                  </DeleteAbsenceMutationProvider>
                )}
              </Col>
              <Col>
                <Button onClick={onClose} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button type="primary" loading={loading} onClick={() => form.submit()}>
                  Save
                </Button>
              </Col>
            </Row>
          }
        >
          <Form layout="vertical" form={form} initialValues={absence} onFinish={onFinish(mutate, params, variables)}>
            <Row gutter={[24, 20]}>
              <Col span={12}>
                <Form.Item name="kind" label="Kind" rules={[{ required: true, message: "is required" }]}>
                  <Select
                    showSearch
                    optionLabelProp="label"
                    filterOption={(input, option) => {
                      let label = "";
                      if (option?.label && isString(option.label)) label = option.label;
                      let value = option?.value;
                      return (
                        label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {getEnumOptions("absenceKinds")}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="hours" label="Hours" rules={[{ required: true, message: "is required" }]}>
                  <InputNumber min={0.25} max={12} step={0.25} />
                </Form.Item>
              </Col>
              {!absence && (
                <Col span={6}>
                  <Form.Item name="doneTime" label="Done Time" rules={[{ required: true, message: "is required" }]}>
                    <TimePicker format="HH:mm" allowClear={false} />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Form>
        </Drawer>
      )}
    </Mutation>
  );
};
