import { Reviews } from "__legacy/frames/shareds/collaborators/Reviews";
import { CandidacyQueryProvider } from "__legacy/queries/candidacies/CandidacyQueryProvider";
import { Card, Layout } from "antd";
import { useParams } from "react-router-dom";

export const Show: React.FC<{ agency?: true }> = () => {
  const { candidacyId } = useParams();

  return (
    <CandidacyQueryProvider variables={{ id: candidacyId }}>
      {({ candidacy }) => (
        <Layout.Content className="content">
          <Card>
            <Reviews reviews={candidacy.candidacyReviews} />
          </Card>
        </Layout.Content>
      )}
    </CandidacyQueryProvider>
  );
};
