import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  ProposeProjectMutation as ProposeProjectMutationType,
  ProposeProjectMutationVariables,
} from "./__generated__/ProposeProjectMutation";

const ProposeProjectMutation = loader("./ProposeProjectMutation.gql");

type ProposeProjectMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted?: (data: ProposeProjectMutationType) => void;
}) => ReactElement;

export const ProposeProjectMutationProvider: ProposeProjectMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<ProposeProjectMutationType, ProposeProjectMutationVariables>(
    ProposeProjectMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
