import { CloudUploadOutlined } from "@ant-design/icons";
import { Alert, Col, DatePicker, Form, Input, InputNumber, Row, Space, Switch } from "antd";
import { FormInstance } from "antd/lib/form";
import Dragger from "antd/lib/upload/Dragger";
import moment from "moment";
import { Fragment, useCallback } from "react";

const formatDate = (date: any) => {
  return moment(date, "MM-DD-YYYY").isValid() ? moment(date, "MM-DD-YYYY").format("YYYY-MM-DD") : null;
};

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const input = {
    package: {
      ...values,
      effectiveAuditOn: formatDate(values.effectiveAuditOn),
      reportReceivedOn: formatDate(values.reportReceivedOn),
      packageCompletedOn: formatDate(values.packageCompletedOn),
      report: values.report?.file ?? null,
      ...params,
    },
  };

  mutate({ variables: { ...variables, input } });
};

export const VisitPackageForm: React.FC<{
  form: FormInstance<any>;
  onFinish: (values: any) => void;
  visitPackage: any;
  errors: string[];
}> = (props) => {
  const { form, visitPackage, onFinish, errors } = props;

  const initialValues = {
    ...visitPackage,
    auditAsPlanned: visitPackage.auditAsPlanned ?? true,
    effectiveAuditOn: visitPackage.effectiveAuditOn ? moment(visitPackage.effectiveAuditOn, "YYYY-MM-DD") : null,
    reportReceivedOn: visitPackage.reportReceivedOn ? moment(visitPackage.reportReceivedOn, "YYYY-MM-DD") : null,
    packageCompletedOn: visitPackage.packageCompletedOn ? moment(visitPackage.packageCompletedOn, "YYYY-MM-DD") : null,
    report: visitPackage.report ? [{ url: visitPackage.report, name: "Download" }] : [],
  };

  const onValuesChange = useCallback(
    (changedValues, allValues) => {
      const fieldValues: any[] = [];
      if (changedValues.report && changedValues.report.file.status === "removed") {
        fieldValues.push({ name: ["report"], value: null });
      }
      form.setFields(fieldValues);
    },
    [form],
  );

  const uploadProps = {
    maxCount: 1,
    accept: ".pdf, .doc, .xls, .xlsx",
    beforeUpload: () => {
      return false;
    },
  };

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
    >
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Row gutter={[24, 0]}>
        <Col span={12}>
          <Form.Item name="auditAsPlanned" label="Audit Performed as Planned" valuePropName={"checked"}>
            <Switch />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item dependencies={["auditAsPlanned"]}>
            {(form: FormInstance) => (
              <Fragment>
                {!form.getFieldValue("auditAsPlanned") && (
                  <Form.Item
                    name="effectiveAuditOn"
                    label="Visit Date"
                    rules={[{ required: true, message: "is required" }]}
                  >
                    <DatePicker mode="date" format="MM-DD-YYYY" style={{ width: "100%" }} />
                  </Form.Item>
                )}
              </Fragment>
            )}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item dependencies={["auditAsPlanned"]}>
            {(form: FormInstance) => (
              <Fragment>
                {!form.getFieldValue("auditAsPlanned") && (
                  <Form.Item name="effectiveAuditJustification" label="Justification">
                    <Input.TextArea rows={2} />
                  </Form.Item>
                )}
              </Fragment>
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="carRaised" label="CAR Raised" valuePropName={"checked"}>
            <Switch />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="obsRaised" label="Obs Raised" valuePropName={"checked"}>
            <Switch />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item dependencies={["obsRaised"]}>
            {(form: FormInstance) => (
              <Fragment>
                {form.getFieldValue("obsRaised") && (
                  <Form.Item name="obs" label="Total">
                    <InputNumber min={0} style={{ width: "100%" }} />
                  </Form.Item>
                )}
              </Fragment>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="reportReceivedOn"
            label="Date Report Received from auditor"
            rules={[{ required: true, message: "is required" }]}
          >
            <DatePicker mode="date" format="MM-DD-YYYY" style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="packageCompletedOn"
            label="Date Package Completed"
            rules={[{ required: true, message: "is required" }]}
          >
            <DatePicker mode="date" format="MM-DD-YYYY" style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="applicabilityStatement" label="Statement of Applicability">
            <Input.TextArea rows={2} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="report" valuePropName={"defaultFileList"}>
            <Dragger {...uploadProps}>
              <CloudUploadOutlined />
              Upload Assessment File
            </Dragger>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
