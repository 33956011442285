import { gql, useQuery } from "@apollo/client";
import { Card, Col, Input, Row, Select, Switch } from "antd";
import { isString } from "lodash";
import { useReducer } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router";

export const ProductForm: React.FC<{}> = () => {
  const { agencyId, projectId } = useParams();

  const [state, dispatch] = useReducer(reducer, {
    sampling: true,
  });

  const [stakeholders, schemes, reasons, certificators] = useDataForProject(agencyId);
  useProject(agencyId, projectId, dispatch);

  console.log(certificators);

  const scheme = schemes?.find((scheme: any) => scheme.id === state.schemeId) ?? { accreditations: [] };

  const { handleSubmit, control, watch } = useForm({ defaultValues: state });
  const onSubmit = (data: any) => console.log("Submit: ", data);
  const fields = watch();

  // console.log("params.agencyId: ", agencyId);
  // console.log("params.projectId: ", projectId);
  // console.log("stakeholders: ", stakeholders);
  // console.log("schemes: ", schemes);
  // console.log("project: ", project);
  console.log("state: ", state);
  console.log("fields: ", fields);
  // console.log("scheme: ", scheme);

  return (
    <Card>
      <form className="ant-form ant-form-vertical" onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={[24, 0]}>
          <Col span={8}>
            <Controller
              as={
                <Select {...selectOptions}>
                  {stakeholders.map((stakeholder: any) => (
                    <Select.Option key={stakeholder.id} value={stakeholder.id} label={stakeholder.companyName}>
                      {stakeholder.companyName}
                    </Select.Option>
                  ))}
                </Select>
              }
              name="subjectId"
              control={control}
            />
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={8}>
            <Field label="Customer" required>
              <Select
                {...selectOptions}
                key={state.subjectId}
                defaultValue={state.subjectId}
                onChange={(value) => dispatch({ type: "subjectChanged", value })}
              >
                {stakeholders.map((stakeholder: any) => (
                  <Select.Option key={stakeholder.id} value={stakeholder.id} label={stakeholder.companyName}>
                    {stakeholder.companyName}
                  </Select.Option>
                ))}
              </Select>
            </Field>
          </Col>
          <Col span={8}>
            <Field label="Scheme" required>
              <Select
                {...selectOptions}
                key={state.subjectId}
                defaultValue={state.schemeId}
                onChange={(value) => dispatch({ type: "schemeChanged", value })}
              >
                {schemes.map((scheme: any) => (
                  <Select.Option key={scheme.id} value={scheme.id} label={scheme.name}>
                    {scheme.name}
                  </Select.Option>
                ))}
              </Select>
            </Field>
          </Col>
          <Col span={8}>
            <Field label="Accreditation" required>
              <Select
                {...selectOptions}
                key={state.accreditationId}
                defaultValue={state.accreditationId}
                onChange={(value) => dispatch({ type: "accreditationChanged", value })}
              >
                {scheme.accreditations.map((accreditation: any) => (
                  <Select.Option key={accreditation.id} value={accreditation.id} label={accreditation.authority.name}>
                    {accreditation.authority.name}
                  </Select.Option>
                ))}
              </Select>
            </Field>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={8}>
            <Field label="Project Type" required>
              <Select
                key={state.reason}
                defaultValue={state.reason}
                onChange={(value) => dispatch({ type: "reasonChanged", value })}
              >
                {reasons.map((reason: any) => (
                  <Select.Option key={reason.name} value={reason.name} label={reason.description}>
                    {reason.description}
                  </Select.Option>
                ))}
              </Select>
            </Field>
          </Col>
          <Col span={16}>
            <Field label="Scope" required>
              <Input.TextArea
                key={state.scope}
                defaultValue={state.scope}
                rows={1}
                onBlur={({ target: { value } }) => dispatch({ type: "scopeChanged", value })}
              />
            </Field>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={2}>
            <Field label="Sampling">
              <Switch
                key={state.sampling}
                checked={state.sampling}
                onChange={(value) => dispatch({ type: "samplingChanged", value })}
              />
            </Field>
          </Col>
        </Row>
      </form>
    </Card>
  );
};

const reducer = (state: any, action: any): any => {
  switch (action.type) {
    case "projectLoaded":
      return action.value;

    case "subjectChanged":
      return { ...state, subjectId: action.value };

    case "schemeChanged":
      return { ...state, schemeId: action.value, accreditationId: undefined };

    case "accreditationChanged":
      return { ...state, accreditationId: action.value };

    case "reasonChanged":
      return { ...state, reason: action.value };

    case "scopeChanged":
      return { ...state, scope: action.value };

    default:
      throw new Error(`Strange reducer action payload: ${action}`);
  }
};

const filterOption = (input: any, option: any) => {
  let label = "";
  if (option?.label && isString(option.label)) label = option.label;
  let value = option?.value;
  return label.toLowerCase().indexOf(input.toLowerCase()) >= 0 || value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const selectOptions = { showSearch: true, optionLabelProp: "label", filterOption };

const Field: React.FC<{ label: string; required?: boolean; errors?: string[] }> = (props) => {
  const { label, required, errors, children } = props;
  const hasErrors = (errors?.length ?? 0) > 0;

  return (
    <div className={`ant-row ant-form-item ${hasErrors ? "ant-form-item-with-help ant-form-item-has-error" : ""}`}>
      <div className="ant-col ant-form-item-label">
        <label className={required ? "ant-form-item-required" : ""} title={label}>
          {label}
        </label>
      </div>
      <div className="ant-col ant-form-item-control">
        <div className="ant-form-item-control-input">
          <div className="ant-form-item-control-input-content">{children}</div>
        </div>
        {hasErrors && (
          <div className="ant-form-item-explain ant-form-item-explain-error">
            <div role="alert">{errors?.join(", ")}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const useDataForProject = (agencyId: string) => {
  const variables = { agencyId, stakeholdersKind: "customer", schemesKind: "system" };
  const result = useQuery(DATA_FOR_PROJECT_QUERY, { variables });
  const data = result.data ?? {};

  // console.log("stakeholdersQueryResult.data.stakeholders: ", stakeholders);

  return [
    data.stakeholders ?? [],
    data.schemes ?? [],
    data.reasons?.enumValues ?? [],
    data.certificators?.enumValues ?? [],
  ];
};

const DATA_FOR_PROJECT_QUERY = gql`
  query DataForProjectQuery($agencyId: ID!, $stakeholdersKind: StakeholderKind, $schemesKind: CertificationKind) {
    stakeholders(agencyId: $agencyId, kind: $stakeholdersKind) {
      id
      companyName
    }

    schemes(kind: $schemesKind) {
      id
      name
      accreditations {
        id
        authority {
          id
          name
        }
      }
    }

    reasons: __type(name: "ProjectReason") {
      enumValues {
        name
        description
      }
    }

    certificators: __type(name: "ProjectCertificator") {
      enumValues {
        name
        description
      }
    }
  }
`;

const useProject = (agencyId: string, projectId: string, dispatch: React.Dispatch<any>) => {
  const isAnEdit = projectId !== undefined;

  const projectQueryVariables = { id: projectId };
  const loadHandler = (data: any) => {
    // console.log("project.onCompleted: ", data);

    const project = data?.project ?? false;

    project &&
      dispatch({
        type: "projectLoaded",
        value: {
          subjectId: project.subject?.id,
          schemeId: project.scheme?.id,
          accreditationId: project.accreditation?.id,
          reason: project.reason,
          scope: project.scope,
          sampling: project.sampling,
        },
      });
  };

  useQuery(PROJECT_QUERY, {
    skip: !isAnEdit,
    variables: projectQueryVariables,
    onCompleted: loadHandler,
  });
};

const PROJECT_QUERY = gql`
  query ProjectQuery($id: ID!) {
    project(id: $id) {
      id
      reason
      scope
      sampling
      subject {
        id
      }
      scheme {
        id
      }
      accreditation {
        id
      }
    }
  }
`;
