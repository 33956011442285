import { ProjectCertificatorsQueryProvider } from "__legacy/queries/enums/ProjectCertificatorsQueryProvider";
import { UpdateReductionsMutationProvider } from "__legacy/queries/projects/UpdateReductionsMutationProvider";
import { Alert, Button, Col, Divider, Drawer, Form, Input, InputNumber, Row, Select, Space, Switch, Typography } from "antd";
import { FormInstance } from "antd/lib/form";
import { isNumber, isString, keys, toNumber } from "lodash";
import { Fragment, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router";

const { Title } = Typography;

export const PwsReductionsForm: React.FC<{
  visible: any;
  onClose: () => void;
  project: any;
}> = (props) => {
  const { project, visible, onClose } = props;
  const { id } = useParams();
  const [form] = Form.useForm();

  const reductions = useMemo<any>(() => {
    return {
      certifiedBy: { aja: 10, other: 5 },
      automationReduction: 10,
      msReduction: 10,
      qms83: 20,
      qms7152: 5,
      qms84: 5,
      qms853: 5,
      qms855: 5,
      mdms73: 20,
    };
  }, []);

  const [totalReduction, setTotalReduction] = useState(project.totalReductionPercentage ?? 0);
  const max = 30;

  const onValuesChange = useCallback(
    (changedValues, allValues) => {
      let tot: number = 0;
      keys(allValues).forEach((field: any) => {
        const value = allValues[field];
        const reduction = reductions[field];
        if (reduction && value) {
          if (isNumber(reduction)) tot += reduction;
          else if (field === "certifiedBy" && allValues.certifiedReduction) tot += reduction[value];
        } else if (field === "otherPercentage" && allValues.otherReduction) tot += toNumber(value);
      });
      setTotalReduction(tot);
    },
    [reductions],
  );

  const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
    const input = {
      ...values,
      otherPercentage: toNumber(values.otherPercentage),
      integratedMsPercentage: toNumber(values.integratedMsPercentage),
      totalReductionPercentage: totalReduction,
      ...params,
    };
    mutate({ variables: { ...variables, input } });
  };

  const [errors, setErrors] = useState<string[]>([]);
  const onError = () => ({ graphQLErrors }: any) => {
    const errors: string[] = [];
    graphQLErrors.forEach((graphQLError: any) => {
      errors.push(graphQLError.message);
    });
    setErrors(errors);
  };

  return (
    <UpdateReductionsMutationProvider onError={onError}>
      {(loading, mutate) => (
        <Drawer
          title="Reductions"
          width={680}
          onClose={onClose}
          visible={visible}
          keyboard={false}
          maskClosable={false}
          footer={
            <Row>
              <Col flex={1}>
                <Alert
                  message={`Total Reductions: ${totalReduction}%  ${
                    totalReduction > max ? ` Max applyable: ${max}%` : ""
                  }`}
                  type={totalReduction > max ? "error" : "info"}
                  showIcon
                />
              </Col>
              <Col
                span={10}
                style={{
                  textAlign: "right",
                }}
              >
                <Button onClick={onClose} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button type="primary" loading={loading} onClick={() => form.submit()}>
                  Save
                </Button>
              </Col>
            </Row>
          }
        >
          <Form
            layout="vertical"
            form={form}
            initialValues={project}
            onValuesChange={onValuesChange}
            onFinish={onFinish(mutate, {}, { id })}
          >
            {errors.length > 0 && (
              <Space direction="vertical" style={{ marginBottom: "10px" }}>
                {errors.map((error, index) => (
                  <Alert key={index} type="error" message="Error" description={error} />
                ))}
              </Space>
            )}
            <Row gutter={[24, 0]}>
              <Col span={4}>
                <Form.Item name="certifiedReduction" label="Certified" valuePropName={"checked"}>
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={20}>
                <ProjectCertificatorsQueryProvider>
                  {({ projectCertificators }) => (
                    <Form.Item label="Certified By" dependencies={["certifiedReduction"]}>
                      {(form: FormInstance) => (
                        <Form.Item name="certifiedBy">
                          <Select
                            disabled={!form.getFieldValue("certifiedReduction")}
                            showSearch
                            optionLabelProp="label"
                            filterOption={(input, option) => {
                              let label = "";
                              if (option?.label && isString(option.label)) label = option.label;
                              let value = option?.value;
                              return (
                                label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {projectCertificators?.enumValues?.map((certificator: any) => (
                              <Select.Option
                                key={certificator.name}
                                value={certificator.name}
                                label={certificator.description}
                              >
                                {certificator.description}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                    </Form.Item>
                  )}
                </ProjectCertificatorsQueryProvider>
              </Col>
              <Col span={4}>
                <Form.Item name="automationReduction" label="Automation" valuePropName={"checked"}>
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={20}>
                <Form.Item label="Automation info" dependencies={["automationReduction"]}>
                  {(form: FormInstance) => (
                    <Form.Item name="automationInfo">
                      <Input.TextArea disabled={!form.getFieldValue("automationReduction")} rows={2} />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col span={4}>
                <Form.Item name="msReduction" label="MS Maturity" valuePropName={"checked"}>
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={20}>
                <Form.Item label="MS info" dependencies={["msReduction"]}>
                  {(form: FormInstance) => (
                    <Form.Item name="msInfo">
                      <Input.TextArea disabled={!form.getFieldValue("msReduction")} rows={2} />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="otherReduction" label="Other" valuePropName={"checked"}>
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Percentage" dependencies={["otherReduction"]}>
                  {(form: FormInstance) => (
                    <Form.Item name="otherPercentage">
                      <InputNumber
                        min={0}
                        step={5}
                        max={20}
                        formatter={(value) => (value ? `${value}%` : "")}
                        disabled={!form.getFieldValue("otherReduction")}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item label="Other info" dependencies={["otherReduction"]}>
                  {(form: FormInstance) => (
                    <Form.Item name="otherInfo">
                      <Input.TextArea rows={3} disabled={!form.getFieldValue("otherReduction")} />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              {project.scheme.mandaysKind === "qms" && (
                <Fragment>
                  <Divider />
                  <Col span={24}>
                    <Title level={5}>QMS Exclusions</Title>
                  </Col>
                  <Col span={4}>
                    <Form.Item name="qms83" label="8 3" valuePropName={"checked"}>
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name="qms7152" label="7 1 5 2" valuePropName={"checked"}>
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name="qms84" label="8 4" valuePropName={"checked"}>
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name="qms853" label="8 5 3" valuePropName={"checked"}>
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name="qms855" label="8 5 5" valuePropName={"checked"}>
                      <Switch />
                    </Form.Item>
                  </Col>
                </Fragment>
              )}
              {project.scheme.mandaysKind === "mdms" && (
                <Fragment>
                  <Divider />
                  <Col span={24}>
                    <Title level={5}>MDMS Exclusions</Title>
                  </Col>
                  <Col span={4}>
                    <Form.Item name="mdms73" label="7 3" valuePropName={"checked"}>
                      <Switch />
                    </Form.Item>
                  </Col>
                </Fragment>
              )}
              {/* <Divider />
              <Col span={5}>
                <Form.Item name="integratedMs" label="Integrated MS" valuePropName={"checked"}>
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Percentage" dependencies={["integratedMs"]}>
                  {(form: FormInstance) => (
                    <Form.Item name="integratedMsPercentage">
                      <InputNumber
                        min={0}
                        step={5}
                        max={20}
                        formatter={(value) => (value ? `${value}%` : "")}
                        disabled={!form.getFieldValue("integratedMs")}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col> */}
            </Row>
          </Form>
        </Drawer>
      )}
    </UpdateReductionsMutationProvider>
  );
};
