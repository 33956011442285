import { DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { AbilitiesQueryProvider } from "__legacy/queries/abilities/AbilitiesQueryProvider";
import { ContactsQueryProvider } from "__legacy/queries/contacts/ContactsQueryProvider";
import { LocaleCountriesQueryProvider } from "__legacy/queries/enums/LocaleCountriesQueryProvider";
import { ExperiencesQueryProvider } from "__legacy/queries/experiences/ExperiencesQueryProvider";
import { ProjectQueryProvider } from "__legacy/queries/projects/ProjectQueryProvider";
import { SitesQueryProvider } from "__legacy/queries/sites/SitesQueryProvider";
import { StakeholderQueryProvider } from "__legacy/queries/stakeholders/StakeholderQueryProvider";
import { TrainingsQueryProvider } from "__legacy/queries/trainings/TrainingsQueryProvider";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Image,
  Layout,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { find } from "lodash";
import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";

const deleteContactMutation = gql`
  mutation($id: ID!) {
    deleteContact(id: $id) {
      contact {
        id
        firstname
        lastname
      }
    }
  }
`;
const deleteSiteMutation = gql`
  mutation($id: ID!) {
    deleteSite(id: $id) {
      site {
        id
        name
      }
    }
  }
`;

export const Show: React.FC<{}> = () => {
  const { agencyId, id } = useParams();
  const navigate = useNavigate();

  const [execDeleteContact] = useMutation(deleteContactMutation, {
    errorPolicy: "none",
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
    onCompleted: ({ deleteContact: { contact } }) => {
      notification.success({
        message: `Contact "${contact.firstname} ${contact.lastname}" deleted`,
      });
    },
    refetchQueries: ["ContactsQuery"],
  });
  const [execDeleteSite] = useMutation(deleteSiteMutation, {
    errorPolicy: "none",
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
    onCompleted: ({ deleteSite: { site } }) => {
      notification.success({
        message: `Site "${site.name}" deleted`,
      });
    },
    refetchQueries: ["SitesQuery"],
  });

  return (
    <StakeholderQueryProvider variables={{ id }}>
      {({ stakeholder }) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title={`Stakeholder ${stakeholder.companyName}`}
            extra={
              <Fragment>
                <Button onClick={() => navigate("../")}>Back to list</Button>
                <Button type="primary" onClick={() => navigate("./edit")}>
                  Edit
                </Button>
              </Fragment>
            }
          />
          <Layout.Content className="content">
            <Card>
              <Descriptions column={2}>
                {stakeholder.website && (
                  <Fragment>
                    <Descriptions.Item label="Website">
                      <a href={stakeholder.website} target="_blank" rel="noreferrer">
                        {stakeholder.website}
                      </a>
                    </Descriptions.Item>
                    <br />
                  </Fragment>
                )}
                <Descriptions.Item label="Lastname">{stakeholder.lastname}</Descriptions.Item>
                <Descriptions.Item label="Firstname">{stakeholder.firstname}</Descriptions.Item>
                <Descriptions.Item label="Phone">{stakeholder.phoneNumber}</Descriptions.Item>
                <Descriptions.Item label="Email">{stakeholder.email}</Descriptions.Item>
                {stakeholder.pec && <Descriptions.Item label="PEC">{stakeholder.pec}</Descriptions.Item>}
                {stakeholder.sdi && <Descriptions.Item label="SDI">{stakeholder.sdi}</Descriptions.Item>}
                {stakeholder.vatCode && <Descriptions.Item label="Vat Code">{stakeholder.vatCode}</Descriptions.Item>}
                {stakeholder.vatCodeEu && (
                  <Descriptions.Item label="Vat Code EU">{stakeholder.vatCodeEu}</Descriptions.Item>
                )}
                {stakeholder.ibanCode && <Descriptions.Item label="IBAN">{stakeholder.ibanCode}</Descriptions.Item>}
                {stakeholder.faxNumber && <Descriptions.Item label="Fax">{stakeholder.faxNumber}</Descriptions.Item>}
                {stakeholder.fiscalCode && (
                  <Descriptions.Item label="Fiscal Code">{stakeholder.fiscalCode}</Descriptions.Item>
                )}
                {stakeholder.referentRole && (
                  <Descriptions.Item label="Referent Role">{stakeholder.referentRole}</Descriptions.Item>
                )}
                {stakeholder.consultantName && (
                  <Descriptions.Item label="Consultant Name">{stakeholder.consultantName}</Descriptions.Item>
                )}
                {stakeholder.notes && <Descriptions.Item label="Notes">{stakeholder.notes}</Descriptions.Item>}
                <Descriptions.Item label="Address">{stakeholder.address}</Descriptions.Item>
                <Descriptions.Item label="City">{stakeholder.city}</Descriptions.Item>
                <Descriptions.Item label="Zip Code">{stakeholder.zipCode}</Descriptions.Item>
                <Descriptions.Item label="State">{stakeholder.state}</Descriptions.Item>
                <Descriptions.Item label="Country">{stakeholder.country}</Descriptions.Item>
                <Descriptions.Item label="Is enabled">{stakeholder.isEnabled ? "yes" : "no"}</Descriptions.Item>
                {stakeholder.avatar && (
                  <Descriptions.Item label="Avatar">
                    <Image src={stakeholder.avatar} width={200} />
                  </Descriptions.Item>
                )}
                {stakeholder.nda && (
                  <Descriptions.Item label="NdA">
                    <a href={stakeholder.nda}>Download</a>
                  </Descriptions.Item>
                )}
                {stakeholder.sca && (
                  <Descriptions.Item label="SCA">
                    <a href={stakeholder.sca}>Download</a>
                  </Descriptions.Item>
                )}
                {stakeholder.cv && (
                  <Descriptions.Item label="CV">
                    <a href={stakeholder.cv}>Download</a>
                  </Descriptions.Item>
                )}
                {stakeholder.degree && (
                  <Descriptions.Item label="Degree">
                    <a href={stakeholder.degree}>Download</a>
                  </Descriptions.Item>
                )}
              </Descriptions>
              <Divider />
              <Tabs type="card">
                <Tabs.TabPane tab="Contacts" key="CONTACTS">
                  <Space align="baseline" style={{ marginBottom: "2rem" }}>
                    <Typography.Title level={5}>Contacts</Typography.Title>
                    <Button size="small" icon={<PlusOutlined />} onClick={() => navigate("./contacts/new")}>
                      Add
                    </Button>
                  </Space>
                  <ContactsQueryProvider variables={{ stakeholderId: id }}>
                    {({ contacts }) => (
                      <Row gutter={[16, 16]}>
                        {contacts.map((contact) => (
                          <Col key={contact.id} span={8}>
                            <Card
                              title={`${contact.lastname} ${contact.firstname}`}
                              actions={[
                                <EditOutlined onClick={() => navigate(`./contacts/${contact.id}/edit`)} />,
                                <Popconfirm
                                  title="Are you sure?"
                                  icon={<QuestionCircleOutlined />}
                                  onConfirm={() => execDeleteContact({ variables: { id: contact.id } })}
                                >
                                  <Tooltip title="Delete" placement="bottom">
                                    <DeleteOutlined style={{ color: "#ff4d4f" }} />
                                  </Tooltip>
                                </Popconfirm>,
                              ]}
                            >
                              {contact.lastname && (
                                <Fragment>
                                  Lastname: {contact.lastname}
                                  <br />
                                </Fragment>
                              )}
                              {contact.firstname && (
                                <Fragment>
                                  Firstname: {contact.firstname}
                                  <br />
                                </Fragment>
                              )}
                              {contact.role && (
                                <Fragment>
                                  Role: {contact.role}
                                  <br />
                                </Fragment>
                              )}
                              {contact.phoneNumber && (
                                <Fragment>
                                  Phone: {contact.phoneNumber}
                                  <br />
                                </Fragment>
                              )}
                              {contact.mobileNumber && (
                                <Fragment>
                                  Mobile: {contact.mobileNumber}
                                  <br />
                                </Fragment>
                              )}
                              {contact.faxNumber && (
                                <Fragment>
                                  Fax: {contact.faxNumber}
                                  <br />
                                </Fragment>
                              )}
                              {contact.email && (
                                <Fragment>
                                  Email: {contact.email}
                                  <br />
                                </Fragment>
                              )}
                              {contact.emailPrivate && (
                                <Fragment>
                                  Private Email: {contact.emailPrivate}
                                  <br />
                                </Fragment>
                              )}
                              {contact.notes && (
                                <Fragment>
                                  <br />
                                  <strong>Notes</strong>
                                  <br />
                                  {contact.notes}
                                </Fragment>
                              )}
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    )}
                  </ContactsQueryProvider>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Sites" key="SITES">
                  <Space align="baseline" style={{ marginBottom: "2rem" }}>
                    <Typography.Title level={5}>Sites</Typography.Title>
                    <Button size="small" icon={<PlusOutlined />} onClick={() => navigate("./sites/new")}>
                      Add
                    </Button>
                  </Space>
                  <LocaleCountriesQueryProvider>
                    {({ localeCountries }) => (
                      <SitesQueryProvider variables={{ agencyId, stakeholderId: id }}>
                        {({ sites }) => (
                          <Row gutter={[16, 16]}>
                            {sites.map((site) => (
                              <Col key={site.id} span={8}>
                                <Card
                                  title={
                                    <Space>
                                      {site.isHead && <Tag color="blue">HEAD</Tag>}
                                      {site.name}
                                    </Space>
                                  }
                                  actions={[
                                    <EditOutlined onClick={() => navigate(`./sites/${site.id}/edit`)} />,
                                    <Popconfirm
                                      title="Are you sure?"
                                      icon={<QuestionCircleOutlined />}
                                      onConfirm={() => execDeleteSite({ variables: { id: site.id } })}
                                    >
                                      <Tooltip title="Delete" placement="bottom">
                                        <DeleteOutlined style={{ color: "#ff4d4f" }} />
                                      </Tooltip>
                                    </Popconfirm>,
                                  ]}
                                >
                                  <strong>Address</strong>
                                  <br />
                                  {site.address}
                                  <br />
                                  {site.zipCode}
                                  <br />
                                  {site.city} - {site.state}
                                  <br />
                                  {find(
                                    localeCountries?.enumValues ?? [],
                                    (localeCountry) => localeCountry.name === site.country,
                                  )?.description?.toUpperCase()}
                                  <br />
                                  <br />
                                  {site.contactName && (
                                    <Fragment>
                                      Contact Name: {site.contactName}
                                      <br />
                                    </Fragment>
                                  )}
                                  {site.phoneNumber && (
                                    <Fragment>
                                      Phone: {site.phoneNumber}
                                      <br />
                                    </Fragment>
                                  )}
                                  {site.faxNumber && (
                                    <Fragment>
                                      Fax: {site.faxNumber}
                                      <br />
                                    </Fragment>
                                  )}
                                  {site.notes && (
                                    <Fragment>
                                      <br />
                                      <strong>Notes</strong>
                                      <br />
                                      {site.notes}
                                    </Fragment>
                                  )}
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        )}
                      </SitesQueryProvider>
                    )}
                  </LocaleCountriesQueryProvider>
                </Tabs.TabPane>
                {stakeholder.isCollaborator && (
                  <Tabs.TabPane tab="Auditor" key="COLLABORATOR">
                    <Space align="baseline" style={{ marginBottom: "2rem" }}>
                      <Typography.Title level={5}>Auditor</Typography.Title>
                      <Button
                        size="small"
                        icon={<EyeOutlined />}
                        onClick={() => navigate(`../../auditors/${stakeholder.id}`)}
                      >
                        Details
                      </Button>
                    </Space>
                    <AbilitiesQueryProvider variables={{ agencyId, stakeholderId: id }}>
                      {({ abilities }) => (
                        <Fragment>
                          <br />
                          Abilities: {abilities.length}
                          <br />
                        </Fragment>
                      )}
                    </AbilitiesQueryProvider>
                    <ExperiencesQueryProvider variables={{ agencyId, stakeholderId: id }}>
                      {({ experiences }) => (
                        <Fragment>
                          <br />
                          Experiences: {experiences.length}
                          <br />
                        </Fragment>
                      )}
                    </ExperiencesQueryProvider>
                    <TrainingsQueryProvider variables={{ agencyId, stakeholderId: id }}>
                      {({ trainings }) => (
                        <Fragment>
                          <br />
                          Trainings: {trainings.length}
                          <br />
                        </Fragment>
                      )}
                    </TrainingsQueryProvider>
                  </Tabs.TabPane>
                )}
                {!!stakeholder.projects?.length && (
                  <Tabs.TabPane tab="System Projects" key="PROJECTS">
                    <Row gutter={[16, 16]}>
                      {stakeholder.projects.map((project) => (
                        <Col key={project.id} span={8}>
                          <ProjectQueryProvider variables={{ id: project.id }}>
                            {({ project }) => {
                              let codes = 0;
                              project?.projectSites.forEach(
                                (ps: any) => (codes += ps.projectSiteClassifications.length),
                              );
                              return (
                                <Card
                                  title={project.code}
                                  actions={[
                                    <Button
                                      type="primary"
                                      ghost
                                      onClick={() =>
                                        navigate(
                                          `../../job-orders/system/${project.step}/${project.status}/${project.id}`,
                                        )
                                      }
                                    >
                                      Show
                                    </Button>,
                                  ]}
                                >
                                  <table>
                                    <tbody>
                                      <tr>
                                        <th>Scheme:</th>
                                        <td>{project.scheme?.name}</td>
                                      </tr>
                                      <tr>
                                        <th>Authority:</th>
                                        <td>{project.accreditation?.authority?.name}</td>
                                      </tr>
                                      <tr>
                                        <th>Classifications:</th>
                                        <td>{codes}</td>
                                      </tr>
                                      <tr>
                                        <th>Certified Sites:</th>
                                        <td>{project.projectSites?.length}</td>
                                      </tr>
                                      <tr>
                                        <th>PWS type:</th>
                                        <td>{project.reason}</td>
                                      </tr>
                                      <tr>
                                        <th>Step / Status:</th>
                                        <td>
                                          {project.step} / {project.status}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </Card>
                              );
                            }}
                          </ProjectQueryProvider>
                        </Col>
                      ))}
                    </Row>
                  </Tabs.TabPane>
                )}
              </Tabs>
            </Card>
          </Layout.Content>
        </Fragment>
      )}
    </StakeholderQueryProvider>
  );
};
