import { useQuery } from "@apollo/client";
import { CenterResult } from "__legacy/components/CenterResult";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  CertificationRiskLevelsQuery as CertificationRiskLevelsQueryType,
} from "./__generated__/CertificationRiskLevelsQuery";

const CertificationRiskLevelsQuery = loader("./CertificationRiskLevelsQuery.gql");

type CertificationRiskLevelsQueryProviderType = (props: {
  children: (data: CertificationRiskLevelsQueryType) => ReactNode;
}) => ReactElement;

export const CertificationRiskLevelsQueryProvider: CertificationRiskLevelsQueryProviderType = (props) => {
  const { children } = props;

  const { error, loading, data } = useQuery<CertificationRiskLevelsQueryType>(CertificationRiskLevelsQuery, {
    fetchPolicy: "cache-and-network",
  });

  if (loading) return <CenterSpinner size="large" />;
  if (error || !data)
    return <CenterResult status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;

  return <Fragment>{children(data)}</Fragment>;
};
