import { useQuery } from "@apollo/client";
import { CenterResult } from "__legacy/components/CenterResult";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import { RolesQuery as RolesQueryType, RolesQueryVariables } from "./__generated__/RolesQuery";

const RolesQuery = loader("./RolesQuery.gql");

type RolesQueryProviderType = (props: {
  children: (data: RolesQueryType) => ReactNode;
  variables: RolesQueryVariables;
}) => ReactElement;

export const RolesQueryProvider: RolesQueryProviderType = (props) => {
  const { children, variables } = props;

  const { error, loading, data } = useQuery<RolesQueryType, RolesQueryVariables>(RolesQuery, {
    variables,
    fetchPolicy: "cache-and-network",
  });

  if (loading) return <CenterSpinner size="large" />;
  if (error || !data)
    return <CenterResult status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;

  return <Fragment>{children(data)}</Fragment>;
};
