import { DeleteOutlined, EditOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { ExperiencesQueryProvider } from "__legacy/queries/experiences/ExperiencesQueryProvider";
import { Button, Card, Col, Layout, notification, PageHeader, Popconfirm, Row, Tooltip } from "antd";
import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";

const deleteExperienceMutation = gql`
  mutation($id: ID!) {
    deleteExperience(id: $id) {
      experience {
        id
        name
      }
    }
  }
`;

export const List: React.FC<{ agency?: true }> = (props) => {
  const { agency } = props;
  const { agencyId, stakeholderId } = useParams();
  const navigate = useNavigate();

  const [execDelete] = useMutation(deleteExperienceMutation, {
    errorPolicy: "none",
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
    onCompleted: ({ deleteExperience: { experience } }) => {
      notification.success({
        message: `Experience "${experience.name}" deleted`,
      });
    },
    refetchQueries: ["ExperiencesQuery"],
  });

  return (
    <ExperiencesQueryProvider variables={{ agencyId, stakeholderId }}>
      {({ experiences }) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title="Experiences"
            extra={
              agency && (
                <Button onClick={() => navigate("new")} type="primary">
                  <PlusOutlined />
                  New
                </Button>
              )
            }
          />
          <Layout.Content className="content">
            <Row gutter={[16, 16]}>
              {experiences.map((experience) => (
                <Col key={experience.id} lg={8}>
                  <Card
                    title={experience.name}
                    actions={
                      agency && [
                        <EditOutlined onClick={() => navigate(`${experience.id}/edit`)} />,
                        <Popconfirm
                          title="Are you sure?"
                          icon={<QuestionCircleOutlined />}
                          onConfirm={() => execDelete({ variables: { id: experience.id } })}
                        >
                          <Tooltip title="Delete" placement="bottom">
                            <DeleteOutlined style={{ color: "#ff4d4f" }} />
                          </Tooltip>
                        </Popconfirm>,
                      ]
                    }
                  >
                    <div style={{ minHeight: "200px" }}>
                      <strong>Institution: </strong>
                      <br />
                      {experience.institution}
                      <br />
                      <strong>Start year: </strong>
                      {experience.startYear}
                      <br />
                      <strong>End year: </strong>
                      {experience.endYear}
                      <br />
                      <strong>Qualification: </strong>
                      {experience.qualification}
                      <br />
                      <strong>Details: </strong>
                      {experience.details}
                      <br />
                      {experience.certificate && (
                        <Fragment>
                          <strong>Certificate: </strong>
                          <a href={experience.certificate} download>
                            Download
                          </a>
                        </Fragment>
                      )}
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </Layout.Content>
        </Fragment>
      )}
    </ExperiencesQueryProvider>
  );
};
