import { useQuery } from "@apollo/client";
import { CenterResult } from "__legacy/components/CenterResult";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  AccreditationsQuery as AccreditationsQueryType,
  AccreditationsQueryVariables,
} from "./__generated__/AccreditationsQuery";

const AccreditationsQuery = loader("./AccreditationsQuery.gql");

type AccreditationsQueryProviderType = (props: {
  children: (data: AccreditationsQueryType) => ReactNode;
  variables: AccreditationsQueryVariables;
}) => ReactElement;

export const AccreditationsQueryProvider: AccreditationsQueryProviderType = (props) => {
  const { children, variables } = props;

  const { error, loading, data } = useQuery<AccreditationsQueryType, AccreditationsQueryVariables>(
    AccreditationsQuery,
    {
      variables,
      fetchPolicy: "cache-and-network",
    },
  );

  if (loading) return <CenterSpinner size="large" />;
  if (error || !data)
    return <CenterResult status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;

  return <Fragment>{children(data)}</Fragment>;
};
