import { CreateRoleMutationProvider } from "__legacy/queries/roles/CreateRoleMutationProvider";
import { Button, Form, Layout, PageHeader } from "antd";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { onError, onFinish, RoleForm } from "./RoleForm";

export const New: React.FC<{ agency?: true }> = (props) => {
  const { agency } = props;
  const { agencyId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <CreateRoleMutationProvider onError={onError(form, setErrors)} onCompleted={() => navigate("../")}>
      {(loading, mutate) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title={`New role`}
            extra={
              <Fragment>
                <Button onClick={() => navigate("../")}>Back to list</Button>
                <Button type="primary" loading={loading} onClick={() => form.submit()}>
                  Save
                </Button>
              </Fragment>
            }
          />
          <Layout.Content className="content">
            <RoleForm
              action="create"
              form={form}
              handler={onFinish(mutate, { agencyId }, { agency })}
              values={{ agency }}
              errors={errors}
            />
          </Layout.Content>
        </Fragment>
      )}
    </CreateRoleMutationProvider>
  );
};
