import { Space, Timeline, Typography } from "antd";
import moment from "moment";
import { Fragment } from "react";

export const Reviews: React.FC<{ reviews: any[] }> = (props) => {
  const { reviews } = props;

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Timeline mode="left">
        {reviews.map((review) => (
          <Timeline.Item key={review.id}>
            <Space direction="vertical">
              {review.author}
              <Typography.Text type="secondary">{moment(review.insertedAt).format("MM-DD-YYYY HH:mm")}</Typography.Text>
              {review.content?.split("\n").map((s: any, i: any) => (
                <div key={i}>{s}</div>
              ))}
              {review.questions?.map((question: any, i: any) => (
                <Fragment key={i}>
                  {question.reply && (
                    <p>
                      {question.ask} <b>{question.reply}</b>
                    </p>
                  )}
                </Fragment>
              ))}
            </Space>
          </Timeline.Item>
        ))}
      </Timeline>
    </Space>
  );
};
