import { TrainingQueryProvider } from "__legacy/queries/trainings/TrainingQueryProvider";
import { UpdateTrainingMutationProvider } from "__legacy/queries/trainings/UpdateTrainingMutationProvider";
import { Button, Form, Layout, PageHeader } from "antd";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { onError, onFinish, TrainingForm } from "./TrainingForm";

export const Edit: React.FC<{}> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <TrainingQueryProvider variables={{ id: id }}>
      {({ training }) => (
        <UpdateTrainingMutationProvider onError={onError(form, setErrors)} onCompleted={() => navigate("../../")}>
          {(loading, mutate) => (
            <Fragment>
              <PageHeader
                ghost={true}
                title={`Edit training of ${training.name} for ${training.stakeholder.companyName}`}
                extra={
                  <Fragment>
                    <Button onClick={() => navigate("../../")}>Back to trainings</Button>
                    <Button type="primary" loading={loading} onClick={() => form.submit()}>
                      Save
                    </Button>
                  </Fragment>
                }
              />
              <Layout.Content className="content">
                <TrainingForm
                  action="update"
                  form={form}
                  handler={onFinish(mutate, {}, { id })}
                  values={{
                    ...training,
                    stakeholderId: training.stakeholder.id,
                  }}
                  errors={errors}
                />
              </Layout.Content>
            </Fragment>
          )}
        </UpdateTrainingMutationProvider>
      )}
    </TrainingQueryProvider>
  );
};
