import { useQuery } from "@apollo/client";
import { CenterResult } from "__legacy/components/CenterResult";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import { ProjectsQuery as ProjectsQueryType, ProjectsQueryVariables } from "./__generated__/ProjectsQuery";

const ProjectsQuery = loader("./ProjectsQuery.gql");

type ProjectsQueryProviderType = (props: {
  children: (data: ProjectsQueryType) => ReactNode;
  variables: ProjectsQueryVariables;
}) => ReactElement;

export const ProjectsQueryProvider: ProjectsQueryProviderType = (props) => {
  const { children, variables } = props;

  const { error, loading, data } = useQuery<ProjectsQueryType, ProjectsQueryVariables>(ProjectsQuery, {
    variables,
    fetchPolicy: "cache-and-network",
  });

  if (loading) return <CenterSpinner size="large" />;
  if (error || !data)
    return <CenterResult status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;

  return <Fragment>{children(data)}</Fragment>;
};
