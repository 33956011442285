import { Alert, Space, Tag, Typography } from "antd";
import { find, isNull } from "lodash";
import { Fragment } from "react";

const { Text } = Typography;

export const ProjectOverview: React.FC<{
  project: any;
}> = (props) => {
  const { project } = props;

  return (
    <Fragment>
      <table>
        <tbody>
          <tr>
            <td colSpan={2}>
              <Typography.Title level={2}>{project.code}</Typography.Title>
            </td>
          </tr>
          <tr>
            <th>Scheme:</th>
            <td>{project.scheme?.name}</td>
          </tr>
          <tr>
            <th>Authority:</th>
            <td>{project.accreditation?.authority?.name}</td>
          </tr>
          <tr>
            <th>PWS type:</th>
            <td>{project.reason}</td>
          </tr>
          <tr>
            <th>Customer:</th>
            <td>
              {project.subject.companyName}{" "}
              {project.subject.website && (
                <a href={project.subject.website} target="_blank" rel="noreferrer">
                  Website
                </a>
              )}
            </td>
          </tr>
          {project.subject.consultantName && (
            <tr>
              <th>Consultant:</th>
              <td>{project.subject.consultantName}</td>
            </tr>
          )}
          <tr>
            <th>Scope:</th>
            <td>{project.scope}</td>
          </tr>
          <tr>
            <th>Sampling:</th>
            <td>{project.sampling ? "Yes" : "No"}</td>
          </tr>
          <tr>
            <th>Certified Sites:</th>
            <td>{project.projectSites?.length}</td>
          </tr>
          {project.questionaire && (
            <tr>
              <th>Questionaire:</th>
              <td>
                <a href={project.questionaire} download>
                  Download
                </a>
              </td>
            </tr>
          )}
          {project.proposal && (
            <tr>
              <th>Proposal:</th>
              <td>
                <a href={project.proposal} download>
                  Download
                </a>
              </td>
            </tr>
          )}
          {project.contract && (
            <tr>
              <th>Contract:</th>
              <td>
                <a href={project.contract} download>
                  Download
                </a>
              </td>
            </tr>
          )}
          {project.contractInfo && (
            <tr>
              <th>Contract info:</th>
              <td>{project.contractInfo}</td>
            </tr>
          )}
        </tbody>
      </table>
      {project.scheme.mandaysKind === "fsms" && (
        <table>
          <tbody>
            <tr>
              <th>Number of the Extra HACCP's Plans:</th>
              <td>{project.fsmsExtraPlans}</td>
            </tr>
            <tr>
              <th>Certified for Other management Systems with AJA:</th>
              <td>{project.fsmsOtherCertifications ? "Yes" : "No"}</td>
            </tr>
          </tbody>
        </table>
      )}
      {project.scheme.mandaysKind === "enms" && (
        <table>
          <tbody>
            <tr>
              <th>Annual Total Energy Consumption in Kwh as declared on Questionnaire:</th>
              <td>{project.enmsEnergyConsumption}</td>
            </tr>
            <tr>
              <th>Number of Energy Sources based on Questionnaire:</th>
              <td>{project.enmsEnergySources}</td>
            </tr>
            <tr>
              <th>Number of Significant Energy Uses based on Questionnaire:</th>
              <td>{project.enmsEnergyUses}</td>
            </tr>
          </tbody>
        </table>
      )}
      {project.mandays[0]?.risk && (
        <Fragment>
          <br />
          <Tag color="warning">{project.mandays[0].risk.toUpperCase()} Risk Level</Tag>
          <br />
        </Fragment>
      )}
      <br />
      <Typography.Title level={4}>Audits</Typography.Title>
      {project.projectSites?.map((site: any, index: number) => (
        <Fragment key={index}>
          <table>
            <tbody>
              <tr>
                <th>Site:</th>
                <td>
                  <Space>
                    {site.isHead && <Tag color="blue">HEAD</Tag>}
                    {site.site?.fullAddress}
                  </Space>
                </td>
              </tr>
              <tr>
                <th>Employee No.:</th>
                <td>{site.employees}</td>
              </tr>
              <tr>
                <th>Effective Empl. No.:</th>
                <td>{site.employeesEffective}</td>
              </tr>
              {site.employeesJustification && (
                <tr>
                  <th>Justification:</th>
                  <td>{site.employeesJustification}</td>
                </tr>
              )}
              {site.differentScope && (
                <tr>
                  <th>Scope at this site:</th>
                  <td>{site.differentScope}</td>
                </tr>
              )}
              <tr>
                <th>Visit at:</th>
                <td>{site.visitAt.map((v: number) => (v > 0 ? `${v}° Surveillance` : "Main Audit")).join(", ")}</td>
              </tr>
              <tr>
                <th style={{ verticalAlign: "top" }}>Codes:</th>
                <td>
                  {site.projectSiteClassifications?.map((psc: any) => {
                    const code: any = {
                      label: `${psc.classification.industry.name} - ${psc.classification.name} - ${psc.classification.description}`,
                    };
                    if (!!psc.classification.candidacies.length) {
                      let agencies: any[] = [];
                      for (const candidacy of psc.classification.candidacies) {
                        if (candidacy.ability.stakeholder.agency.id === project.agency.id) {
                          agencies = [];
                          break;
                        } else agencies.push(candidacy.ability.stakeholder.agency.name);
                      }
                      if (!!agencies.length) {
                        code.type = "warning";
                        code.append = `[${agencies.join(" / ")}]`;
                      }
                    } else {
                      code.type = "danger";
                      code.append = "[No Auditors]";
                    }
                    return (
                      <Text key={psc.id} type={code.type}>
                        <Space size="small">
                          {code.label} {code.append}
                        </Space>
                        <br />
                      </Text>
                    );
                  })}
                </td>
              </tr>
              <tr>
                <th style={{ verticalAlign: "top" }}>Risk Level:</th>
                <td>{project.mandays[0].tips?.find((o: any) => o.siteId === site.site.id)?.risk.toUpperCase()}</td>
              </tr>
            </tbody>
          </table>
          <br />
        </Fragment>
      ))}
      {project.reason === "transfer" && (
        <Fragment>
          <Typography.Title level={4}>Transfer</Typography.Title>
          <table>
            <tbody>
              <tr>
                <th>Existing Cb:</th>
                <td>{project.existingAuthority}</td>
              </tr>
              <tr>
                <th>Existing Accreditation:</th>
                <td>{project.existingAccreditation}</td>
              </tr>
              <tr>
                <th>Cerfiticate Expiry Date:</th>
                <td>{project.existingCertificateExpiredOn}</td>
              </tr>
              <tr>
                <th>Original Cerfiticate Date:</th>
                <td>{project.existingCertificateIssuedOn}</td>
              </tr>
              {project.existingCertificate && (
                <tr>
                  <th>Existing Certificate:</th>
                  <td>
                    <a href={project.existingCertificate} download>
                      Download
                    </a>
                  </td>
                </tr>
              )}
              {project.existingAuthorityReport && (
                <tr>
                  <th>Previous CB Report:</th>
                  <td>
                    <a href={project.existingAuthorityReport} download>
                      Download
                    </a>
                  </td>
                </tr>
              )}
              <tr>
                <th>Transfer Reason:</th>
                <td>{project.transferReason}</td>
              </tr>
            </tbody>
          </table>
          <br />
        </Fragment>
      )}
      {project.reason !== "renewal" && (
        <Fragment>
          <Typography.Title level={4}>Assessment</Typography.Title>
          <table>
            <tbody>
              <tr>
                <th>Stage 1 {project.initialAssessmentOffsite ? "Off" : "On"} Site</th>
              </tr>
              {project.initialAssessmentOffsite && (
                <tr>
                  <th>Reason:</th>
                  <td>{project.initialAssessmentReason}</td>
                </tr>
              )}
            </tbody>
          </table>
          <br />
        </Fragment>
      )}
      <Typography.Title level={4}>
        <Space>
          Reductions
          {!isNull(project.totalReductionPercentage) && <Tag color="blue">{project.totalReductionPercentage}%</Tag>}
        </Space>
      </Typography.Title>
      <table>
        <tbody>
          <tr>
            <th>Certified:</th>
            <td>{project.certifiedReduction ? "Yes" : "No"}</td>
          </tr>
          {project.certifiedReduction && (
            <tr>
              <th>Certified by:</th>
              <td>{project.certifiedBy}</td>
            </tr>
          )}
          <tr>
            <th>Automation:</th>
            <td>{project.automationReduction ? "Yes" : "No"}</td>
          </tr>
          {project.automationReduction && (
            <tr>
              <th>Automation info:</th>
              <td>{project.automationInfo}</td>
            </tr>
          )}
          <tr>
            <th>Ms Maturity:</th>
            <td>{project.msReduction ? "Yes" : "No"}</td>
          </tr>
          {project.msReduction && (
            <tr>
              <th>Other:</th>
              <td>
                {project.otherPercentage}% {project.otherInfo}
              </td>
            </tr>
          )}
          {project.integratedMs && (
            <tr>
              <th>Integrated MS:</th>
              <td>{project.integratedMsPercentage}%</td>
            </tr>
          )}
          {project.scheme.mandaysKind === "qms" && (
            <tr>
              <th>QMS Exclusions:</th>
              <td>
                <Space size="large">
                  <span>
                    8 3: <b>{project.qms83 ? "Yes" : "No"}</b>
                  </span>
                  <span>
                    7 1 5 2: <b>{project.qms7152 ? "Yes" : "No"}</b>
                  </span>
                  <span>
                    8 4: <b>{project.qms84 ? "Yes" : "No"}</b>
                  </span>
                  <span>
                    8 5 3: <b>{project.qms853 ? "Yes" : "No"}</b>
                  </span>
                  <span>
                    8 5 5: <b>{project.qms855 ? "Yes" : "No"}</b>
                  </span>
                </Space>
              </td>
            </tr>
          )}
          {project.scheme.mandaysKind === "mdms" && (
            <tr>
              <th>MDMS Exclusions:</th>
              <td>
                7 3: <b>{project.mdms73 ? "Yes" : "No"}</b>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <br />
      <Typography.Title level={4}>Increases</Typography.Title>
      <table>
        <tbody>
          <tr>
            <th>Other:</th>
            <td>
              {project.increasesOther && (
                <Space>
                  <span>{project.increasesPercentage}%</span>
                  {project.increasesInfo}
                </Space>
              )}
              {!project.increasesOther && "No"}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <Typography.Title level={4}>Multi Standard</Typography.Title>
      <table>
        <tbody>
          <tr>
            <th>Reduction:</th>
            <td>
              {project.multiStandard && (
                <Space>
                  <span>{project.multiStandardPercentage}%</span>
                  {project.multiStandardReason}
                </Space>
              )}
              {!project.multiStandard && "No"}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <Typography.Title level={4}>Mandays</Typography.Title>
      {project.audits?.map((audit: any, index: number) => {
        const mandays =
          audit.kind === "main"
            ? audit.step === 1
              ? find(project.mandays, { audit: audit.kind })
              : null
            : find(project.mandays, { audit: audit.kind + audit.step });
        return (
          <Fragment key={index}>
            <table style={{ width: "100%" }}>
              <tbody>
                {mandays && (
                  <tr>
                    <td colSpan={2}>
                      <Alert
                        style={{ width: "195px" }}
                        message={`Min. Mandays ${mandays.minimum}`}
                        type={mandays.status}
                        showIcon
                      />
                    </td>
                    <td colSpan={2}>
                      <Alert
                        style={{ width: "195px" }}
                        message={`Alloc. Mandays ${mandays.current}`}
                        type={mandays.status}
                        showIcon
                      />
                    </td>
                    <td colSpan={2}>
                      <Alert
                        style={{ width: "195px" }}
                        message={`Miss. Mandays ${mandays.missing}`}
                        type={mandays.status}
                        showIcon
                      />
                    </td>
                  </tr>
                )}
                <tr>
                  <th colSpan={2}>
                    {audit.kind === "main" ? `Main Audit - Stage${audit.step}` : `${audit.step}° Surveillance`}
                  </th>
                </tr>
                {audit.visits?.map((visit: any) => (
                  <Fragment key={visit.id}>
                    <tr>
                      <th rowSpan={2}>{visit.site.name}</th>
                      <td>Mandays: {visit.mandays}</td>
                      <td>Off-Site days: {visit.reportDays}</td>
                      <td>Fee: {visit.fee}%</td>
                      <td width="20%">{visit.includesSubsistence ? "Includes Subsistence" : ""}</td>
                      <td width="20%">{visit.includesTravel ? "Includes Travel" : ""}</td>
                    </tr>
                    {visit.info && (
                      <tr>
                        <td colSpan={5}>Info: {visit.info}</td>
                      </tr>
                    )}
                  </Fragment>
                ))}
              </tbody>
            </table>
            <br />
          </Fragment>
        );
      })}
    </Fragment>
  );
};
