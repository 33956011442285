import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const AuthRedirector: React.FC<{}> = () => {
  // const location = useLocation();
  const navigate = useNavigate();

  console.info("Redirecting...");

  useEffect(() => {
    navigate("/auth/sign-in");
    // navigate("/auth/sign-in", { state: { landingUrl: location.pathname + location.search } });
  });

  return <CenterSpinner size="large" />;
};
