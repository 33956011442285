import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { SchemeQueryProvider } from "__legacy/queries/schemes/SchemeQueryProvider";
import { Button, Collapse, Layout, PageHeader, Space, Typography } from "antd";
import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const List: React.FC<{}> = () => {
  const { schemeId } = useParams();
  const navigate = useNavigate();

  return (
    <SchemeQueryProvider variables={{ id: schemeId }}>
      {({ scheme }) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title="Technical Areas"
            extra={
              <Button onClick={() => navigate("new")} type="primary">
                <PlusOutlined />
                New
              </Button>
            }
          />
          <Layout.Content className="content">
            <Collapse>
              {scheme.areas.map((area) => (
                <Collapse.Panel
                  key={area.id}
                  header={
                    <Typography.Text style={{ lineHeight: "2.4" }}>
                      {area.name} - {area.description} - Codes: {area.classifications.length}
                    </Typography.Text>
                  }
                  extra={
                    <Space>
                      <Button onClick={() => navigate(`${area.id}/edit`)} icon={<EditOutlined />} />
                    </Space>
                  }
                >
                  <Space direction="vertical">
                    <Typography.Title level={5}>Codes:</Typography.Title>
                    {area.classifications.map((classification) => (
                      <Typography.Text key={classification.id}>
                        {classification.name} - {classification.industry.name} - {classification.description}
                      </Typography.Text>
                    ))}
                  </Space>
                </Collapse.Panel>
              ))}
            </Collapse>
          </Layout.Content>
        </Fragment>
      )}
    </SchemeQueryProvider>
  );
};
