import { CloudUploadOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { CertificationKind, SchemeMandaysKind, StakeholderKind } from "__legacy/queries/__generated__/globalTypes";
import { AccreditationQueryProvider } from "__legacy/queries/accreditations/AccreditationQueryProvider";
import { AccreditationsQueryProvider } from "__legacy/queries/accreditations/AccreditationsQueryProvider";
import { ProjectReasonsQueryProvider } from "__legacy/queries/enums/ProjectReasonsQueryProvider";
import { SitesQueryProvider } from "__legacy/queries/sites/SitesQueryProvider";
import { StakeholdersQueryProvider } from "__legacy/queries/stakeholders/StakeholdersQueryProvider";
import { Alert, Badge, Col, Form, Input, InputNumber, Row, Select, Space, Switch, Tag } from "antd";
import { FormInstance } from "antd/lib/form";
import Text from "antd/lib/typography/Text";
import Dragger from "antd/lib/upload/Dragger";
import { loader } from "graphql.macro";
import { curry, find, isEmpty, isString, toNumber, uniq } from "lodash";
import { Fragment, useCallback, useState } from "react";
import { useParams } from "react-router";

const SchemesQuery = loader("__legacy/queries/schemes/SchemesQuery.gql");

export const onFinish = (mutate: any, params: any, variables: any) => (editSites: any, values: any) => {
  const input = {
    ...values,
    additionalSites: editSites ? toNumber(values.additionalSites ?? 0) : undefined,
    questionaire: values.questionaire?.file ?? null,
    classifications: values.classifications?.map((classificationId: any) => ({
      classificationId,
    })),
    ...params,
  };

  mutate({ variables: { ...variables, input } });
};

export const PwsCertificationForm: React.FC<{
  form: FormInstance<any>;
  onFinish: (editSites: any, values: any) => void;
  project?: any;
  errors: string[];
}> = (props) => {
  const { form, project, onFinish, errors } = props;
  const { agencyId } = useParams();

  const [editSites, setEditSites] = useState(isEmpty(project?.projectSites));

  const tagRender = (codes: any, props: any) => {
    const { value, label, closable, onClose } = props;
    const code = find(codes, { id: value });
    return (
      <Tag color={code?.badge} closable={closable} onClose={onClose}>
        {label}
      </Tag>
    );
  };

  const initialValues = project
    ? {
        ...project,
        subjectId: project.subject.id,
        schemeId: project.scheme.id,
        accreditationId: project.accreditation.id,
        classifications: project.projectSites
          .find((ps: any) => ps.site.isHead)
          ?.projectSiteClassifications?.map(
            (projectSiteClassification: any) => projectSiteClassification.classification.id,
          ),
        additionalSites: project.projectSites.length - 1,
        questionaire: project.questionaire ? [{ url: project.questionaire, name: "Download" }] : [],
      }
    : {};

  const onValuesChange = useCallback(
    (changedValues, allValues) => {
      const fieldValues: any[] = [];
      if (changedValues.schemeId) {
        fieldValues.push({ name: ["accreditationId"], value: null });
        fieldValues.push({ name: ["classifications"], value: [] });
        setEditSites(true);
      }
      if (
        "additionalSites" in changedValues ||
        changedValues.accreditationId ||
        changedValues.classifications ||
        changedValues.sampling === false
      )
        setEditSites(true);
      if (changedValues.subjectId) {
        fieldValues.push({ name: ["additionalSites"], value: null });
        setEditSites(true);
      }
      if (changedValues.questionaire && changedValues.questionaire.file.status === "removed") {
        fieldValues.push({ name: ["questionaire"], value: null });
      }
      form.setFields(fieldValues);
    },
    [form],
  );

  const uploadProps = {
    maxCount: 1,
    accept: ".pdf, .doc, .xls, .xlsx",
    beforeUpload: () => {
      return false;
    },
  };

  const { loading, data } = useQuery(SchemesQuery, {
    variables: {
      kind: "system" as CertificationKind,
    },
    fetchPolicy: "cache-and-network",
  });

  if (loading) return <CenterSpinner size="large" />;

  const { schemes } = data;

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={curry(onFinish)(editSites)}
      onValuesChange={onValuesChange}
    >
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Form.Item name="schemeId" label="Scheme" rules={[{ required: true, message: "is required" }]}>
            <Select
              showSearch
              optionLabelProp="label"
              filterOption={(input, option) => {
                let label = "";
                if (option?.label && isString(option.label)) label = option.label;
                let value = option?.value;
                return (
                  label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }}
            >
              {schemes.map((scheme: any) => (
                <Select.Option key={scheme.id} value={scheme.id} label={scheme.fullName}>
                  {scheme.fullName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item dependencies={["schemeId"]}>
            {(form: FormInstance) => (
              <AccreditationsQueryProvider variables={{ schemeId: form.getFieldValue("schemeId") }}>
                {({ accreditations }) => (
                  <Form.Item
                    name="accreditationId"
                    label="Accreditation (Authority)"
                    rules={[{ required: true, message: "is required" }]}
                  >
                    <Select
                      showSearch
                      optionLabelProp="label"
                      disabled={!form.getFieldValue("schemeId")}
                      filterOption={(input, option) => {
                        let label = "";
                        if (option?.label && isString(option.label)) label = option.label;
                        let value = option?.value;
                        return (
                          label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {accreditations.map((accreditation) => (
                        <Select.Option
                          key={accreditation.id}
                          value={accreditation.id}
                          label={accreditation.authority.name}
                        >
                          {accreditation.authority.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </AccreditationsQueryProvider>
            )}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item dependencies={["accreditationId", "schemeId"]}>
            {(form: FormInstance) => (
              <Fragment>
                {form.getFieldValue("accreditationId") && form.getFieldValue("schemeId") && (
                  <AccreditationQueryProvider
                    variables={{ id: form.getFieldValue("accreditationId"), schemeId: form.getFieldValue("schemeId") }}
                  >
                    {({ accreditation }) => {
                      const codes: any[] = [];
                      accreditation.classifications.forEach((classification: any) => {
                        const code: any = {
                          id: classification.id,
                          tag: `${classification.industry.name} - ${classification.name}`,
                        };
                        code.label = `${code.tag} - ${classification.description}`;
                        if (!!classification.candidacies.length) {
                          let agencies: any[] = [];
                          for (const candidacy of classification.candidacies) {
                            if (candidacy.ability.stakeholder.agency.id === agencyId) {
                              agencies = [];
                              break;
                            } else agencies.push(candidacy.ability.stakeholder.agency.name);
                          }
                          if (!!agencies.length)
                            codes.push({ ...code, badge: "warning", warning: `[${uniq(agencies).join(" / ")}]` });
                          else codes.push(code);
                        } else codes.push({ ...code, badge: "error", danger: "[No Auditors]" });
                      });
                      return (
                        <Form.Item
                          name="classifications"
                          label="Classifications"
                          rules={[{ required: true, message: "is required" }]}
                        >
                          <Select
                            mode="multiple"
                            showSearch
                            optionLabelProp="label"
                            tagRender={curry(tagRender)(codes)}
                            filterOption={(input, option) => {
                              let itemText = "";
                              if (option?.label && isString(option.label)) itemText = option.label;
                              itemText = itemText.toLowerCase();
                              const searchValue = input.toLowerCase();
                              return itemText.indexOf(searchValue) > -1;
                            }}
                          >
                            {codes.map((code: any) => (
                              <Select.Option key={code.id} value={code.id} label={code.tag}>
                                <div className="demo-option-label-item">
                                  <Badge status={code.badge ?? "success"} />
                                  {code.label}
                                  {code.warning && <Text type="warning"> {code.warning}</Text>}
                                  {code.danger && <Text type="danger"> {code.danger}</Text>}
                                </div>
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      );
                    }}
                  </AccreditationQueryProvider>
                )}
              </Fragment>
            )}
          </Form.Item>
        </Col>
        <Col span={24}>
          <ProjectReasonsQueryProvider>
            {({ projectReasons }) => (
              <Form.Item name="reason" label="Project Type" rules={[{ required: true, message: "is required" }]}>
                <Select
                  showSearch
                  optionLabelProp="label"
                  filterOption={(input, option) => {
                    let label = "";
                    if (option?.label && isString(option.label)) label = option.label;
                    let value = option?.value;
                    return (
                      label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                      value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {projectReasons?.enumValues?.map((reason) => (
                    <Select.Option key={reason.name} value={reason.name} label={reason.description}>
                      {reason.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </ProjectReasonsQueryProvider>
        </Col>
        <Col span={24}>
          <StakeholdersQueryProvider variables={{ agencyId, kind: "customer" as StakeholderKind }}>
            {({ stakeholders }) => (
              <Form.Item name="subjectId" label="Customer" rules={[{ required: true, message: "is required" }]}>
                <Select
                  showSearch
                  optionLabelProp="label"
                  filterOption={(input, option) => {
                    let label = "";
                    if (option?.label && isString(option.label)) label = option.label;
                    let value = option?.value;
                    return (
                      label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                      value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {stakeholders
                    .filter((sh: any) => sh.sites?.find((s: any) => s.isHead))
                    .map((stakeholder) => (
                      <Select.Option key={stakeholder.id} value={stakeholder.id} label={stakeholder.companyName}>
                        {stakeholder.companyName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
          </StakeholdersQueryProvider>
        </Col>
        <Col span={24}>
          <Form.Item name="scope" label="Scope">
            <Input.TextArea />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="sampling" label="Sampling" valuePropName={"checked"}>
            <Switch />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item dependencies={["subjectId", "schemeId"]}>
            {(form: FormInstance) => (
              <Fragment>
                {form.getFieldValue("subjectId") && (
                  <SitesQueryProvider variables={{ agencyId, stakeholderId: form.getFieldValue("subjectId") }}>
                    {({ sites }) => {
                      const threshold = find(schemes, { id: form.getFieldValue("schemeId") })?.samplingThreshold;
                      const otherSites = sites.length - 1;
                      const min = toNumber(threshold) > 0 ? Math.round((otherSites / 100) * threshold) : 0;
                      return (
                        <Fragment>
                          {sites.length > 1 && (
                            <Space>
                              <Form.Item
                                name="additionalSites"
                                label="Additional Sites"
                                rules={min > 0 ? [{ required: true, message: "is required" }] : []}
                              >
                                <InputNumber min={min} max={otherSites} />
                              </Form.Item>
                              {`/ ${otherSites}`}
                            </Space>
                          )}
                        </Fragment>
                      );
                    }}
                  </SitesQueryProvider>
                )}
              </Fragment>
            )}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item dependencies={["schemeId"]}>
            {(form: FormInstance) => {
              const scheme = find(schemes, { id: form.getFieldValue("schemeId") });
              if (!scheme) return;
              return (
                <Fragment>
                  {scheme.mandaysKind === ("fsms" as SchemeMandaysKind) && (
                    <Fragment>
                      <Col span={24}>
                        <Form.Item name="fsmsExtraPlans" label="Number of the Extra HACCP's Plans">
                          <InputNumber min={0} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="fsmsOtherCertifications"
                          label="Certified for Other management Systems with AJA"
                          valuePropName={"checked"}
                        >
                          <Switch />
                        </Form.Item>
                      </Col>
                    </Fragment>
                  )}
                  {scheme.mandaysKind === ("enms" as SchemeMandaysKind) && (
                    <Fragment>
                      <Col span={24}>
                        <Form.Item
                          name="enmsEnergyConsumption"
                          label="Annual Total Energy Consumption in Kwh as declared on Questionnaire"
                        >
                          <InputNumber min={0} style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="enmsEnergySources" label="Number of Energy Sources based on Questionnaire">
                          <InputNumber min={0} style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="enmsEnergyUses"
                          label="Number of Significant Energy Uses based on Questionnaire"
                        >
                          <InputNumber min={0} style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </Fragment>
                  )}
                </Fragment>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="questionaire" valuePropName={"defaultFileList"}>
            <Dragger {...uploadProps}>
              <CloudUploadOutlined />
              Upload Questionnaire
            </Dragger>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
