import { EditOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { useApplicationContext } from "__legacy/components/Application";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { Alert, Button, Calendar, Layout, PageHeader, Space } from "antd";
import { loader } from "graphql.macro";
import { lowerCase } from "lodash";
import moment from "moment";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ProjectActivityItemsQuery = loader("__legacy/queries/projects/ProjectActivityItemsQuery.gql");
const AbsencesQuery = loader("__legacy/queries/absences/AbsencesQuery.gql");

export const List: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { agencyId } = useParams();
  const { session } = useApplicationContext();
  const [selectedDate, setSelectedDate] = useState();
  const employment = session.currentUser.employments
    .filter((e: any) => e.kind === "agency")
    ?.find((e: any) => e.agency.id === agencyId);

  const variables = {
    employmentId: employment.id,
    done: true,
  };
  const { loading: itemsLoading, data: itemsData } = useQuery(ProjectActivityItemsQuery, {
    variables,
    fetchPolicy: "cache-and-network",
  });
  const { loading: absencesLoading, data: absencesData } = useQuery(AbsencesQuery, {
    variables,
    fetchPolicy: "cache-and-network",
  });

  if (itemsLoading || absencesLoading) return <CenterSpinner size="large" />;

  const { projectActivityItems } = itemsData;
  const { absences } = absencesData;

  return (
    <Fragment>
      <PageHeader ghost={true} title="Timesheet Calendar" />
      <Layout.Content className="content">
        <Calendar
          disabledDate={(d) => d > moment().endOf("day")}
          dateCellRender={(d) => {
            if (d > moment().endOf("day")) return null;
            const hours = employment[`${lowerCase(d.format("dddd"))}Hours`];
            const items = projectActivityItems.filter(
              (i: any) => moment(i.doneOn).format("MM-DD-YYYY") === d.format("MM-DD-YYYY"),
            );
            const abs = absences.filter((i: any) => moment(i.doneOn).format("MM-DD-YYYY") === d.format("MM-DD-YYYY"));
            if (!hours && !items.length && !abs.length) return null;
            let effort = 0;
            items.forEach((i: any) => (effort += i.effortHours));
            abs.forEach((i: any) => (effort += i.hours));
            return (
              <Space direction="vertical">
                {effort < hours && <Alert type="error" message={`Missing Hours: ${hours - effort}`} />}
                {effort > hours && <Alert type="warning" message={`Extra Hours: ${effort - hours}`} />}
                {effort === hours && <Alert type="success" message="Correct Timesheet" />}
                {moment(selectedDate).format() === d.format() && (
                  <Button style={{ width: "100%" }} onClick={() => navigate(d.format("MM-DD-YYYY"))}>
                    <EditOutlined /> Edit
                  </Button>
                )}
              </Space>
            );
          }}
          onSelect={(d: any) => setSelectedDate(d)}
        />
      </Layout.Content>
    </Fragment>
  );
};
