import { Grid, Stack } from "@chakra-ui/react";

import { Card } from "../ui/Card";
import { CardField } from "../ui/CardField";

export const Site: React.FC<{}> = () => {
  return (
    <Card title="Main Office">
      <Stack w="full" spacing="4">
        <Stack>
          <CardField label="Address" value="Corso Argentina 12" />
          <Grid templateColumns="repeat(2, 1fr)" rowGap="2">
            <CardField label="City" value="Milano" />
            <CardField label="Zip" value="12344" />
            <CardField label="State" value="MI" />
            <CardField label="Country" value="Italy" />
          </Grid>
          <CardField
            label="Notes"
            value="Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet"
          />
        </Stack>
      </Stack>
    </Card>
  );
};
