import { Box, Flex, Icon, Link, Tooltip, VStack } from "@chakra-ui/react";
import { ImUser, ImUsers } from "react-icons/im";
import { RiLogoutCircleRLine } from "react-icons/ri";

import { Logo } from "../Logo";

export const MainNav: React.FC<{}> = () => {
  return (
    <Flex position={{ sm: "fixed" }} bg="coolGray.700" boxShadow="10" h="100vh" w="12">
      <VStack h="full">
        <Box h="12" w="10" my="4" p="1">
          <Logo />
        </Box>
        <VStack h="full" justify="space-between" px="1">
          <Box w="10">
            <VStack spacing="2" mb="20">
              <Tooltip hasArrow openDelay={500} label="GLOBAL" placement="right" bg="coolGray.50" color="coolGray.800">
                <Link
                  display="flex"
                  alignItems="center"
                  border="3px"
                  borderColor="transparent"
                  borderRadius="6px"
                  _hover={{ borderColor: "coolGray.400" }}
                >
                  <Box
                    margin="0 auto"
                    align="center"
                    lineHeight="6"
                    color="coolGray.700"
                    h="6"
                    w="6"
                    m="2px"
                    bg="coolGray.300"
                    borderRadius="sm"
                    fontWeight="bold"
                    fontSize="sm"
                  >
                    G
                  </Box>
                </Link>
              </Tooltip>
              <Tooltip
                hasArrow
                openDelay={500}
                label="AJA ITALY"
                placement="right"
                bg="coolGray.50"
                color="coolGray.800"
              >
                <Link
                  display="flex"
                  alignItems="center"
                  border="3px"
                  borderColor="coolGray.200"
                  borderRadius="6px"
                  _hover={{ borderColor: "coolGray.200" }}
                >
                  <Box
                    margin="0 auto"
                    align="center"
                    lineHeight="6"
                    color="coolGray.700"
                    h="6"
                    w="6"
                    m="2px"
                    bg="coolGray.300"
                    borderRadius="sm"
                    fontWeight="bold"
                    fontSize="sm"
                  >
                    IT
                  </Box>
                </Link>
              </Tooltip>
              <Link
                display="flex"
                alignItems="center"
                border="3px"
                borderColor="transparent"
                borderRadius="6px"
                _hover={{ borderColor: "coolGray.400" }}
              >
                <Box
                  margin="0 auto"
                  align="center"
                  lineHeight="6"
                  color="coolGray.700"
                  h="6"
                  w="6"
                  m="2px"
                  bg="coolGray.300"
                  borderRadius="sm"
                  fontWeight="bold"
                  fontSize="sm"
                >
                  GB
                </Box>
              </Link>
            </VStack>
          </Box>
          <Box>
            <VStack spacing="1" mb="2">
              <Tooltip
                hasArrow
                openDelay={500}
                label="Impersonification"
                placement="right"
                bg="coolGray.50"
                color="coolGray.800"
              >
                <Link
                  display="flex"
                  alignItems="center"
                  border="3px"
                  borderColor="transparent"
                  borderRadius="6px"
                  _hover={{ bg: "coolGray.800" }}
                >
                  <Box
                    margin="0 auto"
                    align="center"
                    lineHeight="4"
                    color="coolGray.700"
                    h="6"
                    w="6"
                    m="2px"
                    p="2px"
                    borderRadius="sm"
                    fontWeight="bold"
                    fontSize="sm"
                  >
                    <Icon as={ImUsers} w="full" h="full" color="lime.300" />
                  </Box>
                </Link>
              </Tooltip>
              <Tooltip hasArrow openDelay={500} label="Account" placement="right" bg="coolGray.50" color="coolGray.800">
                <Link
                  display="flex"
                  alignItems="center"
                  border="3px"
                  borderColor="transparent"
                  borderRadius="6px"
                  _hover={{ bg: "coolGray.800" }}
                >
                  <Box
                    margin="0 auto"
                    align="center"
                    lineHeight="4"
                    color="coolGray.700"
                    h="6"
                    w="6"
                    m="2px"
                    p="2px"
                    borderRadius="sm"
                    fontWeight="bold"
                    fontSize="sm"
                  >
                    <Icon as={ImUser} w="full" h="full" color="coolGray.100" />
                  </Box>
                </Link>
              </Tooltip>
              <Tooltip hasArrow openDelay={500} label="Logout" placement="right" bg="coolGray.50" color="coolGray.800">
                <Link
                  display="flex"
                  alignItems="center"
                  border="3px"
                  borderColor="transparent"
                  borderRadius="6px"
                  _hover={{ bg: "coolGray.800" }}
                >
                  <Box
                    margin="0 auto"
                    align="center"
                    lineHeight="4"
                    color="coolGray.700"
                    h="6"
                    w="6"
                    m="2px"
                    p="2px"
                    borderRadius="sm"
                    fontWeight="bold"
                    fontSize="sm"
                  >
                    <Icon as={RiLogoutCircleRLine} w="full" h="full" color="red.500" />
                  </Box>
                </Link>
              </Tooltip>
            </VStack>
          </Box>
        </VStack>
      </VStack>
    </Flex>
  );
};
