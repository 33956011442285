import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  UpdateReductionsMutation as UpdateReductionsMutationType,
  UpdateReductionsMutationVariables,
} from "./__generated__/UpdateReductionsMutation";

const UpdateReductionsMutation = loader("./UpdateReductionsMutation.gql");

type UpdateReductionsMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted?: (data: UpdateReductionsMutationType) => void;
}) => ReactElement;

export const UpdateReductionsMutationProvider: UpdateReductionsMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<UpdateReductionsMutationType, UpdateReductionsMutationVariables>(
    UpdateReductionsMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
