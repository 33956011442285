import { Alert, Card, Col, Form, Input, Row, Select, Space } from "antd";
import { FormInstance } from "antd/lib/form";
import { isString, omit } from "lodash";

type StageFormType = {
  form: FormInstance<any>;
  handler: (values: any) => void;
  values: any;
  errors: string[];
};

const fields = [
  ["name", "name"],
  ["kind", "kind"],
];

export const onError =
  (form: any, setErrors: any) =>
  ({ graphQLErrors }: any) => {
    const fieldsErrors: any[] = [];
    const errors: string[] = [];
    graphQLErrors.forEach((graphQLError: any) => {
      let found = false;
      fields.forEach(([fieldName, errorFieldName]) => {
        if (graphQLError.name === errorFieldName) {
          fieldsErrors.push({ name: fieldName, errors: (graphQLError as any).messages });
          found = true;
        }
      });
      if (!found) {
        errors.push(graphQLError.message);
      }
    });
    form.setFields(fieldsErrors);
    setErrors(errors);
  };

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const cleanValues = {
    ...omit(values, []),
    ...params,
  };

  mutate({ variables: { ...variables, input: cleanValues } });
};

export const StageForm: React.FC<StageFormType> = (props) => {
  const { form, handler, values, errors } = props;

  return (
    <Card>
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Form layout="vertical" form={form} initialValues={values} onFinish={handler}>
        <Row gutter={[24, 0]}>
          <Col span={12}>
            <Form.Item name="name" label="Name" rules={[{ required: true, message: "is required" }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="kind" label="Certification Kind" rules={[{ required: true, message: "is required" }]}>
              <Select
                showSearch
                optionLabelProp="label"
                filterOption={(input, option) => {
                  let label = "";
                  if (option?.label && isString(option.label)) label = option.label;
                  let value = option?.value;
                  return (
                    label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                <Select.Option key="company" value="company" label="Company">
                  Company
                </Select.Option>
                <Select.Option key="product" value="product" label="Product">
                  Product
                </Select.Option>
                <Select.Option key="skill" value="skill" label="Skill">
                  Skill
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="description" label="Description" rules={[{ required: true, message: "is required" }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
