import {
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Textarea,
} from "@chakra-ui/react";
import React from "react";

import { FormItem } from "../ui/FormItem";

export const CustomerForm: React.FC<{}> = () => {
  return (
    <>
      <FormItem id="subjectId" label="Customer" required>
        <Select>
          <option value="option1">Gold</option>
          <option value="option2">Aja</option>
        </Select>
      </FormItem>
      <FormItem id="certifiedSites" required>
        <NumberInput allowMouseWheel min={1} max={10}>
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormItem>
      <FormItem id="scope">
        <Textarea />
      </FormItem>
      <FormItem id="questionaire">
        <Input />
      </FormItem>
    </>
  );
};
