import { ProjectStatus, ProjectStep } from "__legacy/queries/__generated__/globalTypes";
import { AgenciesQueryProvider } from "__legacy/queries/agencies/AgenciesQueryProvider";
import { ProjectsQueryProvider } from "__legacy/queries/projects/ProjectsQueryProvider";
import { VisitsQueryProvider } from "__legacy/queries/projects/VisitsQueryProvider";
import { Button, Card, Col, Layout, PageHeader, Row, Select, Space, Typography } from "antd";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

export const Dash: React.FC<{}> = () => {
  const navigate = useNavigate();
  const [agencyId, setAgencyId] = useState();

  const groups = [
    {
      title: "Accredited office Review Actions",
      cards: [
        {
          title: "PWS",
          resource: "projects",
          step: "application",
          status: "pending",
          filterAuthorities: true,
        },
        {
          title: "Signed Proposals",
          resource: "projects",
          step: "contract",
          status: "pending",
          filterAuthorities: true,
        },
        {
          title: "Assessment Packages",
          resource: "visits",
          kind: "main",
          status: "pending",
          filterAuthorities: true,
        },
        {
          title: "Surveillance Packages",
          resource: "visits",
          kind: "surveillance",
          status: "pending",
          filterAuthorities: true,
        },
      ],
    },
    {
      title: "On Hold for Office Response Actions",
      cards: [
        {
          title: "PWS on Hold",
          resource: "projects",
          step: "application",
          status: "redaction",
          state: { review: true },
        },
        { title: "Signed Proposals on Hold", resource: "projects", step: "contract", status: "redaction" },
        {
          title: "Stage 1 Packages",
          resource: "projects",
          step: "stage1",
          status: "booking",
          state: { visitReview: true },
        },
        {
          title: "Stage 2 Packages",
          resource: "projects",
          step: "stage2",
          status: "booking",
          state: { visitReview: true },
        },
        {
          title: "Surveillance Packages",
          resource: "projects",
          step: "surveillance",
          status: "booking",
          state: { visitReview: true },
        },
      ],
    },
    {
      title: "Office Operational Actions",
      cards: [
        { title: "PWS", resource: "projects", step: "application", status: "redaction", state: { review: false } },
        { title: "Proposals to send", resource: "projects", step: "proposal", status: "redaction" },
        { title: "Proposal without Contract", resource: "projects", step: "contract", status: "redaction" },
        {
          title: "Stage 1 Not Booked",
          resource: "projects",
          step: "stage1",
          status: "booking",
        },
        {
          title: "Stage 2 Not Booked",
          resource: "projects",
          step: "stage2",
          status: "booking",
        },
        {
          title: "Stage 1 Packages to Upload",
          resource: "visits",
          kind: "main",
          status: "redaction",
          state: {
            step: "1",
            bookingFilled: true,
          },
        },
        {
          title: "Stage 2 Packages to Upload",
          resource: "visits",
          kind: "main",
          status: "redaction",
          state: {
            step: "2",
            bookingFilled: true,
          },
        },
        {
          title: "Surveillance Not Booked",
          resource: "visits",
          kind: "surveillance",
          status: "redaction",
          state: {
            bookingFilled: false,
          },
        },
        {
          title: "Surveillance Packages to Upload",
          resource: "visits",
          kind: "surveillance",
          status: "redaction",
          state: {
            bookingFilled: true,
          },
        },
      ],
    },
  ];

  return (
    <Fragment>
      <PageHeader
        ghost={true}
        title="System Job Orders"
        extra={
          <Space>
            <Typography.Title level={5} style={{ marginBottom: "0" }}>
              Agency
            </Typography.Title>
            <AgenciesQueryProvider>
              {({ agencies }) => (
                <Select
                  style={{ width: "300px" }}
                  defaultValue={0}
                  onChange={(v: any) => setAgencyId(v ? v : undefined)}
                >
                  <Select.Option value={0}>ALL</Select.Option>
                  {agencies.map((agency) => (
                    <Select.Option key={agency.id} value={agency.id}>
                      {agency.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </AgenciesQueryProvider>
          </Space>
        }
      />
      <Layout.Content className="content">
        <Row gutter={[24, 24]}>
          {groups.map((group, i) => (
            <Col span={8} key={i}>
              <Space direction="vertical">
                <Typography.Title level={5}>{group.title}</Typography.Title>
                <Row gutter={[16, 16]}>
                  {group.cards.map((card: any, j: any) => {
                    const state = { ...card.state, agencyId };
                    return (
                      <Col span={24} key={j}>
                        {card.resource === "projects" && (
                          <ProjectsQueryProvider
                            variables={{
                              step: card.step as ProjectStep,
                              status: card.status as ProjectStatus,
                              filterAuthorities: card.filterAuthorities,
                              ...state,
                            }}
                          >
                            {({ projects }) => (
                              <Card
                                title={
                                  <Typography.Title level={5} type="secondary">
                                    <Typography.Text type="warning">{projects.length}</Typography.Text> {card.title}
                                  </Typography.Title>
                                }
                                bodyStyle={{ display: "none" }}
                                extra={
                                  <Button
                                    type="primary"
                                    ghost
                                    onClick={() => navigate(`../${card.step}/${card.status}`, { state })}
                                  >
                                    Go to list
                                  </Button>
                                }
                              />
                            )}
                          </ProjectsQueryProvider>
                        )}
                        {card.resource === "visits" && (
                          <VisitsQueryProvider
                            variables={{
                              kind: card.kind,
                              status: card.status as ProjectStatus,
                              filterAuthorities: card.filterAuthorities,
                              ...state,
                            }}
                          >
                            {({ visits }) => (
                              <Card
                                title={
                                  <Typography.Title level={5} type="secondary">
                                    <Typography.Text type="warning">{visits.length}</Typography.Text> {card.title}
                                  </Typography.Title>
                                }
                                bodyStyle={{ display: "none" }}
                                extra={
                                  <Button
                                    type="primary"
                                    ghost
                                    onClick={() => navigate(`../visits/${card.kind}/${card.status}`, { state })}
                                  >
                                    Go to list
                                  </Button>
                                }
                              />
                            )}
                          </VisitsQueryProvider>
                        )}
                      </Col>
                    );
                  })}
                </Row>
              </Space>
            </Col>
          ))}
        </Row>
      </Layout.Content>
    </Fragment>
  );
};
