import { useQuery } from "@apollo/client";
import { CenterResult } from "__legacy/components/CenterResult";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  ProjectActivityItemsQuery as ProjectActivityItemsQueryType,
  ProjectActivityItemsQueryVariables,
} from "./__generated__/ProjectActivityItemsQuery";

const ProjectActivityItemsQuery = loader("./ProjectActivityItemsQuery.gql");

type ProjectActivityItemsQueryProviderType = (props: {
  children: (data: ProjectActivityItemsQueryType) => ReactNode;
  variables: ProjectActivityItemsQueryVariables;
}) => ReactElement;

export const ProjectActivityItemsQueryProvider: ProjectActivityItemsQueryProviderType = (props) => {
  const { children, variables } = props;

  const { error, loading, data } = useQuery<ProjectActivityItemsQueryType, ProjectActivityItemsQueryVariables>(
    ProjectActivityItemsQuery,
    {
      variables,
      fetchPolicy: "cache-and-network",
    },
  );

  if (loading) return <CenterSpinner size="large" />;
  if (error || !data)
    return <CenterResult status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;

  return <Fragment>{children(data)}</Fragment>;
};
