import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { ClassificationsQuery_classifications } from "__legacy/queries/classifications/__generated__/ClassificationsQuery";
import {
  CertificationRiskLevelsQuery_certificationRiskLevels_enumValues,
} from "__legacy/queries/enums/__generated__/CertificationRiskLevelsQuery";
import { Button, Col, Form, Row, Select } from "antd";
import { cloneDeep, includes, isString, pullAt, remove as arrayRemove } from "lodash";
import { Fragment, useState } from "react";

type ClassificationListType = {
  accreditationClassifications: { riskLevel: string; classificationId: string }[];
  classifications: ClassificationsQuery_classifications[];
  riskLevels: CertificationRiskLevelsQuery_certificationRiskLevels_enumValues[];
};

export const ClassificationListForm: React.FC<ClassificationListType> = (props) => {
  const { accreditationClassifications, classifications, riskLevels } = props;
  const [usedClassifications, setUsedClassifications] = useState(
    accreditationClassifications.map((accreditationClassification) => accreditationClassification.classificationId),
  );

  return (
    <Row gutter={[24, 0]}>
      <Col span={24}>
        <Form.Item label="Classifications">
          <Form.List name="accreditationClassifications">
            {(fields, { add, remove }) => (
              <Fragment>
                {fields.map((field) => (
                  <Row key={field.key} gutter={[24, 0]}>
                    <Col span={11}>
                      <Form.Item
                        {...field}
                        label="Classification"
                        name={[field.name, "classificationId"]}
                        fieldKey={[field.key, "classificationId"]}
                        rules={[{ required: true, message: "is required" }]}
                        normalize={(value, prevValue, allValues) => {
                          setUsedClassifications((currentUsedClassifications) => {
                            const usedClassifications = cloneDeep(currentUsedClassifications);
                            arrayRemove(usedClassifications, (usedClassification) => usedClassification === prevValue);
                            usedClassifications.push(value);
                            return usedClassifications;
                          });
                          return value;
                        }}
                      >
                        <Select
                          showSearch
                          optionLabelProp="label"
                          filterOption={(input, option) => {
                            let itemText = "";
                            if (option?.label && isString(option.label)) itemText = option.label;
                            itemText = itemText.toLowerCase();
                            const searchValue = input.toLowerCase();
                            return itemText.indexOf(searchValue) > -1;
                          }}
                        >
                          {classifications.map((classification) => (
                            <Select.Option
                              key={classification.id}
                              value={classification.id}
                              label={`${classification.industry.name} - ${classification.name} - ${classification.description}`}
                              disabled={includes(usedClassifications, classification.id)}
                            >
                              {classification.industry.name} - {classification.name} - {classification.description}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        {...field}
                        label="Risk level"
                        name={[field.name, "riskLevel"]}
                        fieldKey={[field.key, "riskLevel"]}
                        rules={[{ required: true, message: "is required" }]}
                      >
                        <Select
                          showSearch
                          optionLabelProp="label"
                          filterOption={(input, option) => {
                            let label = "";
                            if (option?.label && isString(option.label)) label = option.label;
                            let value = option?.value;
                            return (
                              label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                              value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {riskLevels.map((riskLevel) => (
                            <Select.Option key={riskLevel.name} value={riskLevel.name} label={riskLevel.description}>
                              {riskLevel.description}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item label=" ">
                        <Button
                          type="dashed"
                          danger
                          onClick={() => {
                            setUsedClassifications((currentUsedClassifications) => {
                              const usedClassifications = cloneDeep(currentUsedClassifications);
                              pullAt(usedClassifications, field.name);
                              return usedClassifications;
                            });
                            remove(field.name);
                          }}
                          block
                        >
                          <MinusCircleOutlined />
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                ))}

                <Row gutter={[24, 0]}>
                  <Col span={24}>
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block>
                        <PlusOutlined /> Add classification
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Fragment>
            )}
          </Form.List>
        </Form.Item>
      </Col>
    </Row>
  );
};
