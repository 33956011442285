import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { StrictMode } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import theme from "theme";

import { Show as ProjectShow } from "./graphics/project/Show";
import { Index as StakeholderIndex } from "./graphics/stakeholder/Index";
import { Show as StakeholderShow } from "./graphics/stakeholder/Show";
import { CustomerSites as StakeholderCustomerSites } from "./graphics/stakeholder/CustomerSites";
import { CustomerProjects as StakeholderCustomerProjects } from "./graphics/stakeholder/CustomerProjects";
import { CollaboratorExperiences as StakeholderCollaboratorExperiences } from "./graphics/stakeholder/CollaboratorExperiences";
import { CollaboratorTrainings as StakeholderCollaboratorTrainings } from "./graphics/stakeholder/CollaboratorTrainings";
import { CollaboratorAbilities as StakeholderCollaboratorAbilities } from "./graphics/stakeholder/CollaboratorAbilities";
import { Layout } from "./graphics/ui/Layout";

export const Root: React.FC<any> = () => {
  return (
    <StrictMode>
      <ColorModeScript />
      <ChakraProvider theme={theme}>
        <Router>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/stakeholders" element={<StakeholderIndex />} />
              <Route path="/stakeholders/show" element={<StakeholderShow />} />
              <Route path="/stakeholders/customer/sites" element={<StakeholderCustomerSites />} />
              <Route path="/stakeholders/customer/projects" element={<StakeholderCustomerProjects />} />
              <Route path="/stakeholders/collaborator/experiences" element={<StakeholderCollaboratorExperiences />} />
              <Route path="/stakeholders/collaborator/trainings" element={<StakeholderCollaboratorTrainings />} />
              <Route path="/stakeholders/collaborator/abilities" element={<StakeholderCollaboratorAbilities />} />
              <Route path="/projects/show" element={<ProjectShow />} />
            </Route>
          </Routes>
        </Router>
      </ChakraProvider>
    </StrictMode>
  );
};
