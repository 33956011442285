import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  CloneProjectMutation as CloneProjectMutationType,
  CloneProjectMutationVariables,
} from "./__generated__/CloneProjectMutation";

const CloneProjectMutation = loader("./CloneProjectMutation.gql");

type CloneProjectMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted: (data: CloneProjectMutationType) => void;
}) => ReactElement;

export const CloneProjectMutationProvider: CloneProjectMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<CloneProjectMutationType, CloneProjectMutationVariables>(
    CloneProjectMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
