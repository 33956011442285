import {
  UpdateProjectActivityItemMutationProvider,
} from "__legacy/queries/projects/UpdateProjectActivityItemMutationProvider";
import { Alert, Button, Col, Drawer, Form, Input, InputNumber, Row } from "antd";

export const EditProjectActivityItemForm: React.FC<{
  item: any;
  visible: any;
  onClose: () => void;
}> = (props) => {
  const { visible, onClose, item } = props;
  const [form] = Form.useForm();

  const [effort, params, variables] = [item?.effortHours, {}, { id: item?.id }];
  const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
    const input = {
      ...values,
      ...params,
    };
    mutate({ variables: { ...variables, input } });
  };

  return (
    <UpdateProjectActivityItemMutationProvider onCompleted={onClose}>
      {(loading, mutate) => (
        <Drawer
          title={"Edit Job Order Item"}
          width={680}
          onClose={onClose}
          visible={visible}
          keyboard={false}
          maskClosable={false}
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button onClick={onClose} style={{ marginRight: 8 }}>
                Cancel
              </Button>
              <Button type="primary" loading={loading} onClick={() => form.submit()}>
                Save
              </Button>
            </div>
          }
        >
          <Form layout="vertical" form={form} onFinish={onFinish(mutate, params, variables)}>
            <Row gutter={[24, 20]}>
              <Col span={12}>
                Job Order
                <Input disabled value={item?.project.code} />
              </Col>
              <Col span={12}>
                Activity Item
                <Input disabled value={item?.item.description} />
              </Col>
              <Col span={12}>
                <Form.Item
                  initialValue={effort}
                  name="effortHours"
                  label="Effort Hours"
                  rules={[{ required: true, message: "is required" }]}
                >
                  <InputNumber min={0.25} step={0.25} />
                </Form.Item>
              </Col>
              <Col span={8} style={{ paddingTop: "15px" }}>
                <Alert type="info" message={`Estimated Hours: ${item?.item.estimatedHours}`}></Alert>
              </Col>
            </Row>
          </Form>
        </Drawer>
      )}
    </UpdateProjectActivityItemMutationProvider>
  );
};
