import { CloudUploadOutlined } from "@ant-design/icons";
import { Alert, Col, DatePicker, Form, Input, Row, Space } from "antd";
import { FormInstance } from "antd/lib/form";
import Dragger from "antd/lib/upload/Dragger";
import moment from "moment";
import { useCallback } from "react";

const formatDate = (date: any) => {
  return moment(date, "MM-DD-YYYY").isValid() ? moment(date, "MM-DD-YYYY").format("YYYY-MM-DD") : null;
};

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const input = {
    ...values,
    existingCertificateExpiredOn: formatDate(values.existingCertificateExpiredOn),
    existingCertificateIssuedOn: formatDate(values.existingCertificateIssuedOn),
    existingCertificate: values.existingCertificate?.file ?? null,
    existingAuthorityReport: values.existingAuthorityReport?.file ?? null,
    ...params,
  };
  mutate({ variables: { ...variables, input } });
};

export const PwsTransferForm: React.FC<{
  form: FormInstance<any>;
  onFinish: (values: any) => void;
  project: any;
  errors: string[];
}> = (props) => {
  const { form, project, onFinish, errors } = props;

  const initialValues = {
    ...project,
    existingCertificateExpiredOn: project.existingCertificateExpiredOn
      ? moment(project.existingCertificateExpiredOn, "YYYY-MM-DD")
      : null,
    existingCertificateIssuedOn: project.existingCertificateIssuedOn
      ? moment(project.existingCertificateIssuedOn, "YYYY-MM-DD")
      : null,
    existingCertificate: project.existingCertificate ? [{ url: project.existingCertificate, name: "Download" }] : [],
    existingAuthorityReport: project.existingAuthorityReport
      ? [{ url: project.existingAuthorityReport, name: "Download" }]
      : [],
  };

  const valuesChangeHandler = useCallback(
    (changedValues, allValues) => {
      const fieldValues: any[] = [];
      if (changedValues.existingCertificate && changedValues.existingCertificate.file.status === "removed") {
        fieldValues.push({ name: ["existingCertificate"], value: null });
      }
      if (changedValues.existingAuthorityReport && changedValues.existingAuthorityReport.file.status === "removed") {
        fieldValues.push({ name: ["existingAuthorityReport"], value: null });
      }
      form.setFields(fieldValues);
    },
    [form],
  );

  const uploadProps = {
    maxCount: 1,
    accept: ".pdf, .doc, .xls, .xlsx",
    beforeUpload: () => {
      return false;
    },
  };

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      onValuesChange={valuesChangeHandler}
    >
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Form.Item name="existingAuthority" label="Existing CB">
            <Input.TextArea rows={1} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="existingAccreditation" label="Existing Accreditation">
            <Input.TextArea rows={1} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="existingCertificateExpiredOn" label="Cerfiticate Expiry Date">
            <DatePicker mode="date" format="MM-DD-YYYY" style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="existingCertificateIssuedOn" label="Original Cerfiticate Date">
            <DatePicker mode="date" format="MM-DD-YYYY" style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="existingCertificate" label="Upload Cert/Schedule" valuePropName={"defaultFileList"}>
            <Dragger {...uploadProps}>
              <CloudUploadOutlined />
              Click or drop here
            </Dragger>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="existingAuthorityReport" label="Upload Previous CB Report" valuePropName={"defaultFileList"}>
            <Dragger {...uploadProps}>
              <CloudUploadOutlined />
              Click or drop here
            </Dragger>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="transferReason" label="Transfer Reason">
            <Input.TextArea rows={2} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
