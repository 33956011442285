import { LockOutlined } from "@ant-design/icons";
import { Alert, Card, Checkbox, Col, Form, Input, Row, Space, Switch } from "antd";
import { FormInstance } from "antd/lib/form";
import { omit } from "lodash";

type UserFormType = {
  action: string;
  form: FormInstance<any>;
  handler: (values: any) => void;
  values: any;
  errors: string[];
};

const fields = [
  ["email", "email"],
  ["password", "password"],
  ["passwordConfirmation", "passwordConfirmation"],
  ["isEnabled", "isEnabled"],
  ["isAdmin", "isAdmin"],
];

export const onError = (form: any, setErrors: any) => ({ graphQLErrors }: any) => {
  const fieldsErrors: any[] = [];
  const errors: string[] = [];
  graphQLErrors.forEach((graphQLError: any) => {
    let found = false;
    fields.forEach(([fieldName, errorFieldName]) => {
      if (graphQLError.name === errorFieldName) {
        fieldsErrors.push({ name: fieldName, errors: (graphQLError as any).messages });
        found = true;
      }
    });
    if (!found) {
      errors.push(graphQLError.message);
    }
  });
  // console.log(graphQLErrors);
  // console.log(fieldsErrors);
  // console.log(errors);
  form.setFields(fieldsErrors);
  setErrors(errors);
};

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const cleanValues = {
    ...omit(values, ["passwordConfirmation"]),
    ...params,
    authorization: {
      canManageUsers: values.authorization?.canManageUsers ?? false,
      canManageRoles: values.authorization?.canManageRoles ?? false,
      canManageAgencies: values.authorization?.canManageAgencies ?? false,
      canManageClassifications: values.authorization?.canManageClassifications ?? false,
      canManageCertifications: values.authorization?.canManageCertifications ?? false,
      canManageProjects: values.authorization?.canManageProjects ?? false,
    },
  };

  mutate({ variables: { ...variables, input: cleanValues } });
};

export const UserForm: React.FC<UserFormType> = (props) => {
  const { action, form, handler, values, errors } = props;

  console.log(`Globals/Users/UserForm action: ${action}`);

  return (
    <Card>
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Form layout="vertical" form={form} initialValues={values} onFinish={handler}>
        <Row gutter={[24, 0]}>
          <Col span={8}>
            <Form.Item name="email" label="Email" rules={[{ required: true, message: "is required" }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="password" label="Password">
              <Input.Password prefix={<LockOutlined />} autoComplete="new-password" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="passwordConfirmation"
              label="Confirm Password"
              dependencies={["password"]}
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value && getFieldValue("password") && getFieldValue("password") !== "") {
                      return Promise.reject("is required");
                    }
                    return Promise.resolve();
                  },
                }),
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === "" || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("not matching");
                  },
                }),
              ]}
            >
              <Input.Password prefix={<LockOutlined />} autoComplete="new-password" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="isEnabled" label="Is enabled?" valuePropName={"checked"}>
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="isAdmin" label="Is admin?" valuePropName={"checked"}>
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item label="Authorizations">
              <Row>
                <Col span={8}>
                  <Form.Item name={["authorization", "canManageUsers"]} valuePropName={"checked"}>
                    <Checkbox>Manage Users</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={["authorization", "canManageRoles"]} valuePropName={"checked"}>
                    <Checkbox>Manage Roles</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={["authorization", "canManageAgencies"]} valuePropName={"checked"}>
                    <Checkbox>Manage Agencies</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={["authorization", "canManageClassifications"]} valuePropName={"checked"}>
                    <Checkbox>Manage Classifications</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={["authorization", "canManageCertifications"]} valuePropName={"checked"}>
                    <Checkbox>Manage Certifications</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={["authorization", "canManageProjects"]} valuePropName={"checked"}>
                    <Checkbox>Manage Projects</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
