import { Stack } from "@chakra-ui/react";
import React from "react";

import { Card } from "./Card";
import { Note } from "./Note";

export const Events: React.FC<{}> = () => {
  return (
    <Card scrollable title="Events">
      <Stack spacing="5">
        <Note title="11/12/20 12:34 Charlie">Filled PWS Customer Activity</Note>
        <Note title="11/12/20 12:34 Charlie">Filled PWS Reductions Activity</Note>
        <Note title="11/12/20 12:34 Charlie">Apply for PWS Draft Approval</Note>
      </Stack>
    </Card>
  );
};
