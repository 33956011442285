import { FieldTimeOutlined } from "@ant-design/icons";
import { Card, Space, Timeline, Typography } from "antd";
import moment from "moment";
import { Fragment } from "react";

type PwsEventsCardType = {
  events: any[];
  noCard?: boolean;
};

export const PwsEventsCard: React.FC<PwsEventsCardType> = (props) => {
  const { events, noCard } = props;

  const content = (
    <Fragment>
      <Timeline mode="left">
        {events.map((event) => (
          <Timeline.Item key={event.id} dot={<FieldTimeOutlined />}>
            <Space direction="vertical">
              {event.author}
              <Typography.Text type="secondary">{moment(event.insertedAt).format("MM-DD-YYYY HH:mm")}</Typography.Text>
              {event.description}
            </Space>
          </Timeline.Item>
        ))}
      </Timeline>
    </Fragment>
  );

  return noCard ? content : <Card title="Events">{content}</Card>;
};
