import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  UpdateUserMutation as UpdateUserMutationType,
  UpdateUserMutationVariables,
} from "./__generated__/UpdateUserMutation";

const UpdateUserMutation = loader("./UpdateUserMutation.gql");

type UpdateUserMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted: (data: UpdateUserMutationType) => void;
}) => ReactElement;

export const UpdateUserMutationProvider: UpdateUserMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<UpdateUserMutationType, UpdateUserMutationVariables>(UpdateUserMutation, {
    errorPolicy: "none",
    onError,
    onCompleted,
  });

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
