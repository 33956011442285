import React from "react";
import { Outlet } from "react-router";

import { AreaNav } from "./AreaNav";
import { MainContent } from "./MainContent";
import { MainNav } from "./MainNav";

export const Layout: React.FC<{}> = () => {
  return (
    <>
      <MainNav />
      <AreaNav />
      <MainContent>
        <Outlet />
      </MainContent>
    </>
  );
};
