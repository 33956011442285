import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  UpdateProjectSiteMutation as UpdateProjectSiteMutationType,
  UpdateProjectSiteMutationVariables,
} from "./__generated__/UpdateProjectSiteMutation";

const UpdateProjectSiteMutation = loader("./UpdateProjectSiteMutation.gql");

type UpdateProjectSiteMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted: (data: UpdateProjectSiteMutationType) => void;
}) => ReactElement;

export const UpdateProjectSiteMutationProvider: UpdateProjectSiteMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<UpdateProjectSiteMutationType, UpdateProjectSiteMutationVariables>(
    UpdateProjectSiteMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
