import { EditOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useApplicationContext } from "__legacy/components/Application";
import { AbilitiesQueryProvider } from "__legacy/queries/abilities/AbilitiesQueryProvider";
import { ApplyAbilityMutationProvider } from "__legacy/queries/abilities/ApplyAbilityMutationProvider";
import { Button, Col, Collapse, Layout, PageHeader, Popconfirm, Row, Space, Typography } from "antd";
import { Fragment } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";

export const List: React.FC<{ agency?: true }> = (props) => {
  const { agency } = props;
  const { agencyId, stakeholderId } = useParams();
  const navigate = useNavigate();
  const { getEnumValue } = useApplicationContext();

  return (
    <Fragment>
      <PageHeader
        ghost={true}
        title="Abilities"
        extra={
          agency && (
            <Button onClick={() => navigate("new")} type="primary">
              <PlusOutlined />
              Add Scheme
            </Button>
          )
        }
      />
      <Layout.Content className="content">
        <AbilitiesQueryProvider variables={{ agencyId, stakeholderId }}>
          {({ abilities }) => (
            <Collapse>
              {abilities.map((ability) => (
                <Collapse.Panel
                  key={ability.id}
                  header={
                    <Typography.Text style={{ lineHeight: "2.4" }}>
                      {ability.scheme.name} - Codes:{" "}
                      {
                        ability.candidacies.reduce(
                          (acc, candidacy) => [...acc, ...candidacy.candidacyClassifications],
                          [] as any[],
                        ).length
                      }
                    </Typography.Text>
                  }
                  extra={
                    agency &&
                    ["draft", "need_correction"].includes(ability.status) && (
                      <Space>
                        <Button onClick={() => navigate(`${ability.id}/edit`)} icon={<EditOutlined />} />
                        <ApplyAbilityMutationProvider>
                          {(loading, mutate) => (
                            <Popconfirm
                              title="Are you sure?"
                              placement="top"
                              icon={<QuestionCircleOutlined />}
                              onConfirm={() => mutate({ variables: { id: ability.id } })}
                            >
                              <Button type="primary" loading={loading}>
                                Apply
                              </Button>
                            </Popconfirm>
                          )}
                        </ApplyAbilityMutationProvider>
                      </Space>
                    )
                  }
                >
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Space>
                      <div>
                        <strong>Status: </strong>
                        <br />
                        {getEnumValue("abilityStatuses", ability.status)}
                      </div>
                      <div>
                        <strong>Level: </strong>
                        <br />
                        {getEnumValue("abilityLevels", ability.level)}
                      </div>
                      <div>
                        <strong>Course type: </strong>
                        <br />
                        {getEnumValue("abilityKinds", ability.kind)}
                      </div>
                      <div>
                        <strong>Course date: </strong>
                        <br />
                        {ability.journeyDate}
                      </div>
                      <div>
                        <strong>Validation date: </strong>
                        <br />
                        {ability.startDate}
                      </div>
                      <div>
                        <strong>Due date: </strong>
                        <br />
                        {ability.endDate}
                      </div>
                      {ability.certificate && (
                        <div>
                          <strong>Certificate: </strong>
                          <br />
                          <a href={ability.certificate} download>
                            Download
                          </a>
                        </div>
                      )}
                    </Space>
                    {ability.status === "approved" && (
                      <Fragment>
                        <PageHeader
                          ghost={true}
                          title=""
                          extra={
                            <Button onClick={() => navigate(`${ability.id}/candidacies/new`)} type="primary">
                              <PlusOutlined />
                              Add Codes
                            </Button>
                          }
                        />
                        <Row gutter={[24, 0]}>
                          <Col span={4}>
                            <strong>Status</strong>
                          </Col>
                          <Col span={15}>
                            <strong>Codes</strong>
                          </Col>
                          <Col span={3}>
                            <strong>Attachments</strong>
                          </Col>
                          <Col span={2}>
                            <strong>Actions</strong>
                          </Col>
                        </Row>
                        <Fragment>
                          {ability.candidacies.map((candidacy) => (
                            <Fragment key={candidacy.id}>
                              {candidacy.candidacyClassifications.map((candidacyClassification) => (
                                <Row key={candidacyClassification.id} gutter={[24, 0]}>
                                  <Col span={4}>
                                    <Typography.Text>
                                      {getEnumValue("reviewStatuses", candidacy.status)}
                                    </Typography.Text>
                                  </Col>
                                  <Col span={15}>
                                    {candidacyClassification.classification.name} -{" "}
                                    {candidacyClassification.classification.industry.name} -{" "}
                                    {candidacyClassification.classification.description}
                                  </Col>
                                  <Col span={3}>
                                    {candidacy.attachment && (
                                      <a href={candidacy.attachment} download>
                                        Download
                                      </a>
                                    )}
                                  </Col>
                                  <Col span={2}>
                                    {candidacy.status === "approved" && (
                                      <NavLink to={`${ability.id}/candidacies/${candidacy.id}`}>Detail</NavLink>
                                    )}
                                    {candidacy.status === "need_correction" && (
                                      <NavLink to={`${ability.id}/candidacies/${candidacy.id}/edit`}>Edit</NavLink>
                                    )}
                                  </Col>
                                </Row>
                              ))}
                            </Fragment>
                          ))}
                        </Fragment>
                      </Fragment>
                    )}
                  </Space>
                </Collapse.Panel>
              ))}
            </Collapse>
          )}
        </AbilitiesQueryProvider>
      </Layout.Content>
    </Fragment>
  );
};
