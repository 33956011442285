import { EditOutlined } from "@ant-design/icons";
import { ProjectStatus } from "__legacy/queries/__generated__/globalTypes";
import { ProjectStatusesQuery_projectStatuses_enumValues } from "__legacy/queries/enums/__generated__/ProjectStatusesQuery";
import { ProjectStatusesQueryProvider } from "__legacy/queries/enums/ProjectStatusesQueryProvider";
import { VisitsQueryProvider } from "__legacy/queries/projects/VisitsQueryProvider";
import { Button, Input, Layout, PageHeader, Space, Table, Typography } from "antd";
import { find } from "lodash";
import moment from "moment";
import { Fragment, useState } from "react";
import { useCookies } from "react-cookie";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

export const List: React.FC<{ agency?: true }> = (props) => {
  const { agencyId, kind, status } = useParams();
  const navigate = useNavigate();

  const cookieName = "sessionDate";
  const [cookies] = useCookies([cookieName]);
  let sessionDate = cookies[cookieName];
  sessionDate = sessionDate ? moment(sessionDate).format("YYYY-MM-DD") : null;

  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const handleSearch = (value: string) => setSearchQuery(value.trim());

  const actionLink = (resource: any) => {
    return `../../../${resource.audit.project.step}/${resource.audit.project.status}/${resource.audit.project.id}`;
  };

  const columns = (projectStatus: ProjectStatusesQuery_projectStatuses_enumValues[]) => {
    const columns: any[] = [
      {
        title: "Status",
        dataIndex: "status",
        render: (value: string, resource: any) => find(projectStatus, ({ name }) => name === value)?.description,
      },
      {
        title: "Project",
        render: (value: string, resource: any) => (
          <NavLink to={actionLink(resource)}>{resource.audit.project.code}</NavLink>
        ),
      },
      {
        title: "Site",
        render: (value: string, resource: any) => resource.site.name,
      },
      {
        title: "Mandays",
        dataIndex: "mandays",
      },
      {
        title: "Lead Auditor",
        render: (value: string, resource: any) => resource.leadAuditor?.companyName,
      },
      {
        title: "Due Date",
        render: (value: string, resource: any) =>
          resource.dueDate ? moment(resource.dueDate).format("MM-DD-YYYY") : null,
      },
      {
        width: "80px",
        title: "Actions",
        dataIndex: "id",
        render: (id: string, resource: any) => (
          <Button onClick={() => navigate(actionLink(resource))}>
            <EditOutlined />
          </Button>
        ),
      },
    ];

    return columns;
  };

  return (
    <VisitsQueryProvider
      variables={{ agencyId, kind: kind, status: status as ProjectStatus, sessionDate }}
    >
      {({ visits }) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title={
              <Space direction="vertical">
                {(kind ? (kind === "main" ? "Assessment" : "Surveillance") : "") + ` Packages Visits`}
                {sessionDate && (
                  <Typography.Title level={5} type="secondary">
                    Session Date: {sessionDate}
                  </Typography.Title>
                )}
              </Space>
            }
            extra={
              <Fragment>
                <Input.Search
                  allowClear
                  placeholder="search"
                  onSearch={handleSearch}
                  defaultValue={searchQuery ?? ""}
                  style={{ width: 500 }}
                />
              </Fragment>
            }
          />
          <Layout.Content className="content">
            <ProjectStatusesQueryProvider>
              {({ projectStatuses }) => (
                <Table
                  rowKey="id"
                  columns={columns(projectStatuses?.enumValues ?? [])}
                  dataSource={kind ? visits.filter((visit: any) => visit.audit.kind === kind) : visits}
                  pagination={false}
                />
              )}
            </ProjectStatusesQueryProvider>
          </Layout.Content>
        </Fragment>
      )}
    </VisitsQueryProvider>
  );
};
