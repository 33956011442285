import { useQuery } from "@apollo/client";
import { CenterResult } from "__legacy/components/CenterResult";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import { ExperiencesQuery as ExperiencesQueryType, ExperiencesQueryVariables } from "./__generated__/ExperiencesQuery";

const ExperiencesQuery = loader("./ExperiencesQuery.gql");

type ExperiencesQueryProviderType = (props: {
  children: (data: ExperiencesQueryType) => ReactNode;
  variables: ExperiencesQueryVariables;
}) => ReactElement;

export const ExperiencesQueryProvider: ExperiencesQueryProviderType = (props) => {
  const { children, variables } = props;

  const { error, loading, data } = useQuery<ExperiencesQueryType, ExperiencesQueryVariables>(ExperiencesQuery, {
    variables,
    fetchPolicy: "cache-and-network",
  });

  if (loading) return <CenterSpinner size="large" />;
  if (error || !data)
    return <CenterResult status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;

  return <Fragment>{children(data)}</Fragment>;
};
