import { EditOutlined } from "@ant-design/icons";
import { ReviewStatus } from "__legacy/queries/__generated__/globalTypes";
import { AbilitiesQueryProvider } from "__legacy/queries/abilities/AbilitiesQueryProvider";
import { AbilityLevelsQuery_abilityLevels_enumValues } from "__legacy/queries/enums/__generated__/AbilityLevelsQuery";
import { ReviewStatusesQuery_reviewStatuses_enumValues } from "__legacy/queries/enums/__generated__/ReviewStatusesQuery";
import { AbilityLevelsQueryProvider } from "__legacy/queries/enums/AbilityLevelsQueryProvider";
import { ReviewStatusesQueryProvider } from "__legacy/queries/enums/ReviewStatusesQueryProvider";
import { Button, Input, Layout, PageHeader, Table, Tabs } from "antd";
import { find } from "lodash";
import moment from "moment";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

export const List: React.FC<{}> = () => {
  const navigate = useNavigate();
  const [activePane, setActivePane] = useState<ReviewStatus>();

  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const handleSearch = (value: string) => setSearchQuery(value.trim());

  const columns = (
    reviewStatuses: ReviewStatusesQuery_reviewStatuses_enumValues[],
    abilityLevels: AbilityLevelsQuery_abilityLevels_enumValues[],
  ) => [
    {
      title: "Status",
      dataIndex: "status",
      render: (value: string, resource: any) => find(reviewStatuses, ({ name }) => name === value)?.description,
    },
    {
      title: "Auditor",
      render: (value: string, resource: any) => `${resource.stakeholder.companyName}`,
    },
    {
      title: "Agency",
      render: (value: string, resource: any) => resource.stakeholder.agency.name,
    },
    {
      title: "Scheme",
      render: (value: string, resource: any) => resource.scheme.name,
    },
    {
      title: "Level",
      dataIndex: "level",
      render: (value: string, resource: any) => find(abilityLevels, ({ name }) => name === value)?.description,
    },
    {
      title: "Application Date",
      dataIndex: "insertedAt",
      render: (value: string, resource: any) => moment(value).format("MM-DD-YYYY"),
    },
    {
      width: "80px",
      title: "Actions",
      dataIndex: "id",
      render: (id: string) => (
        <Button onClick={() => navigate(`${id}/review`)}>
          <EditOutlined />
        </Button>
      ),
    },
  ];

  return (
    <AbilitiesQueryProvider
      variables={{ applicationsOnly: true, status: activePane, search: searchQuery, filterAuthorities: true }}
    >
      {({ abilities }) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title="Schema Applications"
            extra={
              <Input.Search
                allowClear
                placeholder="search"
                onSearch={handleSearch}
                defaultValue={searchQuery ?? ""}
                style={{ width: 500 }}
              />
            }
            footer={
              <Tabs
                activeKey={activePane}
                onChange={(activeKey: any) => setActivePane(activeKey !== "all" ? activeKey : undefined)}
              >
                <Tabs.TabPane tab="All" key="all" />
                <Tabs.TabPane tab="For Review" key="for_review" />
                <Tabs.TabPane tab="Need Correction" key="need_correction" />
              </Tabs>
            }
          />
          <Layout.Content className="content">
            <ReviewStatusesQueryProvider>
              {({ reviewStatuses }) => (
                <AbilityLevelsQueryProvider>
                  {({ abilityLevels }) => (
                    <Table
                      rowKey="id"
                      columns={columns(reviewStatuses?.enumValues ?? [], abilityLevels?.enumValues ?? [])}
                      dataSource={abilities}
                      pagination={false}
                    />
                  )}
                </AbilityLevelsQueryProvider>
              )}
            </ReviewStatusesQueryProvider>
          </Layout.Content>
        </Fragment>
      )}
    </AbilitiesQueryProvider>
  );
};
