import { Button, Card, Col, Layout, PageHeader, Row, Typography } from "antd";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

export const Dash: React.FC<{}> = () => {
  const navigate = useNavigate();

  const cards = [
    { title: "Items", resource: "items" },
    { title: "Stages", resource: "stages" },
    { title: "Templates", resource: "templates" },
  ];

  return (
    <Fragment>
      <PageHeader ghost={true} title="Contract Activities" />
      <Layout.Content className="content">
        <Row gutter={[24, 24]}>
          {cards.map((card: any, j: any) => (
            <Col span={8} key={j}>
              <Card title={card.title}>
                <div style={{ minHeight: "100px", textAlign: "center" }}>
                  <Typography.Title level={3} type="secondary">
                    You can manage and overview here the Activities' {card.title}
                  </Typography.Title>
                  <Button type="primary" onClick={() => navigate(card.resource)}>
                    Go to list
                  </Button>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Layout.Content>
    </Fragment>
  );
};
