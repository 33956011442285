import { CloudUploadOutlined } from "@ant-design/icons";
import { useApplicationContext } from "__legacy/components/Application";
import { Reviews } from "__legacy/frames/shareds/projects/Reviews";
import { ProjectQueryProvider } from "__legacy/queries/projects/ProjectQueryProvider";
import { ReviewVisitMutationProvider } from "__legacy/queries/projects/ReviewVisitMutationProvider";
import { VisitQueryProvider } from "__legacy/queries/projects/VisitQueryProvider";
import {
  Alert,
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Layout,
  PageHeader,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { compact, isEmpty, isString } from "lodash";
import { Fragment, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ReviewQuestions } from "../../shareds/reviews/ReviewQuestions";
import { ProjectOverview } from "../projects/ProjectOverview";

const fields = [
  ["action", "action"],
  ["content", "content"],
  ["attachment", "attachment"],
];

export const onError = (form: any, setErrors: any) => ({ graphQLErrors }: any) => {
  const fieldsErrors: any[] = [];
  const errors: string[] = [];
  graphQLErrors.forEach((graphQLError: any) => {
    let found = false;
    fields.forEach(([fieldName, errorFieldName]) => {
      if (graphQLError.name === errorFieldName) {
        fieldsErrors.push({ name: fieldName, errors: (graphQLError as any).messages });
        found = true;
      }
    });
    if (!found) {
      errors.push(graphQLError.message);
    }
  });
  form.setFields(fieldsErrors);
  setErrors(errors);
};

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const input = {
    ...values,
    ...params,
    attachment: values.attachment?.file ?? null,
    questions: compact(values.questions),
  };

  mutate({ variables: { ...variables, input } });
};

export const Review: React.FC<{}> = () => {
  const { visitId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  const { getEnumValue } = useApplicationContext();

  const valuesChangeHandler = useCallback(
    (changedValues, allValues) => {
      const fieldValues: any[] = [];
      if (changedValues.attachment && changedValues.attachment.file.status === "removed") {
        fieldValues.push({ name: ["attachment"], value: null });
      }
      form.setFields(fieldValues);
    },
    [form],
  );

  const uploadProps = {
    maxCount: 1,
    accept: ".pdf, .doc, .xls, .xlsx",
    beforeUpload: () => {
      return false;
    },
  };

  const questions: any = {
    stage1: [
      {
        id: 10,
        ask: "Technical Competence",
        opts: "yn",
      },
      {
        id: 20,
        ask: "Itinerary",
        opts: "yn",
      },
      {
        id: 30,
        ask: "Findings raised",
        opts: "yn",
      },
      {
        id: 40,
        ask: "Technical Content of the Report",
        opts: "yn",
      },
      {
        id: 50,
        ask: "Stage 1 objectives achived",
        opts: "yn",
      },
      {
        id: 60,
        ask: "Lead auditor recommendation for stage 2",
        opts: "yn",
      },
      {
        id: 70,
        ask: "MSM Related",
        opts: "yn",
      },

      { id: 80, ask: "Is the package complete?", opts: "yna" },
      {
        id: 90,
        ask: "Lead Auditor Rating",
        opts: [1, 2, 3, 4, 5],
      },
    ],
    stage2: [
      { id: 10, ask: "Technical Competence (Audit team selection)", opts: "yn" },
      { id: 20, ask: "Itinerary (Audit Time and coverage)", opts: "yn" },
      { id: 30, ask: "3 year panning matrix completed correctly", opts: "yn" },
      { id: 40, ask: "CARs raised at this visit?", opts: "yn" },
      { id: 50, ask: "Root cause, correction and corrective action satisfactory", opts: "yna", depends: 40, if: "yes" },
      { id: 60, ask: "Previous findings addressed", opts: "yna" },
      { id: 70, ask: "Technical Content of the Report (level of detail)", opts: "yn" },
      { id: 80, ask: "Can the Scope of Certification be confirmed from the details in the report?", opts: "yn" },
      { id: 90, ask: "Audit objectives achived", opts: "yn" },
      { id: 100, ask: "Any outstanding change requests", opts: "yna" },
      {
        id: 110,
        ask: "If multi-site, have all planned pemanent and temorary sites been visited as shown in the MSM?",
        opts: "yna",
      },
      { id: 120, ask: "If no, has a suitable sample been achieved?", opts: "yna", depends: 110, if: "no" },
      { id: 130, ask: "Are reasons why additional sites weren't visited included?", opts: "yna" },
      {
        id: 140,
        ask: "If Re-Audit, have any customer complaints been received by the Accredited Office?",
        opts: "yn",
      },
      {
        id: 150,
        ask:
          "If yes, has this been investigated and resolved satisfactorily? If No, OSP and advise the Accredited Office",
        opts: "yn",
      },
      { id: 160, ask: "Administrative Client Details checked", opts: "yn" },
      { id: 170, ask: "Lead auditor recommendation for certififcation", opts: "yn" },
      { id: 180, ask: "Lead Auditor Rating", opts: [1, 2, 3, 4, 5] },
    ],
    surveillance: [
      { id: 10, ask: "Technical Competence (Audit team selection)", opts: "yn" },
      { id: 20, ask: "Itinerary (Audit Time and coverage)", opts: "yn" },
      { id: 30, ask: "3 year panning matrix completed correctly", opts: "yn" },
      { id: 40, ask: "CARs raised at this visit?", opts: "yn" },
      { id: 50, ask: "Root cause, correction and corrective action satisfactory", opts: "yna", depends: 40, if: "yes" },
      { id: 60, ask: "Previous findings addressed", opts: "yna" },
      { id: 70, ask: "Technical Content of the Report (level of detail)", opts: "yn" },
      { id: 80, ask: "Can the Scope of Certification be confirmed from the details in the report?", opts: "yn" },
      { id: 90, ask: "Audit objectives achived", opts: "yn" },
      { id: 100, ask: "Any outstanding change requests", opts: "yna" },
      {
        id: 110,
        ask: "If multi-site, have all planned pemanent and temorary sites been visited as shown in the MSM?",
        opts: "yna",
      },
      { id: 120, ask: "If no, has a suitable sample been achieved?", opts: "yna", depends: 110, if: "no" },
      { id: 130, ask: "Are reasons why additional sites weren't visited included?", opts: "yna" },
      {
        id: 140,
        ask: "If Re-Audit, have any customer complaints been received by the Accredited Office?",
        opts: "yna",
      },
      {
        id: 150,
        ask:
          "If yes, has this been investigated and resolved satisfactorily? If No, OSP and advise the Accredited Office",
        opts: "yn",
      },
      { id: 160, ask: "Administrative Client Details checked", opts: "yn" },
      { id: 170, ask: "Lead auditor recommendation for continue certification", opts: "yn" },
      { id: 180, ask: "Lead Auditor Rating", opts: [1, 2, 3, 4, 5] },
    ],
  };

  const getQuestions = (visit: any) => {
    const step = visit.audit.kind === "main" ? `stage${visit.audit.step}` : "surveillance";
    return questions[step] ?? null;
  };

  return (
    <VisitQueryProvider variables={{ id: visitId }}>
      {({ visit }) => (
        <ProjectQueryProvider variables={{ id: visit.audit.project.id }}>
          {({ project }) => (
            <ReviewVisitMutationProvider onError={onError(form, setErrors)} onCompleted={() => navigate("../..")}>
              {(loading, mutate) => (
                <Fragment>
                  <PageHeader
                    ghost={true}
                    title={`Review Visit`}
                    extra={
                      <Fragment>
                        <Button onClick={() => navigate("../..")}>Back to list</Button>
                        <Button type="primary" loading={loading} onClick={() => form.submit()}>
                          Save
                        </Button>
                      </Fragment>
                    }
                  />
                  <Layout.Content className="content">
                    <Card>
                      <Row gutter={[12, 0]}>
                        <Col span={12}>
                          <Collapse defaultActiveKey={["0"]}>
                            <Collapse.Panel key="0" header="Visit Overview">
                              <Row gutter={[24, 24]}>
                                <Col span={24}>
                                  <Space size="middle">
                                    {visit.audit.kind === "main" ? "Main Audit" : `Surveillance ${visit.audit.step}`}
                                    {visit.audit.kind === "main" && `STAGE ${visit.audit.step}`}
                                    {visit.site.name}
                                  </Space>
                                </Col>
                                <Col span={24}>
                                  <Space size="large">
                                    <span>{visit.mandays} Mandays</span>
                                    <span>{visit.reportDays} Off-Site days</span>
                                    <span>{visit.fee} Fee</span>
                                    {visit.includesSubsistence && <span>Includes Subsistence</span>}
                                    {visit.includesTravel && <span>Includes Travel</span>}
                                  </Space>
                                </Col>
                                <Col span={8}>
                                  Due Date: <b>{visit.dueDate}</b>
                                </Col>
                                <Col span={8}>
                                  Valid Date From: <b>{visit.dateFrom}</b>
                                </Col>
                                <Col span={8}>
                                  Valid Date To: <b>{visit.dateTo}</b>
                                </Col>
                                <Col span={8}>
                                  Lead Auditor: <b>{visit.leadAuditor?.companyName}</b>
                                </Col>
                                <Col span={8}>
                                  Invoiced: <b>{visit.invoiced ? "Yes" : "No"}</b>
                                </Col>
                                <Col span={24}>
                                  <Space direction="vertical" size="small">
                                    <span>
                                      Technical Experts:{" "}
                                      {visit.visitTechnicalExperts?.map((o: any, i: any) => (
                                        <b key={o.id}>{o.stakeholder.companyName}, </b>
                                      ))}
                                    </span>
                                    <span>
                                      Code Auditor:{" "}
                                      {visit.visitAuditors?.map((o: any, i: any) => (
                                        <b key={o.id}>{o.stakeholder.companyName}, </b>
                                      ))}
                                    </span>
                                    <span>
                                      Auditor under training - Code:{" "}
                                      {visit.visitCodeTrainings?.map((o: any, i: any) => (
                                        <b key={o.id}>{o.stakeholder.companyName}, </b>
                                      ))}
                                    </span>
                                    <span>
                                      Auditor under training - Role:{" "}
                                      {visit.visitRoleTrainings?.map((o: any, i: any) => (
                                        <b key={o.id}>{o.stakeholder.companyName}, </b>
                                      ))}
                                    </span>
                                  </Space>
                                </Col>
                                {visit.package && (
                                  <Fragment>
                                    <Col span={12}>
                                      <Space direction="vertical" size="small">
                                        <span>
                                          Audit Performed as Planned:{" "}
                                          <b>{visit.package.auditAsPlanned ? "Yes" : "No"}</b>
                                        </span>
                                        {!visit.package.auditAsPlanned && (
                                          <Fragment>
                                            <span>
                                              Visit Date: <b>{visit.package.effectiveAuditOn}</b>
                                            </span>
                                            <span>
                                              Justification: <b>{visit.package.effectiveAuditJustification}</b>
                                            </span>
                                          </Fragment>
                                        )}
                                        <span>
                                          CAR Raised: <b>{visit.package.carRaised ? "Yes" : "No"}</b>
                                        </span>
                                        <span>
                                          Obs Raised: <b>{visit.package.obsRaised ? "Yes" : "No"}</b>
                                        </span>
                                        {visit.package.obsRaised && (
                                          <span>
                                            Total: <b>{visit.package.obs}</b>
                                          </span>
                                        )}
                                      </Space>
                                    </Col>
                                    <Col span={12}>
                                      <Space direction="vertical" size="small">
                                        <span>
                                          Date Report Received from auditor: <b>{visit.package.reportReceivedOn}</b>
                                        </span>
                                        <span>
                                          Date Package Completed: <b>{visit.package.packageCompletedOn}</b>
                                        </span>
                                        <span>
                                          Statement of Applicability: <br />
                                          <b>{visit.package.applicabilityStatement}</b>
                                        </span>
                                        <span>
                                          Assessment File:{" "}
                                          {visit.package.report && (
                                            <b>
                                              <a href={visit.package.report}>Download</a>
                                            </b>
                                          )}
                                        </span>
                                      </Space>
                                    </Col>
                                  </Fragment>
                                )}
                                {!isEmpty(visit.package?.cars) && (
                                  <Col span={24}>
                                    <Table
                                      size="small"
                                      rowKey="id"
                                      columns={[
                                        {
                                          title: "Kind",
                                          dataIndex: "kind",
                                          render: (v: string, r: any) => getEnumValue("carKinds", v),
                                        },
                                        { title: "Client Res. Date", dataIndex: "clientResponseOn" },
                                        {
                                          title: "Auditor Accept",
                                          dataIndex: "auditorAcceptance",
                                          render: (v: any) => (v ? "Yes" : "No"),
                                        },
                                        { title: "Auditor Accept Date", dataIndex: "auditorAcceptanceOn" },
                                        {
                                          title: "Status",
                                          dataIndex: "status",
                                          render: (v: string) => getEnumValue("carStatuses", v),
                                        },
                                      ]}
                                      dataSource={visit.package?.cars ?? []}
                                      pagination={false}
                                    />
                                  </Col>
                                )}
                              </Row>
                            </Collapse.Panel>
                            <Collapse.Panel key="1" header="Project Overview">
                              <ProjectOverview project={project} />
                            </Collapse.Panel>
                            <Collapse.Panel key="2" header="History">
                              <Reviews reviews={visit.visitReviews} />
                            </Collapse.Panel>
                          </Collapse>
                        </Col>
                        <Col span={12}>
                          <Collapse defaultActiveKey={["0"]}>
                            <Collapse.Panel key="0" header="Review">
                              {errors.length > 0 && (
                                <Space direction="vertical" style={{ marginBottom: "10px" }}>
                                  {errors.map((error, index) => (
                                    <Alert key={index} type="error" message="Error" description={error} />
                                  ))}
                                </Space>
                              )}
                              <Form
                                layout="vertical"
                                form={form}
                                onValuesChange={valuesChangeHandler}
                                onFinish={onFinish(mutate, {}, { id: visitId })}
                              >
                                {getQuestions(visit) && (
                                  <Fragment>
                                    <ReviewQuestions questions={getQuestions(visit)} />
                                    <Divider />
                                  </Fragment>
                                )}
                                <Row gutter={[24, 0]}>
                                  <Col span={24}>
                                    <Form.Item
                                      name="action"
                                      label="Action"
                                      rules={[{ required: true, message: "is required" }]}
                                    >
                                      <Select
                                        showSearch
                                        optionLabelProp="label"
                                        filterOption={(input, option) => {
                                          let label = "";
                                          if (option?.label && isString(option.label)) label = option.label;
                                          let value = option?.value;
                                          return (
                                            label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                            value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                          );
                                        }}
                                      >
                                        {[
                                          { key: "approve", value: "APPROVE" },
                                          { key: "deny", value: "OSP" },
                                        ].map((option) => (
                                          <Select.Option key={option.key} value={option.key} label={option.value}>
                                            {option.value}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col span={24}>
                                    <Form.Item name="content" label="Remarks">
                                      <Input.TextArea />
                                    </Form.Item>
                                  </Col>
                                  <Col span={24}>
                                    <Form.Item name="attachment" valuePropName={"defaultFileList"}>
                                      <Dragger {...uploadProps}>
                                        <CloudUploadOutlined />
                                        Upload Attachment
                                      </Dragger>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Form>
                            </Collapse.Panel>
                          </Collapse>
                        </Col>
                      </Row>
                    </Card>
                  </Layout.Content>
                </Fragment>
              )}
            </ReviewVisitMutationProvider>
          )}
        </ProjectQueryProvider>
      )}
    </VisitQueryProvider>
  );
};
