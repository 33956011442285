import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { ProjectStatus, ProjectStep } from "__legacy/queries/__generated__/globalTypes";
import { ProjectStatusesQuery_projectStatuses_enumValues } from "__legacy/queries/enums/__generated__/ProjectStatusesQuery";
import { ProjectStatusesQueryProvider } from "__legacy/queries/enums/ProjectStatusesQueryProvider";
import { ProjectsQueryProvider } from "__legacy/queries/projects/ProjectsQueryProvider";
import { Button, Input, Layout, PageHeader, Table } from "antd";
import { find, startCase } from "lodash";
import { Fragment, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

export const List: React.FC<{ agency?: true }> = (props) => {
  const { agency } = props;
  const { agencyId, step, status } = useParams();
  const navigate = useNavigate();

  const [activePane] = useState<ProjectStatus>(status as ProjectStatus);

  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const handleSearch = (value: string) => setSearchQuery(value.trim());

  const actionLink = (resource: any) => {
    return !agency && ["pending"].includes(resource.status)
      ? `${resource.id}/review`
      : step === undefined
      ? `${resource.step}/${resource.id}`
      : resource.id;
  };

  const columns = (projectStatus: ProjectStatusesQuery_projectStatuses_enumValues[]) => {
    const columns: any[] = [
      {
        title: "Code",
        dataIndex: "code",
        render: (value: string, resource: any) => <NavLink to={actionLink(resource)}>{value}</NavLink>,
      },
      {
        title: "Customer",
        render: (value: string, resource: any) => resource.subject?.companyName,
      },
      {
        width: "80px",
        title: "Actions",
        dataIndex: "id",
        render: (id: string, resource: any) => (
          <Button onClick={() => navigate(actionLink(resource))}>
            <EditOutlined />
          </Button>
        ),
      },
    ];

    if (!agency)
      columns.unshift({
        title: "Agency",
        dataIndex: "agency.name",
        render: (value: string, resource: any) => resource.agency?.name,
      });

    columns.unshift(
      {
        title: "Status",
        dataIndex: "status",
        render: (value: string, resource: any) => find(projectStatus, ({ name }) => name === value)?.description,
      },
      {
        title: "Kind",
        dataIndex: "kind",
        render: (value: string, resource: any) => resource.kind,
      },
    );

    return columns;
  };

  // let title = "Projects";
  // switch (step) {
  //   case "application":
  //     title = "PWS";
  //     break;
  //   case "contract":
  //     title = "Signed Proposals";
  //     break;
  // }

  const linkTo = (to: string) => {
    let link = step ? `../${to}` : to;
    link = status ? `../${link}` : link;
    return link;
  };

  return (
    <ProjectsQueryProvider
      variables={{
        agencyId,
        search: searchQuery,
        step: step as ProjectStep,
        status: activePane,
        
        ...(!agency ? { filterAuthorities: true } : {}),
      }}
    >
      {({ projects }) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title={`Projects ${startCase(step) ?? ""}`}
            extra={
              <Fragment>
                <Input.Search
                  allowClear
                  placeholder="search"
                  onSearch={handleSearch}
                  defaultValue={searchQuery ?? ""}
                  style={{ width: 500 }}
                />
                {agency && status === "redaction" && (
                  <Button onClick={() => navigate(linkTo("new"))} type="primary">
                    <PlusOutlined />
                    New
                  </Button>
                )}
              </Fragment>
            }
            // footer={
            //   (!agencyId || !step) && (
            //     <Tabs
            //       activeKey={activePane}
            //       onChange={(activeKey: any) => setActivePane(activeKey !== "all" ? activeKey : undefined)}
            //     >
            //       <Tabs.TabPane tab="All" key="all" />
            //       <Tabs.TabPane tab="Redaction" key="redaction" />
            //       <Tabs.TabPane tab="Pending" key="pending" />
            //       <Tabs.TabPane tab="Approved" key="approved" />
            //       <Tabs.TabPane tab="Lost" key="lost" />
            //       {/* <Tabs.TabPane tab="Booking" key="booking" /> */}
            //     </Tabs>
            //   )
            // }
          />
          <Layout.Content className="content">
            <ProjectStatusesQueryProvider>
              {({ projectStatuses }) => (
                <Table
                  rowKey="id"
                  columns={columns(projectStatuses?.enumValues ?? [])}
                  dataSource={projects}
                  pagination={false}
                />
              )}
            </ProjectStatusesQueryProvider>
          </Layout.Content>
        </Fragment>
      )}
    </ProjectsQueryProvider>
  );
};
