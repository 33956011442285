import {
  CheckCircleTwoTone,
  CheckOutlined,
  ExclamationCircleTwoTone,
  LockOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Space } from "antd";
import { filter, isNil, startCase } from "lodash";

type PwsActivitiesCardType = {
  project?: any;
  activities: any[];
};

export const PwsActivitiesCard: React.FC<PwsActivitiesCardType> = (props) => {
  const { project, activities } = props;

  const creation = { id: "creation", main: true, locked: isNil(project), filled: !isNil(project) };
  const allActivities = [creation, ...activities];

  return (
    <Card title="Activities" extra={`${filter(allActivities, { filled: true }).length}/${allActivities.length}`}>
      {allActivities.map((activity: any, i: any) => {
        const icon = activity.filled ? (
          activity.main ? (
            <CheckOutlined />
          ) : (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          )
        ) : activity.percentage > 0 ? (
          <ExclamationCircleTwoTone twoToneColor="#faad14" />
        ) : activity.locked ? (
          <LockOutlined />
        ) : (
          <MinusCircleOutlined />
        );
        return (
          <Row key={i} gutter={[24, 8]} className={activity.main ? "primary" : ""}>
            <Col flex={1}>
              <Space>
                {icon} {startCase(activity.id)} {activity.percentage > 0 && `${activity.percentage}%`}
              </Space>
            </Col>
            {/* {activity.main && (
              <Col>
                <InfoCircleOutlined />
              </Col>
            )} */}
          </Row>
        );
      })}
    </Card>
  );
};
