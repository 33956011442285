import { Box, HStack, Icon, Link, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { FiPaperclip } from "react-icons/fi";

type NoteType = {
  title: string;
  file?: string;
  boxed?: boolean;
};
export const Note: React.FC<NoteType> = (props) => {
  const { children, title, file, boxed } = props;
  const boxProps = boxed ? { py: "1", px: "2", bg: "coolGray.100", borderRadius: "10px" } : {};
  return (
    <Stack>
      <HStack justify="space-between">
        <Text fontSize="sm" fontWeight="600" color="coolGray.400">
          {title}
        </Text>
        {file && (
          <Link download={file} color="blue.500">
            <Icon as={FiPaperclip} boxSize="4" />
          </Link>
        )}
      </HStack>
      <Box {...boxProps}>{children}</Box>
    </Stack>
  );
};
