import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  CreateAccreditationMutation as CreateAccreditationMutationType,
  CreateAccreditationMutationVariables,
} from "./__generated__/CreateAccreditationMutation";

const CreateAccreditationMutation = loader("./CreateAccreditationMutation.gql");

type CreateAccreditationMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted: (data: CreateAccreditationMutationType) => void;
}) => ReactElement;

export const CreateAccreditationMutationProvider: CreateAccreditationMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<CreateAccreditationMutationType, CreateAccreditationMutationVariables>(
    CreateAccreditationMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
