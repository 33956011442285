import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  CreateAgencyMutation as CreateAgencyMutationType,
  CreateAgencyMutationVariables,
} from "./__generated__/CreateAgencyMutation";

const CreateAgencyMutation = loader("./CreateAgencyMutation.gql");

type CreateAgencyMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted: (data: CreateAgencyMutationType) => void;
}) => ReactElement;

export const CreateAgencyMutationProvider: CreateAgencyMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<CreateAgencyMutationType, CreateAgencyMutationVariables>(
    CreateAgencyMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
