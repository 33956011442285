import { ClusterOutlined, FileDoneOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { SchemeQueryProvider } from "__legacy/queries/schemes/SchemeQueryProvider";
import { Button, Layout, Menu, PageHeader } from "antd";
import { Fragment, useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

export const Show: React.FC<{}> = () => {
  const { schemeId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState<string[]>(["info"]);

  useEffect(() => {
    if (location.pathname.includes("/accreditations")) setSelectedKey(["accreditations"]);
    else if (location.pathname.includes("/technicalAreas")) setSelectedKey(["technicalAreas"]);
    else setSelectedKey(["info"]);
  }, [location.pathname]);

  return (
    <SchemeQueryProvider variables={{ id: schemeId }}>
      {({ scheme }) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title={`Scheme ${scheme.name}`}
            extra={
              <Fragment>
                <Button onClick={() => navigate("../")}>Back to list</Button>
                <Button type="primary" onClick={() => navigate("./edit")}>
                  Edit
                </Button>
              </Fragment>
            }
          />
          <Layout.Content className="content">
            <Layout className="show-sidebar-bg" style={{ padding: "24px 0" }}>
              <Layout.Sider className="show-sidebar-bg" width={200}>
                <Menu mode="inline" key={`${selectedKey}`} defaultSelectedKeys={selectedKey} style={{ height: "100%" }}>
                  <Menu.Item key="info" icon={<InfoCircleOutlined />}>
                    <NavLink to="./">
                      <span>Info</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="technicalAreas" icon={<ClusterOutlined />}>
                    <NavLink to="./technicalAreas">
                      <span>Technical Areas</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="accreditations" icon={<FileDoneOutlined />}>
                    <NavLink to="./accreditations">
                      <span>Accreditations</span>
                    </NavLink>
                  </Menu.Item>
                </Menu>
              </Layout.Sider>
              <Layout.Content>
                <Outlet />
              </Layout.Content>
            </Layout>
          </Layout.Content>
        </Fragment>
      )}
    </SchemeQueryProvider>
  );
};
