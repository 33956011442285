import { useApplicationContext } from "__legacy/components/Application";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const MainRedirector: React.FC<{}> = () => {
  const { session } = useApplicationContext();
  const navigate = useNavigate();

  const employments = session.currentUser.employments;

  useEffect(() => {
    const dash = "/job-orders/system/dashboard";
    // if (session.currentUser.isAdmin) navigate(dash);
    if (employments?.find((e: any) => e.kind === "global")) navigate(dash);
    if (employments?.filter((e: any) => e.kind === "agency").length)
      navigate(`/${employments.filter((e: any) => e.kind === "agency")[0].agency.id}${dash}`);
  });

  return <CenterSpinner size="large" />;
};
