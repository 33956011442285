import {
  Badge,
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { HiLockClosed } from "react-icons/hi";
import { RiFilePaper2Line } from "react-icons/ri";

export const Summary: React.FC<{}> = () => {
  return (
    <VStack bg="white" boxShadow="sm">
      <Stack w="full" p="6" pb="2">
        <HStack justify="space-between">
          <HStack>
            <Badge colorScheme="blue">SYSTEM</Badge>
            <Badge colorScheme="green">FIRST CERTIFICATION</Badge>
          </HStack>
          <Box>
            <Tooltip hasArrow label="Download certificate" placement="left">
              <Button px="0" variant="outline" colorScheme="blue" aria-label="Download">
                <RiFilePaper2Line />
              </Button>
            </Tooltip>
          </Box>
        </HStack>
        <HStack w="full" justify="space-between">
          <Box>
            <Heading size="lg">GOLD ENTERPRISE SRL</Heading>
          </Box>
          <Box>
            <Heading size="md" color="blue.500">
              ITA-12345-1-QMS
            </Heading>
          </Box>
        </HStack>
        <HStack w="full" justify="space-between">
          <Box>
            <Text>5 sites with sampling - 25 classifications - 13% reductions</Text>
          </Box>
          <Box>
            <Text>Pws: 2 of 15 activities, 7 reviews</Text>
          </Box>
        </HStack>
        <HStack w="full" justify="space-between">
          <Box>
            <Text>ISO 9001 - UKAS</Text>
          </Box>
          <Box>
            <Text>Alfonso Pagliuca 15/10/2020 11:33 - Khairul Bashar 28/10/2020 10:33</Text>
          </Box>
        </HStack>
      </Stack>
      <Stack w="full" px="6">
        <Tabs variant="unstyled">
          <TabList>
            <Tab
              fontSize="sm"
              fontWeight="bold"
              p="2"
              mx="px"
              borderBottom="1px"
              borderBottomColor="transparent"
              color="coolGray.400"
              _selected={{ color: "coolGray.700", borderBottomColor: "coolGray.700" }}
              _hover={{ color: "coolGray.600", borderBottomColor: "coolGray.600" }}
            >
              PWS
            </Tab>
            <Tab
              fontSize="xs"
              fontWeight="bold"
              p="2"
              mx="px"
              borderBottom="1px"
              borderBottomColor="transparent"
              color="coolGray.400"
              _selected={{ color: "coolGray.700", borderBottomColor: "coolGray.700" }}
              _hover={{ color: "coolGray.600", borderBottomColor: "coolGray.600" }}
            >
              MAIN AUDIT
            </Tab>
            <Tab
              fontSize="xs"
              fontWeight="bold"
              p="2"
              mx="px"
              borderBottom="1px"
              borderBottomColor="transparent"
              color="coolGray.400"
              _selected={{ color: "coolGray.700", borderBottomColor: "coolGray.700" }}
              _hover={{ color: "coolGray.600", borderBottomColor: "coolGray.600" }}
            >
              SURVEILLANCE 1
            </Tab>
            <Tab
              fontSize="xs"
              fontWeight="bold"
              p="1"
              mx="px"
              borderBottom="1px"
              borderBottomColor="transparent"
              color="coolGray.400"
              isDisabled
            >
              <Icon as={HiLockClosed} boxSize="3" mr="1" />
              SURVEILLANCE 2
            </Tab>
          </TabList>
        </Tabs>
      </Stack>
    </VStack>
  );
};
