import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  CreateUserMutation as CreateUserMutationType,
  CreateUserMutationVariables,
} from "./__generated__/CreateUserMutation";

const CreateUserMutation = loader("./CreateUserMutation.gql");

type CreateUserMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted: (data: CreateUserMutationType) => void;
}) => ReactElement;

export const CreateUserMutationProvider: CreateUserMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<CreateUserMutationType, CreateUserMutationVariables>(CreateUserMutation, {
    errorPolicy: "none",
    onError,
    onCompleted,
  });

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
