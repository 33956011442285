import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  UpdateEmploymentMutation as UpdateEmploymentMutationType,
  UpdateEmploymentMutationVariables,
} from "./__generated__/UpdateEmploymentMutation";

const UpdateEmploymentMutation = loader("./UpdateEmploymentMutation.gql");

type UpdateEmploymentMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted: (data: UpdateEmploymentMutationType) => void;
}) => ReactElement;

export const UpdateEmploymentMutationProvider: UpdateEmploymentMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<UpdateEmploymentMutationType, UpdateEmploymentMutationVariables>(
    UpdateEmploymentMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
