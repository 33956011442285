import { AgencyQueryProvider } from "__legacy/queries/agencies/AgencyQueryProvider";
import { UpdateAgencyMutationProvider } from "__legacy/queries/agencies/UpdateAgencyMutationProvider";
import { Button, Form, Layout, PageHeader } from "antd";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AgencyForm, onError, onFinish } from "./AgencyForm";

export const Edit: React.FC<{}> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <AgencyQueryProvider variables={{ id }}>
      {({ agency }) => (
        <UpdateAgencyMutationProvider onError={onError(form, setErrors)} onCompleted={() => navigate("../")}>
          {(loading, mutate) => (
            <Fragment>
              <PageHeader
                ghost={true}
                title={`Edit agency ${agency.name}`}
                extra={
                  <Fragment>
                    <Button onClick={() => navigate("../../")}>Back to list</Button>
                    <Button onClick={() => navigate("../")}>Back to details</Button>
                    <Button type="primary" loading={loading} onClick={() => form.submit()}>
                      Save
                    </Button>
                  </Fragment>
                }
              />
              <Layout.Content className="content">
                <AgencyForm
                  action="update"
                  form={form}
                  handler={onFinish(mutate, {}, { id })}
                  values={agency}
                  errors={errors}
                />
              </Layout.Content>
            </Fragment>
          )}
        </UpdateAgencyMutationProvider>
      )}
    </AgencyQueryProvider>
  );
};
