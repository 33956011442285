import { FileDoneOutlined, PlusOutlined, PrinterOutlined } from "@ant-design/icons";
import { useApplicationContext } from "__legacy/components/Application";
import { CloneProjectMutationProvider } from "__legacy/queries/projects/CloneProjectMutationProvider";
import { RenderProjectMutationProvider } from "__legacy/queries/projects/RenderProjectMutationProvider";
import { Button, Card, Col, Dropdown, Menu, Row, Skeleton, Space, Tag, Typography } from "antd";
import { camelCase } from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router";

const { Title } = Typography;

type ProjectSummaryType = {
  project?: any;
  tabs?: any[];
  currentTab?: any;
  tabChange?: (tab: any) => void;
};

export const ProjectSummary: React.FC<ProjectSummaryType> = (props) => {
  const { project, tabs, currentTab, tabChange } = props;
  const navigate = useNavigate();
  const { getEnumOptions } = useApplicationContext();
  const templates = getEnumOptions("projectCertificates", { raw: true }).map((o) => {
    return { code: o.name, name: o.description };
  });

  const [template, setTemplate] = useState(templates[0].code);

  let codes = 0,
    employees = 0;
  project?.projectSites.forEach((ps: any) => {
    codes += ps.projectSiteClassifications.length;
    employees += ps.employeesEffective;
  });

  const clonable = project?.status !== "archived" && project?.step === "surveillance";
  const printable = clonable;

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <Card bordered={false}>
        {!project && <Skeleton />}
        {project && (
          <Row>
            <Col flex={1}>
              <Space direction="vertical" size="middle">
                <Space>
                  <Tag color="blue">{project.kind?.toUpperCase()}</Tag>
                  <Tag color="green">{project.reason?.toUpperCase()}</Tag>
                  <Tag color="red">{project.status?.toUpperCase()}</Tag>
                </Space>
                <Title level={3}>{project.subject?.companyName ?? null}</Title>
                <Space size="middle">
                  <span>
                    {project.projectSites.length} {project.projectSites.length === 1 ? "site" : "sites"}{" "}
                    {project.sampling ? "with sampling" : null}
                  </span>
                  <span>
                    {codes} {codes === 1 ? "classification" : "classifications"}
                  </span>
                  <span>{employees} total employees</span>
                  {project.mandays[0]?.risk && (
                    <Tag color="warning">{project.mandays[0].risk.toUpperCase()} Risk Level</Tag>
                  )}
                  <span>
                    {project.totalReductionPercentage ? `${project.totalReductionPercentage}% reductions` : null}
                  </span>
                  <span>
                    {project.increasesOther && project.increasesPercentage > 0
                      ? `${project.increasesPercentage}% increases`
                      : null}
                  </span>
                </Space>
                <span>
                  {project.scheme?.name} - {project.accreditation?.authority?.name}
                </span>
              </Space>
            </Col>
            <Col>
              <Row gutter={[24, 8]} justify="end">
                <Col>
                  {project.questionaire && (
                    <Button href={project.questionaire} download type="primary" icon={<FileDoneOutlined />} />
                  )}
                </Col>
                <Col>
                  {printable && (
                    <RenderProjectMutationProvider
                      onCompleted={({ renderProject: { project } }: any) => {
                        window.location.assign(project[camelCase(template)]);
                      }}
                    >
                      {(loading, mutate) => (
                        <Dropdown
                          overlay={
                            <Menu>
                              {templates.map((o) => (
                                <Menu.Item
                                  key="1"
                                  onClick={() => {
                                    setTemplate(o.code);
                                    mutate({ variables: { id: project.id, template: o.code } });
                                  }}
                                >
                                  {o.name}
                                </Menu.Item>
                              ))}
                            </Menu>
                          }
                        >
                          <Button type="primary" size="large" loading={loading}>
                            <PrinterOutlined />
                            Print Certificate
                          </Button>
                        </Dropdown>
                      )}
                    </RenderProjectMutationProvider>
                  )}
                </Col>
              </Row>
              <Row justify="end">
                <Col>
                  <Title className="primary" level={3}>
                    {project.code}
                  </Title>
                </Col>
              </Row>
              <Row gutter={[24, 8]} justify="end"></Row>
              <Row justify="end">
                {/* <Col>Alfonso Pagliuca 15/10/2020 11:33 - Khairul Bashar 28/10/2020 10:33</Col> */}
              </Row>
            </Col>
          </Row>
        )}
      </Card>
      {tabs && tabChange && (
        <Card
          bordered={false}
          tabList={tabs}
          onTabChange={tabChange}
          activeTabKey={currentTab}
          bodyStyle={{ display: "none" }}
          tabBarExtraContent={
            clonable && (
              <CloneProjectMutationProvider
                onError={() => null}
                onCompleted={({ cloneProject: { newProject } }) =>
                  navigate(`../../../application/redaction/${newProject.id}`)
                }
              >
                {(loading, mutate) => (
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key="1" onClick={() => mutate({ variables: { id: project.id, newCode: false } })}>
                          Same Code
                        </Menu.Item>
                        <Menu.Item key="2" onClick={() => mutate({ variables: { id: project.id, newCode: true } })}>
                          New Code
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button type="primary" ghost loading={loading}>
                      <PlusOutlined />
                      Extra Visit
                    </Button>
                  </Dropdown>
                )}
              </CloneProjectMutationProvider>
            )
          }
        />
      )}
    </Card>
  );
};
