import { useQuery } from "@apollo/client";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { Select } from "antd";
import i18n from "i18next";
import i18nXhrBackend from "i18next-xhr-backend";
import { useCallback } from "react";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { BrowserRouter as Router } from "react-router-dom";
import { throwOnCondition, throwOnError, throwOnNull } from "utilities/throwUtilities";

import { ApplicationContextProvider } from "./ApplicationContext";
import { getContextQuery } from "./getContextQuery";

const enumList = [
  "carKinds",
  "carStatuses",
  "reviewStatuses",
  "abilityStatuses",
  "abilityLevels",
  "abilityKinds",
  "schemeMandaysKinds",
  "absenceKinds",
  "roleKinds",
  "projectCertificates",
];
// todo: complete list

export type WrapperProps = {
  debug: boolean;
  siteEnvironment: {
    name: string;
    maintenance: boolean;
    appEndpoint: string;
    cdnEndpoint: string;
    siteOffice: string;
    sessionCookie: string;
    sessionDomain: string;
  };
  token: string;
  setToken: (token: string, persistent: boolean) => void;
  clearToken: () => void;
};

export const Wrapper: React.FC<WrapperProps> = (props) => {
  const { debug, siteEnvironment, token, setToken, clearToken, children } = props;
  const contextQuery = getContextQuery(token, enumList);
  const { loading, error, data, refetch } = useQuery(contextQuery);

  const setSessionToken = useCallback(
    (token: string, persistent: boolean) => {
      setToken(token, persistent);
    },
    [setToken],
  );

  const clearSessionToken = useCallback(() => {
    clearToken();
  }, [clearToken]);

  const refetchSession = useCallback(() => {
    refetch();
  }, [refetch]);

  const getEnumOptions = useCallback(
    (name: string, opts?: any) => {
      if (opts?.raw) return data[name].enumValues;
      else
        return data[name].enumValues.map((o: any) => (
          <Select.Option key={o.name} value={o.name} label={o.description}>
            {o.description}
          </Select.Option>
        ));
    },
    [data],
  );

  const getEnumValue = useCallback(
    (name: string, value: string) => {
      return data[name].enumValues.find((o: any) => o.name === value)?.description;
    },
    [data],
  );

  if (loading) return <CenterSpinner size="large" />;

  const subject = "Application.Wrapper";
  const message = "Can't get context";
  throwOnError(error, subject, message);
  throwOnNull(data, subject, message, "data is null");
  throwOnNull(data.environment, subject, message, "environment is null");
  throwOnNull(data.default, subject, message, "default is null");
  throwOnCondition(token !== undefined && token !== "" && !data.session, subject, message, "account session not found");

  const coreDefault = data.default;
  const coreEnvironment = data.environment;
  const session = data.session ?? {};
  const authenticated = (token ?? false) && (session.id ?? false) && true;

  const enums: any = {};
  enumList.forEach((e) => (enums[e] = data[e]));

  const applicationContext = {
    debug,
    coreDefault,
    coreEnvironment,
    siteEnvironment,
    session,
    authenticated,
    configuration: {
      language: session?.currentUser?.language ?? coreDefault?.language ?? "EN",
      timezone: session?.currentUser?.timezone ?? coreDefault?.timezone ?? "EUROPE_ROME",
    },
    setSessionToken,
    clearSessionToken,
    refetchSession,
    enums,
    getEnumOptions,
    getEnumValue,
  };

  i18n
    .use(i18nXhrBackend)
    .use(initReactI18next)
    .init({
      lng: applicationContext.configuration.language.toLowerCase(),
      fallbackLng: "en",
      debug,
      interpolation: {
        escapeValue: false,
      },
      ns: ["globals"],
      defaultNS: "globals",
      backend: {
        loadPath: "/locales/{{lng}}/{{ns}}.json",
      },
    });

  return (
    <ApplicationContextProvider value={applicationContext}>
      <I18nextProvider i18n={i18n}>
        <Router>{children}</Router>
      </I18nextProvider>
    </ApplicationContextProvider>
  );
};
