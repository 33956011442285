import * as AbsintheSocket from "@absinthe/socket";
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import { ApolloClient, InMemoryCache, NormalizedCacheObject, split } from "@apollo/client";
import { setContext } from "@apollo/link-context";
import { onError } from "@apollo/link-error";
import { hasSubscription } from "@jumpn/utils-graphql";
import { Socket as PhoenixSocket } from "phoenix";

const { createLink } = require("apollo-absinthe-upload-link");

type useApolloClientType = (
  endpoint: string,
  token: string | undefined,
  clearToken: () => void,
) => ApolloClient<NormalizedCacheObject>;

export const useApolloClient: useApolloClientType = (endpoint, token, clearToken) => {
  const apiEndpoint = endpoint.endsWith("/") ? endpoint.slice(0, -1) : endpoint;

  // Apollo also requires you to provide a cache implementation
  // for caching query results. The InMemoryCache is suitable
  // for most use cases.
  const cache = new InMemoryCache();

  if (token === "" || token === undefined)
    return new ApolloClient({
      uri: `${apiEndpoint}/graphql`,
      cache,
    });

  // Create an HTTP link to the Absinthe server.
  const httpLink = createLink({ uri: `${apiEndpoint}/graphql` });

  // Use setContext to create a chainable link object that sets
  // the token cookie to the Authorization header.
  const authLink = setContext((_, { headers }) => {
    // Add the new Authorization header.
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });

  // Chain the HTTP link and the authorization link.
  const authedHttpLink = authLink.concat(httpLink);

  // Handle invalid session errors
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((graphQLError) => {
        const { name, message } = graphQLError;
        console.debug(`[GraphQL]: error ${name}: ${message}`, graphQLError);
      });

      graphQLErrors.some(({ name }) => {
        if (name === "unauthenticated") {
          clearToken();
          return true;
        }
        return false;
      });
    }

    if (networkError) {
      console.debug(`[GraphQL]: network error`, networkError);
    }
  });

  // Chain the authed HTTP link and the error link.
  const managedHttpLink = errorLink.concat(authedHttpLink);

  // Create a standard Phoenix websocket connection. If you need
  // to provide additional params, like an authentication token,
  // you can configure them in the `params` option.
  const phoenixSocket = new PhoenixSocket(`${apiEndpoint}/socket`, {
    params: () => {
      return { token: token };
    },
  });

  // Wrap the Phoenix socket in an AbsintheSocket.
  const absintheSocket = AbsintheSocket.create(phoenixSocket);

  // Create an Apollo link from the AbsintheSocket instance.
  const websocketLink = createAbsintheSocketLink(absintheSocket);

  // If the query contains a subscription, send it through the
  // websocket link. Otherwise, send it through the HTTP link.
  const link = split((operation) => hasSubscription(operation.query), websocketLink, managedHttpLink);

  // Create the client.
  const client = new ApolloClient({
    link,
    cache,
  });

  return client;
};
