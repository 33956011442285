import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  UpdateAccreditationMutation as UpdateAccreditationMutationType,
  UpdateAccreditationMutationVariables,
} from "./__generated__/UpdateAccreditationMutation";

const UpdateAccreditationMutation = loader("./UpdateAccreditationMutation.gql");

type UpdateAccreditationMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted: (data: UpdateAccreditationMutationType) => void;
}) => ReactElement;

export const UpdateAccreditationMutationProvider: UpdateAccreditationMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<UpdateAccreditationMutationType, UpdateAccreditationMutationVariables>(
    UpdateAccreditationMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
