import { FileAddOutlined } from "@ant-design/icons";
import { Alert, Card, Col, Form, Input, Row, Space } from "antd";
import { FormInstance } from "antd/lib/form";
import Dragger from "antd/lib/upload/Dragger";
import { toNumber } from "lodash";
import { useCallback } from "react";

type CodeFormType = {
  action: string;
  form: FormInstance<any>;
  handler: (values: any) => void;
  values: any;
  errors: string[];
};

const fields = [
  ["name", "name"],
  ["institution", "institution"],
  ["startYear", "startYear"],
  ["endYear", "endYear"],
  ["qualification", "qualification"],
  ["details", "details"],
  ["certificate", "certificate"],
];

export const onError = (form: any, setErrors: any) => ({ graphQLErrors }: any) => {
  const fieldsErrors: any[] = [];
  const errors: string[] = [];
  graphQLErrors.forEach((graphQLError: any) => {
    let found = false;
    fields.forEach(([fieldName, errorFieldName]) => {
      if (graphQLError.name === errorFieldName) {
        fieldsErrors.push({ name: fieldName, errors: (graphQLError as any).messages });
        found = true;
      }
    });
    if (!found) {
      errors.push(graphQLError.message);
    }
  });
  // console.log(fieldsErrors);
  // console.log(errors);
  form.setFields(fieldsErrors);
  setErrors(errors);
};

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const input = {
    ...values,
    ...params,
    startYear: toNumber(values.startYear),
    endYear: toNumber(values.endYear),
    certificate: values.certificate?.file ?? null,
  };

  mutate({ variables: { ...variables, input } });
};

export const TrainingForm: React.FC<CodeFormType> = (props) => {
  const { action, form, handler, values, errors } = props;

  console.log(`Agencies/Collaborators/Trainings/TrainingForm action: ${action}`);

  const valuesChangeHandler = useCallback(
    (changedValues, allValues) => {
      const fieldValues: any[] = [];
      if (changedValues.certificate && changedValues.certificate.file.status === "removed") {
        fieldValues.push({ name: ["certificate"], value: null });
      }
      form.setFields(fieldValues);
    },
    [form],
  );

  const initialValues = {
    ...values,
    certificate: values.certificate ? [{ url: values.certificate, name: "Download" }] : [],
  };

  const uploadProps = {
    maxCount: 1,
    accept: ".pdf, .doc, .xls, .xlsx",
    beforeUpload: () => {
      return false;
    },
  };

  return (
    <Card>
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Form
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onValuesChange={valuesChangeHandler}
        onFinish={handler}
      >
        <Row gutter={[24, 0]}>
          <Col span={8}>
            <Form.Item name="name" label="Name" rules={[{ required: true, message: "is required" }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="institution" label="Institution" rules={[{ required: true, message: "is required" }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={8}>
            <Form.Item name="startYear" label="StartYear" rules={[{ required: true, message: "is required" }]}>
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="endYear" label="EndYear" rules={[{ required: true, message: "is required" }]}>
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={16}>
            <Form.Item name="qualification" label="Qualification" rules={[{ required: true, message: "is required" }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={16}>
            <Form.Item name="details" label="Details" rules={[{ required: true, message: "is required" }]}>
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={8}>
            <Form.Item name="certificate" label="Certificate" valuePropName={"defaultFileList"}>
              <Dragger {...uploadProps}>
                <FileAddOutlined />
                Click or drop here
              </Dragger>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
