import { CertificationKindsQueryProvider } from "__legacy/queries/enums/CertificationKindsQueryProvider";
import { SchemeMandaysKindsQueryProvider } from "__legacy/queries/enums/SchemeMandaysKindsQueryProvider";
import { Alert, Card, Col, Form, Input, InputNumber, Row, Select, Space, Switch } from "antd";
import { FormInstance } from "antd/lib/form";
import { isString, omit, toNumber } from "lodash";

type SchemeFormType = {
  action: string;
  form: FormInstance<any>;
  handler: (values: any) => void;
  values: any;
  errors: string[];
};

const fields = [
  ["kind", "kind"],
  ["name", "name"],
];

export const onError =
  (form: any, setErrors: any) =>
  ({ graphQLErrors }: any) => {
    const fieldsErrors: any[] = [];
    const errors: string[] = [];
    graphQLErrors.forEach((graphQLError: any) => {
      let found = false;
      fields.forEach(([fieldName, errorFieldName]) => {
        if (graphQLError.name === errorFieldName) {
          fieldsErrors.push({ name: fieldName, errors: (graphQLError as any).messages });
          found = true;
        }
      });
      if (!found) {
        errors.push(graphQLError.message);
      }
    });
    form.setFields(fieldsErrors);
    setErrors(errors);
  };

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const cleanValues = {
    ...omit(values, []),
    ...params,
    duration: toNumber(values.duration),
    frequency: toNumber(values.frequency),
    samplingThreshold: toNumber(values.samplingThreshold),
  };

  mutate({ variables: { ...variables, input: cleanValues } });
};

export const SchemeForm: React.FC<SchemeFormType> = (props) => {
  const { action, form, handler, values, errors } = props;

  console.log(`Globals/Certifications/SchemeForm action: ${action}`);

  return (
    <Card>
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Form layout="vertical" form={form} initialValues={values} onFinish={handler}>
        <Row gutter={[24, 0]}>
          {action === "create" && (
            <Col span={6}>
              <CertificationKindsQueryProvider>
                {({ certificationKinds }) => (
                  <Form.Item name="kind" label="Kind" rules={[{ required: true, message: "is required" }]}>
                    <Select
                      showSearch
                      optionLabelProp="label"
                      filterOption={(input, option) => {
                        let label = "";
                        if (option?.label && isString(option.label)) label = option.label;
                        let value = option?.value;
                        return (
                          label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {certificationKinds?.enumValues?.map((certificationKind) => (
                        <Select.Option
                          key={certificationKind.name}
                          value={certificationKind.name}
                          label={certificationKind.description}
                        >
                          {certificationKind.description}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </CertificationKindsQueryProvider>
            </Col>
          )}
          <Col span={12}>
            <Form.Item name="name" label="Name" rules={[{ required: true, message: "is required" }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="projectCodeSuffix"
              label="Project Code Suffix"
              rules={[{ required: true, message: "is required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Form.Item dependencies={["kind"]}>
              {(form: FormInstance) => (
                <Form.Item name="duration" label="Duration" rules={[{ required: true, message: "is required" }]}>
                  <InputNumber type="number" min={0} max={form.getFieldValue("kind") === "system" ? 3 : 10} />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="frequency" label="Frequency" rules={[{ required: true, message: "is required" }]}>
              <InputNumber type="number" min={0} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="samplingThreshold" label="Sampling Threshold">
              <InputNumber min={0} step={5} max={100} formatter={(value) => (value ? `${value}%` : "")} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <SchemeMandaysKindsQueryProvider>
              {({ schemeMandaysKinds }) => (
                <Form.Item name="mandaysKind" label="Mandays Kind">
                  <Select
                    showSearch
                    optionLabelProp="label"
                    filterOption={(input, option) => {
                      let label = "";
                      if (option?.label && isString(option.label)) label = option.label;
                      let value = option?.value;
                      return (
                        label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {schemeMandaysKinds?.enumValues?.map((kind: any) => (
                      <Select.Option key={kind.name} value={kind.name} label={kind.description}>
                        {kind.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </SchemeMandaysKindsQueryProvider>
          </Col>
        </Row>
        {action === "update" && (
          <Row>
            <Col span={8}>
              <Form.Item name="isEnabled" label="Is enabled?" valuePropName={"checked"}>
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Card>
  );
};
