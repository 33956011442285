import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  ApplyAbilityMutation as ApplyAbilityMutationType,
  ApplyAbilityMutationVariables,
} from "./__generated__/ApplyAbilityMutation";

const ApplyAbilityMutation = loader("./ApplyAbilityMutation.gql");

type ApplyAbilityMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError?: (error: ApolloError) => void;
  onCompleted?: (data: ApplyAbilityMutationType) => void;
}) => ReactElement;

export const ApplyAbilityMutationProvider: ApplyAbilityMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<ApplyAbilityMutationType, ApplyAbilityMutationVariables>(
    ApplyAbilityMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
