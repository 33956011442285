import {
  CandidacyQuery_candidacy_candidacyClassifications,
} from "__legacy/queries/candidacies/__generated__/CandidacyQuery";
import { ClassificationsQueryProvider } from "__legacy/queries/classifications/ClassificationsQueryProvider";
import { SchemeQuery_scheme } from "__legacy/queries/schemes/__generated__/SchemeQuery";
import { Form, Transfer } from "antd";

type ClassificationTransferType = {
  scheme: SchemeQuery_scheme;
  candidacyClassifications: CandidacyQuery_candidacy_candidacyClassifications[];
};

export const ClassificationTransfer: React.FC<ClassificationTransferType> = (props) => {
  const { scheme, candidacyClassifications } = props;

  return (
    <ClassificationsQueryProvider variables={{ kind: scheme.kind, schemeId: scheme.id }}>
      {({ classifications }) => (
        <Form.Item
          name="candidacyClassifications"
          valuePropName="targetKeys"
          label="Classifications"
          initialValue={candidacyClassifications.map(
            (candidacyClassification) => candidacyClassification.classification.id,
          )}
        >
          <Transfer
            titles={["Availables", "Abiliteds"]}
            listStyle={{ height: "500px", width: "50%" }}
            showSearch
            filterOption={(inputValue, item) => {
              let itemText = `${item.industryName} - ${item.title} - ${item.description}`;
              item.areas.forEach((area: any) => {
                itemText += `- ${area.name} - ${area.description}`;
              });
              itemText = itemText.toLowerCase();
              const searchValue = inputValue.toLowerCase();
              return itemText.indexOf(searchValue) > -1;
            }}
            render={(item) => {
              let itemText = `${item.industryName} - ${item.title} - ${item.description}`;
              let tas: string[] = [];
              item.areas?.forEach((area: any) => {
                tas.push(area.name);
              });
              itemText += ` (${tas.join(", ")})`;
              return itemText ?? item.key;
            }}
            dataSource={classifications.map((classification) => ({
              key: classification.id,
              title: classification.name,
              description: classification.description,
              industryName: classification.industry.name,
              areas: classification.areas,
              disabled: false,
            }))}
          />
        </Form.Item>
      )}
    </ClassificationsQueryProvider>
  );
};
