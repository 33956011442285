import { useQuery } from "@apollo/client";
import { CenterResult } from "__legacy/components/CenterResult";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import { SitesQuery as SitesQueryType, SitesQueryVariables } from "./__generated__/SitesQuery";

const SitesQuery = loader("./SitesQuery.gql");

type SitesQueryProviderType = (props: {
  children: (data: SitesQueryType) => ReactNode;
  variables: SitesQueryVariables;
}) => ReactElement;

export const SitesQueryProvider: SitesQueryProviderType = (props) => {
  const { children, variables } = props;

  const { error, loading, data } = useQuery<SitesQueryType, SitesQueryVariables>(SitesQuery, {
    variables,
    fetchPolicy: "cache-and-network",
  });

  if (loading) return <CenterSpinner size="large" />;
  if (error || !data)
    return <CenterResult status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;

  return <Fragment>{children(data)}</Fragment>;
};
