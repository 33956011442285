import { AbilityQueryProvider } from "__legacy/queries/abilities/AbilityQueryProvider";
import { CreateCandidacyMutationProvider } from "__legacy/queries/candidacies/CreateCandidacyMutationProvider";
import { Button, Form, Layout, PageHeader } from "antd";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CandidacyForm, onError, onFinish } from "./CandidacyForm";

export const New: React.FC<{}> = () => {
  const { abilityId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <AbilityQueryProvider variables={{ id: abilityId }}>
      {({ ability }) => (
        <CreateCandidacyMutationProvider onError={onError(form, setErrors)} onCompleted={() => navigate("../../../")}>
          {(loading, mutate) => (
            <Fragment>
              <PageHeader
                ghost={true}
                title={`New candidacy for stakeholder ${ability.stakeholder.companyName} on ${ability.scheme.name}`}
                extra={
                  <Fragment>
                    <Button onClick={() => navigate("../../../")}>Back to abilities</Button>
                    <Button type="primary" loading={loading} onClick={() => form.submit()}>
                      Save
                    </Button>
                  </Fragment>
                }
              />
              <Layout.Content className="content">
                <CandidacyForm
                  action="create"
                  form={form}
                  handler={onFinish(mutate, { abilityId }, {})}
                  values={{ scheme: ability.scheme }}
                  errors={errors}
                />
              </Layout.Content>
            </Fragment>
          )}
        </CreateCandidacyMutationProvider>
      )}
    </AbilityQueryProvider>
  );
};
