import { Grid, Stack } from "@chakra-ui/react";

import { Card } from "../ui/Card";
import { CardField } from "../ui/CardField";

export const Training: React.FC<{}> = () => {
  return (
    <Card title="Professional Course">
      <Stack w="full" spacing="4">
        <Stack>
          <Grid templateColumns="repeat(2, 1fr)" rowGap="2">
            <CardField label="Institution" value="Université Centrale" />
            <CardField label="Qualification" value="Professional Course" />
            <CardField label="Start year" value="1998" />
            <CardField label="End year" value="2000" />
          </Grid>
          <CardField label="Details" value="Duis bibendum. Morbi non quam nec dui luctus rutrum. Nulla tellus." />
        </Stack>
      </Stack>
    </Card>
  );
};
