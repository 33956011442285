import { Box, Heading, HStack, Stack } from "@chakra-ui/react";

type CardType = {
  title: string;
  actions?: any;
  scrollable?: boolean;
};

export const Card: React.FC<CardType> = (props) => {
  const { children, title, actions, scrollable } = props;
  const scrollProps = scrollable ? { overflow: "scroll", maxH: "sm", my: "2" } : {};
  return (
    <Stack bg="white" spacing="0" boxShadow="sm">
      <HStack justify="space-between" p="3" px="4">
        <Heading size="sm">{title}</Heading>
        <Box h="8" lineHeight="8">
          {actions}
        </Box>
      </HStack>
      {children && (
        <Stack p="4" {...scrollProps} borderTop="1px" borderColor="coolGray.200">
          {children}
        </Stack>
      )}
    </Stack>
  );
};
