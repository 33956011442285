import { Alert, Col, Form, Input, InputNumber, Row, Space, Switch } from "antd";
import { FormInstance } from "antd/lib/form";
import { toNumber } from "lodash";

const minutes = (days: any) => {
  if (days === undefined) return days;
  return Math.round(toNumber(days) * 8 * 60);
};

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const input = {
    ...values,
    mandays: undefined,
    minutes: minutes(values.mandays),
    report: minutes(values.report),
    fee: toNumber(values.fee),
    ...params,
  };

  mutate({ variables: { ...variables, input } });
};

export const PwsVisitForm: React.FC<{
  form: FormInstance<any>;
  onFinish: (values: any) => void;
  visit: any;
  audit: any;
  mandays: any;
  errors: string[];
}> = (props) => {
  const { form, visit, audit, mandays, onFinish, errors } = props;

  const initialValues = {
    ...visit,
    report: visit.reportDays,
  };

  let step = 0.25,
    min = 0.25,
    max = 9999;
  if (audit.kind === "main") {
    step = 0.01;
    if (audit.step === 1) {
      min = 0;
      max = +(mandays.minimum * 0.4).toFixed(2);
    } else min = +(mandays.minimum * 0.4).toFixed(2);
  }

  const hasReport = !(audit.kind === "main" && audit.step === 1);

  return (
    <Form layout="vertical" form={form} initialValues={initialValues} onFinish={onFinish}>
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Row gutter={[24, 0]}>
        <Col span={6}>
          <Form.Item name="mandays" label="Mandays">
            <InputNumber min={min} step={step} max={max} />
          </Form.Item>
        </Col>
        {hasReport && (
          <Col span={6}>
            <Form.Item name="report" label="Off-Site days" tooltip={`Max 20% of Minimum Mandays (${mandays.minimum})`}>
              <InputNumber min={0} step={0.01} max={+(mandays.minimum * 0.2).toFixed(2)} />
            </Form.Item>
          </Col>
        )}
        <Col span={24}>
          <Form.Item name="fee" label="Fee">
            <InputNumber min={0} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="includesSubsistence" label="Includes Subsistence" valuePropName={"checked"}>
            <Switch />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="includesTravel" label="Includes Travel" valuePropName={"checked"}>
            <Switch />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="info" label="Info">
            <Input.TextArea />
          </Form.Item>
        </Col>
        <Form.Item hidden name="allSurveillances">
          <Input hidden />
        </Form.Item>
      </Row>
    </Form>
  );
};
