import { CreateStakeholderMutationProvider } from "__legacy/queries/stakeholders/CreateStakeholderMutationProvider";
import { Button, Form, Layout, PageHeader } from "antd";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { onError, onFinish, StakeholderForm } from "./StakeholderForm";

export const New: React.FC<{}> = () => {
  const { agencyId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <CreateStakeholderMutationProvider
      onError={onError(form, setErrors)}
      onCompleted={({ createStakeholder: { stakeholder } }) => navigate(`../${stakeholder.id}`)}
    >
      {(loading, mutate) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title={`New stakeholder`}
            extra={
              <Fragment>
                <Button onClick={() => navigate("../")}>Back to list</Button>
                <Button type="primary" loading={loading} onClick={() => form.submit()}>
                  Save
                </Button>
              </Fragment>
            }
          />
          <Layout.Content className="content">
            <StakeholderForm
              action="create"
              form={form}
              handler={onFinish(mutate, { agencyId }, {})}
              values={{}}
              errors={errors}
            />
          </Layout.Content>
        </Fragment>
      )}
    </CreateStakeholderMutationProvider>
  );
};
