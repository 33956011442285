import { Box, Button, Divider, Grid, Heading, HStack, Icon, Stack, Tag, Text } from "@chakra-ui/react";
import { FiCheckCircle } from "react-icons/fi";

import { Card } from "../ui/Card";
import { CardField } from "../ui/CardField";

export const MainAudit: React.FC<{}> = () => {
  const sites = ["Main Office", "Commercial Office"];
  return (
    <Card
      title="Main Audit"
      actions={
        <HStack>
          <Text>Mandays: 12/10</Text>
          <Box>
            <Icon as={FiCheckCircle} boxSize="4" color="green.500" />
          </Box>
        </HStack>
      }
    >
      <Stack w="full" spacing="4">
        {[1, 2].map((stage) => {
          return sites.map((site, i) => (
            <Stack>
              <HStack justify="space-between">
                <Heading size="xs">
                  STAGE {stage} - {site}
                </Heading>
                <Button colorScheme="blue" aria-label="Fill" variant="outline">
                  Edit
                </Button>
              </HStack>
              <Grid templateColumns="repeat(2, 1fr)">
                <Grid templateColumns="repeat(3, 1fr)">
                  <CardField label="Mandays" value="3" />
                  <CardField label="Fee" value="2%" />
                  <CardField label="Report" value="0" />
                </Grid>
                <HStack>
                  <CardField label="Includes" value="" />
                  <Tag>Subsistence</Tag>
                  <Tag>Travel</Tag>
                </HStack>
              </Grid>
              {(stage < 2 || i < sites.length - 1) && <Divider />}
            </Stack>
          ));
        })}
      </Stack>
    </Card>
  );
};
