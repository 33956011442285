import { LockOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { Logo } from "__legacy/components/Logo";
import { Alert, Button, Card, Col, Form, Input, Row, Space } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";

const updateAccountPasswordMutation = gql`
  mutation($input: updateAccountPasswordInput!) {
    updateAccountPassword(input: $input) {
      successful
    }
  }
`;

interface FieldData {
  name: string;
  errors: string[];
}

export const CompletePasswordReset: React.FC<{}> = () => {
  const [form] = Form.useForm();
  const [otherErrors, setOtherErrors] = useState<string[]>([]);

  const [execMutation, { loading }] = useMutation(updateAccountPasswordMutation, {
    errorPolicy: "none",
    onError: ({ graphQLErrors }) => {
      const fieldsErrors: FieldData[] = [];
      const otherErrors: string[] = [];
      graphQLErrors.forEach((graphQLError) => {
        if (graphQLError.name) {
          fieldsErrors.push({ name: graphQLError.name, errors: (graphQLError as any).messages });
        } else {
          otherErrors.push(graphQLError.message);
        }
      });
      form.setFields(fieldsErrors);
      setOtherErrors(otherErrors);
    },
    onCompleted: (data) => {
      if (data?.updateAccountPassword?.successful) {
        console.log("COMPLETE PASSWORD REQUESTED!");
      }
    },
  });

  const onFinish = (values: any) => {
    execMutation({ variables: { input: values } });
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: "80vh" }}>
      <Col span={8}>
        <Card
          className="login-form"
          cover={<Logo style={{ marginTop: "30px", height: "100px" }} />}
          style={{ margin: "0 auto" }}
        >
          {otherErrors.length > 0 && (
            <Space direction="vertical" style={{ marginBottom: "10px" }}>
              {otherErrors.map((otherError, index) => (
                <Alert key={index} type="error" message="Error" description={otherError} />
              ))}
            </Space>
          )}

          <Form form={form} onFinish={onFinish}>
            <Form.Item name="password" rules={[{ required: true, message: "is required" }]}>
              <Input.Password prefix={<LockOutlined />} placeholder="Password" autoComplete="new-password" />
            </Form.Item>

            <Form.Item
              name="passwordConfirmation"
              dependencies={["password"]}
              rules={[
                { required: true, message: "is required" },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("not matching");
                  },
                }),
              ]}
            >
              <Input.Password prefix={<LockOutlined />} placeholder="Confirm Password" autoComplete="new-password" />
            </Form.Item>

            <Form.Item>
              <Link to="/auth/sign-in" className="login-form-forgot">
                Sign In
              </Link>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                Complete Password Reset
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};
