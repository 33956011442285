import { ActivityStageQueryProvider } from "__legacy/queries/activities/ActivityStageQueryProvider";
import { UpdateActivityStageMutationProvider } from "__legacy/queries/activities/UpdateActivityStageMutationProvider";
import { Button, Form, Layout, PageHeader } from "antd";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { onError, onFinish, StageForm } from "./StageForm";

export const Edit: React.FC<{}> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <ActivityStageQueryProvider variables={{ id }}>
      {({ activityStage }) => (
        <UpdateActivityStageMutationProvider onError={onError(form, setErrors)} onCompleted={() => navigate("../..")}>
          {(loading, mutate) => (
            <Fragment>
              <PageHeader
                ghost={true}
                title={`Edit Stage ${activityStage.name}`}
                extra={
                  <Fragment>
                    <Button onClick={() => navigate("../..")}>Back to list</Button>
                    <Button type="primary" loading={loading} onClick={() => form.submit()}>
                      Save
                    </Button>
                  </Fragment>
                }
              />
              <Layout.Content className="content">
                <StageForm form={form} handler={onFinish(mutate, {}, { id })} values={activityStage} errors={errors} />
              </Layout.Content>
            </Fragment>
          )}
        </UpdateActivityStageMutationProvider>
      )}
    </ActivityStageQueryProvider>
  );
};
