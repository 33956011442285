import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  CreateAbilityMutation as CreateAbilityMutationType,
  CreateAbilityMutationVariables,
} from "./__generated__/CreateAbilityMutation";

const CreateAbilityMutation = loader("./CreateAbilityMutation.gql");

type CreateAbilityMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted: (data: CreateAbilityMutationType) => void;
}) => ReactElement;

export const CreateAbilityMutationProvider: CreateAbilityMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<CreateAbilityMutationType, CreateAbilityMutationVariables>(
    CreateAbilityMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
