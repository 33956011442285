import { Alert, Card, Col, Divider, Row, Space } from "antd";
import Tooltip from "antd/es/tooltip";
import { Fragment } from "react";

import { PwsVisitCard } from "./PwsVisitCard";

type PwsMainAuditCardType = {
  audits: any[];
  mandays: any;
  projectSites: any[];
  editable: boolean;
};

export const PwsMainAuditCard: React.FC<PwsMainAuditCardType> = (props) => {
  const { audits, mandays, projectSites, editable } = props;

  return (
    <Card
      title="Main Audit"
      extra={
        <Fragment>
          {mandays && (
            <Row gutter={[10, 0]} justify="space-around" style={mandays.singleSite ? {} : { marginRight: "20px" }}>
              {!!mandays.singleSite && (
                <Col span={6}>
                  <Tooltip
                    title={
                      <Space direction="vertical">
                        {"SINGLE SITE MANDAYS: " + mandays.singleSite}
                        {"THIS IS THE SUPPOSED MINIMUM MANDAYS NEEDED IF YOUR PROJECT WOULD BE SINGLE SITE"}
                      </Space>
                    }
                  >
                    <Alert style={{ width: "195px" }} message={`S-Site Mandays ${mandays.singleSite}`} showIcon />
                  </Tooltip>
                </Col>
              )}
              <Col span={6}>
                <Tooltip
                  title={
                    <Space direction="vertical">
                      {"STARTING MANDAYS: " + mandays.start}
                      {"REDUCTIONS MANDAYS: " + mandays.reduction}
                      {/* {"INTEGRATED MANDAYS: " + mandays.integrated} */}
                      {"INCREASES MANDAYS: " + mandays.increase}
                      {"MULTI STANDARD MANDAYS: " + mandays.multiStandard}
                      {"MINIMUM MANDAYS: " + mandays.minimum}
                    </Space>
                  }
                >
                  <Alert
                    style={{ width: "195px" }}
                    message={`Min. Mandays ${mandays.minimum}`}
                    type={mandays.status}
                    showIcon
                  />
                </Tooltip>
              </Col>
              <Col span={6}>
                <Alert
                  style={{ width: "195px" }}
                  message={`Alloc. Mandays ${mandays.current}`}
                  type={mandays.status}
                  showIcon
                />
              </Col>
              <Col span={6}>
                <Alert
                  style={{ width: "195px" }}
                  message={`Miss. Mandays ${mandays.missing}`}
                  type={mandays.status}
                  showIcon
                />
              </Col>
            </Row>
          )}
        </Fragment>
      }
    >
      {[...Array(audits[0].visits.length)].map((_, i: any) => {
        return [0, 1].map((j: any) => {
          const audit = audits[j];
          const visit = audit?.visits[i];
          return visit ? (
            <Fragment key={visit.id}>
              <Row gutter={[24, 0]}>
                <PwsVisitCard
                  key={visit.code}
                  visit={visit}
                  audit={audit}
                  mandays={mandays}
                  projectSite={projectSites.find((ps: any) => ps.site?.id === visit.site.id)}
                  editable={editable}
                />
              </Row>
              {(j < audit.visits.length - 1 || i < audits.length - 1) && <Divider />}
            </Fragment>
          ) : null;
        });
      })}
    </Card>
  );
};
