import { CommentOutlined } from "@ant-design/icons";
import { useApplicationContext } from "__legacy/components/Application";
import { CreateNoteMutationProvider } from "__legacy/queries/notes/CreateNoteMutationProvider";
import { Button, Card, Col, Form, Input, Row, Space, Timeline, Typography } from "antd";
import moment from "moment";
import { Fragment } from "react";

type ProjectNotesCardType = {
  projectId: any;
  notes: any[];
  noCard?: boolean;
};

export const ProjectNotesCard: React.FC<ProjectNotesCardType> = (props) => {
  const { projectId, notes, noCard } = props;
  const { session } = useApplicationContext();
  const [form] = Form.useForm();

  const onFinish = (mutate: any) => (values: any) => {
    const input = {
      ...values,
      author: session.currentUser.email,
      projectId,
    };
    mutate({ variables: { input } });
  };

  const content = (
    <Fragment>
      <Timeline mode="left">
        {notes.map((note) => (
          <Timeline.Item key={note.id} dot={<CommentOutlined />}>
            <Space direction="vertical">
              {note.author}
              <Typography.Text type="secondary">{moment(note.insertedAt).format("MM-DD-YYYY HH:mm")}</Typography.Text>
              {note.text}
            </Space>
          </Timeline.Item>
        ))}
      </Timeline>
      <CreateNoteMutationProvider onCompleted={() => form.resetFields()}>
        {(loading, mutate) => (
          <Form layout="vertical" form={form} onFinish={onFinish(mutate)}>
            <Row gutter={[24, 0]}>
              <Col span={24}>
                <Form.Item
                  name="text"
                  label="Text"
                  rules={[{ required: true, message: "is required", whitespace: true }]}
                >
                  <Input.TextArea rows={3} />
                </Form.Item>
              </Col>
            </Row>
            <Button type="primary" loading={loading} onClick={() => form.submit()}>
              Add
            </Button>
          </Form>
        )}
      </CreateNoteMutationProvider>
    </Fragment>
  );

  return noCard ? content : <Card title="Notes">{content}</Card>;
};
