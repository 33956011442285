import { DeleteOutlined, EditOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { useApplicationContext } from "__legacy/components/Application";
import { UsersQueryProvider } from "__legacy/queries/users/UsersQueryProvider";
import { Button, Input, Layout, notification, PageHeader, Popconfirm, Row, Space, Table, Tag, Tooltip } from "antd";
import { Fragment, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

const deleteUserMutation = gql`
  mutation($id: ID!) {
    deleteUser(id: $id) {
      user {
        id
        email
      }
    }
  }
`;

export const List: React.FC<{}> = () => {
  const { session } = useApplicationContext();
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const handleSearch = (value: string) => setSearchQuery(value.trim());

  const [execDelete] = useMutation(deleteUserMutation, {
    errorPolicy: "none",
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
    onCompleted: ({ deleteUser: { user } }) => {
      notification.success({
        message: `User "${user.email}" deleted`,
      });
    },
    refetchQueries: ["UsersQuery"],
  });

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "users.email",
      render: (value: string, resource: any) => (
        <NavLink to={resource.id}>
          {value === session.currentUser.email ? <Tag>ME</Tag> : ""}
          {value}
        </NavLink>
      ),
    },
    {
      width: "80px",
      title: "Actions",
      dataIndex: "id",
      render: (id: string) => (
        <Row justify="end">
          <Space>
            <Tooltip title="Edit" placement="bottom">
              <Button onClick={() => navigate(`${id}/edit`)} icon={<EditOutlined />}></Button>
            </Tooltip>
            {id !== session.currentUser.id && session.currentUser.isAdmin && (
              <Popconfirm
                title="Are you sure?"
                icon={<QuestionCircleOutlined />}
                onConfirm={() => execDelete({ variables: { id } })}
              >
                <Tooltip title="Delete" placement="bottom">
                  <Button danger icon={<DeleteOutlined />}></Button>
                </Tooltip>
              </Popconfirm>
            )}
          </Space>
        </Row>
      ),
    },
  ];

  return (
    <UsersQueryProvider variables={{ search: searchQuery }}>
      {({ users }) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title="Users"
            extra={
              <Fragment>
                <Input.Search
                  allowClear
                  placeholder="search"
                  onSearch={handleSearch}
                  defaultValue={searchQuery ?? ""}
                  style={{ width: 500 }}
                />
                <Button onClick={() => navigate("new")} type="primary">
                  <PlusOutlined />
                  New
                </Button>
              </Fragment>
            }
          />
          <Layout.Content className="content">
            <Table rowKey="id" columns={columns} dataSource={users} pagination={false} />
          </Layout.Content>
        </Fragment>
      )}
    </UsersQueryProvider>
  );
};
