import { Grid, Stack, Tag, Text } from "@chakra-ui/react";

import { Card } from "../ui/Card";
import { CardField } from "../ui/CardField";

export const Ability: React.FC<{}> = () => {
  return (
    <Card
      title="ISO 270001 - ISMS"
      actions={
        <Stack align="center">
          <Text>
            <Tag mt="2" colorScheme="green">
              Approved
            </Tag>
          </Text>
        </Stack>
      }
    >
      <Stack w="full" spacing="4">
        <Stack>
          <Grid templateColumns="repeat(3, 1fr)" rowGap="2">
            <CardField label="Level" value="Lead Auditor" />
            <CardField label="Course Type" value="Upgrade" />
            <CardField label="Course Date" value="2020-01-05" />
            <CardField label="Validation Date" value="2020-01-05" />
            <CardField label="Due Date" value="2020-02-28" />
          </Grid>
          <CardField
            label="Codes"
            value="2.04 - EA1 - Seed processing for propagation,2.03 - EA1 - Post-harvest crop activities,1.50 - EA1 - Mixed Farming,1.49 - EA1 - Raising of other animals,1.47 - EA1 - Raising of poultry,1.46 - EA1 - Raising of swine/pigs,1.44 - EA1 - Raising of camels and camelids"
          />
        </Stack>
      </Stack>
    </Card>
  );
};
