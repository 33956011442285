import { Button, Heading, HStack, IconButton, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { RiAddLine, RiSearch2Line } from "react-icons/ri";

type ListHeaderType = {
  title?: string;
  openForm?: () => void;
};

export const ListHeader: React.FC<ListHeaderType> = (props) => {
  const { title, openForm } = props;

  return (
    <HStack justify="space-between" w="full">
      <Heading size="md">{title}</Heading>
      <HStack spacing="6">
        <InputGroup w="96">
          <InputRightElement
            children={<IconButton variant="ghost" size="sm" aria-label="Search" icon={<RiSearch2Line />} />}
          ></InputRightElement>
          <Input bg="white" placeholder="Search" />
        </InputGroup>
        <Button colorScheme="blue" aria-label="New" leftIcon={<RiAddLine />} onClick={openForm}>
          New
        </Button>
      </HStack>
    </HStack>
  );
};
