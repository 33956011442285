import { HStack, Stack } from "@chakra-ui/react";
import React from "react";

import { Events } from "../ui/Events";
import { Notes } from "../ui/Notes";
import { Summary } from "./Summary";

export const Show: React.FC<{}> = () => {
  return (
    <>
      <Summary />
      <HStack w="full" spacing="4" alignItems="start">
        <Stack w="75%" spacing="4"></Stack>
        <Stack w="25%" spacing="4">
          <Notes />
          <Events />
        </Stack>
      </HStack>
    </>
  );
};
