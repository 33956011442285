import { useQuery } from "@apollo/client";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { SitesQueryProvider } from "__legacy/queries/sites/SitesQueryProvider";
import { Alert, Badge, Col, Form, Input, InputNumber, Row, Select, Space, Tag } from "antd";
import { FormInstance } from "antd/lib/form";
import Text from "antd/lib/typography/Text";
import { loader } from "graphql.macro";
import { curry, find, includes, isString, map, toNumber, uniq } from "lodash";
import { useParams } from "react-router";

const AccreditationQuery = loader("__legacy/queries/accreditations/AccreditationQuery.gql");

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const input = {
    ...values,
    visitAt: values.visitAt?.map((visitItem: any) => toNumber(visitItem)) ?? [],
    employees: toNumber(values.employees),
    employeesEffective: toNumber(values.employeesEffective || values.employees),
    projectSiteClassifications: values.projectSiteClassifications?.map((classificationId: any) => ({
      classificationId,
    })),
    ...params,
  };
  mutate({ variables: { ...variables, input } });
};

export const PwsProjectSiteForm: React.FC<{
  form: FormInstance<any>;
  onFinish: (values: any) => void;
  project: any;
  projectSite: any;
  errors: string[];
}> = (props) => {
  const { form, project, projectSite, onFinish, errors } = props;
  const { agencyId } = useParams();

  const visits: any[] = [{ value: 0, label: "Main Audit" }];
  for (let i = 1; i <= project.scheme.duration / project.scheme.frequency - 1; i++) {
    visits.push({ value: i, label: `${i}° Surveillance` });
  }

  const projectCodes = project.projectSites
    .find((ps: any) => ps.site.isHead)
    .projectSiteClassifications?.map((projectSiteClassification: any) => projectSiteClassification.classification.id);

  const visitAlways = projectSite.site?.isHead || !project.sampling;

  const initialValues = {
    ...projectSite,
    siteId: projectSite.site?.id,
    projectSiteClassifications: projectSite.projectSiteClassifications?.map(
      (projectSiteClassification: any) => projectSiteClassification.classification.id,
    ),
    visitAt: visitAlways ? map(visits, "value") : projectSite.visitAt,
  };

  const tagRender = (codes: any, props: any) => {
    const { value, label, closable, onClose } = props;
    const code = find(codes, { id: value });
    return (
      <Tag color={code?.badge} closable={closable} onClose={onClose}>
        {label}
      </Tag>
    );
  };

  const { loading, data } = useQuery(AccreditationQuery, {
    variables: {
      id: project.accreditation.id,
      schemeId: project.scheme.id,
    },
    fetchPolicy: "no-cache",
  });

  if (loading) return <CenterSpinner size="large" />;

  const { accreditation } = data;

  const codes: any[] = [];
  accreditation.classifications.forEach((classification: any) => {
    if (projectCodes.includes(classification.id)) {
      const code: any = {
        id: classification.id,
        tag: `${classification.industry.name} - ${classification.name}`,
      };
      code.label = `${code.tag} - ${classification.description}`;
      let noCollabs = false;
      if (!!classification.candidacies.length) {
        let agencies: any[] = [];
        for (const candidacy of classification.candidacies) {
          if (candidacy.ability.scheme.id !== project.scheme.id) {
            noCollabs = true;
            break;
          } else {
            if (candidacy.ability.stakeholder.agency.id === agencyId) {
              agencies = [];
              break;
            } else agencies.push(candidacy.ability.stakeholder.agency.name);
          }
        }
        if (!noCollabs) {
          if (!!agencies.length) codes.push({ ...code, badge: "warning", warning: `[${uniq(agencies).join(" / ")}]` });
          else codes.push(code);
        }
      } else noCollabs = true;
      if (noCollabs) codes.push({ ...code, badge: "error", danger: "[No Auditors]" });
    }
  });

  return (
    <Form layout="vertical" form={form} initialValues={initialValues} onFinish={onFinish}>
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <SitesQueryProvider variables={{ agencyId, stakeholderId: project.subject.id }}>
            {({ sites }) => (
              <Form.Item name="siteId" label="Site" rules={[{ required: true, message: "is required" }]}>
                <Select
                  disabled={projectSite.site?.isHead}
                  showSearch
                  optionLabelProp="label"
                  filterOption={(input, option) => {
                    let itemText = "";
                    if (option?.label && isString(option.label)) itemText = option.label;
                    itemText = itemText.toLowerCase();
                    const searchValue = input.toLowerCase();
                    return itemText.indexOf(searchValue) > -1;
                  }}
                >
                  {sites.map((site) => (
                    <Select.Option
                      key={site.id}
                      value={site.id}
                      label={site.fullAddress}
                      disabled={includes(
                        project.projectSites.map((ps: any) => ps.site?.id),
                        site.id,
                      )}
                    >
                      {site.fullAddress}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </SitesQueryProvider>
        </Col>
        <Col span={24}>
          <Form.Item
            name="projectSiteClassifications"
            label="Classifications"
            rules={[{ required: true, message: "is required" }]}
          >
            <Select
              disabled={projectSite.site?.isHead}
              mode="multiple"
              showSearch
              optionLabelProp="label"
              tagRender={curry(tagRender)(codes)}
              filterOption={(input, option) => {
                let itemText = "";
                if (option?.label && isString(option.label)) itemText = option.label;
                itemText = itemText.toLowerCase();
                const searchValue = input.toLowerCase();
                return itemText.indexOf(searchValue) > -1;
              }}
            >
              {codes.map((code: any) => (
                <Select.Option key={code.id} value={code.id} label={code.tag}>
                  <div className="demo-option-label-item">
                    <Badge status={code.badge ?? "success"} />
                    {code.label}
                    {code.warning && <Text type="warning"> {code.warning}</Text>}
                    {code.danger && <Text type="danger"> {code.danger}</Text>}
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="visitAt" label="Visit at" rules={[{ required: true, message: "is required" }]}>
            <Select
              mode="multiple"
              showSearch
              optionLabelProp="label"
              disabled={visitAlways}
              filterOption={(input, option) => {
                let itemText = "";
                if (option?.label && isString(option.label)) itemText = option.label;
                itemText = itemText.toLowerCase();
                const searchValue = input.toLowerCase();
                return itemText.indexOf(searchValue) > -1;
              }}
            >
              {visits.map((visit) => (
                <Select.Option key={visit.value} value={visit.value} label={visit.label}>
                  {visit.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="employees" label="Employees" rules={[{ required: true, message: "is required" }]}>
            <InputNumber min={1} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="employeesEffective" label="Effective Employees">
            <InputNumber min={1} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="employeesJustification" label="Employees Justification">
            <Input.TextArea rows={2} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="differentScope" label="Scope at this site">
            <Input.TextArea rows={2} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
