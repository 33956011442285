import { Button, Divider, Heading, HStack, Stack, Tag, Tooltip, Wrap, WrapItem } from "@chakra-ui/react";

import { Card } from "../ui/Card";
import { CardField } from "../ui/CardField";

export const Sites: React.FC<{}> = () => {
  const compiledSites = ["Main Office", "Commercial Office"];
  const unCompiledSites = ["Office 3", "Office 4", "Office 5"];
  return (
    <Card title="Sites">
      <Stack w="full" spacing="4">
        {compiledSites.map((site, i) => (
          <Stack>
            <HStack justify="space-between">
              <Heading size="xs">{site}</Heading>
              <Tooltip
                hasArrow
                label="By editing this activity you'll reset: Main Audits - Surveillance 1 - Surveillance 2"
                placement="top"
              >
                <Button colorScheme="blue" aria-label="Fill" variant="outline">
                  Edit
                </Button>
              </Tooltip>
            </HStack>
            <HStack alignItems="baseline">
              <CardField label="Classifications" value="" />
              <Wrap>
                <WrapItem>
                  <Tag>EA29 - 95.29.00</Tag>
                </WrapItem>
                <WrapItem>
                  <Tooltip hasArrow label="Collaborators available in: AJA ITALY / AJA SPAIN" placement="top">
                    <Tag colorScheme="amber">EA29 - 95.29.00</Tag>
                  </Tooltip>
                </WrapItem>
                <WrapItem>
                  <Tooltip hasArrow label="Collaborators available in: AJA ITALY / AJA SPAIN" placement="top">
                    <Tag colorScheme="amber">EA29 - 95.29.00</Tag>
                  </Tooltip>
                </WrapItem>
                <WrapItem>
                  <Tag>EA29 - 95.29.00</Tag>
                </WrapItem>
                <WrapItem>
                  <Tag>EA29 - 95.29.00</Tag>
                </WrapItem>
                <WrapItem>
                  <Tag>EA29 - 95.29.00</Tag>
                </WrapItem>
                <WrapItem>
                  <Tag>EA29 - 95.29.00</Tag>
                </WrapItem>
                <WrapItem>
                  <Tag>EA29 - 95.29.00</Tag>
                </WrapItem>
                <WrapItem>
                  <Tag>EA29 - 95.29.00</Tag>
                </WrapItem>
                <WrapItem>
                  <Tag>EA29 - 95.29.00</Tag>
                </WrapItem>
                <WrapItem>
                  <Tag>EA29 - 95.29.00</Tag>
                </WrapItem>
                <WrapItem>
                  <Tag>EA29 - 95.29.00</Tag>
                </WrapItem>
                <WrapItem>
                  <Tooltip hasArrow label="No Collaborators available">
                    <Tag colorScheme="red">EA29 - 95.29.00</Tag>
                  </Tooltip>
                </WrapItem>
              </Wrap>
            </HStack>
            <CardField
              label="Employees"
              value="100 (120 effective) - 34 assembly employees performing activities with similar OH&S
              risks reduced by 50%"
            />
            <CardField label="Visit at" value="Main Audit, Surveillance 2" />
            <CardField
              label="Scope"
              value="34 assembly employees performing activities with similar OH&S risks reduced by 50%"
            />
            {i < compiledSites.length - 1 && <Divider />}
          </Stack>
        ))}
        <Divider />
        {unCompiledSites.map((site, i) => (
          <Stack>
            <HStack justify="space-between" mb="2">
              <Heading size="xs">{site}</Heading>
              <Button colorScheme="blue" aria-label="Fill">
                Fill
              </Button>
            </HStack>
            {i < unCompiledSites.length - 1 && <Divider />}
          </Stack>
        ))}
      </Stack>
    </Card>
  );
};
