import { Box, FormControl, FormLabel } from "@chakra-ui/react";
import { startCase } from "lodash";

type FormItemType = {
  id: string;
  label?: any;
  required?: boolean;
};

export const FormItem: React.FC<FormItemType> = (props) => {
  const { children, id, label, required } = props;
  return (
    <Box>
      <FormControl id={id} isRequired={required}>
        <FormLabel fontSize="sm" fontWeight="600" color="coolGray.400">
          {label ?? startCase(id)}
        </FormLabel>
        {children}
      </FormControl>
    </Box>
  );
};
