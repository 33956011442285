import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  UpdateMultiStandardMutation as UpdateMultiStandardMutationType,
  UpdateMultiStandardMutationVariables,
} from "./__generated__/UpdateMultiStandardMutation";

const UpdateMultiStandardMutation = loader("./UpdateMultiStandardMutation.gql");

type UpdateMultiStandardMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted?: (data: UpdateMultiStandardMutationType) => void;
}) => ReactElement;

export const UpdateMultiStandardMutationProvider: UpdateMultiStandardMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<UpdateMultiStandardMutationType, UpdateMultiStandardMutationVariables>(
    UpdateMultiStandardMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
