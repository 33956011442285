import { EditOutlined, LockOutlined, PlusOutlined, PrinterOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useApplicationContext } from "__legacy/components/Application";
import { ApplyVisitMutationProvider } from "__legacy/queries/projects/ApplyVisitMutationProvider";
import { RenderVisitMutationProvider } from "__legacy/queries/projects/RenderVisitMutationProvider";
import { UpdateVisitBookingMutationProvider } from "__legacy/queries/projects/UpdateVisitBookingMutationProvider";
import { UpdateVisitPackageMutationProvider } from "__legacy/queries/projects/UpdateVisitPackageMutationProvider";
import { Button, Card, Col, Divider, Drawer, Form, Popconfirm, Row, Space, Table } from "antd";
import Text from "antd/lib/typography/Text";
import { isEmpty } from "lodash";
import { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";

import { CarForm } from "./CarForm";
import { onFinish, VisitBookingForm } from "./VisitBookingForm";
import { onFinish as onPackageFinish, VisitPackageForm } from "./VisitPackageForm";

type VisitBookingCardType = {
  visit: any;
  audit: any;
  project: any;
  editable: boolean;
};

export const VisitBookingCard: React.FC<VisitBookingCardType> = (props) => {
  const { visit, audit, project, editable } = props;
  const stage = audit.kind === "main" && `STAGE ${audit.step}`;
  const title = (
    <Space size="middle">
      Visit's Booking
      {audit.kind === "main" ? "Main Audit" : `Surveillance ${audit.step}`}
      {stage}
      {visit.site.name}
    </Space>
  );
  const [form] = Form.useForm();
  const [packageForm] = Form.useForm();
  const variables = { id: visit.id };

  const { getEnumValue } = useApplicationContext();

  const [drawerVisible, setDrawerVisible] = useState(false);
  const onClose = () => setDrawerVisible(false);
  const [packageDrawerVisible, setPackageDrawerVisible] = useState(false);
  const onPackageClose = () => setPackageDrawerVisible(false);
  const [carDrawerVisible, setCarDrawerVisible] = useState(false);
  const [car, setCar] = useState<any>();
  const onCarClose = () => {
    setCar(undefined);
    setCarDrawerVisible(false);
  };

  const [errors, setErrors] = useState<string[]>([]);
  const onError = () => ({ graphQLErrors }: any) => {
    const errors: string[] = [];
    graphQLErrors.forEach((graphQLError: any) => {
      errors.push(graphQLError.message);
    });
    setErrors(errors);
  };

  const filled = visit.bookingFilled;
  const applyable = visit.package && visit.status === "redaction";
  const readonly = visit.status === "pending" || visit.status === "approved";

  return (
    <Fragment>
      <Card
        title={
          <Space size="middle">
            {stage}
            {visit.site.name}
          </Space>
        }
        bodyStyle={filled ? {} : { display: "none" }}
        extra={
          <Fragment>
            {editable && !readonly && (
              <Button type={filled ? "ghost" : "primary"} onClick={() => setDrawerVisible(true)}>
                {filled ? "Edit" : "Fill"}
              </Button>
            )}
            {!editable && <Button icon={<LockOutlined />} disabled />}
          </Fragment>
        }
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Text type="secondary">
              <Space direction="vertical">
                <Space size="large">
                  <span>{visit.mandays} Mandays</span>
                  <span>{visit.reportDays} Off-Site days</span>
                  <span>{visit.fee} Fee</span>
                  {visit.includesSubsistence && <span>Includes Subsistence</span>}
                  {visit.includesTravel && <span>Includes Travel</span>}
                </Space>
                {visit.info && <span>Info: {visit.info}</span>}
              </Space>
            </Text>
          </Col>
          <Col span={8}>
            Due Date: <b>{visit.dueDate}</b>
          </Col>
          <Col span={8}>
            Valid Date From: <b>{visit.dateFrom}</b>
          </Col>
          <Col span={8}>
            Valid Date To: <b>{visit.dateTo}</b>
          </Col>
          <Col span={8}>
            Lead Auditor: <b>{visit.leadAuditor?.companyName}</b>
          </Col>
          <Col span={8}>
            Invoiced: <b>{visit.invoiced ? "Yes" : "No"}</b>
          </Col>
          <Col span={24}>
            <Space direction="vertical" size="small">
              <span>
                Technical Experts:{" "}
                {visit.visitTechnicalExperts?.map((o: any, i: any) => (
                  <b key={o.id}>
                    {o.stakeholder.companyName}
                    {i < visit.visitTechnicalExperts.length - 1 ? ", " : ""}
                  </b>
                ))}
              </span>
              <span>
                Code Auditor:{" "}
                {visit.visitAuditors?.map((o: any, i: any) => (
                  <b key={o.id}>
                    {o.stakeholder.companyName}
                    {i < visit.visitAuditors.length - 1 ? ", " : ""}
                  </b>
                ))}
              </span>
              <span>
                Auditor under training - Code:{" "}
                {visit.visitCodeTrainings?.map((o: any, i: any) => (
                  <b key={o.id}>
                    {o.stakeholder.companyName}
                    {i < visit.visitCodeTrainings.length - 1 ? ", " : ""}
                  </b>
                ))}
              </span>
              <span>
                Auditor under training - Role:{" "}
                {visit.visitRoleTrainings?.map((o: any, i: any) => (
                  <b key={o.id}>
                    {o.stakeholder.companyName}
                    {i < visit.visitRoleTrainings.length - 1 ? ", " : ""}
                  </b>
                ))}
              </span>
            </Space>
          </Col>
          {visit.package && (
            <Fragment>
              <Divider style={{ margin: 0 }} />
              <Col span={12}>
                <Space direction="vertical" size="small">
                  <span>
                    Audit Performed as Planned: <b>{visit.package.auditAsPlanned ? "Yes" : "No"}</b>
                  </span>
                  {!visit.package.auditAsPlanned && (
                    <Fragment>
                      <span>
                        Visit Date: <b>{visit.package.effectiveAuditOn}</b>
                      </span>
                      <span>
                        Justification: <b>{visit.package.effectiveAuditJustification}</b>
                      </span>
                    </Fragment>
                  )}
                  <span>
                    CAR Raised: <b>{visit.package.carRaised ? "Yes" : "No"}</b>
                  </span>
                  <span>
                    Obs Raised: <b>{visit.package.obsRaised ? "Yes" : "No"}</b>
                  </span>
                  {visit.package.obsRaised && (
                    <span>
                      Total: <b>{visit.package.obs}</b>
                    </span>
                  )}
                </Space>
              </Col>
              <Col span={12}>
                <Space direction="vertical" size="small">
                  <span>
                    Date Report Received from auditor: <b>{visit.package.reportReceivedOn}</b>
                  </span>
                  <span>
                    Date Package Completed: <b>{visit.package.packageCompletedOn}</b>
                  </span>
                  <span>
                    Statement of Applicability: <br />
                    <b>{visit.package.applicabilityStatement}</b>
                  </span>
                  <span>
                    Assessment File:{" "}
                    {visit.package.report && (
                      <b>
                        <a href={visit.package.report}>Download</a>
                      </b>
                    )}
                  </span>
                </Space>
              </Col>
            </Fragment>
          )}
          {!isEmpty(visit.package?.cars) && (
            <Col span={24}>
              <Table
                size="small"
                rowKey="id"
                columns={[
                  {
                    title: "Kind",
                    dataIndex: "kind",
                    render: (v: string, r: any) => (
                      <Fragment>
                        {!readonly && (
                          <NavLink
                            to=""
                            onClick={() => {
                              setCar(r);
                              setCarDrawerVisible(true);
                            }}
                          >
                            {getEnumValue("carKinds", v)}
                          </NavLink>
                        )}
                        {readonly && getEnumValue("carKinds", v)}
                      </Fragment>
                    ),
                  },
                  { title: "Client Res. Date", dataIndex: "clientResponseOn" },
                  { title: "Auditor Accept", dataIndex: "auditorAcceptance", render: (v: any) => (v ? "Yes" : "No") },
                  { title: "Auditor Accept Date", dataIndex: "auditorAcceptanceOn" },
                  { title: "Status", dataIndex: "status", render: (v: string) => getEnumValue("carStatuses", v) },
                ]}
                dataSource={visit.package?.cars}
                pagination={false}
              />
            </Col>
          )}
          {isEmpty(visit.package?.cars) && <Divider style={{ margin: 0 }} />}
          <Col span={20}>
            <Space>
              {visit.job && (
                <a href={visit.job} download>
                  <Button icon={<PrinterOutlined />}>Print</Button>
                </a>
              )}
              {!visit.job && (
                <RenderVisitMutationProvider
                  onCompleted={({ renderVisit: { visit } }: any) => window.location.assign(visit.job)}
                >
                  {(loading, mutate) => (
                    <Button
                      loading={loading}
                      onClick={() => mutate({ variables: { id: visit.id } })}
                      icon={<PrinterOutlined />}
                    >
                      Print
                    </Button>
                  )}
                </RenderVisitMutationProvider>
              )}
              {!visit.package && (
                <Button type="primary" ghost icon={<PlusOutlined />} onClick={() => setPackageDrawerVisible(true)}>
                  Add Package
                </Button>
              )}
              {visit.package && !readonly && (
                <Fragment>
                  <Button icon={<EditOutlined />} onClick={() => setPackageDrawerVisible(true)}>
                    Edit Package
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setCar(undefined);
                      setCarDrawerVisible(true);
                    }}
                  >
                    Add CAR
                  </Button>
                </Fragment>
              )}
            </Space>
          </Col>
          {applyable && (
            <Col span={4}>
              <ApplyVisitMutationProvider onError={() => null} onCompleted={() => null}>
                {(loading, mutate) => (
                  <Popconfirm
                    title="Are you sure?"
                    placement="right"
                    icon={<QuestionCircleOutlined />}
                    onConfirm={() => mutate({ variables: { id: visit.id } })}
                  >
                    <Button type="primary" loading={loading}>
                      Apply
                    </Button>
                  </Popconfirm>
                )}
              </ApplyVisitMutationProvider>
            </Col>
          )}
        </Row>
      </Card>
      <UpdateVisitBookingMutationProvider
        onError={onError}
        onCompleted={() => {
          setDrawerVisible(false);
        }}
      >
        {(loading, mutate) => (
          <Drawer
            title={title}
            width={680}
            onClose={onClose}
            visible={drawerVisible}
            keyboard={false}
            maskClosable={false}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <Button onClick={onClose} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button type="primary" loading={loading} onClick={() => form.submit()}>
                  Save
                </Button>
              </div>
            }
          >
            <VisitBookingForm
              form={form}
              visit={visit}
              project={project}
              onFinish={onFinish(mutate, {}, variables)}
              errors={errors}
            />
          </Drawer>
        )}
      </UpdateVisitBookingMutationProvider>
      <UpdateVisitPackageMutationProvider
        onError={onError}
        onCompleted={() => {
          setPackageDrawerVisible(false);
        }}
      >
        {(loading, mutate) => (
          <Drawer
            title="Visit's Package"
            width={680}
            onClose={onPackageClose}
            visible={packageDrawerVisible}
            keyboard={false}
            maskClosable={false}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <Button onClick={onPackageClose} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button type="primary" loading={loading} onClick={() => packageForm.submit()}>
                  Save
                </Button>
              </div>
            }
          >
            <VisitPackageForm
              form={packageForm}
              visitPackage={visit.package ?? {}}
              onFinish={onPackageFinish(mutate, { visitId: visit.id }, variables)}
              errors={errors}
            />
          </Drawer>
        )}
      </UpdateVisitPackageMutationProvider>
      <CarForm key={car} visible={carDrawerVisible} onClose={onCarClose} visitPackage={visit.package} car={car} />
    </Fragment>
  );
};
