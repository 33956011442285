import { UpdateVisitMutationProvider } from "__legacy/queries/projects/UpdateVisitMutationProvider";
import { Button, Col, Drawer, Form, Row, Space, Tag } from "antd";
import Text from "antd/lib/typography/Text";
import { find } from "lodash";
import { Fragment, useState } from "react";

import { onFinish, PwsVisitForm } from "./PwsVisitForm";

type PwsVisitCardType = {
  visit: any;
  audit: any;
  projectSite: any;
  mandays: any;
  editable: boolean;
};

export const PwsVisitCard: React.FC<PwsVisitCardType> = (props) => {
  const { visit, audit, mandays, projectSite, editable } = props;
  const stage = audit.kind === "main" && `STAGE ${audit.step}`;
  const suggestedMandays = find(mandays?.tips, { visitId: visit.id })?.hint;
  const title = (
    <Fragment>
      {stage}
      {visit.site.name}
      <span>{projectSite?.employeesEffective} employees</span>
      <Tag>Suggested Mandays: {suggestedMandays}</Tag>
    </Fragment>
  );
  const formTitle = (
    <Space size="middle">
      {audit.kind === "main" ? "Main Audit" : `Surveillance ${audit.step}`}
      {title}
    </Space>
  );
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  const [drawerVisible, setDrawerVisible] = useState(false);
  const onClose = () => setDrawerVisible(false);

  const [Mutation, params, variables] = [UpdateVisitMutationProvider, {}, { id: visit.id }];

  const onError = () => ({ graphQLErrors }: any) => {
    const errors: string[] = [];
    graphQLErrors.forEach((graphQLError: any) => {
      errors.push(graphQLError.message);
    });
    setErrors(errors);
  };

  return (
    <Fragment>
      <Col flex={1} span={22}>
        <Row gutter={[24, 10]}>
          <Col span={24}>
            <Space size="middle">{title}</Space>
          </Col>
          <Col span={24}>
            <Text type="secondary">
              <Space direction="vertical">
                <Space size="large">
                  <Tag color="blue">{visit.mandays} Mandays</Tag>
                  {!!visit.reportDays && <span>{visit.reportDays} Off-Site days</span>}
                  <span>{visit.fee} Fee</span>
                  {visit.includesSubsistence && <span>Includes Subsistence</span>}
                  {visit.includesTravel && <span>Includes Travel</span>}
                </Space>
                {visit.info && <span>Info: {visit.info}</span>}
              </Space>
            </Text>
          </Col>
        </Row>
      </Col>
      {editable && (
        <Col>
          <Button type={!visit.filled ? "primary" : "ghost"} onClick={() => setDrawerVisible(true)}>
            {!visit.filled ? "Fill" : "Edit"}
          </Button>
        </Col>
      )}

      <Mutation onError={onError}>
        {(loading, mutate) => (
          <Drawer
            title={formTitle}
            width={680}
            onClose={onClose}
            visible={drawerVisible}
            keyboard={false}
            maskClosable={false}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <Space>
                  <Button onClick={onClose}>Cancel</Button>
                  <Button type="primary" loading={loading} onClick={() => form.submit()}>
                    Save
                  </Button>
                  {audit.kind === "surveillance" && (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={() => {
                        form.setFieldsValue({ allSurveillances: true });
                        form.submit();
                      }}
                    >
                      Save all Surveillances
                    </Button>
                  )}
                </Space>
              </div>
            }
          >
            <PwsVisitForm
              form={form}
              visit={visit}
              audit={audit}
              mandays={mandays}
              onFinish={onFinish(mutate, params, variables)}
              errors={errors}
            />
          </Drawer>
        )}
      </Mutation>
    </Fragment>
  );
};
