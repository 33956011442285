import { useQuery } from "@apollo/client";
import { CenterResult } from "__legacy/components/CenterResult";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import { IndustriesQuery as IndustriesQueryType, IndustriesQueryVariables } from "./__generated__/IndustriesQuery";

const IndustriesQuery = loader("./IndustriesQuery.gql");

type IndustriesQueryProviderType = (props: {
  children: (data: IndustriesQueryType) => ReactNode;
  variables: IndustriesQueryVariables;
}) => ReactElement;

export const IndustriesQueryProvider: IndustriesQueryProviderType = (props) => {
  const { children, variables } = props;

  const { error, loading, data } = useQuery<IndustriesQueryType, IndustriesQueryVariables>(IndustriesQuery, {
    variables,
    fetchPolicy: "cache-and-network",
  });

  if (loading) return <CenterSpinner size="large" />;
  if (error || !data)
    return <CenterResult status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;

  return <Fragment>{children(data)}</Fragment>;
};
