import { AuthoritiesQueryProvider } from "__legacy/queries/authorities/AuthoritiesQueryProvider";
import { ClassificationsQueryProvider } from "__legacy/queries/classifications/ClassificationsQueryProvider";
import { CertificationRiskLevelsQueryProvider } from "__legacy/queries/enums/CertificationRiskLevelsQueryProvider";
import { SchemeQueryProvider } from "__legacy/queries/schemes/SchemeQueryProvider";
import { Alert, Card, Col, Form, Row, Select, Space } from "antd";
import { FormInstance } from "antd/lib/form";
import { isString, omit } from "lodash";
import { useParams } from "react-router";

import { ClassificationListForm } from "./ClassificationListForm";

type CodeFormType = {
  action: string;
  form: FormInstance<any>;
  handler: (values: any) => void;
  values: any;
  errors: string[];
};

const fields = [["authorityId", "authorityId"]];

export const onError = (form: any, setErrors: any) => ({ graphQLErrors }: any) => {
  const fieldsErrors: any[] = [];
  const errors: string[] = [];
  graphQLErrors.forEach((graphQLError: any) => {
    let found = false;
    fields.forEach(([fieldName, errorFieldName]) => {
      if (graphQLError.name === errorFieldName) {
        fieldsErrors.push({ name: fieldName, errors: (graphQLError as any).messages });
        found = true;
      }
    });
    if (!found) {
      errors.push(graphQLError.message);
    }
  });
  // console.log(fieldsErrors);
  // console.log(errors);
  form.setFields(fieldsErrors);
  setErrors(errors);
};

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const cleanValues = {
    ...omit(values, []),
    ...params,
  };

  mutate({ variables: { ...variables, input: cleanValues } });
};

export const AccreditationForm: React.FC<CodeFormType> = (props) => {
  const { schemeId } = useParams();

  const { action, form, handler, values, errors } = props;

  console.log(`Globals/Certifications/Schemes/Accreditations/AccreditationForm action: ${action}`);

  return (
    <Card>
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Form layout="vertical" form={form} initialValues={omit(values, [])} onFinish={handler}>
        <Row gutter={[24, 0]}>
          <Col span={8}>
            <AuthoritiesQueryProvider>
              {({ authorities }) => (
                <Form.Item name="authorityId" label="Authority" rules={[{ required: true, message: "is required" }]}>
                  <Select
                    showSearch
                    optionLabelProp="label"
                    filterOption={(input, option) => {
                      let label = "";
                      if (option?.label && isString(option.label)) label = option.label;
                      let value = option?.value;
                      return (
                        label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {authorities.map((authority) => (
                      <Select.Option key={authority.id} value={authority.id} label={authority.name}>
                        {authority.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </AuthoritiesQueryProvider>
          </Col>
        </Row>
        <SchemeQueryProvider variables={{ id: schemeId }}>
          {({ scheme }) => (
            <ClassificationsQueryProvider variables={{ kind: scheme.kind }}>
              {({ classifications }) => (
                <CertificationRiskLevelsQueryProvider>
                  {({ certificationRiskLevels }) => (
                    <ClassificationListForm
                      accreditationClassifications={values.accreditationClassifications}
                      classifications={classifications}
                      riskLevels={certificationRiskLevels?.enumValues ?? []}
                    />
                  )}
                </CertificationRiskLevelsQueryProvider>
              )}
            </ClassificationsQueryProvider>
          )}
        </SchemeQueryProvider>
      </Form>
    </Card>
  );
};
