import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  ApplyProjectMutation as ApplyProjectMutationType,
  ApplyProjectMutationVariables,
} from "./__generated__/ApplyProjectMutation";

const ApplyProjectMutation = loader("./ApplyProjectMutation.gql");

type ApplyProjectMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted: (data: ApplyProjectMutationType) => void;
}) => ReactElement;

export const ApplyProjectMutationProvider: ApplyProjectMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<ApplyProjectMutationType, ApplyProjectMutationVariables>(
    ApplyProjectMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
