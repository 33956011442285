import { StakeholderQueryProvider } from "__legacy/queries/stakeholders/StakeholderQueryProvider";
import { Descriptions, Image, Layout } from "antd";
import { useParams } from "react-router-dom";

export const Show: React.FC<{ agency?: true }> = () => {
  const { stakeholderId } = useParams();

  return (
    <StakeholderQueryProvider variables={{ id: stakeholderId }}>
      {({ stakeholder }) => (
        <Layout.Content className="content">
          <Descriptions column={2}>
            <Descriptions.Item label="Firstname">{stakeholder.firstname}</Descriptions.Item>
            <Descriptions.Item label="Lastname">{stakeholder.lastname}</Descriptions.Item>
            <Descriptions.Item label="Vat Code">{stakeholder.vatCode}</Descriptions.Item>
            <Descriptions.Item label="Fiscal Code">{stakeholder.fiscalCode}</Descriptions.Item>
            <Descriptions.Item label="Address">{stakeholder.address}</Descriptions.Item>
            <Descriptions.Item label="City">{stakeholder.city}</Descriptions.Item>
            <Descriptions.Item label="Zip Code">{stakeholder.zipCode}</Descriptions.Item>
            <Descriptions.Item label="State">{stakeholder.state}</Descriptions.Item>
            <Descriptions.Item label="Country">{stakeholder.country}</Descriptions.Item>
            <Descriptions.Item label="Is enabled">{stakeholder.isEnabled ? "yes" : "no"}</Descriptions.Item>
            {stakeholder.avatar && (
              <Descriptions.Item label="Avatar">
                <Image src={stakeholder.avatar} width={200} />
              </Descriptions.Item>
            )}
            {stakeholder.nda && (
              <Descriptions.Item label="NdA">
                <a href={stakeholder.nda}>Download</a>
              </Descriptions.Item>
            )}
            {stakeholder.sca && (
              <Descriptions.Item label="SCA">
                <a href={stakeholder.sca}>Download</a>
              </Descriptions.Item>
            )}
            {stakeholder.cv && (
              <Descriptions.Item label="CV">
                <a href={stakeholder.cv}>Download</a>
              </Descriptions.Item>
            )}
            {stakeholder.degree && (
              <Descriptions.Item label="Degree">
                <a href={stakeholder.degree}>Download</a>
              </Descriptions.Item>
            )}
          </Descriptions>
        </Layout.Content>
      )}
    </StakeholderQueryProvider>
  );
};
