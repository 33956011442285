import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  UpdateVisitMutation as UpdateVisitMutationType,
  UpdateVisitMutationVariables,
} from "./__generated__/UpdateVisitMutation";

const UpdateVisitMutation = loader("./UpdateVisitMutation.gql");

type UpdateVisitMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted?: (data: UpdateVisitMutationType) => void;
}) => ReactElement;

export const UpdateVisitMutationProvider: UpdateVisitMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<UpdateVisitMutationType, UpdateVisitMutationVariables>(
    UpdateVisitMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
