import { ClassificationsQueryProvider } from "__legacy/queries/classifications/ClassificationsQueryProvider";
import { SchemeQueryProvider } from "__legacy/queries/schemes/SchemeQueryProvider";
import { Form, Transfer } from "antd";
import { useParams } from "react-router";

type ClassificationTransferType = {
  areaClassifications: any[];
};

export const ClassificationTransfer: React.FC<ClassificationTransferType> = (props) => {
  const { schemeId } = useParams();
  const { areaClassifications } = props;

  return (
    <SchemeQueryProvider variables={{ id: schemeId }}>
      {({ scheme }) => (
        <ClassificationsQueryProvider variables={{ kind: scheme.kind }}>
          {({ classifications }) => (
            <Form.Item
              name="areaClassifications"
              valuePropName="targetKeys"
              label="Classifications"
              initialValue={areaClassifications.map((classification) => classification.id)}
            >
              <Transfer
                titles={["Availables", "Abiliteds"]}
                listStyle={{ height: "500px", width: "50%" }}
                showSearch
                filterOption={(inputValue, item) => {
                  const itemText = `${item.industryName} - ${item.title} - ${item.description}`.toLowerCase();
                  const searchValue = inputValue.toLowerCase();
                  return itemText.indexOf(searchValue) > -1;
                }}
                render={(item) => `${item.industryName} - ${item.title} - ${item.description}` ?? item.key}
                dataSource={classifications.map((classification) => ({
                  key: classification.id,
                  title: classification.name,
                  description: classification.description,
                  industryName: classification.industry.name,
                  disabled: false,
                }))}
              />
            </Form.Item>
          )}
        </ClassificationsQueryProvider>
      )}
    </SchemeQueryProvider>
  );
};
