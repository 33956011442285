import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import { CreateCarMutation as CreateCarMutationType, CreateCarMutationVariables } from "./__generated__/CreateCarMutation";

const CreateCarMutation = loader("./CreateCarMutation.gql");

type CreateCarMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted?: (data: CreateCarMutationType) => void;
}) => ReactElement;

export const CreateCarMutationProvider: CreateCarMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<CreateCarMutationType, CreateCarMutationVariables>(CreateCarMutation, {
    errorPolicy: "none",
    onError,
    onCompleted,
  });

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
