import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  CreateProjectActivityItemsMutation as CreateProjectActivityItemsMutationType,
  CreateProjectActivityItemsMutationVariables,
} from "./__generated__/CreateProjectActivityItemsMutation";

const CreateProjectActivityItemsMutation = loader("./CreateProjectActivityItemsMutation.gql");

type CreateProjectActivityItemsMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError?: (error: ApolloError) => void;
  onCompleted?: (data: CreateProjectActivityItemsMutationType) => void;
}) => ReactElement;

export const CreateProjectActivityItemsMutationProvider: CreateProjectActivityItemsMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<
    CreateProjectActivityItemsMutationType,
    CreateProjectActivityItemsMutationVariables
  >(CreateProjectActivityItemsMutation, {
    errorPolicy: "none",
    onError,
    onCompleted,
  });

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
