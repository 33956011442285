import { useQuery } from "@apollo/client";
import { CenterResult } from "__legacy/components/CenterResult";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  ActivityTemplateQuery as ActivityTemplateQueryType,
  ActivityTemplateQueryVariables,
} from "./__generated__/ActivityTemplateQuery";

const ActivityTemplateQuery = loader("./ActivityTemplateQuery.gql");

type ActivityTemplateQueryProviderType = (props: {
  children: (data: ActivityTemplateQueryType) => ReactNode;
  variables: ActivityTemplateQueryVariables;
}) => ReactElement;

export const ActivityTemplateQueryProvider: ActivityTemplateQueryProviderType = (props) => {
  const { children, variables } = props;

  const { error, loading, data } = useQuery<ActivityTemplateQueryType, ActivityTemplateQueryVariables>(
    ActivityTemplateQuery,
    {
      variables,
      fetchPolicy: "cache-and-network",
    },
  );

  if (loading) return <CenterSpinner size="large" />;
  if (error || !data)
    return <CenterResult status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;

  return <Fragment>{children(data)}</Fragment>;
};
