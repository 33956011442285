import { EditOutlined } from "@ant-design/icons";
import { useApplicationContext } from "__legacy/components/Application";
import { EmploymentsQueryProvider } from "__legacy/queries/employments/EmploymentsQueryProvider";
import { Button, Layout, PageHeader, Table, Tag } from "antd";
import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const List: React.FC<{}> = () => {
  const { agencyId } = useParams();
  const { session } = useApplicationContext();
  const navigate = useNavigate();

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      render: (value: string, resource: any) => (
        <Fragment>
          {value === session.currentUser.email ? <Tag>ME</Tag> : ""}
          {resource.user.email}
        </Fragment>
      ),
    },
    {
      width: "80px",
      title: "Actions",
      dataIndex: "id",
      render: (id: string) => (
        <Button onClick={() => navigate(`${id}/edit`)}>
          <EditOutlined />
        </Button>
      ),
    },
  ];

  return (
    <EmploymentsQueryProvider variables={{ agencyId }}>
      {({ employments }) => (
        <Fragment>
          <PageHeader ghost={true} title="Users" />
          <Layout.Content className="content">
            <Table rowKey="id" columns={columns} dataSource={employments} pagination={false} />
          </Layout.Content>
        </Fragment>
      )}
    </EmploymentsQueryProvider>
  );
};
