import { useQuery } from "@apollo/client";
import { CenterResult } from "__legacy/components/CenterResult";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import { UsersQuery as UsersQueryType, UsersQueryVariables } from "./__generated__/UsersQuery";

const UsersQuery = loader("./UsersQuery.gql");

type UsersQueryProviderType = (props: {
  children: (data: UsersQueryType) => ReactNode;
  variables: UsersQueryVariables;
}) => ReactElement;

export const UsersQueryProvider: UsersQueryProviderType = (props) => {
  const { children, variables } = props;

  const { error, loading, data } = useQuery<UsersQueryType, UsersQueryVariables>(UsersQuery, {
    variables,
    fetchPolicy: "cache-and-network",
  });

  if (loading) return <CenterSpinner size="large" />;
  if (error || !data)
    return <CenterResult status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;

  return <Fragment>{children(data)}</Fragment>;
};
