import { CreateAuthorityMutationProvider } from "__legacy/queries/authorities/CreateAuthorityMutationProvider";
import { Button, Form, Layout, PageHeader } from "antd";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

import { BodyForm, onError, onFinish } from "./BodyForm";

export const New: React.FC<{}> = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <CreateAuthorityMutationProvider onError={onError(form, setErrors)} onCompleted={() => navigate("../")}>
      {(loading, mutate) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title={`New body`}
            extra={
              <Fragment>
                <Button onClick={() => navigate("../")}>Back to list</Button>
                <Button type="primary" loading={loading} onClick={() => form.submit()}>
                  Save
                </Button>
              </Fragment>
            }
          />
          <Layout.Content className="content">
            <BodyForm action="create" form={form} handler={onFinish(mutate, {}, {})} values={{}} errors={errors} />
          </Layout.Content>
        </Fragment>
      )}
    </CreateAuthorityMutationProvider>
  );
};
