import { QuestionCircleOutlined } from "@ant-design/icons";
import { ProposeProjectMutationProvider } from "__legacy/queries/projects/ProposeProjectMutationProvider";
import { Button, Card, Popconfirm } from "antd";
import { useState } from "react";
import { useParams } from "react-router";

type PwsProposalCardType = {
  project: any;
  editable: boolean;
};

export const PwsProposalCard: React.FC<PwsProposalCardType> = (props) => {
  const { project, editable } = props;
  const { id } = useParams();
  const title = "Proposal";
  // const [form] = Form.useForm();
  const [, setErrors] = useState<string[]>([]);
  // const navigate = useNavigate();

  const onError =
    () =>
    ({ graphQLErrors }: any) => {
      const errors: string[] = [];
      graphQLErrors.forEach((graphQLError: any) => {
        errors.push(graphQLError.message);
      });
      setErrors(errors);
    };

  // const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  //   const input = {
  //     proposal: values.proposal?.file ?? null,
  //   };
  //   mutate({ variables: { ...variables, input } });
  // };

  // const uploadProps = {
  //   maxCount: 1,
  //   accept: ".pdf, .doc, .xls, .xlsx",
  //   beforeUpload: () => {
  //     return false;
  //   },
  // };

  return (
    <Card
      title={
        !editable && project.proposal ? (
          <b>
            <a href={project.proposal} download>
              {title}
            </a>
          </b>
        ) : (
          title
        )
      }
      // bodyStyle={editable ? {} : { display: "none" }}
      bodyStyle={{ display: "none" }}
      // extra={
      //   editable && (
      //     <Button type="primary" onClick={() => form.submit()}>
      //       Apply
      //     </Button>
      //   )
      // }
      extra={
        editable && (
          <ProposeProjectMutationProvider onError={onError}>
            {(loading, mutate) => (
              <Popconfirm
                title="Are you sure?"
                placement="right"
                icon={<QuestionCircleOutlined />}
                onConfirm={() => mutate({ variables: { id } })}
              >
                <Button type="primary" loading={loading}>
                  Send Proposal
                </Button>
              </Popconfirm>
            )}
          </ProposeProjectMutationProvider>
        )
      }
    >
      {/* <Row gutter={[24, 24]}>
         <Col span={12}>
           {editable && (
             <ApplyProjectMutationProvider onError={onError} onCompleted={() => navigate("../../../dashboard")}>
               {(loading, mutate) => (
                 <Form form={form} onFinish={onFinish(mutate, {}, { id })}>
                   {errors.length > 0 && (
                     <Space direction="vertical" style={{ marginBottom: "10px" }}>
                       {errors.map((error, index) => (
                         <Alert key={index} type="error" message="Error" description={error} />
                       ))}
                     </Space>
                   )}
                   <Form.Item
                     name="proposal"
                     valuePropName={"defaultFileList"}
                     rules={[{ required: true, message: "is required" }]}
                   >
                     <Dragger {...uploadProps} defaultFileList={[]}>
                       <CloudUploadOutlined />
                       Upload Proposal
                     </Dragger>
                   </Form.Item>
                 </Form>
               )}
             </ApplyProjectMutationProvider>
           )}
         </Col>
       </Row> */}
    </Card>
  );
};
