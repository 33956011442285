import { PlusOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { useApplicationContext } from "__legacy/components/Application";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { Alert, Button, Card, Col, DatePicker, Input, Layout, PageHeader, Row, Space } from "antd";
import { loader } from "graphql.macro";
import { lowerCase } from "lodash";
import moment from "moment";
import { Fragment, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import { AbsenceForm } from "./AbsenceForm";
import { AddProjectActivityItemsForm } from "./AddProjectActivityItemsForm";
import { EditProjectActivityItemForm } from "./EditProjectActivityItemForm";

const ProjectActivityItemsQuery = loader("__legacy/queries/projects/ProjectActivityItemsQuery.gql");
const AbsencesQuery = loader("__legacy/queries/absences/AbsencesQuery.gql");

export const Show: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { agencyId, date } = useParams();
  const { session, getEnumValue } = useApplicationContext();

  const [item, setItem] = useState<any>();
  const editItem = (item: any) => {
    setItem(item);
    setEditItemVisible(true);
  };
  const [editItemVisible, setEditItemVisible] = useState(false);
  const editItemClose = () => {
    setItem(undefined);
    setEditItemVisible(false);
  };
  const [addItemVisible, setAddItemVisible] = useState(false);
  const [absence, setAbsence] = useState<any>();
  const editAbsence = (absence: any) => {
    setAbsence(absence);
    setAbsenceFormVisible(true);
  };
  const [absenceFormVisible, setAbsenceFormVisible] = useState(false);
  const editAbsenceClose = () => {
    setAbsence(undefined);
    setAbsenceFormVisible(false);
  };

  const day = moment(date).format("MM-DD-YYYY");
  const employment = session.currentUser.employments
    .filter((e: any) => e.kind === "agency")
    ?.find((e: any) => e.agency.id === agencyId);

  const variables = {
    employmentId: employment.id,
    doneOn: moment(day).format("YYYY-MM-DD"),
  };
  const { loading: itemsLoading, data: itemsData } = useQuery(ProjectActivityItemsQuery, {
    variables,
    fetchPolicy: "cache-and-network",
  });
  const { loading: absencesLoading, data: absencesData } = useQuery(AbsencesQuery, {
    variables,
    fetchPolicy: "cache-and-network",
  });

  if (itemsLoading || absencesLoading) return <CenterSpinner size="large" />;

  const { projectActivityItems } = itemsData;
  const { absences } = absencesData;

  const hours = employment[`${lowerCase(moment(day).format("dddd"))}Hours`];
  let effort = 0;
  let estimated = 0;
  projectActivityItems.forEach((i: any) => {
    effort += i.effortHours;
    estimated += i.item.estimatedHours;
  });
  absences.forEach((a: any) => (effort += a.hours));

  const itemsOrAbsences = !!projectActivityItems.length || !!absences.length;

  return (
    <Fragment>
      <PageHeader
        ghost={true}
        title="Daily Timesheet"
        extra={<Button onClick={() => navigate("../")}>Back to Calendar</Button>}
      />
      <Layout.Content className="content">
        <Card>
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <Row gutter={[24, 24]}>
              <Col span={6}>
                Day
                <DatePicker
                  value={moment(day)}
                  allowClear={false}
                  mode="date"
                  format="MM-DD-YYYY"
                  style={{ width: "100%" }}
                  disabledDate={(d) => d > moment().endOf("day")}
                  onChange={(d: any) => navigate(`../${d.format("MM-DD-YYYY")}`)}
                />
              </Col>
              <Col span={4}>
                Working Hours
                <Input disabled value={employment[`${lowerCase(moment(day).format("dddd"))}Hours`]} />
              </Col>
              {(hours > 0 || itemsOrAbsences) && (
                <Col span={8} offset={5} style={{ paddingTop: "15px" }}>
                  {effort < hours && <Alert type="error" message={`Missing Hours: ${hours - effort}`} />}
                  {effort > hours && <Alert type="warning" message={`Extra Hours: ${effort - hours}`} />}
                  {effort === hours && <Alert type="success" message="Correct Timesheet" />}
                </Col>
              )}
            </Row>
            {itemsOrAbsences && (
              <div>
                <Row style={{ marginBottom: "10px" }}>
                  <Col span={8}>
                    <b>Job Order</b>
                  </Col>
                  <Col span={8}>
                    <b>Item</b>
                  </Col>
                  <Col span={4}>
                    <b>Estimated Hours</b>
                  </Col>
                  <Col span={4}>
                    <b>Effort Hours</b>
                  </Col>
                </Row>
                {projectActivityItems.map((i: any) => (
                  <Row key={i.id}>
                    <Col span={8}>{i.project.code}</Col>
                    <Col span={8}>
                      <NavLink to={""} onClick={() => editItem(i)}>
                        {i.item.description}
                      </NavLink>
                    </Col>
                    <Col span={4}>{i.item.estimatedHours}</Col>
                    <Col span={4}>{i.effortHours}</Col>
                  </Row>
                ))}
                {absences.map((a: any) => (
                  <Row key={a.id}>
                    <Col span={8}>Authorized Absence</Col>
                    <Col span={8}>
                      <NavLink to={""} onClick={() => editAbsence(a)}>
                        {getEnumValue("absenceKinds", a.kind)}
                      </NavLink>
                    </Col>
                    <Col span={4} offset={4}>
                      {a.hours}
                    </Col>
                  </Row>
                ))}
                <Row style={{ marginTop: "10px" }}>
                  <Col span={4} offset={16}>
                    {estimated}
                  </Col>
                  <Col span={4}>{effort}</Col>
                </Row>
              </div>
            )}
            <Row gutter={[24, 24]}>
              <Col>
                <Button type="primary" ghost onClick={() => setAddItemVisible(true)}>
                  <PlusOutlined />
                  Add Job Order Activity Items
                </Button>
              </Col>
              <Col>
                <Button type="primary" ghost onClick={() => setAbsenceFormVisible(true)}>
                  <PlusOutlined />
                  Add Authorized Absence
                </Button>
              </Col>
            </Row>
            <EditProjectActivityItemForm key={item} item={item} visible={editItemVisible} onClose={editItemClose} />
            <AddProjectActivityItemsForm
              employment={employment}
              date={date}
              visible={addItemVisible}
              onClose={() => setAddItemVisible(false)}
              onCompleted={() => {
                setAddItemVisible(false);
                window.location.reload();
              }}
            />
            <AbsenceForm
              key={absence}
              absence={absence}
              employment={employment}
              date={date}
              visible={absenceFormVisible}
              onClose={editAbsenceClose}
              onCompleted={() => {
                setAbsenceFormVisible(false);
                if (absence) setAbsence(undefined);
                else window.location.reload();
              }}
            />
          </Space>
        </Card>
      </Layout.Content>
    </Fragment>
  );
};
