import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  UpdateProjectActivityItemsMutation as UpdateProjectActivityItemsMutationType,
  UpdateProjectActivityItemsMutationVariables,
} from "./__generated__/UpdateProjectActivityItemsMutation";

const UpdateProjectActivityItemsMutation = loader("./UpdateProjectActivityItemsMutation.gql");

type UpdateProjectActivityItemsMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError?: (error: ApolloError) => void;
  onCompleted?: (data: UpdateProjectActivityItemsMutationType) => void;
}) => ReactElement;

export const UpdateProjectActivityItemsMutationProvider: UpdateProjectActivityItemsMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<
    UpdateProjectActivityItemsMutationType,
    UpdateProjectActivityItemsMutationVariables
  >(UpdateProjectActivityItemsMutation, {
    errorPolicy: "none",
    onError,
    onCompleted,
  });

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
