import { Box, HStack, Icon, Stack, Text, Tooltip } from "@chakra-ui/react";
import { FiAlertCircle, FiCheck, FiCheckCircle, FiInfo, FiLock, FiMinusCircle } from "react-icons/fi";

import { Card } from "../ui/Card";

export const Activities: React.FC<{}> = () => {
  return (
    <Card title="Activities" actions="2/15">
      <Stack w="full">
        <HStack justify="space-between" color="blue.500">
          <HStack>
            <Icon as={FiCheck} boxSize="4" />
            <Text>Creation</Text>
          </HStack>
          <Tooltip hasArrow label="Alfonso Pagliuca 15/10/2020 11:33" placement="top">
            <Box>
              <Icon as={FiInfo} boxSize="4" />
            </Box>
          </Tooltip>
        </HStack>
        <HStack>
          <Icon as={FiCheckCircle} boxSize="4" color="green.500" />
          <Text>Certification</Text>
        </HStack>
        <HStack>
          <Icon as={FiMinusCircle} boxSize="4" color="gray.500" />
          <Text>Customer</Text>
        </HStack>
        <HStack>
          <Icon as={FiAlertCircle} boxSize="4" color="amber.500" />
          <Text>Sites</Text>
          <Text>50%</Text>
        </HStack>
        <HStack>
          <Icon as={FiMinusCircle} boxSize="4" color="gray.500" />
          <Text>Transfer</Text>
        </HStack>
        <HStack>
          <Icon as={FiMinusCircle} boxSize="4" color="gray.500" />
          <Text>Assessment</Text>
        </HStack>
        <HStack>
          <Icon as={FiMinusCircle} boxSize="4" color="gray.500" />
          <Text>Reductions</Text>
        </HStack>
        <HStack>
          <Icon as={FiCheckCircle} boxSize="4" color="green.500" />
          <Text>Main Audit</Text>
        </HStack>
        <HStack>
          <Icon as={FiMinusCircle} boxSize="4" color="gray.500" />
          <Text>Surveillance 1</Text>
        </HStack>
        <HStack justify="space-between" color="blue.500">
          <HStack>
            <Icon as={FiCheck} boxSize="4" />
            <Text>Draft Approval</Text>
          </HStack>
          <Tooltip
            hasArrow
            label="Applied: Alfonso Pagliuca 15/10/2020 11:33 Approved: Khairul Bashar 15/10/2020 11:33 "
            placement="top"
          >
            <Box>
              <Icon as={FiInfo} boxSize="4" />
            </Box>
          </Tooltip>
        </HStack>
        <HStack>
          <Icon as={FiMinusCircle} boxSize="4" color="gray.500" />
          <Text>Proposal</Text>
        </HStack>
        <HStack justify="space-between" color="blue.500">
          <HStack>
            <Icon as={FiLock} boxSize="4" />
            <Text>Proposal Approval</Text>
          </HStack>
        </HStack>
        <HStack>
          <Icon as={FiMinusCircle} boxSize="4" color="gray.500" />
          <Text>Contract</Text>
        </HStack>
        <HStack justify="space-between" color="blue.500">
          <HStack>
            <Icon as={FiLock} boxSize="4" />
            <Text>Contract Approval</Text>
          </HStack>
        </HStack>
      </Stack>
    </Card>
  );
};
