import { useQuery } from "@apollo/client";
import { CenterResult } from "__legacy/components/CenterResult";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import { ExperienceQuery as ExperienceQueryType, ExperienceQueryVariables } from "./__generated__/ExperienceQuery";

const ExperienceQuery = loader("./ExperienceQuery.gql");

type ExperienceQueryProviderType = (props: {
  children: (data: ExperienceQueryType) => ReactNode;
  variables: ExperienceQueryVariables;
}) => ReactElement;

export const ExperienceQueryProvider: ExperienceQueryProviderType = (props) => {
  const { children, variables } = props;

  const { error, loading, data } = useQuery<ExperienceQueryType, ExperienceQueryVariables>(ExperienceQuery, {
    variables,
    fetchPolicy: "cache-and-network",
  });

  if (loading) return <CenterSpinner size="large" />;
  if (error || !data)
    return <CenterResult status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;

  return <Fragment>{children(data)}</Fragment>;
};
