import { StakeholderQueryProvider } from "__legacy/queries/stakeholders/StakeholderQueryProvider";
import { CreateTrainingMutationProvider } from "__legacy/queries/trainings/CreateTrainingMutationProvider";
import { Button, Form, Layout, PageHeader } from "antd";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { onError, onFinish, TrainingForm } from "./TrainingForm";

export const New: React.FC<{}> = () => {
  const { stakeholderId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <StakeholderQueryProvider variables={{ id: stakeholderId }}>
      {({ stakeholder }) => (
        <CreateTrainingMutationProvider onError={onError(form, setErrors)} onCompleted={() => navigate("../")}>
          {(loading, mutate) => (
            <Fragment>
              <PageHeader
                ghost={true}
                title={`New training for stakeholder ${stakeholder.companyName}`}
                extra={
                  <Fragment>
                    <Button onClick={() => navigate("../")}>Back to trainings</Button>
                    <Button type="primary" loading={loading} onClick={() => form.submit()}>
                      Save
                    </Button>
                  </Fragment>
                }
              />
              <Layout.Content className="content">
                <TrainingForm
                  action="create"
                  form={form}
                  handler={onFinish(mutate, { stakeholderId }, {})}
                  values={{}}
                  errors={errors}
                />
              </Layout.Content>
            </Fragment>
          )}
        </CreateTrainingMutationProvider>
      )}
    </StakeholderQueryProvider>
  );
};
