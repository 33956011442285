import { gql } from "@apollo/client";
import { camelize, singularize } from "inflected";

const UserFragment = `
fragment UserFragment on User {
  id
  email
  isEnabled
  isAdmin
  authorization {
    id
    canManageUsers
    canManageRoles
    canManageAgencies
    canManageClassifications
    canManageCertifications
    canManageProjects
  }
  employments {
    id
    kind
    isManager
    mondayHours
    tuesdayHours
    wednesdayHours
    thursdayHours
    fridayHours
    saturdayHours
    sundayHours
    agency {
      id
      name
      country
      isEnabled
    }
    authorities {
      id
      name
    }
    authorization {
      canManageProjects
      canManageEmployees
      canManageStakeholders
      canCreateCollaborators
    }
  }
}
`;

const SessionQuery = `
  session {
    id
    isPersistent
    remoteIp
    createdAt
    expiredAt
    user: realUser {
      ...UserFragment
    }
    currentUser {
      ...UserFragment
    }
  }
`;

const EnvironmentQuery = `
  environment {
    name
  }
`;

const DefaultQuery = `
  default {
    language
    timezone
  }
`;

const enumQuery = (name: string) => {
  return `
  ${name}: __type(name: "${camelize(singularize(name))}") {
    enumValues {
      name
      description
    }
  }`;
};

export const getContextQuery = (token: string | undefined, enumList: string[]) => {
  const validToken = token && token !== "" ? true : false;

  let query = "";
  if (validToken) query += UserFragment;
  query += "query GetContextQuery {";
  if (validToken) query += SessionQuery;
  query += EnvironmentQuery;
  query += DefaultQuery;
  query += enumList.map((e: any) => enumQuery(e));
  query += "}";

  return gql(query);
};
