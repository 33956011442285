import { HStack, Stack } from "@chakra-ui/react";
import React from "react";

import { CardButton } from "../ui/CardButton";
import { Events } from "../ui/Events";
import { Training } from "./Training";
import { Notes } from "../ui/Notes";
import { CollaboratorSubTabs } from "./CollaboratorSubTabs";
import { Summary } from "./Summary";
import { HiPlus } from "react-icons/hi";

export const CollaboratorTrainings: React.FC<{}> = () => {
  return (
    <>
      <Summary />
      <Stack bg="white" boxShadow="sm" w="full" px="6">
        <CollaboratorSubTabs />
      </Stack>
      <HStack w="full" spacing="4" alignItems="start">
        <Stack w="75%" spacing="4">
          <Training />
        </Stack>
        <Stack w="25%" spacing="4">
          <CardButton title="New Training" colorScheme="blue" leftIcon={<HiPlus />} />
          <Notes />
          <Events />
        </Stack>
      </HStack>
    </>
  );
};
