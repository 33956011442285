import { DeleteOutlined, EditOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { useApplicationContext } from "__legacy/components/Application";
import { AgencyQueryProvider } from "__legacy/queries/agencies/AgencyQueryProvider";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Layout,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Tooltip,
  Typography,
} from "antd";
import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";

const deleteEmploymentMutation = gql`
  mutation($id: ID!) {
    deleteEmployment(id: $id) {
      employment {
        id
        user {
          email
        }
      }
    }
  }
`;

export const Show: React.FC<{}> = () => {
  const { id } = useParams();
  const { session } = useApplicationContext();
  const navigate = useNavigate();

  const [execDelete] = useMutation(deleteEmploymentMutation, {
    errorPolicy: "none",
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
    onCompleted: ({ deleteEmployment: { employment } }) => {
      notification.success({
        message: `Employment "${employment.user.email}" deleted`,
      });
    },
    refetchQueries: ["AgencyQuery"],
  });

  return (
    <AgencyQueryProvider variables={{ id }}>
      {({ agency }) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title={`Agency ${agency.name}`}
            extra={
              <Fragment>
                <Button onClick={() => navigate("../")}>Back to list</Button>
                <Button type="primary" onClick={() => navigate("./edit")}>
                  Edit
                </Button>
              </Fragment>
            }
          />
          <Layout.Content className="content">
            <Card>
              <Row gutter={[16, 16]} justify="space-between">
                <Col>
                  <Typography.Title level={2}>{agency.name}</Typography.Title>
                  <Typography.Title level={2}>{agency.country}</Typography.Title>
                  <Checkbox disabled checked={agency.isEnabled}>
                    Enabled
                  </Checkbox>
                </Col>
              </Row>
              <Divider />
              <Row gutter={[16, 16]} justify="space-between">
                <Col>
                  <Typography.Title level={3}>Users</Typography.Title>
                </Col>
                <Col>
                  <Button onClick={() => navigate("./users/new")} type="primary">
                    <PlusOutlined />
                    New
                  </Button>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                {agency.employments.map((employment) => {
                  let authorizations = [];
                  if (employment.authorization.canManageProjects === true) authorizations.push("Manage Projects");
                  if (employment.authorization.canManageEmployees === true) authorizations.push("Manage Employees");
                  if (employment.authorization.canManageStakeholders === true)
                    authorizations.push("Manage Stakeholders");
                  if (employment.authorization.canCreateCollaborators === true)
                    authorizations.push("Create Collaborators");

                  const actions = [<EditOutlined onClick={() => navigate(`./users/${employment.id}/edit`)} />];
                  if (session.currentUser.isAdmin)
                    actions.push(
                      <Popconfirm
                        title="Are you sure?"
                        icon={<QuestionCircleOutlined />}
                        onConfirm={() => execDelete({ variables: { id: employment.id } })}
                      >
                        <Tooltip title="Delete" placement="bottom">
                          <DeleteOutlined style={{ color: "#ff4d4f" }} />
                        </Tooltip>
                      </Popconfirm>,
                    );

                  return (
                    <Col key={employment.id} span={8}>
                      <Card title={employment.user.email} actions={actions}>
                        <div style={{ minHeight: "100px" }}>
                          <Typography.Title level={5} type="secondary">
                            Authorizations
                          </Typography.Title>
                          {(!!authorizations.length && authorizations.join(", ")) || "none"}
                        </div>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </Card>
          </Layout.Content>
        </Fragment>
      )}
    </AgencyQueryProvider>
  );
};
