import { createContext, useContext } from "react";
import { throwOnNull } from "utilities/throwUtilities";

type ApplicationContextType = {
  debug: boolean;
  coreDefault: {
    language: string;
    timezone: string;
  };
  coreEnvironment: {
    name: string;
  };
  siteEnvironment: {
    name: string;
    maintenance: boolean;
    appEndpoint: string;
    cdnEndpoint: string;
    siteOffice: string;
    sessionCookie: string;
    sessionDomain: string;
  };
  session: any;
  authenticated: boolean;
  configuration: {
    language: string;
    timezone: string;
  };
  setSessionToken: (token: string, persistent: boolean) => void;
  clearSessionToken: () => void;
  refetchSession: () => void;
  enums: any;
  getEnumOptions: (name: string, opts?: any) => any[];
  getEnumValue: (name: string, value: string) => string;
};

const ApplicationContext = createContext<ApplicationContextType | null>(null);

export const ApplicationContextProvider = ApplicationContext.Provider;

export const useApplicationContext = () => {
  const applicationContext = useContext(ApplicationContext);

  throwOnNull(applicationContext, "Application.ApplicationContext", "Can't get context", "context is null");

  return applicationContext as ApplicationContextType;
};
