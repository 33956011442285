import { useQuery } from "@apollo/client";
import { CenterResult } from "__legacy/components/CenterResult";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import { ContactsQuery as ContactsQueryType, ContactsQueryVariables } from "./__generated__/ContactsQuery";

const ContactsQuery = loader("./ContactsQuery.gql");

type ContactsQueryProviderType = (props: {
  children: (data: ContactsQueryType) => ReactNode;
  variables: ContactsQueryVariables;
}) => ReactElement;

export const ContactsQueryProvider: ContactsQueryProviderType = (props) => {
  const { children, variables } = props;

  const { error, loading, data } = useQuery<ContactsQueryType, ContactsQueryVariables>(ContactsQuery, {
    variables,
    fetchPolicy: "cache-and-network",
  });

  if (loading) return <CenterSpinner size="large" />;
  if (error || !data)
    return <CenterResult status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;

  return <Fragment>{children(data)}</Fragment>;
};
