import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  UpdateProjectDraftMutation as UpdateProjectDraftMutationType,
  UpdateProjectDraftMutationVariables,
} from "./__generated__/UpdateProjectDraftMutation";

const UpdateProjectDraftMutation = loader("./UpdateProjectDraftMutation.gql");

type UpdateProjectDraftMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted?: (data: UpdateProjectDraftMutationType) => void;
}) => ReactElement;

export const UpdateProjectDraftMutationProvider: UpdateProjectDraftMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<UpdateProjectDraftMutationType, UpdateProjectDraftMutationVariables>(
    UpdateProjectDraftMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
