import { SiteQueryProvider } from "__legacy/queries/sites/SiteQueryProvider";
import { UpdateSiteMutationProvider } from "__legacy/queries/sites/UpdateSiteMutationProvider";
import { StakeholderQueryProvider } from "__legacy/queries/stakeholders/StakeholderQueryProvider";
import { Button, Form, Layout, PageHeader } from "antd";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { onError, onFinish, SiteForm } from "./SiteForm";

export const Edit: React.FC<{}> = () => {
  const { stakeholderId, id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <StakeholderQueryProvider variables={{ id: stakeholderId }}>
      {({ stakeholder }) => (
        <SiteQueryProvider variables={{ id: id }}>
          {({ site }) => (
            <UpdateSiteMutationProvider onError={onError(form, setErrors)} onCompleted={() => navigate("../../../")}>
              {(loading, mutate) => (
                <Fragment>
                  <PageHeader
                    ghost={true}
                    title={`Edit site ${site.name} of ${stakeholder.companyName}`}
                    extra={
                      <Fragment>
                        <Button onClick={() => navigate("../../../../")}>Back to list</Button>
                        <Button onClick={() => navigate("../../../")}>Back to Stakeholder</Button>
                        <Button type="primary" loading={loading} onClick={() => form.submit()}>
                          Save
                        </Button>
                      </Fragment>
                    }
                  />
                  <Layout.Content className="content">
                    <SiteForm
                      action="update"
                      form={form}
                      handler={onFinish(mutate, {}, { id })}
                      values={site}
                      errors={errors}
                      sites={stakeholder.sites}
                    />
                  </Layout.Content>
                </Fragment>
              )}
            </UpdateSiteMutationProvider>
          )}
        </SiteQueryProvider>
      )}
    </StakeholderQueryProvider>
  );
};
