import { CreateAreaMutationProvider } from "__legacy/queries/areas/CreateAreaMutationProvider";
import { SchemeQueryProvider } from "__legacy/queries/schemes/SchemeQueryProvider";
import { Button, Form, Layout, PageHeader } from "antd";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { onError, onFinish, TechnicalAreaForm } from "./TechnicalAreaForm";

export const New: React.FC<{}> = () => {
  const { schemeId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <SchemeQueryProvider variables={{ id: schemeId }}>
      {({ scheme }) => (
        <CreateAreaMutationProvider onError={onError(form, setErrors)} onCompleted={() => navigate("../")}>
          {(loading, mutate) => (
            <Fragment>
              <PageHeader
                ghost={true}
                title={`New Scheme Area for ${scheme.name}`}
                extra={
                  <Fragment>
                    <Button onClick={() => navigate("../../../")}>Back to list</Button>
                    <Button onClick={() => navigate("../")}>Back to technical areas</Button>
                    <Button type="primary" loading={loading} onClick={() => form.submit()}>
                      Save
                    </Button>
                  </Fragment>
                }
              />
              <Layout.Content className="content">
                <TechnicalAreaForm
                  action="create"
                  form={form}
                  handler={onFinish(mutate, { schemeId }, {})}
                  values={{}}
                  errors={errors}
                />
              </Layout.Content>
            </Fragment>
          )}
        </CreateAreaMutationProvider>
      )}
    </SchemeQueryProvider>
  );
};
