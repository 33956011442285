import { PlusOutlined } from "@ant-design/icons";
import { ProjectStatus, ProjectStep } from "__legacy/queries/__generated__/globalTypes";
import { ProjectsQueryProvider } from "__legacy/queries/projects/ProjectsQueryProvider";
import { VisitsQueryProvider } from "__legacy/queries/projects/VisitsQueryProvider";
import { Button, Card, Col, Layout, PageHeader, Row, Space, Typography } from "antd";
import moment from "moment";
import { Fragment } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";

export const Dash: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { agencyId } = useParams();

  const cookieName = "sessionDate";
  const [cookies] = useCookies([cookieName]);
  let sessionDate = cookies[cookieName];
  sessionDate = sessionDate ? moment(sessionDate).format("YYYY-MM-DD") : null;

  const groups = [
    {
      title: "Accredited office Review Actions",
      cards: [
        { title: "PWS", resource: "projects", step: "application", status: "pending" },
        { title: "Signed Proposals", resource: "projects", step: "contract", status: "pending" },
        {
          title: "Assessment Packages",
          resource: "visits",
          kind: "main",
          status: "pending",
        },
        {
          title: "Surveillance Packages",
          resource: "visits",
          kind: "surveillance",
          status: "pending",
        },
      ],
    },
    {
      title: "On Hold for Office Response Actions",
      cards: [
        {
          title: "PWS on Hold",
          resource: "projects",
          step: "application",
          status: "redaction",
          state: { review: true },
        },
        { title: "Signed Proposals on Hold", resource: "projects", step: "contract", status: "redaction" },
        {
          title: "Stage 1 Packages",
          resource: "projects",
          step: "stage1",
          status: "booking",
          state: { visitReview: true },
        },
        {
          title: "Stage 2 Packages",
          resource: "projects",
          step: "stage2",
          status: "booking",
          state: { visitReview: true },
        },
        {
          title: "Surveillance Packages",
          resource: "projects",
          step: "surveillance",
          status: "booking",
          state: { visitReview: true },
        },
      ],
    },
    {
      title: "Office Operational Actions",
      cards: [
        { title: "PWS", resource: "projects", step: "application", status: "redaction", state: { review: false } },
        { title: "Proposals to send", resource: "projects", step: "proposal", status: "redaction" },
        { title: "Proposal without Contract", resource: "projects", step: "contract", status: "redaction" },
        {
          title: "Stage 1 Not Booked",
          resource: "projects",
          step: "stage1",
          status: "booking",
          // resource: "visits", // TODO fix
          // kind: "main",
          // status: "redaction",
          // state: {
          //   step: "1",
          //   bookingFilled: false,
          // },
        },
        {
          title: "Stage 2 Not Booked",
          resource: "projects",
          step: "stage2",
          status: "booking",
          // resource: "visits", // TODO fix
          // kind: "main",
          // status: "redaction",
          // state: {
          //   step: "2",
          //   bookingFilled: false,
          // },
        },
        {
          title: "Stage 1 Packages to Upload",
          resource: "visits",
          kind: "main",
          status: "redaction",
          state: {
            step: "1",
            bookingFilled: true,
          },
        },
        {
          title: "Stage 2 Packages to Upload",
          resource: "visits",
          kind: "main",
          status: "redaction",
          state: {
            step: "2",
            bookingFilled: true,
          },
        },
        {
          title: "Surveillance Not Booked",
          resource: "visits",
          kind: "surveillance",
          status: "redaction",
          state: {
            bookingFilled: false,
          },
        },
        {
          title: "Surveillance Packages to Upload",
          resource: "visits",
          kind: "surveillance",
          status: "redaction",
          state: {
            bookingFilled: true,
          },
        },
      ],
    },
  ];

  return (
    <Fragment>
      <PageHeader
        ghost={true}
        title="System Job Orders"
        extra={
          <Button onClick={() => navigate("../new")} type="primary">
            <PlusOutlined />
            New
          </Button>
        }
      />
      <Layout.Content className="content">
        <Row gutter={[24, 24]}>
          {groups.map((group, i) => (
            <Col span={8} key={i}>
              <Space direction="vertical">
                <Typography.Title level={5}>{group.title}</Typography.Title>
                <Row gutter={[16, 16]}>
                  {group.cards.map((card: any, j: any) => (
                    <Col span={24} key={j}>
                      {card.resource === "projects" && (
                        <ProjectsQueryProvider
                          variables={{
                            agencyId,
                            step: card.step as ProjectStep,
                            status: card.status as ProjectStatus,
                            ...card.state,
                          }}
                        >
                          {({ projects }) => (
                            <Card
                              title={
                                <Typography.Title level={5} type="secondary">
                                  <Typography.Text type="warning">{projects.length}</Typography.Text> {card.title}
                                </Typography.Title>
                              }
                              bodyStyle={{ display: "none" }}
                              extra={
                                <Button
                                  type="primary"
                                  ghost
                                  onClick={() => navigate(`../${card.step}/${card.status}`, { state: card.state })}
                                >
                                  Go to list
                                </Button>
                              }
                            />
                          )}
                        </ProjectsQueryProvider>
                      )}
                      {card.resource === "visits" && (
                        <VisitsQueryProvider
                          variables={{
                            agencyId,
                            kind: card.kind,
                            status: card.status as ProjectStatus,
                            ...card.state,
                            ...(card.status === "redaction" ? { sessionDate } : {}),
                          }}
                        >
                          {({ visits }) => (
                            <Card
                              title={
                                <Typography.Title level={5} type="secondary">
                                  <Typography.Text type="warning">{visits.length}</Typography.Text> {card.title}
                                </Typography.Title>
                              }
                              bodyStyle={{ display: "none" }}
                              extra={
                                <Button
                                  type="primary"
                                  ghost
                                  onClick={() =>
                                    navigate(`../visits/${card.kind}/${card.status}`, {
                                      state: card.state,
                                    })
                                  }
                                >
                                  Go to list
                                </Button>
                              }
                            />
                          )}
                        </VisitsQueryProvider>
                      )}
                    </Col>
                  ))}
                </Row>
              </Space>
            </Col>
          ))}
        </Row>
      </Layout.Content>
    </Fragment>
  );
};
