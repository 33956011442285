import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Suspense } from "react";

import { Application, Wrapper } from "./components/Application";
import { CenterResult } from "./components/CenterResult";
import { CenterSpinner } from "./components/CenterSpinner";
import { Maintenance } from "./components/Maintenance";
import { useApolloClient } from "./hooks/useApolloClient";
import { useSessionCookie } from "./hooks/useSessionCookie";

import "__legacy/styles/base.less";

type RootProps = {
  debug: boolean;
  siteEnvironment: {
    name: string;
    maintenance: boolean;
    appEndpoint: string;
    cdnEndpoint: string;
    siteOffice: string;
    sessionCookie: string;
    sessionDomain: string;
  };
};

export const Root: React.FC<RootProps> = (props) => {
  const { debug, siteEnvironment } = props;

  const { token, setToken, clearToken } = useSessionCookie(
    siteEnvironment.sessionCookie,
    siteEnvironment.sessionDomain,
  );
  const apolloClient = useApolloClient(siteEnvironment.appEndpoint, token, clearToken);

  const ErrorFallback = <CenterResult status="500" title="500" subTitle="Sorry, the server is wrong." />;

  const wrapperProps = {
    debug,
    siteEnvironment,
    token,
    setToken,
    clearToken,
  };

  if (siteEnvironment.maintenance === true) return <Maintenance />;

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
      <Suspense fallback={<CenterSpinner size="large" />}>
        <ApolloProvider client={apolloClient}>
          <Wrapper {...wrapperProps}>
            <Application />
          </Wrapper>
        </ApolloProvider>
      </Suspense>
    </Sentry.ErrorBoundary>
  );
};
