import { EmploymentQueryProvider } from "__legacy/queries/employments/EmploymentQueryProvider";
import { UpdateEmploymentMutationProvider } from "__legacy/queries/employments/UpdateEmploymentMutationProvider";
import { UserQueryProvider } from "__legacy/queries/users/UserQueryProvider";
import { Button, Form, Layout, PageHeader } from "antd";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { EmploymentForm, onError, onFinish } from "./EmploymentForm";

export const Edit: React.FC<{}> = () => {
  const { userId, id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <UserQueryProvider variables={{ id: userId }}>
      {({ user }) => (
        <EmploymentQueryProvider variables={{ id: id }}>
          {({ employment }) => (
            <UpdateEmploymentMutationProvider
              onError={onError(form, setErrors)}
              onCompleted={() => navigate("../../../")}
            >
              {(loading, mutate) => (
                <Fragment>
                  <PageHeader
                    ghost={true}
                    title={`Edit Employment for ${user.email}`}
                    extra={
                      <Fragment>
                        <Button onClick={() => navigate("../../../../")}>Back to list</Button>
                        <Button onClick={() => navigate("../../../")}>Back to user</Button>
                        <Button type="primary" loading={loading} onClick={() => form.submit()}>
                          Save
                        </Button>
                      </Fragment>
                    }
                  />
                  <Layout.Content className="content">
                    <EmploymentForm
                      action="update"
                      form={form}
                      handler={onFinish(mutate, {}, { id })}
                      values={employment}
                      errors={errors}
                      user={user}
                    />
                  </Layout.Content>
                </Fragment>
              )}
            </UpdateEmploymentMutationProvider>
          )}
        </EmploymentQueryProvider>
      )}
    </UserQueryProvider>
  );
};
