import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  UpdateProjectActivityItemMutation as UpdateProjectActivityItemMutationType,
  UpdateProjectActivityItemMutationVariables,
} from "./__generated__/UpdateProjectActivityItemMutation";

const UpdateProjectActivityItemMutation = loader("./UpdateProjectActivityItemMutation.gql");

type UpdateProjectActivityItemMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError?: (error: ApolloError) => void;
  onCompleted?: (data: UpdateProjectActivityItemMutationType) => void;
}) => ReactElement;

export const UpdateProjectActivityItemMutationProvider: UpdateProjectActivityItemMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<
    UpdateProjectActivityItemMutationType,
    UpdateProjectActivityItemMutationVariables
  >(UpdateProjectActivityItemMutation, {
    errorPolicy: "none",
    onError,
    onCompleted,
  });

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
