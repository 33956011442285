import { FileAddOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Card, message, Modal, Upload } from "antd";
import { loader } from "graphql.macro";
import { Fragment } from "react";

const { Dragger } = Upload;

const CreateFileMutation = loader("__legacy/queries/projects/CreateFileMutation.gql");
const DeleteFileMutation = loader("__legacy/queries/projects/DeleteFileMutation.gql");

type PwsFilesCardType = {
  project: any;
};

export const PwsFilesCard: React.FC<PwsFilesCardType> = (props) => {
  const { project } = props;

  const [createFile] = useMutation(CreateFileMutation);
  const [deleteFile] = useMutation(DeleteFileMutation);

  const uploadProps = {
    multiple: true,
    maxCount: 10,
    accept: "*",
    defaultFileList: project.files.map((file: any, i: any) => ({
      uid: i,
      id: file.id,
      url: file.file,
      name: file.fileName,
    })),
    onChange(info: any) {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    customRequest: async (options: any) => {
      const { file, onSuccess, onError } = options;

      const input = { projectId: project.id, file: file };
      try {
        const {
          data: {
            createFile: { file },
          },
        } = await createFile({ variables: { input } });
        onSuccess(file);
      } catch (err) {
        onError({ err });
      }
    },
    onRemove(file: any) {
      const { confirm } = Modal;
      const id = file.response ? file.response.id : file.id;
      return new Promise<boolean>((resolve, reject) => {
        confirm({
          title: `Are you sure you want to delete ${file.name}?`,
          onOk: () => {
            resolve(true);
            deleteFile({ variables: { id } });
            message.success(`${file.name} deleted.`);
          },
          onCancel: () => {
            reject(true);
          },
        });
      });
    },
  };

  const content = (
    <Fragment>
      <Dragger {...uploadProps}>
        <div>
          <FileAddOutlined style={{ fontSize: "30px" }} />
          <div style={{ marginTop: 8 }}>Click or drop files here</div>
        </div>
      </Dragger>
    </Fragment>
  );

  return <Card title="Files">{content}</Card>;
};
