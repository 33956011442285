import { LocaleCountriesQueryProvider } from "__legacy/queries/enums/LocaleCountriesQueryProvider";
import { Alert, Card, Col, Form, Input, Row, Select, Space, Switch } from "antd";
import { FormInstance } from "antd/lib/form";
import { isString, omit } from "lodash";

type AgencyFormType = {
  action: string;
  form: FormInstance<any>;
  handler: (values: any) => void;
  values: any;
  errors: string[];
};

const fields = [
  ["name", "name"],
  ["country", "country"],
  ["isEnabled", "isEnabled"],
];

export const onError = (form: any, setErrors: any) => ({ graphQLErrors }: any) => {
  const fieldsErrors: any[] = [];
  const errors: string[] = [];
  graphQLErrors.forEach((graphQLError: any) => {
    let found = false;
    fields.forEach(([fieldName, errorFieldName]) => {
      if (graphQLError.name === errorFieldName) {
        fieldsErrors.push({ name: fieldName, errors: (graphQLError as any).messages });
        found = true;
      }
    });
    if (!found) {
      errors.push(graphQLError.message);
    }
  });
  // console.log(fieldsErrors);
  // console.log(errors);
  form.setFields(fieldsErrors);
  setErrors(errors);
};

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const cleanValues = {
    ...omit(values, []),
    ...params,
  };

  mutate({ variables: { ...variables, input: cleanValues } });
};

export const AgencyForm: React.FC<AgencyFormType> = (props) => {
  const { action, form, handler, values, errors } = props;

  console.log(`Globals/Agencies/AgencyForm action: ${action}`);

  return (
    <Card>
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Form layout="vertical" form={form} initialValues={values} onFinish={handler}>
        <Row gutter={[24, 0]}>
          <Col span={8}>
            <Form.Item name="name" label="Name" rules={[{ required: true, message: "is required" }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <LocaleCountriesQueryProvider>
              {({ localeCountries }) => (
                <Form.Item name="country" label="Country" rules={[{ required: true, message: "is required" }]}>
                  <Select
                    showSearch
                    optionLabelProp="label"
                    filterOption={(input, option) => {
                      let label = "";
                      if (option?.label && isString(option.label)) label = option.label;
                      let value = option?.value;
                      return (
                        label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {localeCountries?.enumValues?.map((localeCountry) => (
                      <Select.Option
                        key={localeCountry.name}
                        value={localeCountry.name}
                        label={localeCountry.description}
                      >
                        {localeCountry.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </LocaleCountriesQueryProvider>
          </Col>
          <Col span={8}>
            <Form.Item
              name="projectCodePrefix"
              label="Project Code Prefix"
              rules={[{ required: true, message: "is required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="isEnabled" label="Is enabled?" valuePropName={"checked"}>
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
