export const throwOnError = (error: any, subject: string, message: string) => {
  if (error) {
    throw new Error(`[${subject}] ${message}: ${error}`);
  }
};

export const throwOnNull = (resource: any, subject: string, message: string, details?: string) => {
  if (!resource) {
    throw new Error(`[${subject}] ${message}: ${details}`);
  }
};

export const throwOnCondition = (condition: boolean, subject: string, message: string, details?: string) => {
  if (condition) {
    throw new Error(`[${subject}] ${message}: ${details}`);
  }
};
