import { ActivityItemsQueryProvider } from "__legacy/queries/activities/ActivityItemsQueryProvider";
import { ActivityStagesQueryProvider } from "__legacy/queries/activities/ActivityStagesQueryProvider";
import { ActivityTemplatesQueryProvider } from "__legacy/queries/activities/ActivityTemplatesQueryProvider";
import { Alert, Card, Col, Form, Input, InputNumber, Row, Select, Space, Switch } from "antd";
import { FormInstance } from "antd/lib/form";
import { isString } from "lodash";
import { useParams } from "react-router-dom";

type ItemFormType = {
  form: FormInstance<any>;
  handler: (values: any) => void;
  values: any;
  errors: string[];
};

const fields = [
  ["name", "name"],
  ["kind", "kind"],
];

export const onError = (form: any, setErrors: any) => ({ graphQLErrors }: any) => {
  const fieldsErrors: any[] = [];
  const errors: string[] = [];
  graphQLErrors.forEach((graphQLError: any) => {
    let found = false;
    fields.forEach(([fieldName, errorFieldName]) => {
      if (graphQLError.name === errorFieldName) {
        fieldsErrors.push({ name: fieldName, errors: (graphQLError as any).messages });
        found = true;
      }
    });
    if (!found) {
      errors.push(graphQLError.message);
    }
  });
  form.setFields(fieldsErrors);
  setErrors(errors);
};

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const input = {
    ...values,
    order: values.order ?? 1,
    stageId: values.stageId ?? null,
    templateItems: values.templateItems?.map((templateId: any) => ({
      templateId,
    })),
    ...params,
  };

  mutate({ variables: { ...variables, input } });
};

export const ItemForm: React.FC<ItemFormType> = (props) => {
  const { form, handler, values, errors } = props;
  const { agencyId } = useParams();

  const initialValues = {
    ...values,
    stageId: values.stage?.id,
    templateItems: values.templates?.map((template: any) => template.id),
    dependencyId: values.dependency?.id,
  };

  return (
    <Card>
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Form layout="vertical" form={form} initialValues={initialValues} onFinish={handler}>
        <Row gutter={[24, 0]}>
          <Col span={12}>
            <Form.Item name="name" label="Name" rules={[{ required: true, message: "is required" }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="kind" label="Kind" rules={[{ required: true, message: "is required" }]}>
              <Select
                showSearch
                optionLabelProp="label"
                filterOption={(input, option) => {
                  let label = "";
                  if (option?.label && isString(option.label)) label = option.label;
                  let value = option?.value;
                  return (
                    label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                <Select.Option key="cost" value="cost" label="Cost">
                  Cost
                </Select.Option>
                <Select.Option key="revenue" value="revenue" label="Revenue">
                  Revenue
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="balanceKind" label="Balance Kind" rules={[{ required: true, message: "is required" }]}>
              <Select
                showSearch
                optionLabelProp="label"
                filterOption={(input, option) => {
                  let label = "";
                  if (option?.label && isString(option.label)) label = option.label;
                  let value = option?.value;
                  return (
                    label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                <Select.Option key="timesheet" value="timesheet" label="Timesheet">
                  Timesheet
                </Select.Option>
                <Select.Option key="timesheet_manual" value="timesheet_manual" label="Timesheet / Manual">
                  Timesheet / Manual
                </Select.Option>
                <Select.Option key="manual" value="manual" label="Manual">
                  Manual
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="assignment" label="Assignment" rules={[{ required: true, message: "is required" }]}>
              <Select
                showSearch
                optionLabelProp="label"
                filterOption={(input, option) => {
                  let label = "";
                  if (option?.label && isString(option.label)) label = option.label;
                  let value = option?.value;
                  return (
                    label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                <Select.Option key="agency" value="agency" label="Agency">
                  Agency
                </Select.Option>
                <Select.Option key="global" value="global" label="Global">
                  Global
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="description" label="Description" rules={[{ required: true, message: "is required" }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="estimatedHours"
              label="Estimated Hours"
              rules={[{ required: true, message: "is required" }]}
            >
              <InputNumber min={0.25} step={0.25} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="mandatory" label="Mandatory?" valuePropName={"checked"}>
              <Switch />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="order" label="Order">
              <InputNumber min={1} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <ActivityItemsQueryProvider variables={{ agencyId }}>
              {({ activityItems }) => (
                <Form.Item
                  name="dependencyId"
                  label="Dependency"
                  help="Cannot be done before the selected item is done"
                >
                  <Select
                    allowClear
                    showSearch
                    optionLabelProp="label"
                    filterOption={(input, option) => {
                      let label = "";
                      if (option?.label && isString(option.label)) label = option.label;
                      let value = option?.value;
                      return (
                        label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {activityItems.map((item) => {
                      if (item.id === values.id) return null;
                      return (
                        <Select.Option key={item.id} value={item.id} label={item.name}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}
            </ActivityItemsQueryProvider>
          </Col>
          <Col span={24}>
            <ActivityTemplatesQueryProvider variables={{ agencyId }}>
              {({ activityTemplates }) => (
                <Form.Item name="templateItems" label="Templates" rules={[{ required: true, message: "is required" }]}>
                  <Select
                    mode="multiple"
                    showSearch
                    optionLabelProp="label"
                    filterOption={(input, option) => {
                      let label = "";
                      if (option?.label && isString(option.label)) label = option.label;
                      let value = option?.value;
                      return (
                        label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {activityTemplates.map((template) => (
                      <Select.Option key={template.id} value={template.id} label={template.name}>
                        {template.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </ActivityTemplatesQueryProvider>
          </Col>
          <Col span={24}>
            <ActivityStagesQueryProvider>
              {({ activityStages }) => (
                <Form.Item name="stageId" label="Stage">
                  <Select
                    showSearch
                    allowClear
                    optionLabelProp="label"
                    filterOption={(input, option) => {
                      let label = "";
                      if (option?.label && isString(option.label)) label = option.label;
                      let value = option?.value;
                      return (
                        label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {activityStages.map((template) => (
                      <Select.Option key={template.id} value={template.id} label={template.name}>
                        {template.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </ActivityStagesQueryProvider>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
