import { ProjectActivityItemsQueryProvider } from "__legacy/queries/projects/ProjectActivityItemsQueryProvider";
import { ProjectsQueryProvider } from "__legacy/queries/projects/ProjectsQueryProvider";
import {
  UpdateProjectActivityItemsMutationProvider,
} from "__legacy/queries/projects/UpdateProjectActivityItemsMutationProvider";
import { Alert, Button, Col, Drawer, Form, InputNumber, Row, Select, TimePicker } from "antd";
import { isString } from "lodash";
import moment from "moment";
import { FormInstance } from "rc-field-form";
import { Fragment } from "react";
import { useParams } from "react-router";

export const AddProjectActivityItemsForm: React.FC<{
  employment: any;
  date: any;
  visible: any;
  onClose: () => void;
  onCompleted: () => void;
}> = (props) => {
  const { visible, onClose, onCompleted, employment, date } = props;
  const { agencyId } = useParams();
  const [form] = Form.useForm();

  const params = { employmentId: employment.id };
  const onFinish = (mutate: any, params: any) => (values: any) => {
    const input = {
      ...values,
      projectId: undefined,
      doneTime: undefined,
      doneOn: moment(date + moment(values.doneTime).format("HH:mm"), "MM-DD-YYYY HH:mm"),
      ...params,
    };
    mutate({ variables: { input } });
  };

  return (
    <UpdateProjectActivityItemsMutationProvider onCompleted={onCompleted}>
      {(loading, mutate) => (
        <Drawer
          title={"Add Job Order Items"}
          width={680}
          onClose={onClose}
          visible={visible}
          keyboard={false}
          maskClosable={false}
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button onClick={onClose} style={{ marginRight: 8 }}>
                Cancel
              </Button>
              <Button type="primary" loading={loading} onClick={() => form.submit()}>
                Save
              </Button>
            </div>
          }
        >
          <Form layout="vertical" form={form} onFinish={onFinish(mutate, params)}>
            <Row gutter={[24, 0]}>
              <Col span={24}>
                <ProjectsQueryProvider variables={{ agencyId, itemsTodo: true }}>
                  {({ projects }) => (
                    <Form.Item name="projectId" label="Job Order" rules={[{ required: true, message: "is required" }]}>
                      <Select
                        showSearch
                        optionLabelProp="label"
                        style={{ width: "100%" }}
                        onChange={() => form.setFieldsValue({ itemIds: [], effortHours: null, estimatedHours: null })}
                        filterOption={(input, option) => {
                          let label = "";
                          if (option?.label && isString(option.label)) label = option.label;
                          let value = option?.value;
                          return (
                            label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                            value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {projects.map((project: any) => (
                          <Select.Option key={project.id} value={project.id} label={project.code}>
                            {project.code}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </ProjectsQueryProvider>
              </Col>
              <Col span={24}>
                <Form.Item dependencies={["projectId"]}>
                  {(form: FormInstance) => (
                    <Fragment>
                      {form.getFieldValue("projectId") && (
                        <ProjectActivityItemsQueryProvider
                          variables={{ projectId: form.getFieldValue("projectId"), done: false }}
                        >
                          {({ projectActivityItems }) => {
                            let stages: any[] = [];
                            let unstaged: any[] = [];
                            projectActivityItems.forEach((o: any) => {
                              if (o.item.stage) {
                                const stage = stages.find((s: any) => s.id === o.item.stage.id);
                                if (!stage)
                                  stages.push({ id: o.item.stage.id, title: o.item.stage.description, items: [o] });
                                else stage.items.push(o);
                              } else unstaged.push(o);
                            });
                            return (
                              <Form.Item
                                name="itemIds"
                                label="Activity Item"
                                rules={[{ required: true, message: "is required" }]}
                              >
                                <Select
                                  mode="multiple"
                                  showSearch
                                  optionLabelProp="label"
                                  onChange={(items: any) => {
                                    if (items.length === 1) {
                                      const item = projectActivityItems.find((i: any) => i.id === items[0]);
                                      form.setFieldsValue({
                                        effortHours: item?.effortHours,
                                        estimatedHours: item?.item.estimatedHours,
                                      });
                                    }
                                  }}
                                  style={{ width: "100%" }}
                                  filterOption={(input, option) => {
                                    let label = "";
                                    if (option?.label && isString(option.label)) label = option.label;
                                    let value = option?.value;
                                    return (
                                      label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                      value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    );
                                  }}
                                >
                                  {stages.map((stage: any) => (
                                    <Select.OptGroup key={stage.id} label={stage.title}>
                                      {stage.items.map((item: any) => (
                                        <Select.Option key={item.id} value={item.id} label={item.item.description}>
                                          {item.item.description}
                                        </Select.Option>
                                      ))}
                                    </Select.OptGroup>
                                  ))}
                                  {unstaged.map((item: any) => (
                                    <Select.Option key={item.id} value={item.id} label={item.item.description}>
                                      {item.item.description}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );
                          }}
                        </ProjectActivityItemsQueryProvider>
                      )}
                    </Fragment>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item dependencies={["itemIds"]}>
                  {(form: FormInstance) => (
                    <Fragment>
                      <Fragment>
                        <Form.Item
                          name="effortHours"
                          label={
                            "Effort Hours" + (form.getFieldValue("itemIds")?.length > 1 ? " of the single Item" : "")
                          }
                          rules={[{ required: true, message: "is required" }]}
                        >
                          <InputNumber min={0.25} step={0.25} />
                        </Form.Item>
                        <Form.Item
                          name="doneTime"
                          label="End Time"
                          rules={[{ required: true, message: "is required" }]}
                        >
                          <TimePicker format="HH:mm" allowClear={false} />
                        </Form.Item>
                      </Fragment>
                    </Fragment>
                  )}
                </Form.Item>
              </Col>
              <Col span={8} style={{ paddingTop: "15px" }}>
                <Form.Item dependencies={["itemIds"]}>
                  {(form: FormInstance) => (
                    <Fragment>
                      {form.getFieldValue("itemIds")?.length === 1 && (
                        <Alert type="info" message={`Estimated Hours: ${form.getFieldValue("estimatedHours")}`}></Alert>
                      )}
                    </Fragment>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      )}
    </UpdateProjectActivityItemsMutationProvider>
  );
};
