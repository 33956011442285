import { CreateActivityStageMutationProvider } from "__legacy/queries/activities/CreateActivityStageMutationProvider";
import { Button, Form, Layout, PageHeader } from "antd";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

import { onError, onFinish, StageForm } from "./StageForm";

export const New: React.FC<{}> = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <CreateActivityStageMutationProvider onError={onError(form, setErrors)} onCompleted={() => navigate("..")}>
      {(loading, mutate) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title={`New Stage`}
            extra={
              <Fragment>
                <Button onClick={() => navigate("..")}>Back to list</Button>
                <Button type="primary" loading={loading} onClick={() => form.submit()}>
                  Save
                </Button>
              </Fragment>
            }
          />
          <Layout.Content className="content">
            <StageForm form={form} handler={onFinish(mutate, {}, {})} values={{}} errors={errors} />
          </Layout.Content>
        </Fragment>
      )}
    </CreateActivityStageMutationProvider>
  );
};
