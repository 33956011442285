import { Button, Grid, Tooltip } from "@chakra-ui/react";

import { Card } from "../ui/Card";
import { CardField } from "../ui/CardField";
import { CertificationForm } from "./CertificationForm";

type CertificationType = {
  openForm: (form: any, title: any) => void;
};

export const Certification: React.FC<CertificationType> = (props) => {
  const { openForm } = props;
  const title = "Certification";
  const form = <CertificationForm />;

  return (
    <Card
      title="Certification"
      actions={
        <Tooltip
          hasArrow
          label="By editing this activity you'll reset these activities: Certifications "
          placement="top"
        >
          <Button variant="outline" aria-label="Edit" colorScheme="blue" onClick={() => openForm(form, title)}>
            Edit
          </Button>
        </Tooltip>
      }
    >
      <Grid templateColumns="repeat(4, 1fr)">
        <CardField label="Schema" value="ISO 9001:2020" />
        <CardField label="Authority" value="UKAS" />
        <CardField label="Project Type" value="NEW" />
        <CardField label="Sampling" value="No" />
      </Grid>
    </Card>
  );
};
