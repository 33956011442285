import { Result } from "antd";
import { ResultProps } from "antd/es/result";
import classNames from "classnames";
import { omit } from "lodash";

type MaintenanceProps = ResultProps & {
  whitelabel?: boolean;
};

export const Maintenance: React.FC<MaintenanceProps> = (props) => {
  const { whitelabel } = props;
  const spinProps = omit(props, ["whitelabel"]);

  const classes = classNames("verticalCenterWrapper", {
    whitelabel: whitelabel,
  });

  return (
    <div className={classes}>
      <Result {...spinProps} />
    </div>
  );
};
