import { useApplicationContext } from "__legacy/components/Application";
import { AgenciesQueryProvider } from "__legacy/queries/agencies/AgenciesQueryProvider";
import { AuthoritiesQueryProvider } from "__legacy/queries/authorities/AuthoritiesQueryProvider";
import { Alert, Card, Checkbox, Col, Form, InputNumber, Row, Select, Space, Typography } from "antd";
import { FormInstance } from "antd/lib/form";
import { isString } from "lodash";
import { Fragment } from "react";

type EmploymentFormType = {
  action: string;
  form: FormInstance<any>;
  handler: (values: any) => void;
  values: any;
  errors: string[];
  user: any;
};

const fields = [["agencyId", "userId"]];

export const onError = (form: any, setErrors: any) => ({ graphQLErrors }: any) => {
  const fieldsErrors: any[] = [];
  const errors: string[] = [];
  graphQLErrors.forEach((graphQLError: any) => {
    let found = false;
    fields.forEach(([fieldName, errorFieldName]) => {
      if (graphQLError.name === errorFieldName) {
        fieldsErrors.push({ name: fieldName, errors: (graphQLError as any).messages });
        found = true;
      }
    });
    if (!found) {
      errors.push(graphQLError.message);
    }
  });
  // console.log(fieldsErrors);
  // console.log(errors);
  form.setFields(fieldsErrors);
  setErrors(errors);
};

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const input = {
    ...values,
    employmentAuthorities: values.employmentAuthorities?.map((authorityId: any) => ({
      authorityId,
    })),
    authorization: {
      canManageProjects: values.authorization?.canManageProjects ?? false,
      canManageEmployees: values.authorization?.canManageEmployees ?? false,
      canManageStakeholders: values.authorization?.canManageStakeholders ?? false,
      canCreateCollaborators: values.authorization?.canCreateCollaborators ?? false,
    },
    ...params,
  };

  mutate({ variables: { ...variables, input } });
};

export const EmploymentForm: React.FC<EmploymentFormType> = (props) => {
  const { action, form, handler, values, errors, user } = props;
  const { getEnumOptions } = useApplicationContext();

  const initialValues = {
    ...values,
    employmentAuthorities: values.authorities?.map((a: any) => a.id),
  };

  return (
    <Card>
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Typography.Title level={3}>
        {action === "update" && (values.kind === "agency" ? values.agency.name : "GLOBAL")}
      </Typography.Title>
      <Form layout="vertical" form={form} initialValues={initialValues} onFinish={handler}>
        {action === "create" && (
          <Row gutter={[24, 0]}>
            <Col span={8}>
              <Form.Item name="kind" label="Kind" rules={[{ required: true, message: "is required" }]}>
                <Select
                  showSearch
                  optionLabelProp="label"
                  filterOption={(input, option) => {
                    let label = "";
                    if (option?.label && isString(option.label)) label = option.label;
                    let value = option?.value;
                    return (
                      label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                      value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {getEnumOptions("roleKinds", { raw: true }).map((o) => {
                    if (o.name === "global" && user.employments?.find((e: any) => e.kind === "global")) return null;
                    return (
                      <Select.Option key={o.name} value={o.name} label={o.description}>
                        {o.description}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item dependencies={["kind"]} noStyle>
                {(form: FormInstance) => (
                  <Fragment>
                    {form.getFieldValue("kind") === "agency" && (
                      <AgenciesQueryProvider>
                        {({ agencies }) => (
                          <Form.Item
                            name="agencyId"
                            label="Agency"
                            rules={[{ required: true, message: "is required" }]}
                          >
                            <Select
                              showSearch
                              optionLabelProp="label"
                              filterOption={(input, option) => {
                                let label = "";
                                if (option?.label && isString(option.label)) label = option.label;
                                let value = option?.value;
                                return (
                                  label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                  value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                );
                              }}
                            >
                              {agencies.map((agency) => (
                                <Select.Option key={agency.id} value={agency.id} label={agency.name}>
                                  {agency.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                      </AgenciesQueryProvider>
                    )}
                  </Fragment>
                )}
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item dependencies={["kind"]} noStyle>
              {(form: FormInstance) => (
                <Fragment>
                  {form.getFieldValue("kind") === "global" && (
                    <AuthoritiesQueryProvider>
                      {({ authorities }) => (
                        <Form.Item
                          name="employmentAuthorities"
                          label="Authorities"
                          rules={[{ required: true, message: "is required" }]}
                        >
                          <Select
                            mode="multiple"
                            showSearch
                            optionLabelProp="label"
                            filterOption={(input, option) => {
                              let label = "";
                              if (option?.label && isString(option.label)) label = option.label;
                              let value = option?.value;
                              return (
                                label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {authorities.map((authority) => (
                              <Select.Option key={authority.id} value={authority.id} label={authority.name}>
                                {authority.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                    </AuthoritiesQueryProvider>
                  )}
                </Fragment>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item label={<b>Authorizations</b>}>
              <Row>
                <Col span={6}>
                  <Form.Item name={["authorization", "canManageProjects"]} valuePropName={"checked"}>
                    <Checkbox>Manage Projects</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={["authorization", "canManageEmployees"]} valuePropName={"checked"}>
                    <Checkbox>Manage Employees</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={["authorization", "canManageStakeholders"]} valuePropName={"checked"}>
                    <Checkbox>Manage Stakeholders</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={["authorization", "canCreateCollaborators"]} valuePropName={"checked"}>
                    <Checkbox>Create Collaborators</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <p>
                <b>Working Hours</b>
              </p>
              <Row>
                <Col span={3}>
                  <Form.Item name="mondayHours" label="Monday">
                    <InputNumber min={0} max={12} step={0.25} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name="tuesdayHours" label="Tuesday">
                    <InputNumber min={0} max={12} step={0.25} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name="wednesdayHours" label="Wednesday">
                    <InputNumber min={0} max={12} step={0.25} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name="thursdayHours" label="Thursday">
                    <InputNumber min={0} max={12} step={0.25} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name="fridayHours" label="Friday">
                    <InputNumber min={0} max={12} step={0.25} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name="saturdayHours" label="Saturday">
                    <InputNumber min={0} max={12} step={0.25} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name="sundayHours" label="Sunday">
                    <InputNumber min={0} max={12} step={0.25} />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
