import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  DeleteAbsenceMutation as DeleteAbsenceMutationType,
  DeleteAbsenceMutationVariables,
} from "./__generated__/DeleteAbsenceMutation";

const DeleteAbsenceMutation = loader("./DeleteAbsenceMutation.gql");

type DeleteAbsenceMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError?: (error: ApolloError) => void;
  onCompleted?: (data: DeleteAbsenceMutationType) => void;
}) => ReactElement;

export const DeleteAbsenceMutationProvider: DeleteAbsenceMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<DeleteAbsenceMutationType, DeleteAbsenceMutationVariables>(
    DeleteAbsenceMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
