import { Alert, Card, Checkbox, Col, Form, Row, Space } from "antd";
import { FormInstance } from "antd/lib/form";

type UserFormType = {
  action: string;
  form: FormInstance<any>;
  handler: (values: any) => void;
  values: any;
  errors: string[];
};

const fields: any[] = [];

export const onError = (form: any, setErrors: any) => ({ graphQLErrors }: any) => {
  const fieldsErrors: any[] = [];
  const errors: string[] = [];
  graphQLErrors.forEach((graphQLError: any) => {
    let found = false;
    fields.forEach(([fieldName, errorFieldName]) => {
      if (graphQLError.name === errorFieldName) {
        fieldsErrors.push({ name: fieldName, errors: (graphQLError as any).messages });
        found = true;
      }
    });
    if (!found) {
      errors.push(graphQLError.message);
    }
  });
  // console.log(graphQLErrors);
  // console.log(fieldsErrors);
  // console.log(errors);
  form.setFields(fieldsErrors);
  setErrors(errors);
};

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const cleanValues = {
    ...params,
    authorization: {
      canManageProjects: values.authorization?.canManageProjects ?? false,
      canManageEmployees: values.authorization?.canManageEmployees ?? false,
      canManageStakeholders: values.authorization?.canManageStakeholders ?? false,
      canCreateCollaborators: values.authorization?.canCreateCollaborators ?? false,
    },
  };

  mutate({ variables: { ...variables, input: cleanValues } });
};

export const UserForm: React.FC<UserFormType> = (props) => {
  const { form, handler, values, errors } = props;

  return (
    <Card>
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Form layout="vertical" form={form} initialValues={values} onFinish={handler}>
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item label="Authorizations">
              <Row>
                <Col span={8}>
                  <Form.Item name={["authorization", "canManageProjects"]} valuePropName={"checked"}>
                    <Checkbox>Manage Projects</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={["authorization", "canManageEmployees"]} valuePropName={"checked"}>
                    <Checkbox>Manage Employees</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={["authorization", "canManageStakeholders"]} valuePropName={"checked"}>
                    <Checkbox>Manage Stakeholders</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={["authorization", "canCreateCollaborators"]} valuePropName={"checked"}>
                    <Checkbox>Create Collaborators</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
