import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { CertificationRiskLevelsQueryProvider } from "__legacy/queries/enums/CertificationRiskLevelsQueryProvider";
import { SchemeQueryProvider } from "__legacy/queries/schemes/SchemeQueryProvider";
import { Button, Collapse, Layout, PageHeader, Space, Typography } from "antd";
import { find } from "lodash";
import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const List: React.FC<{}> = () => {
  const { schemeId } = useParams();
  const navigate = useNavigate();

  return (
    <CertificationRiskLevelsQueryProvider>
      {({ certificationRiskLevels }) => (
        <SchemeQueryProvider variables={{ id: schemeId }}>
          {({ scheme }) => (
            <Fragment>
              <PageHeader
                ghost={true}
                title="Accreditations"
                extra={
                  <Button onClick={() => navigate("new")} type="primary">
                    <PlusOutlined />
                    New
                  </Button>
                }
              />
              <Layout.Content className="content">
                <Collapse>
                  {scheme.accreditations.map((accreditation) => (
                    <Collapse.Panel
                      key={accreditation.id}
                      header={
                        <Typography.Text style={{ lineHeight: "2.4" }}>
                          {accreditation.authority.name} - Codes: {accreditation.classifications.length}
                        </Typography.Text>
                      }
                      extra={
                        <Space>
                          <Button onClick={() => navigate(`${accreditation.id}/edit`)} icon={<EditOutlined />} />
                        </Space>
                      }
                    >
                      <Space direction="vertical">
                        <Typography.Title level={5}>Codes:</Typography.Title>
                        {accreditation.accreditationClassifications.map((accreditationClassification) => (
                          <Typography.Text key={accreditationClassification.id}>
                            {accreditationClassification.classification.name} -{" "}
                            {accreditationClassification.classification.industry.name} -{" "}
                            {accreditationClassification.classification.description} (
                            {
                              find(certificationRiskLevels?.enumValues, { name: accreditationClassification.riskLevel })
                                ?.description
                            }
                            )
                          </Typography.Text>
                        ))}
                      </Space>
                    </Collapse.Panel>
                  ))}
                </Collapse>
              </Layout.Content>
            </Fragment>
          )}
        </SchemeQueryProvider>
      )}
    </CertificationRiskLevelsQueryProvider>
  );
};
