import { useQuery } from "@apollo/client";
import { CenterResult } from "__legacy/components/CenterResult";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import { ProjectQuery as ProjectQueryType, ProjectQueryVariables } from "./__generated__/ProjectQuery";

const ProjectQuery = loader("./ProjectQuery.gql");

type ProjectQueryProviderType = (props: {
  children: (data: ProjectQueryType) => ReactNode;
  variables: ProjectQueryVariables;
}) => ReactElement;

export const ProjectQueryProvider: ProjectQueryProviderType = (props) => {
  const { children, variables } = props;

  const { error, loading, data } = useQuery<ProjectQueryType, ProjectQueryVariables>(ProjectQuery, {
    variables,
    fetchPolicy: "cache-and-network",
  });

  if (loading) return <CenterSpinner size="large" />;
  if (error || !data)
    return <CenterResult status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;

  return <Fragment>{children(data)}</Fragment>;
};
