import { useQuery } from "@apollo/client";
import { CenterResult } from "__legacy/components/CenterResult";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import { IndustryQuery as IndustryQueryType, IndustryQueryVariables } from "./__generated__/IndustryQuery";

const IndustryQuery = loader("./IndustryQuery.gql");

type IndustryQueryProviderType = (props: {
  children: (data: IndustryQueryType) => ReactNode;
  variables: IndustryQueryVariables;
}) => ReactElement;

export const IndustryQueryProvider: IndustryQueryProviderType = (props) => {
  const { children, variables } = props;

  const { error, loading, data } = useQuery<IndustryQueryType, IndustryQueryVariables>(IndustryQuery, {
    variables,
    fetchPolicy: "cache-and-network",
  });

  if (loading) return <CenterSpinner size="large" />;
  if (error || !data)
    return <CenterResult status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;

  return <Fragment>{children(data)}</Fragment>;
};
