import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { useApplicationContext } from "__legacy/components/Application";
import { Logo } from "__legacy/components/Logo";
import { Alert, Button, Card, Checkbox, Col, DatePicker, Form, Input, Row, Space } from "antd";
import moment from "moment";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

const signInSessionMutation = gql`
  mutation($input: SignInSession!) {
    signInSession(input: $input) {
      token
    }
  }
`;

interface FieldData {
  name: string;
  errors: string[];
}

export const SignIn: React.FC<{}> = () => {
  const [form] = Form.useForm();
  const [otherErrors, setOtherErrors] = useState<string[]>([]);
  const { setSessionToken } = useApplicationContext();

  const cookieName = "sessionDate";
  const [cookies, storeCookie, removeCookie] = useCookies([cookieName]);
  let sessionDate = cookies[cookieName];
  sessionDate = sessionDate ? moment(sessionDate) : null;

  const [execMutation, { loading }] = useMutation(signInSessionMutation, {
    errorPolicy: "none",
    onError: ({ graphQLErrors }) => {
      const fieldsErrors: FieldData[] = [];
      const otherErrors: string[] = [];
      graphQLErrors.forEach((graphQLError) => {
        if (graphQLError.name) {
          fieldsErrors.push({ name: graphQLError.name, errors: (graphQLError as any).messages });
        } else {
          otherErrors.push(graphQLError.message);
        }
      });
      form.setFields(fieldsErrors);
      setOtherErrors(otherErrors);
    },
    onCompleted: (data) => {
      setSessionToken(data?.signInSession?.token, form.getFieldValue("persistent"));
    },
  });

  const onFinish = (values: any) => {
    const input = { ...values, sessionDate: undefined };
    execMutation({ variables: { input } });
    values.sessionDate ? storeCookie(cookieName, values.sessionDate) : removeCookie(cookieName);
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: "80vh" }}>
      <Col span={8}>
        <Card
          className="login-form"
          cover={<Logo style={{ marginTop: "30px", height: "100px" }} />}
          style={{ margin: "0 auto" }}
        >
          {otherErrors.length > 0 && (
            <Space direction="vertical" style={{ marginBottom: "10px" }}>
              {otherErrors.map((otherError, index) => (
                <Alert key={index} type="error" message="Error" description={otherError} />
              ))}
            </Space>
          )}

          <Form form={form} initialValues={{ persistent: true, sessionDate }} onFinish={onFinish}>
            <Form.Item name="email" rules={[{ required: true, message: "is required" }]}>
              <Input prefix={<UserOutlined />} placeholder="Email" autoComplete="username" />
            </Form.Item>

            <Form.Item name="password" rules={[{ required: true, message: "is required" }]}>
              <Input.Password prefix={<LockOutlined />} placeholder="Password" autoComplete="current-password" />
            </Form.Item>

            <Form.Item name="sessionDate">
              <DatePicker format="MM-DD-YYYY" placeholder="Session Date" allowClear style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item>
              <Form.Item
                name="persistent"
                rules={[{ required: true, message: "is required" }]}
                valuePropName="checked"
                noStyle
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Link to="/auth/password-reset" className="login-form-forgot">
                Forgot password
              </Link>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};
