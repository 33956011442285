import { CloudUploadOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { ApplyProjectMutationProvider } from "__legacy/queries/projects/ApplyProjectMutationProvider";
import { Alert, Button, Card, Col, Form, Input, Popconfirm, Row, Space } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";

type PwsContractCardType = {
  project: any;
  editable: boolean;
};

export const PwsContractCard: React.FC<PwsContractCardType> = (props) => {
  const { project, editable } = props;
  const { id } = useParams();
  const title = "Contract";
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);
  const navigate = useNavigate();

  const onError =
    () =>
    ({ graphQLErrors }: any) => {
      const errors: string[] = [];
      graphQLErrors.forEach((graphQLError: any) => {
        errors.push(graphQLError.message);
      });
      setErrors(errors);
    };

  const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
    const input = {
      ...values,
      contract: values.contract?.file ?? null,
    };
    mutate({ variables: { ...variables, input } });
  };

  const uploadProps = {
    maxCount: 1,
    accept: ".pdf, .doc, .xls, .xlsx",
    beforeUpload: () => {
      return false;
    },
  };

  return (
    <Card
      title={
        !editable && project.contract ? (
          <b>
            <a href={project.contract} download>
              {title}
            </a>
          </b>
        ) : (
          title
        )
      }
      bodyStyle={editable || project.contractInfo ? {} : { display: "none" }}
      extra={
        editable && (
          <Popconfirm
            title="Are you sure?"
            placement="right"
            icon={<QuestionCircleOutlined />}
            onConfirm={() => form.submit()}
          >
            <Button type="primary">Apply</Button>
          </Popconfirm>
        )
      }
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>
          {editable && (
            <ApplyProjectMutationProvider onError={onError} onCompleted={() => navigate("../../../dashboard")}>
              {(loading, mutate) => (
                <Form
                  form={form}
                  initialValues={{ contractInfo: project.contractInfo }}
                  onFinish={onFinish(mutate, {}, { id })}
                >
                  {errors.length > 0 && (
                    <Space direction="vertical" style={{ marginBottom: "10px" }}>
                      {errors.map((error, index) => (
                        <Alert key={index} type="error" message="Error" description={error} />
                      ))}
                    </Space>
                  )}{" "}
                  <Row gutter={[24, 24]}>
                    <Col span={12}>
                      <Form.Item
                        name="contract"
                        valuePropName={"defaultFileList"}
                        rules={[{ required: true, message: "is required" }]}
                      >
                        <Dragger {...uploadProps} defaultFileList={[]}>
                          <CloudUploadOutlined />
                          Upload Contract
                        </Dragger>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="contractInfo" label="Info">
                        <Input.TextArea />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              )}
            </ApplyProjectMutationProvider>
          )}
          {!editable && project.contractInfo && (
            <span>
              Info: <b>{project.contractInfo}</b>
            </span>
          )}
        </Col>
      </Row>
    </Card>
  );
};
