import { AuthorityQueryProvider } from "__legacy/queries/authorities/AuthorityQueryProvider";
import { Button, Card, Col, Layout, PageHeader, Row, Typography } from "antd";
import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const Show: React.FC<{}> = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <AuthorityQueryProvider variables={{ id }}>
      {({ authority }) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title={`Authority ${authority.name}`}
            extra={
              <Fragment>
                <Button onClick={() => navigate("../")}>Back to list</Button>
                <Button type="primary" onClick={() => navigate("./edit")}>
                  Edit
                </Button>
              </Fragment>
            }
          />
          <Layout.Content className="content">
            <Card>
              <Row gutter={[16, 16]} justify="space-between">
                <Col>
                  <Typography.Title level={2}>{authority.name}</Typography.Title>
                </Col>
              </Row>
            </Card>
          </Layout.Content>
        </Fragment>
      )}
    </AuthorityQueryProvider>
  );
};
