import { ContactQueryProvider } from "__legacy/queries/contacts/ContactQueryProvider";
import { UpdateContactMutationProvider } from "__legacy/queries/contacts/UpdateContactMutationProvider";
import { StakeholderQueryProvider } from "__legacy/queries/stakeholders/StakeholderQueryProvider";
import { Button, Form, Layout, PageHeader } from "antd";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ContactForm, onError, onFinish } from "./ContactForm";

export const Edit: React.FC<{}> = () => {
  const { stakeholderId, id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <StakeholderQueryProvider variables={{ id: stakeholderId }}>
      {({ stakeholder }) => (
        <ContactQueryProvider variables={{ id }}>
          {({ contact }) => (
            <UpdateContactMutationProvider onError={onError(form, setErrors)} onCompleted={() => navigate("../../../")}>
              {(loading, mutate) => (
                <Fragment>
                  <PageHeader
                    ghost={true}
                    title={`Edit contact ${contact.lastname} ${contact.firstname} of ${stakeholder.companyName}`}
                    extra={
                      <Fragment>
                        <Button onClick={() => navigate("../../../../")}>Back to list</Button>
                        <Button onClick={() => navigate("../../../")}>Back to Stakeholder</Button>
                        <Button type="primary" loading={loading} onClick={() => form.submit()}>
                          Save
                        </Button>
                      </Fragment>
                    }
                  />
                  <Layout.Content className="content">
                    <ContactForm form={form} handler={onFinish(mutate, {}, { id })} values={contact} errors={errors} />
                  </Layout.Content>
                </Fragment>
              )}
            </UpdateContactMutationProvider>
          )}
        </ContactQueryProvider>
      )}
    </StakeholderQueryProvider>
  );
};
