import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  CreateStakeholderMutation as CreateStakeholderMutationType,
  CreateStakeholderMutationVariables,
} from "./__generated__/CreateStakeholderMutation";

const CreateStakeholderMutation = loader("./CreateStakeholderMutation.gql");

type CreateStakeholderMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted: (data: CreateStakeholderMutationType) => void;
}) => ReactElement;

export const CreateStakeholderMutationProvider: CreateStakeholderMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<CreateStakeholderMutationType, CreateStakeholderMutationVariables>(
    CreateStakeholderMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
