import { DeleteOutlined, EditOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { RoleKind } from "__legacy/queries/__generated__/globalTypes";
import { RolesQueryProvider } from "__legacy/queries/roles/RolesQueryProvider";
import { Button, Input, Layout, notification, PageHeader, Popconfirm, Row, Space, Table, Tooltip } from "antd";
import { Fragment, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";

const deleteRoleMutation = gql`
  mutation($id: ID!) {
    deleteRole(id: $id) {
      role {
        id
        name
      }
    }
  }
`;

export const List: React.FC<{ agency?: true }> = (props) => {
  const { agency } = props;
  const kind = (agency ? "agency" : "global") as RoleKind;
  const { agencyId } = useParams();
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const handleSearch = (value: string) => setSearchQuery(value.trim());

  const [execDelete] = useMutation(deleteRoleMutation, {
    errorPolicy: "none",
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
    onCompleted: ({ deleteRole: { role } }) => {
      notification.success({
        message: `Role "${role.name}" deleted`,
      });
    },
    refetchQueries: ["RolesQuery"],
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "roles.name",
      render: (value: string, resource: any) => <NavLink to={resource.id}>{value}</NavLink>,
    },
    {
      width: "80px",
      title: "Actions",
      dataIndex: "id",
      render: (id: string) => (
        <Row justify="end">
          <Space>
            <Tooltip title="Edit" placement="bottom">
              <Button onClick={() => navigate(`${id}/edit`)} icon={<EditOutlined />}></Button>
            </Tooltip>
            <Popconfirm
              title="Are you sure?"
              icon={<QuestionCircleOutlined />}
              onConfirm={() => execDelete({ variables: { id } })}
            >
              <Tooltip title="Delete" placement="bottom">
                <Button danger icon={<DeleteOutlined />}></Button>
              </Tooltip>
            </Popconfirm>
          </Space>
        </Row>
      ),
    },
  ];

  return (
    <RolesQueryProvider variables={{ agencyId, kind, search: searchQuery }}>
      {({ roles }) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title="Roles"
            extra={
              <Fragment>
                <Input.Search
                  allowClear
                  placeholder="search"
                  onSearch={handleSearch}
                  defaultValue={searchQuery ?? ""}
                  style={{ width: 500 }}
                />
                <Button onClick={() => navigate("new")} type="primary">
                  <PlusOutlined />
                  New
                </Button>
              </Fragment>
            }
          />
          <Layout.Content className="content">
            <Table rowKey="id" columns={columns} dataSource={roles} pagination={false} />
          </Layout.Content>
        </Fragment>
      )}
    </RolesQueryProvider>
  );
};
