import { Alert, Card, Checkbox, Col, Form, Input, Row, Space } from "antd";
import { FormInstance } from "antd/lib/form";
import { omit } from "lodash";
import { Fragment } from "react";

type RoleFormType = {
  action: string;
  form: FormInstance<any>;
  handler: (values: any) => void;
  values: any;
  errors: string[];
};

const fields = [
  ["name", "name"],
  ["country", "country"],
];

export const onError = (form: any, setErrors: any) => ({ graphQLErrors }: any) => {
  const fieldsErrors: any[] = [];
  const errors: string[] = [];
  graphQLErrors.forEach((graphQLError: any) => {
    let found = false;
    fields.forEach(([fieldName, errorFieldName]) => {
      if (graphQLError.name === errorFieldName) {
        fieldsErrors.push({ name: fieldName, errors: (graphQLError as any).messages });
        found = true;
      }
    });
    if (!found) {
      errors.push(graphQLError.message);
    }
  });
  // console.log(fieldsErrors);
  // console.log(errors);
  form.setFields(fieldsErrors);
  setErrors(errors);
};

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  let authorization: any = { canManageProjects: values.authorization?.canManageProjects ?? false };

  if (variables.agency)
    authorization = {
      ...authorization,
      canManageEmployees: values.authorization?.canManageEmployees ?? false,
      canManageStakeholders: values.authorization?.canManageStakeholders ?? false,
      canCreateCollaborators: values.authorization?.canCreateCollaborators ?? false,
    };
  else
    authorization = {
      ...authorization,
      canManageUsers: values.authorization?.canManageUsers ?? false,
      canManageRoles: values.authorization?.canManageRoles ?? false,
      canManageAgencies: values.authorization?.canManageAgencies ?? false,
      canManageClassifications: values.authorization?.canManageClassifications ?? false,
      canManageCertifications: values.authorization?.canManageCertifications ?? false,
    };

  const cleanValues = {
    ...omit(values, []),
    ...params,
    authorization,
  };

  mutate({ variables: { ...variables, input: cleanValues } });
};

export const RoleForm: React.FC<RoleFormType> = (props) => {
  const { action, form, handler, values, errors } = props;

  console.log(`Shareds/Roles/RoleForm action: ${action}`);

  return (
    <Card>
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Form layout="vertical" form={form} initialValues={values} onFinish={handler}>
        <Row gutter={[24, 0]}>
          <Col span={8}>
            <Form.Item name="name" label="Name" rules={[{ required: true, message: "is required" }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item label="Authorizations">
              <Row>
                {!values.agency && (
                  <Fragment>
                    <Form.Item name={["authorization", "canManageUsers"]} valuePropName={"checked"}>
                      <Checkbox>Manage Users</Checkbox>
                    </Form.Item>
                    <Form.Item name={["authorization", "canManageRoles"]} valuePropName={"checked"}>
                      <Checkbox>Manage Roles</Checkbox>
                    </Form.Item>
                    <Form.Item name={["authorization", "canManageAgencies"]} valuePropName={"checked"}>
                      <Checkbox>Manage Agencies</Checkbox>
                    </Form.Item>
                    <Form.Item name={["authorization", "canManageClassifications"]} valuePropName={"checked"}>
                      <Checkbox>Manage Classifications</Checkbox>
                    </Form.Item>
                    <Form.Item name={["authorization", "canManageCertifications"]} valuePropName={"checked"}>
                      <Checkbox>Manage Certifications</Checkbox>
                    </Form.Item>
                  </Fragment>
                )}
                <Col>
                  <Form.Item name={["authorization", "canManageProjects"]} valuePropName={"checked"}>
                    <Checkbox>Manage Projects</Checkbox>
                  </Form.Item>
                </Col>
                {values.agency && (
                  <Fragment>
                    <Form.Item name={["authorization", "canManageEmployees"]} valuePropName={"checked"}>
                      <Checkbox>Manage Employees</Checkbox>
                    </Form.Item>
                    <Form.Item name={["authorization", "canManageStakeholders"]} valuePropName={"checked"}>
                      <Checkbox>Manage Stakeholders</Checkbox>
                    </Form.Item>
                    <Form.Item name={["authorization", "canCreateCollaborators"]} valuePropName={"checked"}>
                      <Checkbox>Create Collaborators</Checkbox>
                    </Form.Item>
                  </Fragment>
                )}
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
