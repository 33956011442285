import { useApplicationContext } from "__legacy/components/Application";
import { ActivityItemsQueryProvider } from "__legacy/queries/activities/ActivityItemsQueryProvider";
import { Alert, Card, Col, Form, Input, Row, Select, Space, Switch } from "antd";
import { FormInstance } from "antd/lib/form";
import { isString } from "lodash";
import { useParams } from "react-router";

type TemplateFormType = {
  form: FormInstance<any>;
  handler: (values: any) => void;
  values: any;
  errors: string[];
};

const fields = [
  ["name", "name"],
  ["kind", "kind"],
];

export const onError = (form: any, setErrors: any) => ({ graphQLErrors }: any) => {
  const fieldsErrors: any[] = [];
  const errors: string[] = [];
  graphQLErrors.forEach((graphQLError: any) => {
    let found = false;
    fields.forEach(([fieldName, errorFieldName]) => {
      if (graphQLError.name === errorFieldName) {
        fieldsErrors.push({ name: fieldName, errors: (graphQLError as any).messages });
        found = true;
      }
    });
    if (!found) {
      errors.push(graphQLError.message);
    }
  });
  form.setFields(fieldsErrors);
  setErrors(errors);
};

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const input = {
    ...values,
    templateItems: values.templateItems?.map((itemId: any) => ({
      itemId,
    })),
    ...params,
  };

  mutate({ variables: { ...variables, input } });
};

export const TemplateForm: React.FC<TemplateFormType> = (props) => {
  const { form, handler, values, errors } = props;
  const { agencyId } = useParams();
  const { getEnumOptions } = useApplicationContext();

  const initialValues = {
    ...values,
    templateItems: values.items?.map((item: any) => item.id),
  };

  return (
    <Card>
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Form layout="vertical" form={form} initialValues={initialValues} onFinish={handler}>
        <Row gutter={[24, 0]}>
          <Col span={12}>
            <Form.Item name="name" label="Name" rules={[{ required: true, message: "is required" }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="kind" label="Certification Kind" rules={[{ required: true, message: "is required" }]}>
              <Select
                showSearch
                optionLabelProp="label"
                filterOption={(input, option) => {
                  let label = "";
                  if (option?.label && isString(option.label)) label = option.label;
                  let value = option?.value;
                  return (
                    label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {getEnumOptions("schemeMandaysKinds")}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="isDefault" label="Default?" valuePropName={"checked"}>
              <Switch />
            </Form.Item>
          </Col>
          <Col span={24}>
            <ActivityItemsQueryProvider variables={{ agencyId }}>
              {({ activityItems }) => (
                <Form.Item name="templateItems" label="Items">
                  <Select
                    mode="multiple"
                    showSearch
                    optionLabelProp="label"
                    filterOption={(input, option) => {
                      let label = "";
                      if (option?.label && isString(option.label)) label = option.label;
                      let value = option?.value;
                      return (
                        label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {activityItems.map((item) => (
                      <Select.Option key={item.id} value={item.id} label={item.name}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </ActivityItemsQueryProvider>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
