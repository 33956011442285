import { Select, Switch } from "@chakra-ui/react";
import React from "react";

import { FormItem } from "../ui/FormItem";

export const CertificationForm: React.FC<{}> = () => {
  return (
    <>
      <FormItem id="schema" required>
        <Select>
          <option value="option1">ISO 9001:2020</option>
          <option value="option2">ISO 9001:2020</option>
          <option value="option3">ISO 9001:2020</option>
        </Select>
      </FormItem>
      <FormItem id="authority" required>
        <Select placeholder="---">
          <option value="option1">UKAS</option>
          <option value="option2">UKAS</option>
          <option value="option3">UKAS</option>
        </Select>
      </FormItem>
      <FormItem id="reason" label="Project Kind" required>
        <Select isRequired>
          <option value="option1">NEW</option>
          <option value="option2">TRANSFER</option>
        </Select>
      </FormItem>
      <FormItem id="sampling">
        <Switch />
      </FormItem>
    </>
  );
};
