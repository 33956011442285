import { RoleQueryProvider } from "__legacy/queries/roles/RoleQueryProvider";
import { UpdateRoleMutationProvider } from "__legacy/queries/roles/UpdateRoleMutationProvider";
import { Button, Form, Layout, PageHeader } from "antd";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { onError, onFinish, RoleForm } from "./RoleForm";

export const Edit: React.FC<{ agency?: true }> = (props) => {
  const { agency } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <RoleQueryProvider variables={{ id }}>
      {({ role }) => (
        <UpdateRoleMutationProvider onError={onError(form, setErrors)} onCompleted={() => navigate("../")}>
          {(loading, mutate) => (
            <Fragment>
              <PageHeader
                ghost={true}
                title={`Edit role ${role.name}`}
                extra={
                  <Fragment>
                    <Button onClick={() => navigate("../../")}>Back to list</Button>
                    <Button onClick={() => navigate("../")}>Back to details</Button>
                    <Button type="primary" loading={loading} onClick={() => form.submit()}>
                      Save
                    </Button>
                  </Fragment>
                }
              />
              <Layout.Content className="content">
                <RoleForm
                  action="update"
                  form={form}
                  handler={onFinish(mutate, {}, { id, agency })}
                  values={{ ...role, agency }}
                  errors={errors}
                />
              </Layout.Content>
            </Fragment>
          )}
        </UpdateRoleMutationProvider>
      )}
    </RoleQueryProvider>
  );
};
