import * as Sentry from "@sentry/react";
import { Root as Legacy } from "__legacy/Root";
import { Root as Modern } from "__modern/Root";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "serviceWorker";

import reportWebVitals from "./reportWebVitals";

const siteEnvironment = (window as any).siteEnvironment ?? {};
const debug = process.env.REACT_APP_DEBUG !== undefined ? true : false;
const development = process.env.NODE_ENV === "development" ? true : false;
const version = process.env.REACT_APP_VERSION ?? false;

if (!development) {
  Sentry.init({
    dsn: "https://code@sentry.com/1234",
    debug,
  });
  Sentry.configureScope((scope) => scope.setTag("app", "pane").setTag("env", siteEnvironment.name));
}
const props = { debug, siteEnvironment };

let Root;

if (version === "legacy") Root = React.createElement(Legacy, props);
else if (version === "modern") Root = React.createElement(Modern, props);
else Root = React.createElement(Legacy, props);

ReactDOM.render(Root, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//Extend Number

declare global {
    interface Number {
          toLowerCase: () => string;
    }
}

Number.prototype.toLowerCase = function() : string {
  return this.toString();
}


// Enable HMR
if (module.hot && development) {
  module.hot.accept();
}

