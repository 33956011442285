import { Button, Stack } from "@chakra-ui/react";
import React from "react";

import { Card } from "./Card";
import { Note } from "./Note";

export const Notes: React.FC<{}> = () => {
  return (
    <Card
      scrollable
      title="Notes"
      actions={
        <Button aria-label="Add" colorScheme="blue">
          Add
        </Button>
      }
    >
      <Stack spacing="4">
        <Note boxed title="12/12/20 12:34 Khairul Bashar" file="#">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Proin risus. Praesent lectus.
        </Note>
        <Note boxed title="11/12/20 12:34 Charlie">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Proin risus. Praesent lectus.
        </Note>
        <Note boxed title="10/12/20 12:34 Ebdo" file="#">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Proin risus. Praesent lectus.
        </Note>
        <Note boxed title="11/12/20 12:34 Charlie">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Proin risus. Praesent lectus.
        </Note>
        <Note boxed title="11/12/20 12:34 Charlie">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Proin risus. Praesent lectus.
        </Note>
        <Note boxed title="11/12/20 12:34 Charlie">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Proin risus. Praesent lectus.
        </Note>
        <Note boxed title="11/12/20 12:34 Charlie">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Proin risus. Praesent lectus.
        </Note>
        <Note boxed title="11/12/20 12:34 Charlie">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Proin risus. Praesent lectus.
        </Note>
        <Note boxed title="11/12/20 12:34 Charlie">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Proin risus. Praesent lectus.
        </Note>
        <Note boxed title="11/12/20 12:34 Charlie">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Proin risus. Praesent lectus.
        </Note>
      </Stack>
    </Card>
  );
};
