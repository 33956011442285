import { RoleQueryProvider } from "__legacy/queries/roles/RoleQueryProvider";
import { Button, Card, Checkbox, Layout, PageHeader, Typography } from "antd";
import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const Show: React.FC<{ agency?: true }> = (props) => {
  const { agency } = props;
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <RoleQueryProvider variables={{ id }}>
      {({ role }) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title={`Role ${role.name}`}
            extra={
              <Fragment>
                <Button onClick={() => navigate("../")}>Back to list</Button>
                <Button type="primary" onClick={() => navigate("./edit")}>
                  Edit
                </Button>
              </Fragment>
            }
          />
          <Layout.Content className="content">
            <Card>
              <Typography.Title level={2}>{role.name}</Typography.Title>
              <Typography.Title level={4}>Authorization</Typography.Title>
              {!agency && (
                <Fragment>
                  <Checkbox disabled checked={role.authorization.canManageUsers}>
                    Manage Users
                  </Checkbox>
                  <Checkbox disabled checked={role.authorization.canManageRoles}>
                    Manage Roles
                  </Checkbox>
                  <Checkbox disabled checked={role.authorization.canManageAgencies}>
                    Manage Agencies
                  </Checkbox>
                  <Checkbox disabled checked={role.authorization.canManageClassifications}>
                    Manage Classifications
                  </Checkbox>

                  <Checkbox disabled checked={role.authorization.canManageCertifications}>
                    Manage Certifications
                  </Checkbox>
                </Fragment>
              )}
              <Checkbox disabled checked={role.authorization.canManageProjects}>
                Manage Projects
              </Checkbox>
              {agency && (
                <Fragment>
                  <Checkbox disabled checked={role.authorization.canManageEmployees}>
                    Create Employees
                  </Checkbox>
                  <Checkbox disabled checked={role.authorization.canManageStakeholders}>
                    Create Stakeholders
                  </Checkbox>
                  <Checkbox disabled checked={role.authorization.canCreateCollaborators}>
                    Create Collaborators
                  </Checkbox>
                </Fragment>
              )}
            </Card>
          </Layout.Content>
        </Fragment>
      )}
    </RoleQueryProvider>
  );
};
