import { Edit as AgenciesCollaboratorsAbilitiesCandidaciesEdit } from "./agencies/collaborators/abilities/candidacies/Edit";
import { New as AgenciesCollaboratorsAbilitiesCandidaciesNew } from "./agencies/collaborators/abilities/candidacies/New";
import { Show as AgenciesCollaboratorsAbilitiesCandidaciesShow } from "./agencies/collaborators/abilities/candidacies/Show";
import { Edit as AgenciesCollaboratorsAbilitiesEdit } from "./agencies/collaborators/abilities/Edit";
import { New as AgenciesCollaboratorsAbilitiesNew } from "./agencies/collaborators/abilities/New";
import { Edit as AgenciesCollaboratorsExperiencesEdit } from "./agencies/collaborators/experiences/Edit";
import { New as AgenciesCollaboratorsExperiencesNew } from "./agencies/collaborators/experiences/New";
import { Edit as AgenciesCollaboratorsTrainingsEdit } from "./agencies/collaborators/trainings/Edit";
import { New as AgenciesCollaboratorsTrainingsNew } from "./agencies/collaborators/trainings/New";
import { ProductForm as AgenciesJobOrdersProductForm } from "./agencies/jobOrders/product/ProductForm";
import { Dash as AgenciesProjectsDash } from "./agencies/projects/Dash";
import { New as AgenciesProjectsNew } from "./agencies/projects/New";
import { Edit as AgenciesStakeholdersContactsEdit } from "./agencies/stakeholders/contacts/Edit";
import { New as AgenciesStakeholdersContactsNew } from "./agencies/stakeholders/contacts/New";
import { Edit as AgenciesStakeholdersEdit } from "./agencies/stakeholders/Edit";
import { List as AgenciesStakeholdersList } from "./agencies/stakeholders/List";
import { New as AgenciesStakeholdersNew } from "./agencies/stakeholders/New";
import { Show as AgenciesStakeholdersShow } from "./agencies/stakeholders/Show";
import { Edit as AgenciesStakeholdersSitesEdit } from "./agencies/stakeholders/sites/Edit";
import { New as AgenciesStakeholdersSitesNew } from "./agencies/stakeholders/sites/New";
import { List as AgenciesTimesheetList } from "./agencies/timesheet/List";
import { Show as AgenciesTimesheetShow } from "./agencies/timesheet/Show";
import { Edit as AgenciesUsersEdit } from "./agencies/users/Edit";
import { List as AgenciesUsersList } from "./agencies/users/List";
import { CompletePasswordReset as AuthCompletePasswordReset } from "./auth/CompletePasswordReset";
import { RequestPasswordReset as AuthRequestPasswordReset } from "./auth/RequestPasswordReset";
import { SignIn as AuthSignIn } from "./auth/SignIn";
import { Edit as GlobalsAgenciesEdit } from "./globals/agencies/Edit";
import { List as GlobalsAgenciesList } from "./globals/agencies/List";
import { New as GlobalsAgenciesNew } from "./globals/agencies/New";
import { Show as GlobalsAgenciesShow } from "./globals/agencies/Show";
import { Edit as GlobalsAgenciesUsersEdit } from "./globals/agencies/users/Edit";
import { New as GlobalsAgenciesUsersNew } from "./globals/agencies/users/New";
import { Edit as GlobalsCertificationsBodiesEdit } from "./globals/certifications/bodies/Edit";
import { List as GlobalsCertificationsBodiesList } from "./globals/certifications/bodies/List";
import { New as GlobalsCertificationsBodiesNew } from "./globals/certifications/bodies/New";
import { Show as GlobalsCertificationsBodiesShow } from "./globals/certifications/bodies/Show";
import {
  Edit as GlobalsCertificationsSchemesAccreditationsEdit,
} from "./globals/certifications/schemes/accreditations/Edit";
import {
  List as GlobalsCertificationsSchemesAccreditationsList,
} from "./globals/certifications/schemes/accreditations/List";
import { New as GlobalsCertificationsSchemesAccreditationsNew } from "./globals/certifications/schemes/accreditations/New";
import { Edit as GlobalsCertificationsSchemesEdit } from "./globals/certifications/schemes/Edit";
import { Show as GlobalsCertificationsSchemesInfoShow } from "./globals/certifications/schemes/info/Show";
import { List as GlobalsCertificationsSchemesList } from "./globals/certifications/schemes/List";
import { New as GlobalsCertificationsSchemesNew } from "./globals/certifications/schemes/New";
import { Show as GlobalsCertificationsSchemesShow } from "./globals/certifications/schemes/Show";
import {
  Edit as GlobalsCertificationsSchemesTechnicalAreasEdit,
} from "./globals/certifications/schemes/technicalAreas/Edit";
import {
  List as GlobalsCertificationsSchemesTechnicalAreasList,
} from "./globals/certifications/schemes/technicalAreas/List";
import { New as GlobalsCertificationsSchemesTechnicalAreasNew } from "./globals/certifications/schemes/technicalAreas/New";
import { Edit as GlobalsClassificationsCodesEdit } from "./globals/classifications/codes/Edit";
import { New as GlobalsClassificationsCodesNew } from "./globals/classifications/codes/New";
import { Edit as GlobalsClassificationsEdit } from "./globals/classifications/Edit";
import { List as GlobalsClassificationsList } from "./globals/classifications/List";
import { New as GlobalsClassificationsNew } from "./globals/classifications/New";
import { Show as GlobalsClassificationsShow } from "./globals/classifications/Show";
import { List as GlobalsCollaboratorsCodeApplicationsList } from "./globals/collaborators/codeApplications/List";
import { Review as GlobalsCollaboratorsCodeApplicationsReview } from "./globals/collaborators/codeApplications/Review";
import { Dash as GlobalsCollaboratorsDash } from "./globals/collaborators/Dash";
import { List as GlobalsCollaboratorsSchemeApplicationsList } from "./globals/collaborators/schemeApplications/List";
import { Review as GlobalsCollaboratorsSchemeApplicationsReview } from "./globals/collaborators/schemeApplications/Review";
import { Dash as GlobalsProjectsDash } from "./globals/projects/Dash";
import { Review as GlobalsProjectsReview } from "./globals/projects/Review";
import { Edit as GlobalsUsersEdit } from "./globals/users/Edit";
import { Edit as GlobalsUsersEmploymentsEdit } from "./globals/users/employments/Edit";
import { New as GlobalsUsersEmploymentsNew } from "./globals/users/employments/New";
import { List as GlobalsUsersList } from "./globals/users/List";
import { New as GlobalsUsersNew } from "./globals/users/New";
import { Show as GlobalsUsersShow } from "./globals/users/Show";
import { List as GlobalsVisitsList } from "./globals/visits/List";
import { Review as GlobalsVisitsReview } from "./globals/visits/Review";
import { Dash as SharedsActivitiesDash } from "./shareds/activities/Dash";
import { Edit as SharedsActivitiesItemsEdit } from "./shareds/activities/items/Edit";
import { List as SharedsActivitiesItemsList } from "./shareds/activities/items/List";
import { New as SharedsActivitiesItemsNew } from "./shareds/activities/items/New";
import { Edit as SharedsActivitiesStagesEdit } from "./shareds/activities/stages/Edit";
import { List as SharedsActivitiesStagesList } from "./shareds/activities/stages/List";
import { New as SharedsActivitiesStagesNew } from "./shareds/activities/stages/New";
import { Edit as SharedsActivitiesTemplatesEdit } from "./shareds/activities/templates/Edit";
import { List as SharedsActivitiesTemplatesList } from "./shareds/activities/templates/List";
import { New as SharedsActivitiesTemplatesNew } from "./shareds/activities/templates/New";
import { List as SharedsCollaboratorsAbilitiesList } from "./shareds/collaborators/abilities/List";
import { List as SharedsCollaboratorsExperiencesList } from "./shareds/collaborators/experiences/List";
import { List as SharedsCollaboratorsList } from "./shareds/collaborators/List";
import { Show as SharedsCollaboratorsShow } from "./shareds/collaborators/Show";
import { Show as SharedsCollaboratorsStakeholderShow } from "./shareds/collaborators/stakeholder/Show";
import { List as SharedsCollaboratorsTrainingsList } from "./shareds/collaborators/trainings/List";
import { List as SharedsProjectsList } from "./shareds/projects/List";
import { Show as SharedsProjectsShow } from "./shareds/projects/Show";
import { Edit as SharedsRolesEdit } from "./shareds/roles/Edit";
import { List as SharedsRolesList } from "./shareds/roles/List";
import { New as SharedsRolesNew } from "./shareds/roles/New";
import { Show as SharedsRolesShow } from "./shareds/roles/Show";
import { List as SharedsVisitsList } from "./shareds/visits/List";

const frames = {
  auth: {
    CompletePasswordReset: AuthCompletePasswordReset,
    RequestPasswordReset: AuthRequestPasswordReset,
    SignIn: AuthSignIn,
  },

  globals: {
    users: {
      List: GlobalsUsersList,
      Show: GlobalsUsersShow,
      New: GlobalsUsersNew,
      Edit: GlobalsUsersEdit,

      employments: {
        New: GlobalsUsersEmploymentsNew,
        Edit: GlobalsUsersEmploymentsEdit,
      },
    },

    agencies: {
      List: GlobalsAgenciesList,
      Show: GlobalsAgenciesShow,
      New: GlobalsAgenciesNew,
      Edit: GlobalsAgenciesEdit,

      users: {
        New: GlobalsAgenciesUsersNew,
        Edit: GlobalsAgenciesUsersEdit,
      },
    },

    classifications: {
      List: GlobalsClassificationsList,
      Show: GlobalsClassificationsShow,
      New: GlobalsClassificationsNew,
      Edit: GlobalsClassificationsEdit,

      codes: {
        New: GlobalsClassificationsCodesNew,
        Edit: GlobalsClassificationsCodesEdit,
      },
    },

    certifications: {
      bodies: {
        List: GlobalsCertificationsBodiesList,
        Show: GlobalsCertificationsBodiesShow,
        New: GlobalsCertificationsBodiesNew,
        Edit: GlobalsCertificationsBodiesEdit,
      },

      schemes: {
        List: GlobalsCertificationsSchemesList,
        Show: GlobalsCertificationsSchemesShow,
        New: GlobalsCertificationsSchemesNew,
        Edit: GlobalsCertificationsSchemesEdit,

        info: {
          Show: GlobalsCertificationsSchemesInfoShow,
        },

        technicalAreas: {
          List: GlobalsCertificationsSchemesTechnicalAreasList,
          New: GlobalsCertificationsSchemesTechnicalAreasNew,
          Edit: GlobalsCertificationsSchemesTechnicalAreasEdit,
        },

        accreditations: {
          List: GlobalsCertificationsSchemesAccreditationsList,
          New: GlobalsCertificationsSchemesAccreditationsNew,
          Edit: GlobalsCertificationsSchemesAccreditationsEdit,
        },
      },
    },

    collaborators: {
      codeApplications: {
        List: GlobalsCollaboratorsCodeApplicationsList,
        Review: GlobalsCollaboratorsCodeApplicationsReview,
      },

      schemeApplications: {
        List: GlobalsCollaboratorsSchemeApplicationsList,
        Review: GlobalsCollaboratorsSchemeApplicationsReview,
      },

      Dash: GlobalsCollaboratorsDash,
    },

    projects: {
      Dash: GlobalsProjectsDash,
      Review: GlobalsProjectsReview,
    },

    visits: {
      List: GlobalsVisitsList,
      Review: GlobalsVisitsReview,
    },
  },

  agencies: {
    collaborators: {
      experiences: {
        New: AgenciesCollaboratorsExperiencesNew,
        Edit: AgenciesCollaboratorsExperiencesEdit,
      },

      trainings: {
        New: AgenciesCollaboratorsTrainingsNew,
        Edit: AgenciesCollaboratorsTrainingsEdit,
      },

      abilities: {
        New: AgenciesCollaboratorsAbilitiesNew,
        Edit: AgenciesCollaboratorsAbilitiesEdit,

        candidacies: {
          Show: AgenciesCollaboratorsAbilitiesCandidaciesShow,
          New: AgenciesCollaboratorsAbilitiesCandidaciesNew,
          Edit: AgenciesCollaboratorsAbilitiesCandidaciesEdit,
        },
      },
    },

    stakeholders: {
      List: AgenciesStakeholdersList,
      Show: AgenciesStakeholdersShow,
      New: AgenciesStakeholdersNew,
      Edit: AgenciesStakeholdersEdit,

      sites: {
        New: AgenciesStakeholdersSitesNew,
        Edit: AgenciesStakeholdersSitesEdit,
      },
      contacts: {
        New: AgenciesStakeholdersContactsNew,
        Edit: AgenciesStakeholdersContactsEdit,
      },
    },

    users: {
      List: AgenciesUsersList,
      Edit: AgenciesUsersEdit,
    },

    projects: {
      Dash: AgenciesProjectsDash,
      New: AgenciesProjectsNew,
    },

    visits: {
      List: SharedsVisitsList,
    },

    jobOrders: {
      product: {
        ProductForm: AgenciesJobOrdersProductForm,
      },
    },

    timesheet: {
      List: AgenciesTimesheetList,
      Show: AgenciesTimesheetShow,
    },
  },

  shareds: {
    collaborators: {
      List: SharedsCollaboratorsList,
      Show: SharedsCollaboratorsShow,

      stakeholder: {
        Show: SharedsCollaboratorsStakeholderShow,
      },

      experiences: {
        List: SharedsCollaboratorsExperiencesList,
      },

      trainings: {
        List: SharedsCollaboratorsTrainingsList,
      },

      abilities: {
        List: SharedsCollaboratorsAbilitiesList,
      },
    },

    roles: {
      List: SharedsRolesList,
      Show: SharedsRolesShow,
      New: SharedsRolesNew,
      Edit: SharedsRolesEdit,
    },

    projects: {
      List: SharedsProjectsList,
      Show: SharedsProjectsShow,
    },

    activities: {
      Dash: SharedsActivitiesDash,
      stages: {
        List: SharedsActivitiesStagesList,
        New: SharedsActivitiesStagesNew,
        Edit: SharedsActivitiesStagesEdit,
      },
      items: { List: SharedsActivitiesItemsList, New: SharedsActivitiesItemsNew, Edit: SharedsActivitiesItemsEdit },
      templates: {
        List: SharedsActivitiesTemplatesList,
        New: SharedsActivitiesTemplatesNew,
        Edit: SharedsActivitiesTemplatesEdit,
      },
    },
  },
};

export default frames;
