import { Alert, Card, Col, Form, Input, Row, Space } from "antd";
import { FormInstance } from "antd/lib/form";
import { omit } from "lodash";

type CodeFormType = {
  action: string;
  form: FormInstance<any>;
  handler: (values: any) => void;
  values: any;
  errors: string[];
};

const fields = [
  ["name", "name"],
  ["description", "description"],
];

export const onError = (form: any, setErrors: any) => ({ graphQLErrors }: any) => {
  const fieldsErrors: any[] = [];
  const errors: string[] = [];
  graphQLErrors.forEach((graphQLError: any) => {
    let found = false;
    fields.forEach(([fieldName, errorFieldName]) => {
      if (graphQLError.name === errorFieldName) {
        fieldsErrors.push({ name: fieldName, errors: (graphQLError as any).messages });
        found = true;
      }
    });
    if (!found) {
      errors.push(graphQLError.message);
    }
  });
  // console.log(fieldsErrors);
  // console.log(errors);
  form.setFields(fieldsErrors);
  setErrors(errors);
};

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const cleanValues = {
    ...omit(values, []),
    ...params,
  };

  mutate({ variables: { ...variables, input: cleanValues } });
};

export const CodeForm: React.FC<CodeFormType> = (props) => {
  const { action, form, handler, values, errors } = props;

  console.log(`Globals/Classifications/Codes/CodeForm action: ${action}`);

  return (
    <Card>
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Form layout="vertical" form={form} initialValues={values} onFinish={handler}>
        <Row gutter={[24, 0]}>
          <Col span={8}>
            <Form.Item name="name" label="Name" rules={[{ required: true, message: "is required" }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="description" label="Description" rules={[{ required: true, message: "is required" }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
