import { useDisclosure } from "@chakra-ui/react";
import React from "react";

import { FormDrawer } from "../ui/FormDrawer";
import { ListHeader } from "../ui/ListHeader";
import { ListTable } from "../ui/ListTable";
import { New } from "./New";

export const Index: React.FC<{}> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <ListHeader title="Stakeholders" openForm={onOpen} />
      <ListTable />
      <FormDrawer isOpen={isOpen} onClose={onClose} title="New Stakeholder">
        <New />
      </FormDrawer>
    </>
  );
};
