import { EditOutlined } from "@ant-design/icons";
import { StakeholderKind } from "__legacy/queries/__generated__/globalTypes";
import { StakeholdersQueryProvider } from "__legacy/queries/stakeholders/StakeholdersQueryProvider";
import { Button, Input, Layout, PageHeader, Table } from "antd";
import { Fragment, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";

export const List: React.FC<{ agency?: true }> = (props) => {
  const { agency } = props;
  const { agencyId } = useParams();
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const handleSearch = (value: string) => setSearchQuery(value.trim());

  const columns: any[] = [
    {
      title: "Lastname",
      dataIndex: "lastname",
      render: (value: string, resource: any) => {
        const link = agency ? resource.id : `${resource.agency.id}/${resource.id}`;
        return <NavLink to={link}>{value}</NavLink>;
      },
    },
    {
      title: "Firstname",
      dataIndex: "firstname",
      render: (value: string, resource: any) => {
        const link = agency ? resource.id : `${resource.agency.id}/${resource.id}`;
        return <NavLink to={link}>{value}</NavLink>;
      },
    },
    {
      title: "Country",
      dataIndex: "country",
    },
  ];

  if (!agency)
    columns.unshift({
      title: "Agency",
      dataIndex: "agency.name",
      render: (value: string, resource: any) => (
        <NavLink to={`${resource.agency.id}/${resource.id}`}>{resource.agency.name}</NavLink>
      ),
    });
  else
    columns.push({
      width: "80px",
      title: "Actions",
      dataIndex: "id",
      render: (id: string) => (
        <Button onClick={() => navigate(`${id}`)}>
          <EditOutlined />
        </Button>
      ),
    });

  return (
    <StakeholdersQueryProvider
      variables={{
        agencyId,
        kind: "collaborator" as StakeholderKind,
        search: searchQuery,
        ...(!agency ? { filterAuthorities: true } : {}),
      }}
    >
      {({ stakeholders }) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title="Auditors"
            extra={
              <Input.Search
                allowClear
                placeholder="search"
                onSearch={handleSearch}
                defaultValue={searchQuery ?? ""}
                style={{ width: 500 }}
              />
            }
          />
          <Layout.Content className="content">
            <Table rowKey="id" columns={columns} dataSource={stakeholders} pagination={false} />
          </Layout.Content>
        </Fragment>
      )}
    </StakeholdersQueryProvider>
  );
};
