import { Reviews } from "__legacy/frames/shareds/collaborators/Reviews";
import { CandidacyQueryProvider } from "__legacy/queries/candidacies/CandidacyQueryProvider";
import { ReviewCandidacyMutationProvider } from "__legacy/queries/candidacies/ReviewCandidacyMutationProvider";
import { ReviewStatusesQueryProvider } from "__legacy/queries/enums/ReviewStatusesQueryProvider";
import { Button, Card, Divider, Form, Layout, PageHeader, Space, Typography } from "antd";
import { find } from "lodash";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { onError, onFinish, ReviewForm } from "../../../shareds/reviews/ReviewForm";

export const Review: React.FC<{}> = () => {
  const { candidacyId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  const questions: any = {
    qms: [
      { id: 0, note: "How are code competence pre-qualification criteria met?" },
      { id: 10, ask: "Minimum of 2 years direct work in applied technial areas.", opts: "check" },
      { id: 20, ask: "Degree of equivalent qualification relevant to applied technial areas.", opts: "check" },
      { id: 30, ask: "Audit Experience within applied technial areas. (5 or 3)", opts: "check" },
      { id: 40, ask: "Consultation within applied technial areas. (2)", opts: "check" },
      { id: 50, ask: "Evidence of other Accreditation CB code award", opts: "check" },
    ],
  };

  const getQuestions = (schemeKind: any) => {
    return schemeKind ? questions[schemeKind] : null;
  };

  return (
    <CandidacyQueryProvider variables={{ id: candidacyId }}>
      {({ candidacy }) => {
        const codes = candidacy.candidacyClassifications.map((code: any) => ({
          id: code.id,
          label: `${code.classification.industry.name} - ${code.classification.name} - ${code.classification.description}`,
        }));

        const schemeKind = candidacy.ability.scheme.mandaysKind;

        return (
          <ReviewCandidacyMutationProvider onError={onError(form, setErrors)} onCompleted={() => navigate("../../")}>
            {(loading, mutate) => (
              <Fragment>
                <PageHeader
                  ghost={true}
                  title={`Review candidacy on ${candidacy.ability.scheme.name} for ${candidacy.ability.stakeholder.companyName}`}
                  extra={
                    <Fragment>
                      <Button onClick={() => navigate("../../")}>Back to list</Button>
                      <Button type="primary" loading={loading} onClick={() => form.submit()}>
                        Save
                      </Button>
                    </Fragment>
                  }
                />
                <Layout.Content className="content">
                  <Card>
                    <ReviewStatusesQueryProvider>
                      {({ reviewStatuses }) => (
                        <Space direction="vertical">
                          <div>
                            <strong>Status: </strong>
                            {
                              find(reviewStatuses?.enumValues ?? [], ({ name }) => name === candidacy.status)
                                ?.description
                            }
                          </div>
                          <div>
                            <strong>Content: </strong>
                            {candidacy.content}
                          </div>
                          <div>
                            <strong>Codes: </strong>
                            {codes.map((code) => (
                              <div key={code.id}>{code.label}</div>
                            ))}
                          </div>
                          {candidacy.attachment && (
                            <div>
                              <strong>Attachment: </strong>
                              <a href={candidacy.attachment} download>
                                Download
                              </a>
                            </div>
                          )}
                        </Space>
                      )}
                    </ReviewStatusesQueryProvider>
                    <Divider />
                    <Reviews reviews={candidacy.candidacyReviews} />
                    <Divider />
                    <Typography.Title level={4}>New Review</Typography.Title>
                    <ReviewForm
                      action="review"
                      form={form}
                      handler={onFinish(mutate, {}, { id: candidacyId })}
                      values={{}}
                      errors={errors}
                      codes={codes}
                      questions={getQuestions(schemeKind)}
                    />
                  </Card>
                </Layout.Content>
              </Fragment>
            )}
          </ReviewCandidacyMutationProvider>
        );
      }}
    </CandidacyQueryProvider>
  );
};
