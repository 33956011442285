import { Alert, Card, Col, Form, Input, Row, Space } from "antd";
import { FormInstance } from "antd/lib/form";

type ContactFormType = {
  form: FormInstance<any>;
  handler: (values: any) => void;
  values: any;
  errors: string[];
};

export const onError = (form: any, setErrors: any) => ({ graphQLErrors }: any) => {
  setErrors(graphQLErrors);
};

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const input = {
    ...values,
    ...params,
  };
  mutate({ variables: { ...variables, input } });
};

export const ContactForm: React.FC<ContactFormType> = (props) => {
  const { form, handler, values, errors } = props;

  return (
    <Card>
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Form layout="vertical" form={form} initialValues={values} onFinish={handler}>
        <Row gutter={[24, 0]}>
          <Col span={12}>
            <Form.Item name="lastname" label="Lastname" rules={[{ required: true, message: "is required" }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="firstname" label="Firstname" rules={[{ required: true, message: "is required" }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="role" label="Role">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="phoneNumber" label="Phone">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="mobileNumber" label="Mobile">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="faxNumber" label="Fax">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="email" label="Email">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="emailPrivate" label="Private Email">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="notes" label="Notes">
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
