import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  CreateTrainingMutation as CreateTrainingMutationType,
  CreateTrainingMutationVariables,
} from "./__generated__/CreateTrainingMutation";

const CreateTrainingMutation = loader("./CreateTrainingMutation.gql");

type CreateTrainingMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted: (data: CreateTrainingMutationType) => void;
}) => ReactElement;

export const CreateTrainingMutationProvider: CreateTrainingMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<CreateTrainingMutationType, CreateTrainingMutationVariables>(
    CreateTrainingMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
