import { useQuery } from "@apollo/client";
import { CenterResult } from "__legacy/components/CenterResult";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import { ReviewStatusesQuery as ReviewStatusesQueryType } from "./__generated__/ReviewStatusesQuery";

const ReviewStatusesQuery = loader("./ReviewStatusesQuery.gql");

type ReviewStatusesQueryProviderType = (props: {
  children: (data: ReviewStatusesQueryType) => ReactNode;
}) => ReactElement;

export const ReviewStatusesQueryProvider: ReviewStatusesQueryProviderType = (props) => {
  const { children } = props;

  const { error, loading, data } = useQuery<ReviewStatusesQueryType>(ReviewStatusesQuery, {
    fetchPolicy: "cache-and-network",
  });

  if (loading) return <CenterSpinner size="large" />;
  if (error || !data)
    return <CenterResult status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;

  return <Fragment>{children(data)}</Fragment>;
};
