import { Button, Card, Col, Row, Space, Tag } from "antd";
import { isNull } from "lodash";
import { Fragment, useState } from "react";

import { PwsReductionsForm } from "./PwsReductionsForm";

type PwsReductionsCardType = {
  project: any;
  editable: boolean;
};

export const PwsReductionsCard: React.FC<PwsReductionsCardType> = (props) => {
  const { project, editable } = props;

  const [drawerVisible, setDrawerVisible] = useState(false);
  const onClose = () => setDrawerVisible(false);

  const filled = project.reductionsFilled;
  const max = 30;

  return (
    <Fragment>
      <Card
        title={
          <Space>
            Reductions
            {!isNull(project.totalReductionPercentage) && (
              <Tag color={project.totalReductionPercentage > max ? "red" : "blue"}>
                {project.totalReductionPercentage}%
              </Tag>
            )}
          </Space>
        }
        bodyStyle={filled ? {} : { display: "none" }}
        extra={
          editable && (
            <Button type={filled ? "ghost" : "primary"} onClick={() => setDrawerVisible(true)}>
              {filled ? "Edit" : "Fill"}
            </Button>
          )
        }
      >
        <Row gutter={[24, 24]}>
          <Col span={12}>
            Certified: <b>{project.certifiedReduction ? "Yes" : "No"}</b>
          </Col>
          <Col span={12}>
            Certified by: <b>{project.certifiedBy}</b>
          </Col>
          <Col span={12}>
            Automation: <b>{project.automationReduction ? "Yes" : "No"}</b>
          </Col>
          <Col span={12}>
            {project.automationInfo && (
              <Fragment>
                Automation info: <b>{project.automationInfo}</b>
              </Fragment>
            )}
          </Col>
          <Col span={12}>
            MS Maturity: <b>{project.msReduction ? "Yes" : "No"}</b>
          </Col>
          <Col span={12}>
            {project.msInfo && (
              <Fragment>
                MS info: <b>{project.msInfo}</b>
              </Fragment>
            )}
          </Col>
          <Col span={24}>
            Other:{" "}
            {project.otherReduction && (
              <b>
                <Space>
                  <span>{project.otherPercentage}%</span>
                  {project.otherInfo}
                </Space>
              </b>
            )}
            {!project.otherReduction && <b>No</b>}
          </Col>
          {project.integratedMs && (
            <Col span={24}>
              Integrated MS: <b>{project.integratedMsPercentage}%</b>
            </Col>
          )}
          {project.scheme.mandaysKind === "qms" && (
            <Col>
              <Space direction="vertical">
                <b>QMS Exclusions</b>
                <Space size="large">
                  <span>
                    8 3: <b>{project.qms83 ? "Yes" : "No"}</b>
                  </span>
                  <span>
                    7 1 5 2: <b>{project.qms7152 ? "Yes" : "No"}</b>
                  </span>
                  <span>
                    8 4: <b>{project.qms84 ? "Yes" : "No"}</b>
                  </span>
                  <span>
                    8 5 3: <b>{project.qms853 ? "Yes" : "No"}</b>
                  </span>
                  <span>
                    8 5 5: <b>{project.qms855 ? "Yes" : "No"}</b>
                  </span>
                </Space>
              </Space>
            </Col>
          )}
          {project.scheme.mandaysKind === "mdms" && (
            <Col>
              <Space direction="vertical">
                MDMS Exclusions
                <span>
                  7 3: <b>{project.mdms73 ? "Yes" : "No"}</b>
                </span>
              </Space>
            </Col>
          )}
        </Row>
      </Card>
      <PwsReductionsForm visible={drawerVisible} onClose={onClose} project={project} />
    </Fragment>
  );
};
