import { DateTime } from "luxon";
import { useState } from "react";
import { useCookies } from "react-cookie";

type useSessionCookieType = (
  cookieName: string,
  cookieDomain: string,
) => {
  token: string;
  setToken: (token: string, persistent: boolean) => void;
  clearToken: () => void;
};

export const useSessionCookie: useSessionCookieType = (cookieName, cookieDomain) => {
  const [cookies, storeCookie, removeCookie] = useCookies([cookieName]);

  const [token, setToken] = useState<string>(cookies[cookieName]);

  const updateToken = (token: string, persistent: boolean) => {
    if (persistent) {
      const expiration = DateTime.local().plus({ days: 5 }).toJSDate();
      storeCookie(cookieName, token, { expires: expiration, path: "/", domain: cookieDomain });
    } else {
      storeCookie(cookieName, token, { path: "/", domain: cookieDomain });
    }
    setToken(token);
  };

  const clearToken = () => {
    removeCookie(cookieName);
    setToken("");
  };

  return { token, setToken: updateToken, clearToken };
};
