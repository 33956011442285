import { useQuery } from "@apollo/client";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { StakeholderKind } from "__legacy/queries/__generated__/globalTypes";
import { StakeholdersQueryProvider } from "__legacy/queries/stakeholders/StakeholdersQueryProvider";
import { Alert, Badge, Col, DatePicker, Form, Row, Select, Space, Switch } from "antd";
import { FormInstance } from "antd/lib/form";
import Text from "antd/lib/typography/Text";
import { loader } from "graphql.macro";
import { difference, find, intersection, isString } from "lodash";
import moment from "moment";
import { useParams } from "react-router";

const AuditorsQuery = loader("__legacy/queries/stakeholders/AuditorsQuery.gql");

const formatDate = (date: any) => {
  return moment(date, "MM-DD-YYYY").isValid() ? moment(date, "MM-DD-YYYY").format("YYYY-MM-DD") : null;
};

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const input = {
    ...values,
    dueDate: formatDate(values.dueDate),
    dateFrom: formatDate(values.dateFrom),
    dateTo: formatDate(values.dateTo),
    visitTechnicalExperts: values.visitTechnicalExperts?.map((stakeholderId: any) => ({
      stakeholderId,
    })),
    visitAuditors: values.visitAuditors?.map((stakeholderId: any) => ({
      stakeholderId,
    })),
    visitCodeTrainings: values.visitCodeTrainings?.map((stakeholderId: any) => ({
      stakeholderId,
    })),
    visitRoleTrainings: values.visitRoleTrainings?.map((stakeholderId: any) => ({
      stakeholderId,
    })),
    ...params,
  };
  mutate({ variables: { ...variables, input } });
};

export const VisitBookingForm: React.FC<{
  form: FormInstance<any>;
  onFinish: (values: any) => void;
  visit: any;
  project: any;
  errors: string[];
}> = (props) => {
  const { form, visit, project, onFinish, errors } = props;
  const { agencyId } = useParams();

  const { loading, data } = useQuery(AuditorsQuery, {
    variables: {
      agencyId,
      schemeId: project.scheme.id,
    },
    fetchPolicy: "cache-and-network",
  });

  const visitCodes = find(project.projectSites, (ps: any) => {
    return ps.siteId === visit.siteId;
  }).projectSiteClassifications.map((psc: any) => psc.classification.name);

  const initialValues = {
    ...visit,
    dueDate: visit.dueDate ? moment(visit.dueDate, "YYYY-MM-DD") : null,
    dateFrom: visit.dateFrom ? moment(visit.dateFrom, "YYYY-MM-DD") : null,
    dateTo: visit.dateTo ? moment(visit.dateTo, "YYYY-MM-DD") : null,
    leadAuditorId: visit.leadAuditor?.id,
    visitTechnicalExperts: visit.visitTechnicalExperts?.map((o: any) => o.stakeholder.id),
    visitAuditors: visit.visitAuditors?.map((o: any) => o.stakeholder.id),
    visitCodeTrainings: visit.visitCodeTrainings?.map((o: any) => o.stakeholder.id),
    visitRoleTrainings: visit.visitRoleTrainings?.map((o: any) => o.stakeholder.id),
  };

  if (loading) return <CenterSpinner size="large" />;

  const { auditors } = data;

  const getAuditors = (level?: string) => {
    return level ? auditors.filter((auditor: any) => auditor.abilities[0].level === level) : auditors;
  };
  const getAuditor = (id: any) => {
    return find(auditors, (a: any) => a.id === id);
  };
  const getAuditorCodes = (id: any) => {
    if (!id) return [];
    return getAuditor(id).abilities[0].candidacyClassifications.map((c: any) => c.classification.name);
  };

  const renderAuditors = (level?: string, noEmpty?: boolean) => {
    return getAuditors(level).map((auditor: any) => {
      const missingCodes = difference(visitCodes, getAuditorCodes(auditor.id));
      const gotCodes = difference(visitCodes, missingCodes);

      const o: any = {
        id: auditor.id,
        badge: !!gotCodes.length ? (!!missingCodes.length ? "warning" : "success") : "error",
        label: auditor.companyName,
      };

      if (noEmpty && !gotCodes.length) return false;

      return (
        <Select.Option key={o.id} value={o.id} label={o.label}>
          <div className="demo-option-label-item">
            <Badge status={o.badge} />
            {o.label}
            {(!gotCodes.length && <Text type="danger"> [no codes]</Text>) ||
              (!!missingCodes.length && <Text type="warning"> [codes: {gotCodes.join(", ")}]</Text>)}
          </div>
        </Select.Option>
      );
    });
  };

  return (
    <Form layout="vertical" form={form} initialValues={initialValues} onFinish={onFinish}>
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Space direction="vertical" size="large">
        <Text type="secondary">
          <Space direction="vertical">
            <Space size="large">
              <span>{visit.mandays} Mandays</span>
              <span>{visit.reportDays} Off-Site days</span>
              <span>{visit.fee} Fee</span>
              {visit.includesSubsistence && <span>Includes Subsistence</span>}
              {visit.includesTravel && <span>Includes Travel</span>}
            </Space>
            {visit.info && <span>Info: {visit.info}</span>}
          </Space>
        </Text>
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item name="invoiced" label="Invoiced" valuePropName={"checked"}>
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="dueDate" label="Due Date" rules={[{ required: true, message: "is required" }]}>
              <DatePicker mode="date" format="MM-DD-YYYY" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item dependencies={["dueDate"]}>
              {(form: FormInstance) => (
                <Form.Item name="dateFrom" label="Visit Date From" rules={[{ required: true, message: "is required" }]}>
                  <DatePicker
                    mode="date"
                    format="MM-DD-YYYY"
                    style={{ width: "100%" }}
                    disabledDate={(d) =>
                      d > moment(form.getFieldValue("dueDate")).subtract(visit.mandays, "day").endOf("day")
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item dependencies={["dueDate", "dateFrom"]}>
              {(form: FormInstance) => (
                <Form.Item name="dateTo" label="Visit Date To" rules={[{ required: true, message: "is required" }]}>
                  <DatePicker
                    mode="date"
                    format="MM-DD-YYYY"
                    style={{ width: "100%" }}
                    disabledDate={(d) =>
                      d < moment(form.getFieldValue("dateFrom")).add(visit.mandays, "day") ||
                      d > moment(form.getFieldValue("dueDate")).endOf("day")
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="leadAuditorId" label="Lead Auditor" rules={[{ required: true, message: "is required" }]}>
              <Select
                showSearch
                optionLabelProp="label"
                filterOption={(input, option) => {
                  let label = "";
                  if (option?.label && isString(option.label)) label = option.label;
                  let value = option?.value;
                  return (
                    label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {renderAuditors("lead_auditor")}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <StakeholdersQueryProvider
              variables={{
                agencyId,
                kind: "collaborator" as StakeholderKind,
                visitId: visit.id,
              }}
            >
              {({ stakeholders }) => (
                <Form.Item name="visitTechnicalExperts" label="Technical Experts">
                  <Select
                    mode="multiple"
                    showSearch
                    optionLabelProp="label"
                    filterOption={(input, option) => {
                      let label = "";
                      if (option?.label && isString(option.label)) label = option.label;
                      let value = option?.value;
                      return (
                        label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {stakeholders.map((stakeholder) => (
                      <Select.Option key={stakeholder.id} value={stakeholder.id} label={stakeholder.companyName}>
                        {stakeholder.companyName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </StakeholdersQueryProvider>
          </Col>
          <Col span={24}>
            <Form.Item name="visitAuditors" label="Code Auditor">
              <Select
                mode="multiple"
                showSearch
                optionLabelProp="label"
                filterOption={(input, option) => {
                  let label = "";
                  if (option?.label && isString(option.label)) label = option.label;
                  let value = option?.value;
                  return (
                    label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {renderAuditors(undefined, true)}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <StakeholdersQueryProvider
              variables={{
                agencyId,
                kind: "collaborator" as StakeholderKind,
              }}
            >
              {({ stakeholders }) => (
                <Form.Item name="visitCodeTrainings" label="Auditor under training - Code">
                  <Select
                    mode="multiple"
                    showSearch
                    optionLabelProp="label"
                    filterOption={(input, option) => {
                      let label = "";
                      if (option?.label && isString(option.label)) label = option.label;
                      let value = option?.value;
                      return (
                        label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {stakeholders.map((stakeholder) => (
                      <Select.Option key={stakeholder.id} value={stakeholder.id} label={stakeholder.companyName}>
                        {stakeholder.companyName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </StakeholdersQueryProvider>
          </Col>
          <Col span={24}>
            <StakeholdersQueryProvider
              variables={{
                agencyId,
                kind: "collaborator" as StakeholderKind,
              }}
            >
              {({ stakeholders }) => (
                <Form.Item name="visitRoleTrainings" label="Auditor under training - Role">
                  <Select
                    mode="multiple"
                    showSearch
                    optionLabelProp="label"
                    filterOption={(input, option) => {
                      let label = "";
                      if (option?.label && isString(option.label)) label = option.label;
                      let value = option?.value;
                      return (
                        label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {stakeholders.map((stakeholder) => (
                      <Select.Option key={stakeholder.id} value={stakeholder.id} label={stakeholder.companyName}>
                        {stakeholder.companyName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </StakeholdersQueryProvider>
          </Col>
          <Col span={24}>
            <Form.Item dependencies={["leadAuditorId", "visitAuditors"]}>
              {(form: FormInstance) => {
                const leadAuditorCodes = intersection(visitCodes, getAuditorCodes(form.getFieldValue("leadAuditorId")));
                const matchingCodes: any = [];
                form.getFieldValue("visitAuditors").forEach((id: any) => {
                  const codes = getAuditorCodes(id);
                  matchingCodes.push(...intersection(visitCodes, codes));
                });
                const auditorsCodes = matchingCodes;
                const missingCodes = difference(visitCodes, [...leadAuditorCodes, ...auditorsCodes]);
                return (
                  <Alert
                    message={
                      !!missingCodes.length
                        ? `Select Auditors with the required abilities to check ${missingCodes.length} site's code${
                            missingCodes.length > 1 ? "s" : ""
                          }: ${missingCodes.join(", ")}`
                        : "The selected Auditors have the required abilities to check all the site’s codes"
                    }
                    type={!!missingCodes.length ? "warning" : "success"}
                    showIcon
                  />
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </Space>
    </Form>
  );
};
