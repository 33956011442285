import { Box, Flex, HStack, Icon, Link, Text, VStack } from "@chakra-ui/react";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";

export const AreaNav: React.FC<{}> = () => {
  return (
    <Flex position={{ sm: "fixed" }} bg="coolGray.800" ml="12" h="100vh" w="60" px="px">
      <Box w="full" color="coolGray.300">
        <Box mt="6" px="4" h="10">
          <Text fontSize="lg">AJA ITALY</Text>
        </Box>
        <VStack spacing="0" mt="4">
          <Link
            p="2h"
            px="4"
            mt="1"
            w="full"
            borderRight="4px"
            borderRightColor="coolGray.300"
            borderLeft="4px"
            borderLeftColor="coolGray.800"
            _hover={{ color: "coolGray.300" }}
          >
            Users
          </Link>
          <Link
            p="2h"
            px="4"
            w="full"
            borderRight="4px"
            borderRightColor="coolGray.800"
            borderLeft="4px"
            borderLeftColor="coolGray.800"
            _hover={{ color: "coolGray.300", bg: "coolGray.900" }}
          >
            Roles
          </Link>
          <Link
            p="2h"
            px="4"
            w="full"
            borderRight="4px"
            borderRightColor="coolGray.800"
            borderLeft="4px"
            borderLeftColor="coolGray.800"
            _hover={{ color: "coolGray.300", bg: "coolGray.900" }}
          >
            Agencies
          </Link>
          <Link
            p="2h"
            px="4"
            w="full"
            borderRight="4px"
            borderRightColor="coolGray.800"
            borderLeft="4px"
            borderLeftColor="coolGray.800"
            _hover={{ color: "coolGray.300", bg: "coolGray.900" }}
          >
            Classifications
          </Link>
          <Link
            p="2h"
            px="4"
            w="full"
            borderRight="4px"
            borderRightColor="coolGray.800"
            borderLeft="4px"
            borderLeftColor="coolGray.800"
            _hover={{ color: "coolGray.300" }}
          >
            <HStack justify="space-between">
              <Text>Certifications</Text>
              <Box
                align="center"
                lineHeight="3"
                color="coolGray.700"
                h="5"
                w="5"
                p="2px"
                mb="1px"
                borderRadius="sm"
                _hover={{ bg: "coolGray.900" }}
              >
                <Icon as={RiArrowDownSLine} h="full" w="full" color="coolGray.300" />
              </Box>
            </HStack>
          </Link>
          <Link
            p="2h"
            px="4"
            w="full"
            borderRight="4px"
            borderRightColor="coolGray.800"
            borderLeft="4px"
            borderLeftColor="coolGray.800"
            _hover={{ color: "coolGray.300", bg: "coolGray.900" }}
          >
            Collaborators
          </Link>
          <Link
            p="2h"
            px="4"
            w="full"
            borderRight="4px"
            borderRightColor="coolGray.800"
            borderLeft="4px"
            borderLeftColor="coolGray.800"
            _hover={{ color: "coolGray.300" }}
          >
            <HStack justify="space-between">
              <Text>Job Orders</Text>
              <Box
                align="center"
                lineHeight="3"
                color="coolGray.700"
                h="5"
                w="5"
                p="2px"
                mb="1px"
                borderRadius="sm"
                _hover={{ bg: "coolGray.900" }}
              >
                <Icon as={RiArrowUpSLine} h="full" w="full" color="coolGray.300" />
              </Box>
            </HStack>
          </Link>
          <VStack spacing="0" w="full">
            <Link
              p="2h"
              pl="8"
              w="full"
              borderRight="4px"
              borderRightColor="coolGray.800"
              borderLeft="4px"
              borderLeftColor="coolGray.800"
              _hover={{ color: "coolGray.300", bg: "coolGray.900" }}
            >
              System
            </Link>
            <Link
              p="2h"
              pl="8"
              w="full"
              borderRight="4px"
              borderRightColor="coolGray.800"
              borderLeft="4px"
              borderLeftColor="coolGray.800"
              _hover={{ color: "coolGray.300", bg: "coolGray.900" }}
            >
              Product
            </Link>
            <Link
              p="2h"
              pl="8"
              w="full"
              borderRight="4px"
              borderRightColor="coolGray.800"
              borderLeft="4px"
              borderLeftColor="coolGray.800"
              _hover={{ color: "coolGray.300", bg: "coolGray.900" }}
            >
              Personnel
            </Link>
            <Link
              p="2h"
              px="4"
              w="full"
              borderRight="4px"
              borderRightColor="coolGray.800"
              borderLeft="4px"
              borderLeftColor="coolGray.800"
              _hover={{ color: "coolGray.300", bg: "coolGray.900" }}
            >
              Collaborators
            </Link>
            <Link
              p="2h"
              px="4"
              w="full"
              borderRight="4px"
              borderRightColor="coolGray.800"
              borderLeft="4px"
              borderLeftColor="coolGray.800"
              _hover={{ color: "coolGray.300", bg: "coolGray.900" }}
            >
              Collaborators
            </Link>
            <Link
              p="2h"
              px="4"
              w="full"
              borderRight="4px"
              borderRightColor="coolGray.800"
              borderLeft="4px"
              borderLeftColor="coolGray.800"
              _hover={{ color: "coolGray.300", bg: "coolGray.900" }}
            >
              Collaborators
            </Link>
            <Link
              p="2h"
              px="4"
              w="full"
              borderRight="4px"
              borderRightColor="coolGray.800"
              borderLeft="4px"
              borderLeftColor="coolGray.800"
              _hover={{ color: "coolGray.300", bg: "coolGray.900" }}
            >
              Collaborators
            </Link>
          </VStack>
        </VStack>
      </Box>
    </Flex>
  );
};
