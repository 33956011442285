import { Input } from "@chakra-ui/react";
import React from "react";

import { FormItem } from "../ui/FormItem";

export const New: React.FC<{}> = () => {
  return (
    <>
      <FormItem id="businessName" required>
        <Input />
      </FormItem>
      <FormItem id="lastname" required>
        <Input />
      </FormItem>
      <FormItem id="firstname" required>
        <Input />
      </FormItem>
      <FormItem id="vatCode" required>
        <Input />
      </FormItem>
      <FormItem id="fiscalCode" required>
        <Input />
      </FormItem>
      <FormItem id="address" required>
        <Input />
      </FormItem>
      <FormItem id="city" required>
        <Input />
      </FormItem>
      <FormItem id="zipCode" required>
        <Input />
      </FormItem>
      <FormItem id="state" required>
        <Input />
      </FormItem>
      <FormItem id="country" required>
        <Input />
      </FormItem>
    </>
  );
};
