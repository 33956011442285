import { AppstoreOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import { useApplicationContext } from "__legacy/components/Application";
import { Logo } from "__legacy/components/Logo";
import { Avatar, Layout, Menu, Row } from "antd";
import { Fragment, useEffect, useState } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";

export const MainLayout: React.FC<{}> = () => {
  const { session, setSessionToken } = useApplicationContext();
  const location = useLocation();
  const [openedKey, setOpenedKey] = useState<string[]>([""]);
  const [selectedKey, setSelectedKey] = useState<string[]>([""]);

  const employments = session.currentUser.employments;

  useEffect(() => {
    let pathFound = false;
    if (location.pathname.startsWith("/job-orders/system")) {
      pathFound = true;
      setOpenedKey(["global"]);
      setSelectedKey(["global_projects"]);
    } else if (location.pathname.startsWith("/auditors")) {
      pathFound = true;
      setOpenedKey(["global"]);
      setSelectedKey(["global_auditors"]);
    } else if (location.pathname.startsWith("/users")) {
      pathFound = true;
      setOpenedKey(["global", "global_settings"]);
      setSelectedKey(["global_users"]);
    } else if (location.pathname.startsWith("/roles")) {
      pathFound = true;
      setOpenedKey(["global", "global_settings"]);
      setSelectedKey(["global_roles"]);
    } else if (location.pathname.startsWith("/agencies")) {
      pathFound = true;
      setOpenedKey(["global", "global_settings"]);
      setSelectedKey(["global_agencies"]);
    } else if (location.pathname.startsWith("/classifications")) {
      pathFound = true;
      setOpenedKey(["global", "global_settings"]);
      setSelectedKey(["global_classifications"]);
    } else if (location.pathname.startsWith("/certifications")) {
      setOpenedKey(["global", "global_settings"]);
      if (location.pathname.startsWith("/certifications/bodies")) {
        pathFound = true;
        setSelectedKey(["global_bodies"]);
      } else if (location.pathname.startsWith("/certifications/schemes")) {
        pathFound = true;
        setSelectedKey(["global_schemes"]);
      }
    }

    if (!pathFound) {
      employments
        .filter((e: any) => e.kind === "agency")
        .forEach((employment: any) => {
          const agencyId = employment.agency.id;
          if (!pathFound && location.pathname.startsWith(`/${agencyId}`)) {
            setOpenedKey([agencyId]);
            if (location.pathname.startsWith(`/${agencyId}/job-orders/system`)) {
              pathFound = true;
              setSelectedKey([`${agencyId}_projects`]);
            } else if (location.pathname.startsWith(`/${agencyId}/stakeholders`)) {
              pathFound = true;
              setSelectedKey([`${agencyId}_stakeholders`]);
            } else if (location.pathname.startsWith(`/${agencyId}/auditors`)) {
              pathFound = true;
              setSelectedKey([`${agencyId}_stakeholders`]);
            } else if (location.pathname.startsWith(`/${agencyId}/activities`)) {
              pathFound = true;
              setSelectedKey([`${agencyId}_activities`]);
            } else if (location.pathname.startsWith(`/${agencyId}/timesheet`)) {
              pathFound = true;
              setSelectedKey([`${agencyId}_timesheet`]);
            } else if (location.pathname.startsWith(`/${agencyId}/users`)) {
              pathFound = true;
              setOpenedKey([agencyId, `${agencyId}_settings`]);
              setSelectedKey([`${agencyId}_users`]);
            } else if (location.pathname.startsWith(`/${agencyId}/roles`)) {
              pathFound = true;
              setOpenedKey([agencyId, `${agencyId}_settings`]);
              setSelectedKey([`${agencyId}_roles`]);
            }
          }
        });
    }

    if (!pathFound) {
      setOpenedKey([""]);
      setSelectedKey([""]);
    }
  }, [location.pathname, employments]);

  const handleSignout = (e: any) => {
    e.preventDefault();
    setSessionToken("", false);
    return false;
  };

  return (
    <Layout className="layout-main">
      <Layout.Sider theme="light" width={"250px"} breakpoint="lg" collapsedWidth="0">
        <div className="sider-wrapper">
          <Menu
            mode="inline"
            className="top"
            key={`${openedKey}_${selectedKey}`}
            defaultOpenKeys={openedKey}
            defaultSelectedKeys={selectedKey}
          >
            <div className="logo">
              <NavLink to="/">
                <Logo />
              </NavLink>
            </div>

            {/* {session.currentUser.isAdmin && ( */}
            {employments.find((e: any) => e.kind === "global") && (
              <Menu.SubMenu key="global" title="GLOBAL" icon={<AppstoreOutlined />}>
                <Menu.Item key="global_projects">
                  <NavLink to="/job-orders/system/dashboard">
                    <span>Projects</span>
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="global_auditors">
                  <NavLink to="/auditors/dashboard">
                    <span>Auditors</span>
                  </NavLink>
                </Menu.Item>
                <Menu.SubMenu key="global_settings" title="Settings">
                  <Menu.Item key="global_users">
                    <NavLink to="/users">
                      <span>Users</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="global_roles">
                    <NavLink to="/roles">
                      <span>Roles</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="global_agencies">
                    <NavLink to="/agencies">
                      <span>Agencies</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="global_classifications">
                    <NavLink to="/classifications">
                      <span>Classifications</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="global_bodies">
                    <NavLink to="/certifications/bodies">
                      <span>Authorities</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="global_schemes">
                    <NavLink to="/certifications/schemes">
                      <span>Certifications</span>
                    </NavLink>
                  </Menu.Item>
                </Menu.SubMenu>
              </Menu.SubMenu>
            )}

            {employments
              .filter((e: any) => e.kind === "agency")
              .map((employment: any) => {
                const agency = employment.agency;
                return (
                  <Menu.SubMenu key={agency.id} title={agency.name} icon={<AppstoreOutlined />}>
                    <Menu.Item key={`${agency.id}_projects`}>
                      <NavLink to={`/${agency.id}/job-orders/system/dashboard`}>
                        <span>Projects</span>
                      </NavLink>
                    </Menu.Item>
                    {session.currentUser.email.includes("ajaeurope.eu") && (
                      <Menu.Item key={`${agency.id}_activities`}>
                        <NavLink to={`/${agency.id}/activities`}>
                          <span>Contract Activities</span>
                        </NavLink>
                      </Menu.Item>
                    )}
                    {session.currentUser.email.includes("ajaeurope.eu") && (
                      <Menu.Item key={`${agency.id}_timesheet`}>
                        <NavLink to={`/${agency.id}/timesheet`}>
                          <span>Timesheet</span>
                        </NavLink>
                      </Menu.Item>
                    )}
                    <Menu.Item key={`${agency.id}_stakeholders`}>
                      <NavLink to={`/${agency.id}/stakeholders`}>
                        <span>Stakeholders</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.SubMenu key={`${agency.id}_settings`} title="Settings">
                      <Menu.Item key={`${agency.id}_users`}>
                        <NavLink to={`/${agency.id}/users`}>
                          <span>Users</span>
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item key={`${agency.id}_roles`}>
                        <NavLink to={`/${agency.id}/roles`}>
                          <span>Roles</span>
                        </NavLink>
                      </Menu.Item>
                    </Menu.SubMenu>
                  </Menu.SubMenu>
                );
              })}
          </Menu>
        </div>
      </Layout.Sider>
      <Layout>
        <Layout.Header className="header-background">
          <Row justify="end">
            <Menu theme="light" mode="horizontal" triggerSubMenuAction="click">
              <Menu.SubMenu
                key="user"
                title={
                  <Fragment>
                    <Avatar size={40} className="custom-avatar" icon={<UserOutlined />} />
                    <span>&nbsp;&nbsp; {session.currentUser.email}</span>
                  </Fragment>
                }
              >
                <Menu.Item key="profile">
                  <NavLink to="/global/profile/">
                    <UserOutlined />
                    <span>Profile</span>
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="settings">
                  <NavLink to="/global/settings">
                    <SettingOutlined />
                    <span>Settings</span>
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="sign_out">
                  <a href="/#" onClick={handleSignout}>
                    <LogoutOutlined />
                    <span>Sign out</span>
                  </a>
                </Menu.Item>
              </Menu.SubMenu>
            </Menu>
          </Row>
        </Layout.Header>
        <Layout className="content">
          <Outlet />
        </Layout>
      </Layout>
    </Layout>
  );
};
