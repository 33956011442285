import { UpdateProjectSiteMutationProvider } from "__legacy/queries/projects/UpdateProjectSiteMutationProvider";
import { Button, Col, Divider, Drawer, Form, Row, Space, Tag } from "antd";
import Text from "antd/lib/typography/Text";
import { Fragment, useState } from "react";

import { onFinish, PwsProjectSiteForm } from "./PwsProjectSiteForm";

type PwsProjectSiteCardType = {
  project: any;
  projectSite: any;
  i: any;
  editable: boolean;
};

export const PwsProjectSiteCard: React.FC<PwsProjectSiteCardType> = (props) => {
  const { project, projectSite, i, editable } = props;
  const title = "Site";
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  const [drawerVisible, setDrawerVisible] = useState(false);
  const onClose = () => setDrawerVisible(false);

  const [Mutation, params, variables] = [UpdateProjectSiteMutationProvider, {}, { id: projectSite.id }];

  const onError = () => ({ graphQLErrors }: any) => {
    const errors: string[] = [];
    graphQLErrors.forEach((graphQLError: any) => {
      errors.push(graphQLError.message);
    });
    setErrors(errors);
  };

  const faultCodes: any[] = [];
  projectSite.projectSiteClassifications.forEach((psc: any) => {
    const code: any = {
      label: `${psc.classification.industry.name} - ${psc.classification.name} - ${psc.classification.description}`,
    };
    let noCollabs = false;

    if (!!psc.classification.candidacies.length) {
      let agencies: any[] = [];
      for (const candidacy of psc.classification.candidacies) {
        if (candidacy.ability.scheme.id !== project.scheme.id) {
          noCollabs = true;
        } else {
          if (candidacy.ability.stakeholder.agency.id === project.agency.id) {
            noCollabs = false;
            agencies = [];
            break;
          } else agencies.push(candidacy.ability.stakeholder.agency.name);
        }
      }
      if (!noCollabs && !!agencies.length)
        faultCodes.push({ ...code, type: "warning", append: `[${agencies.join(" / ")}]` });
    } else noCollabs = true;
    if (noCollabs) faultCodes.push({ ...code, type: "danger", append: "[No Auditors]" });
  });

  const risk =
    projectSite.filled && project.mandays[0]?.risk
      ? project.mandays[0].tips.find((o: any) => o.siteId === projectSite.site.id)?.risk.toUpperCase()
      : null;

  return (
    <Fragment>
      <Row gutter={[24, 5]}>
        <Col flex={1}>
          <Row gutter={[24, 5]}>
            <Col span={24}>
              <Space>
                {projectSite.site?.isHead && <Tag color="blue">HEAD</Tag>}
                {projectSite.site?.fullAddress || `Site ${i + 1}`}
              </Space>
            </Col>
            <Col span={24}>
              <Text type="secondary">
                <Space size="large">
                  <span>{projectSite.projectSiteClassifications?.length} classifications</span>
                  <span>{projectSite.employees ?? 0} employees</span>
                  <span>{projectSite.employeesEffective ?? 0} effective employees</span>
                  {risk && <Tag color="warning">{risk} Risk Level</Tag>}
                </Space>
              </Text>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Text type="secondary">
                <span>{projectSite.projectSiteClassifications?.map((c: any) => c.classification.name).join(", ")}</span>
              </Text>
            </Col>
            {projectSite.differentScope && (
              <Col span={24}>
                <Text type="secondary">
                  Scope: <span>{projectSite.differentScope}</span>
                </Text>
              </Col>
            )}
          </Row>
        </Col>
        {editable && (
          <Col>
            <Button type={!projectSite.filled ? "primary" : "ghost"} onClick={() => setDrawerVisible(true)}>
              {!projectSite.filled ? "Fill" : "Edit"}
            </Button>
          </Col>
        )}
        <Col span={24}>
          {faultCodes.map((code: any, i: any) => (
            <Text key={i} type={code.type}>
              <Space>
                {code.label} {code.append}
              </Space>
              <br />
            </Text>
          ))}
        </Col>
        {i < project.projectSites.length - 1 && <Divider />}
      </Row>
      <Mutation
        onError={onError}
        onCompleted={() => {
          setDrawerVisible(false);
        }}
      >
        {(loading, mutate) => (
          <Drawer
            title={title}
            width={680}
            onClose={onClose}
            visible={drawerVisible}
            keyboard={false}
            maskClosable={false}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <Button onClick={onClose} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button type="primary" loading={loading} onClick={() => form.submit()}>
                  Save
                </Button>
              </div>
            }
          >
            <PwsProjectSiteForm
              form={form}
              project={project}
              projectSite={projectSite}
              onFinish={onFinish(mutate, params, variables)}
              errors={errors}
            />
          </Drawer>
        )}
      </Mutation>
    </Fragment>
  );
};
