export const Logo: React.FC<{ style?: any }> = (props) => {
  return (
    <svg viewBox="26 21 68 77" style={props.style}>
      <g>
        <path
          fill="#9D9D9C"
          d="M61.295 22.9821C60.8556 22.3618 60.1578 22 59.4083 22C58.6589 22 57.9869 22.3877 57.6251 23.0079L27.3101 75.2128C26.9483 75.8589 26.8966 76.66 27.2843 77.332C27.6978 78.0556 28.4472 78.495 29.2484 78.495C29.2484 78.495 37.0533 78.5467 37.3376 78.4433C38.2163 78.2624 38.9657 77.7196 39.4051 76.9702L65.1457 32.7769C65.766 31.7173 65.6884 30.3993 64.9648 29.3397L61.295 22.9821Z"
        />
        <path
          fill="#9D9D9C"
          d="M75.2243 53.2195C75.6636 52.5992 76.3614 52.2374 77.1109 52.2374C77.8604 52.2374 78.5323 52.6251 78.8941 53.2453L93.0308 77.6938C93.3926 78.3399 93.4443 79.141 93.0567 79.813C92.6431 80.5366 91.8937 80.9759 91.0925 80.9759C91.0925 80.9759 83.2876 81.0276 83.0033 80.9243C82.1246 80.7434 81.3752 80.2006 80.9358 79.4512L71.3735 63.0144C70.7533 61.9548 70.8308 60.6367 71.5544 59.5771L75.2243 53.2195Z"
        />
        <path
          fill="#A5CB6B"
          d="M73.8546 44.3809L70.2106 38.075C69.7712 37.4547 69.0734 37.0929 68.3239 37.0929C67.5745 37.0929 66.9025 37.4806 66.5407 38.1008L40.4383 83.0177L39.3528 81.9581C38.8618 81.4929 38.164 81.0018 37.4921 81.0018H28.2141C27.0769 81.0277 26.5859 82.6817 27.4129 83.4829L39.4562 95.8363C39.7922 96.1981 40.2057 96.4824 40.645 96.6633C42.9451 97.542 45.6071 96.6633 46.8993 94.4666L69.9263 54.9769C70.0038 54.8477 70.0555 54.7185 70.1072 54.5893L74.0096 47.8698C74.6299 46.7585 74.5782 45.4405 73.8546 44.3809Z"
        />
      </g>
    </svg>
  );
};
