import { Badge, Box, Button, HStack, Stack, Text, Tooltip } from "@chakra-ui/react";

import { Card } from "../ui/Card";
import { RiFilePaper2Line } from "react-icons/ri";

export const Project: React.FC<{}> = () => {
  return (
    <Card
      title="ITA-12345-1-QMS"
      actions={
        <HStack>
          <Tooltip hasArrow label="Download certificate" placement="left">
            <Button px="0" variant="outline" colorScheme="blue" aria-label="Download">
              <RiFilePaper2Line />
            </Button>
          </Tooltip>
          <Button colorScheme="blue" aria-label="View">
            View
          </Button>
        </HStack>
      }
    >
      <Stack w="full" spacing="4">
        <HStack justify="space-between">
          <HStack>
            <Badge colorScheme="blue">SYSTEM</Badge>
            <Badge colorScheme="green">FIRST CERTIFICATION</Badge>
          </HStack>
        </HStack>
        <HStack w="full" justify="space-between">
          <Box>
            <Text>5 sites with sampling - 25 classifications - 13% reductions</Text>
          </Box>
          <Box>
            <Text>Pws: 2 of 15 activities, 7 reviews</Text>
          </Box>
        </HStack>
        <HStack w="full" justify="space-between">
          <Box>
            <Text>ISO 9001 - UKAS</Text>
          </Box>
          <Box>
            <Text>Alfonso Pagliuca 15/10/2020 11:33 - Khairul Bashar 28/10/2020 10:33</Text>
          </Box>
        </HStack>
      </Stack>
    </Card>
  );
};
