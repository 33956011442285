import { ActivityItemQueryProvider } from "__legacy/queries/activities/ActivityItemQueryProvider";
import { UpdateActivityItemMutationProvider } from "__legacy/queries/activities/UpdateActivityItemMutationProvider";
import { Button, Form, Layout, PageHeader } from "antd";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ItemForm, onError, onFinish } from "./ItemForm";

export const Edit: React.FC<{}> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <ActivityItemQueryProvider variables={{ id }}>
      {({ activityItem }) => (
        <UpdateActivityItemMutationProvider onError={onError(form, setErrors)} onCompleted={() => navigate("../..")}>
          {(loading, mutate) => (
            <Fragment>
              <PageHeader
                ghost={true}
                title={`Edit Item ${activityItem.name}`}
                extra={
                  <Fragment>
                    <Button onClick={() => navigate("../..")}>Back to list</Button>
                    <Button type="primary" loading={loading} onClick={() => form.submit()}>
                      Save
                    </Button>
                  </Fragment>
                }
              />
              <Layout.Content className="content">
                <ItemForm form={form} handler={onFinish(mutate, {}, { id })} values={activityItem} errors={errors} />
              </Layout.Content>
            </Fragment>
          )}
        </UpdateActivityItemMutationProvider>
      )}
    </ActivityItemQueryProvider>
  );
};
