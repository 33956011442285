import { Button, HStack, Stack, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";

import { Card } from "../ui/Card";
import { Events } from "../ui/Events";
import { FormDrawer } from "../ui/FormDrawer";
import { Notes } from "../ui/Notes";
import { Activities } from "./Activities";
import { Assessment } from "./Assessment";
import { Certification } from "./Certification";
import { Customer } from "./Customer";
import { MainAudit } from "./MainAudit";
import { Reductions } from "./Reductions";
import { Sites } from "./Sites";
import { Summary } from "./Summary";
import { Surveillance } from "./Surveillance";
import { Transfer } from "./Transfer";

export const Show: React.FC<{}> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formTitle, setFormTitle] = useState();
  const [form, setForm] = useState();
  const openForm = (form: any, title: any) => {
    setForm(form);
    setFormTitle(title);
    onOpen();
  };

  return (
    <>
      <Summary />
      <HStack w="full" spacing="4" alignItems="start">
        <Stack w="75%" spacing="4">
          <Certification openForm={openForm} />
          <Customer openForm={openForm} />
          <Sites />
          <Transfer />
          <Assessment />
          <Reductions />
          <MainAudit />
          <Surveillance n="1" />
          <Card
            title="Draft (Agency view)"
            actions={
              <HStack>
                <Button colorScheme="red" variant="outline" aria-label="Lose">
                  Lose
                </Button>
                <Button colorScheme="blue" aria-label="Apply">
                  Apply
                </Button>
              </HStack>
            }
          />
          <Card
            title="Draft (Global view)"
            actions={
              <HStack>
                <Button colorScheme="red" variant="outline" aria-label="Lose">
                  Lose
                </Button>
                <Button colorScheme="red" aria-label="Dismiss">
                  Dismiss
                </Button>
                <Button colorScheme="blue" aria-label="Approve">
                  Approve
                </Button>
              </HStack>
            }
          />
        </Stack>
        <Stack w="25%" spacing="4">
          <Activities />
          <Notes />
          <Events />
        </Stack>
      </HStack>
      <FormDrawer isOpen={isOpen} onClose={onClose} title={formTitle}>
        {form}
      </FormDrawer>
    </>
  );
};
