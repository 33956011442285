import { useQuery } from "@apollo/client";
import { CenterResult } from "__legacy/components/CenterResult";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  ActivityStagesQuery as ActivityStagesQueryType,
  ActivityStagesQueryVariables,
} from "./__generated__/ActivityStagesQuery";

const ActivityStagesQuery = loader("./ActivityStagesQuery.gql");

type ActivityStagesQueryProviderType = (props: {
  children: (data: ActivityStagesQueryType) => ReactNode;
  variables?: ActivityStagesQueryVariables;
}) => ReactElement;

export const ActivityStagesQueryProvider: ActivityStagesQueryProviderType = (props) => {
  const { children, variables } = props;

  const { error, loading, data } = useQuery<ActivityStagesQueryType, ActivityStagesQueryVariables>(
    ActivityStagesQuery,
    {
      variables,
      fetchPolicy: "cache-and-network",
    },
  );

  if (loading) return <CenterSpinner size="large" />;
  if (error || !data)
    return <CenterResult status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;

  return <Fragment>{children(data)}</Fragment>;
};
