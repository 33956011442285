import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  UpdateVisitBookingMutation as UpdateVisitBookingMutationType,
  UpdateVisitBookingMutationVariables,
} from "./__generated__/UpdateVisitBookingMutation";

const UpdateVisitBookingMutation = loader("./UpdateVisitBookingMutation.gql");

type UpdateVisitBookingMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted?: (data: UpdateVisitBookingMutationType) => void;
}) => ReactElement;

export const UpdateVisitBookingMutationProvider: UpdateVisitBookingMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<UpdateVisitBookingMutationType, UpdateVisitBookingMutationVariables>(
    UpdateVisitBookingMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
