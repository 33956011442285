import { useQuery } from "@apollo/client";
import { CenterResult } from "__legacy/components/CenterResult";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  ActivityTemplatesQuery as ActivityTemplatesQueryType,
  ActivityTemplatesQueryVariables,
} from "./__generated__/ActivityTemplatesQuery";

const ActivityTemplatesQuery = loader("./ActivityTemplatesQuery.gql");

type ActivityTemplatesQueryProviderType = (props: {
  children: (data: ActivityTemplatesQueryType) => ReactNode;
  variables: ActivityTemplatesQueryVariables;
}) => ReactElement;

export const ActivityTemplatesQueryProvider: ActivityTemplatesQueryProviderType = (props) => {
  const { children, variables } = props;

  const { error, loading, data } = useQuery<ActivityTemplatesQueryType, ActivityTemplatesQueryVariables>(
    ActivityTemplatesQuery,
    {
      variables,
      fetchPolicy: "cache-and-network",
    },
  );

  if (loading) return <CenterSpinner size="large" />;
  if (error || !data)
    return <CenterResult status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;

  return <Fragment>{children(data)}</Fragment>;
};
