import { Box, HStack, Text } from "@chakra-ui/react";

type CardFieldType = {
  label?: string;
  value?: any;
};

export const CardField: React.FC<CardFieldType> = (props) => {
  const { label, value } = props;
  return (
    <Box>
      <HStack alignItems="baseline">
        {label && (
          <Text fontSize="sm" fontWeight="600" color="coolGray.400">
            {label}:
          </Text>
        )}
        <Box>{value}</Box>
      </HStack>
    </Box>
  );
};
