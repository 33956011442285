import { HStack, Stack } from "@chakra-ui/react";
import React from "react";

import { CustomerSubTabs } from "./CustomerSubTabs";
import { CardButton } from "../ui/CardButton";
import { Events } from "../ui/Events";
import { Notes } from "../ui/Notes";
import { Site } from "./Site";
import { Summary } from "./Summary";
import { HiPlus } from "react-icons/hi";

export const CustomerSites: React.FC<{}> = () => {
  return (
    <>
      <Summary />

      <Stack bg="white" boxShadow="sm" w="full" px="6">
        <CustomerSubTabs />
      </Stack>
      <HStack w="full" spacing="4" alignItems="start">
        <Stack w="75%" spacing="4">
          <Site />
        </Stack>
        <Stack w="25%" spacing="4">
          <CardButton title="New Site" colorScheme="blue" leftIcon={<HiPlus />} />
          <Notes />
          <Events />
        </Stack>
      </HStack>
    </>
  );
};
