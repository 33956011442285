import { Flex, VStack } from "@chakra-ui/react";

import { Footer } from "./Footer";

export const MainContent: React.FC<{}> = (props) => {
  const { children } = props;
  return (
    <Flex ml="72">
      <VStack spacing="4" w="full" p="6" align="left">
        {children}
        <Footer />
      </VStack>
    </Flex>
  );
};
