import { UpdateIncreasesMutationProvider } from "__legacy/queries/projects/UpdateIncreasesMutationProvider";
import { Button, Card, Col, Drawer, Form, Row, Space } from "antd";
import { Fragment, useState } from "react";
import { useParams } from "react-router";

import { onFinish, PwsIncreasesForm } from "./PwsIncreasesForm";

type PwsIncreasesCardType = {
  project: any;
  editable: boolean;
};

export const PwsIncreasesCard: React.FC<PwsIncreasesCardType> = (props) => {
  const { project, editable } = props;
  const { id } = useParams();
  const title = "Increases";
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  const [drawerVisible, setDrawerVisible] = useState(false);
  const onClose = () => setDrawerVisible(false);

  const [Mutation, params, variables] = [UpdateIncreasesMutationProvider, {}, { id }];

  const onError = () => ({ graphQLErrors }: any) => {
    const errors: string[] = [];
    graphQLErrors.forEach((graphQLError: any) => {
      errors.push(graphQLError.message);
    });
    setErrors(errors);
  };

  const filled = project.increasesFilled;

  return (
    <Fragment>
      <Card
        title={title}
        bodyStyle={filled ? {} : { display: "none" }}
        extra={
          editable && (
            <Button type={filled ? "ghost" : "primary"} onClick={() => setDrawerVisible(true)}>
              {filled ? "Edit" : "Fill"}
            </Button>
          )
        }
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            Other:{" "}
            {project.increasesOther && (
              <b>
                <Space>
                  <span>{project.increasesPercentage}%</span>
                  {project.increasesInfo}
                </Space>
              </b>
            )}
            {!project.increasesOther && <b>No</b>}
          </Col>
        </Row>
      </Card>
      <Mutation onError={onError}>
        {(loading, mutate) => (
          <Drawer
            title={title}
            width={680}
            onClose={onClose}
            visible={drawerVisible}
            keyboard={false}
            maskClosable={false}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <Button onClick={onClose} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button type="primary" loading={loading} onClick={() => form.submit()}>
                  Save
                </Button>
              </div>
            }
          >
            <PwsIncreasesForm
              form={form}
              project={project}
              onFinish={onFinish(mutate, params, variables)}
              errors={errors}
            />
          </Drawer>
        )}
      </Mutation>
    </Fragment>
  );
};
