import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import { UpdateCarMutation as UpdateCarMutationType, UpdateCarMutationVariables } from "./__generated__/UpdateCarMutation";

const UpdateCarMutation = loader("./UpdateCarMutation.gql");

type UpdateCarMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted?: (data: UpdateCarMutationType) => void;
}) => ReactElement;

export const UpdateCarMutationProvider: UpdateCarMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<UpdateCarMutationType, UpdateCarMutationVariables>(UpdateCarMutation, {
    errorPolicy: "none",
    onError,
    onCompleted,
  });

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
