import { ClassificationQueryProvider } from "__legacy/queries/classifications/ClassificationQueryProvider";
import { UpdateClassificationMutationProvider } from "__legacy/queries/classifications/UpdateClassificationMutationProvider";
import { IndustryQueryProvider } from "__legacy/queries/industries/IndustryQueryProvider";
import { Button, Form, Layout, PageHeader } from "antd";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CodeForm, onError, onFinish } from "./CodeForm";

export const Edit: React.FC<{}> = () => {
  const { groupId, id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <IndustryQueryProvider variables={{ id: groupId }}>
      {({ industry }) => (
        <ClassificationQueryProvider variables={{ id: id }}>
          {({ classification }) => (
            <UpdateClassificationMutationProvider
              onError={onError(form, setErrors)}
              onCompleted={() => navigate("../../../")}
            >
              {(loading, mutate) => (
                <Fragment>
                  <PageHeader
                    ghost={true}
                    title={`Edit classification code ${classification.name} for ${industry.name}`}
                    extra={
                      <Fragment>
                        <Button onClick={() => navigate("../../../../")}>Back to list</Button>
                        <Button onClick={() => navigate("../../../")}>Back to agency</Button>
                        <Button type="primary" loading={loading} onClick={() => form.submit()}>
                          Save
                        </Button>
                      </Fragment>
                    }
                  />
                  <Layout.Content className="content">
                    <CodeForm
                      action="update"
                      form={form}
                      handler={onFinish(mutate, {}, { id })}
                      values={classification}
                      errors={errors}
                    />
                  </Layout.Content>
                </Fragment>
              )}
            </UpdateClassificationMutationProvider>
          )}
        </ClassificationQueryProvider>
      )}
    </IndustryQueryProvider>
  );
};
