import { Alert, Col, Form, Input, InputNumber, Row, Space, Switch } from "antd";
import { FormInstance } from "antd/lib/form";
import { toNumber } from "lodash";

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const input = {
    ...values,
    multiStandardPercentage: toNumber(values.multiStandardPercentage),
    ...params,
  };
  mutate({ variables: { ...variables, input } });
};

export const PwsMultiStandardForm: React.FC<{
  form: FormInstance<any>;
  onFinish: (values: any) => void;
  project: any;
  errors: string[];
}> = (props) => {
  const { form, project, onFinish, errors } = props;

  return (
    <Form layout="vertical" form={form} initialValues={project} onFinish={onFinish}>
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Row gutter={[24, 0]}>
        <Col span={5}>
          <Form.Item name="multiStandard" label="Reduction" valuePropName={"checked"}>
            <Switch />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Percentage" dependencies={["multiStandard"]}>
            {(form: FormInstance) => (
              <Form.Item name="multiStandardPercentage">
                <InputNumber
                  min={0}
                  step={5}
                  max={20}
                  formatter={(value) => (value ? `${value}%` : "")}
                  disabled={!form.getFieldValue("multiStandard")}
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
        <Col span={15}>
          <Form.Item label="Reason" dependencies={["multiStandard"]}>
            {(form: FormInstance) => (
              <Form.Item name="multiStandardReason">
                <Input.TextArea rows={3} disabled={!form.getFieldValue("multiStandard")} />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
