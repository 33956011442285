import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { StakeholderKind } from "__legacy/queries/__generated__/globalTypes";
import { StakeholdersQueryProvider } from "__legacy/queries/stakeholders/StakeholdersQueryProvider";
import { Button, Image, Input, Layout, PageHeader, Table, Tabs } from "antd";
import { Fragment, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";

export const List: React.FC<{}> = () => {
  const { agencyId } = useParams();
  const navigate = useNavigate();
  const [activePane, setActivePane] = useState<StakeholderKind>();

  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const handleSearch = (value: string) => setSearchQuery(value.trim());

  const columns = [
    {
      title: "Avatar",
      dataIndex: "avatar",
      render: (value: string) => <Image src={value} width={50} />,
    },

    {
      title: "Business name",
      dataIndex: "companyName",
      render: (value: string, resource: any) => <NavLink to={resource.id}>{value}</NavLink>,
    },
    {
      title: "Fullname",
      dataIndex: "id",
      render: (_value: string, resource: any) => (
        <NavLink to={resource.id}>
          {resource.firstname} {resource.lastname}
        </NavLink>
      ),
    },
    {
      title: "Country",
      dataIndex: "country",
    },
    {
      width: "80px",
      title: "Actions",
      dataIndex: "id",
      render: (id: string) => (
        <Button onClick={() => navigate(`${id}/edit`)}>
          <EditOutlined />
        </Button>
      ),
    },
  ];

  return (
    <StakeholdersQueryProvider variables={{ agencyId, kind: activePane, search: searchQuery }}>
      {({ stakeholders }) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title="Stakeholders"
            extra={
              <Fragment>
                <Input.Search
                  allowClear
                  placeholder="search"
                  onSearch={handleSearch}
                  defaultValue={searchQuery ?? ""}
                  style={{ width: 500 }}
                />
                <Button onClick={() => navigate("new")} type="primary">
                  <PlusOutlined />
                  New
                </Button>
              </Fragment>
            }
            footer={
              <Tabs
                activeKey={activePane}
                onChange={(activeKey: any) => setActivePane(activeKey !== "all" ? activeKey : undefined)}
              >
                <Tabs.TabPane tab="All" key="all" />
                <Tabs.TabPane tab="Customers" key="customer" />
                <Tabs.TabPane tab="Suppliers" key="supplier" />
                <Tabs.TabPane tab="Auditors" key="collaborator" />
              </Tabs>
            }
          />
          <Layout.Content className="content">
            <Table rowKey="id" columns={columns} dataSource={stakeholders} pagination={false} />
          </Layout.Content>
        </Fragment>
      )}
    </StakeholdersQueryProvider>
  );
};
