import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  ReviewVisitMutation as ReviewVisitMutationType,
  ReviewVisitMutationVariables,
} from "./__generated__/ReviewVisitMutation";

const ReviewVisitMutation = loader("./ReviewVisitMutation.gql");

type ReviewVisitMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted: (data: ReviewVisitMutationType) => void;
}) => ReactElement;

export const ReviewVisitMutationProvider: ReviewVisitMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<ReviewVisitMutationType, ReviewVisitMutationVariables>(
    ReviewVisitMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
      refetchQueries: ["AbilitiesQuery"],
      awaitRefetchQueries: true,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
