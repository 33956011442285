import { Reviews } from "__legacy/frames/shareds/collaborators/Reviews";
import { AbilityQueryProvider } from "__legacy/queries/abilities/AbilityQueryProvider";
import { ReviewAbilityMutationProvider } from "__legacy/queries/abilities/ReviewAbilityMutationProvider";
import { AbilityKindsQueryProvider } from "__legacy/queries/enums/AbilityKindsQueryProvider";
import { AbilityLevelsQueryProvider } from "__legacy/queries/enums/AbilityLevelsQueryProvider";
import { ReviewStatusesQueryProvider } from "__legacy/queries/enums/ReviewStatusesQueryProvider";
import { Button, Card, Divider, Form, Layout, PageHeader, Space, Typography } from "antd";
import { find } from "lodash";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { onError, onFinish, ReviewForm } from "../../../shareds/reviews/ReviewForm";

export const Review: React.FC<{}> = () => {
  const { abilityId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <AbilityQueryProvider variables={{ id: abilityId }}>
      {({ ability }) => (
        <ReviewAbilityMutationProvider onError={onError(form, setErrors)} onCompleted={() => navigate("../../")}>
          {(loading, mutate) => (
            <Fragment>
              <PageHeader
                ghost={true}
                title={`Review ability ${ability.scheme.name} for ${ability.stakeholder.companyName}`}
                extra={
                  <Fragment>
                    <Button onClick={() => navigate("../../")}>Back to list</Button>
                    <Button type="primary" loading={loading} onClick={() => form.submit()}>
                      Save
                    </Button>
                  </Fragment>
                }
              />
              <Layout.Content className="content">
                <Card>
                  <ReviewStatusesQueryProvider>
                    {({ reviewStatuses }) => (
                      <AbilityLevelsQueryProvider>
                        {({ abilityLevels }) => (
                          <AbilityKindsQueryProvider>
                            {({ abilityKinds }) => (
                              <Space direction="vertical">
                                <div>
                                  <strong>Status: </strong>
                                  {
                                    find(reviewStatuses?.enumValues ?? [], ({ name }) => name === ability.status)
                                      ?.description
                                  }
                                </div>
                                <div>
                                  <strong>Level: </strong>
                                  {
                                    find(abilityLevels?.enumValues ?? [], ({ name }) => name === ability.level)
                                      ?.description
                                  }
                                </div>
                                <div>
                                  <strong>Course type: </strong>
                                  {
                                    find(abilityKinds?.enumValues ?? [], ({ name }) => name === ability.kind)
                                      ?.description
                                  }
                                </div>
                                <div>
                                  <strong>Course date: </strong>
                                  {ability.journeyDate}
                                </div>
                                <div>
                                  <strong>Validation date: </strong>
                                  {ability.startDate}
                                </div>
                                <div>
                                  <strong>Due date: </strong>
                                  {ability.endDate}
                                </div>
                                {ability.certificate && (
                                  <div>
                                    <strong>Attachment: </strong>
                                    <a href={ability.certificate} download>
                                      Download
                                    </a>
                                  </div>
                                )}
                              </Space>
                            )}
                          </AbilityKindsQueryProvider>
                        )}
                      </AbilityLevelsQueryProvider>
                    )}
                  </ReviewStatusesQueryProvider>
                  <Divider />
                  <Reviews reviews={ability.abilityReviews} />
                  <Divider />
                  <Typography.Title level={4}>New Review</Typography.Title>
                  <ReviewForm
                    action="review"
                    form={form}
                    handler={onFinish(mutate, {}, { id: abilityId })}
                    values={{}}
                    errors={errors}
                    questions={[
                      {
                        id: 10,
                        ask:
                          "Auditor does not meet relevant specified pre-qualification criteria as per Briefing Notes",
                        opts: "check",
                        depends: "status",
                        if: "need_correction",
                      },
                      {
                        id: 20,
                        ask: "Auditor technical submission does not demonstrate required level of knowledge",
                        opts: "check",
                        depends: "status",
                        if: "need_correction",
                      },
                      {
                        id: 30,
                        ask:
                          "Auditor technical submission includes clear cut/paste/copy statements from either other already approved submissions or from technical reference works",
                        opts: "check",
                        depends: "status",
                        if: "need_correction",
                      },
                      {
                        id: 40,
                        ask: "Submission does not include all of the required supporting documents",
                        opts: "check",
                        depends: "status",
                        if: "need_correction",
                      },
                    ]}
                  />
                </Card>
              </Layout.Content>
            </Fragment>
          )}
        </ReviewAbilityMutationProvider>
      )}
    </AbilityQueryProvider>
  );
};
