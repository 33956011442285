import { DeleteOutlined, EditOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { useApplicationContext } from "__legacy/components/Application";
import { UserQueryProvider } from "__legacy/queries/users/UserQueryProvider";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Layout,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";

const deleteEmploymentMutation = gql`
  mutation($id: ID!) {
    deleteEmployment(id: $id) {
      employment {
        id
        kind
        agency {
          name
        }
      }
    }
  }
`;

export const Show: React.FC<{}> = () => {
  const { id } = useParams();
  const { session } = useApplicationContext();
  const navigate = useNavigate();

  const [execDelete] = useMutation(deleteEmploymentMutation, {
    errorPolicy: "none",
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
    onCompleted: ({ deleteEmployment: { employment } }) => {
      const name = employment.agency?.name ?? "GLOBAL";
      notification.success({
        message: `Employment "${name}" deleted`,
      });
    },
    refetchQueries: ["UserQuery"],
  });

  return (
    <UserQueryProvider variables={{ id }}>
      {({ user }) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title={`User ${user.email}`}
            extra={
              <Fragment>
                <Button onClick={() => navigate("../")}>Back to list</Button>
                <Button type="primary" onClick={() => navigate("./edit")}>
                  Edit
                </Button>
              </Fragment>
            }
          />
          <Layout.Content className="content">
            <Card>
              <Row gutter={[16, 16]} justify="space-between">
                <Col>
                  <Typography.Title level={2}>{user.email}</Typography.Title>
                  <Typography.Text>{user.isAdmin ? "Admin" : "Not admin"}</Typography.Text>
                  <Typography.Title level={4}>Authorization</Typography.Title>
                  <Checkbox disabled checked={user.authorization.canManageUsers}>
                    Manage Users
                  </Checkbox>
                  <Checkbox disabled checked={user.authorization.canManageRoles}>
                    Manage Roles
                  </Checkbox>
                  <Checkbox disabled checked={user.authorization.canManageAgencies}>
                    Manage Agencies
                  </Checkbox>
                  <Checkbox disabled checked={user.authorization.canManageClassifications}>
                    Manage Classifications
                  </Checkbox>
                  <Checkbox disabled checked={user.authorization.canManageCertifications}>
                    Manage Certifications
                  </Checkbox>
                  <Checkbox disabled checked={user.authorization.canManageProjects}>
                    Manage Projects
                  </Checkbox>
                </Col>
              </Row>
              <Divider />
              <Row gutter={[16, 16]} justify="space-between">
                <Col>
                  <Typography.Title level={3}>Employments</Typography.Title>
                </Col>
                <Col>
                  <Button onClick={() => navigate("./employments/new")} type="primary">
                    <PlusOutlined />
                    New
                  </Button>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                {user.employments.map((employment) => {
                  let authorizations = [];
                  if (employment.authorization.canManageProjects === true) authorizations.push("Manage Projects");
                  if (employment.authorization.canManageEmployees === true) authorizations.push("Manage Employees");
                  if (employment.authorization.canManageStakeholders === true)
                    authorizations.push("Manage Stakeholders");
                  if (employment.authorization.canCreateCollaborators === true)
                    authorizations.push("Create Collaborators");

                  const actions = [<EditOutlined onClick={() => navigate(`./employments/${employment.id}/edit`)} />];
                  if (session.currentUser.isAdmin)
                    actions.push(
                      <Popconfirm
                        title="Are you sure?"
                        icon={<QuestionCircleOutlined />}
                        onConfirm={() => execDelete({ variables: { id: employment.id } })}
                      >
                        <Tooltip title="Delete" placement="bottom">
                          <DeleteOutlined style={{ color: "#ff4d4f" }} />
                        </Tooltip>
                      </Popconfirm>,
                    );
                  return (
                    <Col key={employment.id} span={8}>
                      <Card title={employment.agency?.name ?? "GLOBAL"} actions={actions}>
                        <div style={{ minHeight: "100px" }}>
                          {employment.kind === "global" && (
                            <Fragment>
                              <Typography.Title level={5} type="secondary">
                                Authorities
                              </Typography.Title>
                              {employment.authorities?.map((a: any) => a.name).join(", ")}
                            </Fragment>
                          )}
                          <Typography.Title level={5} type="secondary">
                            Authorizations
                          </Typography.Title>
                          {(!!authorizations.length && authorizations.join(", ")) || "none"}
                          <Typography.Title level={5} type="secondary">
                            Working Hours
                          </Typography.Title>
                          <Space direction="vertical">
                            <span>Monday: {employment.mondayHours}</span>
                            <span>Tuesday: {employment.tuesdayHours}</span>
                            <span>Wednesday: {employment.wednesdayHours}</span>
                            <span>Thursday: {employment.thursdayHours}</span>
                            <span>Friday: {employment.fridayHours}</span>
                            <span>Saturday: {employment.saturdayHours}</span>
                            <span>Sunday: {employment.sundayHours}</span>
                          </Space>
                        </div>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </Card>
          </Layout.Content>
        </Fragment>
      )}
    </UserQueryProvider>
  );
};
