import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  CreateSiteMutation as CreateSiteMutationType,
  CreateSiteMutationVariables,
} from "./__generated__/CreateSiteMutation";

const CreateSiteMutation = loader("./CreateSiteMutation.gql");

type CreateSiteMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted: (data: CreateSiteMutationType) => void;
}) => ReactElement;

export const CreateSiteMutationProvider: CreateSiteMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<CreateSiteMutationType, CreateSiteMutationVariables>(CreateSiteMutation, {
    errorPolicy: "none",
    onError,
    onCompleted,
    refetchQueries: ["GetContextQuery"],
    awaitRefetchQueries: true,
  });

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
