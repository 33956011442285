import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  CreateAbsenceMutation as CreateAbsenceMutationType,
  CreateAbsenceMutationVariables,
} from "./__generated__/CreateAbsenceMutation";

const CreateAbsenceMutation = loader("./CreateAbsenceMutation.gql");

type CreateAbsenceMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError?: (error: ApolloError) => void;
  onCompleted?: (data: CreateAbsenceMutationType) => void;
}) => ReactElement;

export const CreateAbsenceMutationProvider: CreateAbsenceMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<CreateAbsenceMutationType, CreateAbsenceMutationVariables>(
    CreateAbsenceMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
      refetchQueries: ["GetContextQuery"],
      awaitRefetchQueries: true,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
