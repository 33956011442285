import { Link, HStack, Text } from "@chakra-ui/react";

type FooterType = {};

export const Footer: React.FC<FooterType> = () => {
  return (
    <HStack w="full" justify="center" pt="6" spacing="1" fontSize="xs" color="coolGray.600">
      <Text>AJA EUROPE © 2020 | Made by</Text>
      <Link fontWeight="700" color="blue.500">
        AJA
      </Link>
      <Text> | v0.1</Text>
      <Text>| </Text>
      <Text fontWeight="bold" color="violet.500">
        DEVELOPMENT
      </Text>
      <Text fontWeight="bold" color="amber.500">
        STAGING
      </Text>
    </HStack>
  );
};
