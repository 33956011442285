import { Space, Timeline, Typography } from "antd";
import moment from "moment";
import { Fragment } from "react";

export const Reviews: React.FC<{ reviews: any[] }> = (props) => {
  const { reviews } = props;

  return (
    <Fragment>
      <Typography.Title level={4}>Reviews History</Typography.Title>
      <Timeline mode="left">
        {reviews.map((review) => (
          <Timeline.Item key={review.id} label={`${moment(review.insertedAt).format("MM-DD-YYYY HH:mm")}`}>
            <Space direction="vertical">
              {review.content?.split("\n").map((s: any, i: any) => (
                <div key={i}>{s}</div>
              ))}
              {review.questions?.map((question: any, i: any) => (
                <Fragment key={i}>
                  {question.reply && (
                    <p>
                      {question.ask} <b>{question.reply}</b>
                    </p>
                  )}
                </Fragment>
              ))}
              {review.attachment && (
                <a href={review.attachment} download>
                  Download
                </a>
              )}
            </Space>
          </Timeline.Item>
        ))}
      </Timeline>
    </Fragment>
  );
};
