import { LocaleCountriesQueryProvider } from "__legacy/queries/enums/LocaleCountriesQueryProvider";
import { Alert, Card, Col, Form, Input, Row, Select, Space, Switch } from "antd";
import { FormInstance } from "antd/lib/form";
import { isString, omit } from "lodash";

type SiteFormType = {
  action: string;
  form: FormInstance<any>;
  handler: (values: any) => void;
  values: any;
  errors: string[];
  sites: any[];
};

const fields = [
  ["name", "name"],
  ["address", "address"],
  ["city", "city"],
  ["zipCode", "zipCode"],
  ["state", "state"],
  ["country", "country"],
  ["notes", "notes"],
  ["isEnabled", "isEnabled"],
];

export const onError = (form: any, setErrors: any) => ({ graphQLErrors }: any) => {
  const fieldsErrors: any[] = [];
  const errors: string[] = [];
  graphQLErrors.forEach((graphQLError: any) => {
    let found = false;
    fields.forEach(([fieldName, errorFieldName]) => {
      if (graphQLError.name === errorFieldName) {
        fieldsErrors.push({ name: fieldName, errors: (graphQLError as any).messages });
        found = true;
      }
    });
    if (!found) {
      errors.push(graphQLError.message);
    }
  });
  form.setFields(fieldsErrors);
  setErrors(errors);
};

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const cleanValues = {
    ...omit(values, []),
    ...params,
  };

  mutate({ variables: { ...variables, input: cleanValues } });
};

export const SiteForm: React.FC<SiteFormType> = (props) => {
  const { action, form, handler, values, errors, sites } = props;

  console.log(`Agencies/Stakeholders/Sites/SiteForm action: ${action}`);

  const head = values.isHead || !sites.find((site: any) => site.isHead);

  return (
    <Card>
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Form layout="vertical" form={form} initialValues={values} onFinish={handler}>
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item name="name" label="Site's name" rules={[{ required: true, message: "is required" }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="address" label="Address">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="city" label="City">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="zipCode" label="Zip Code">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="state" label="State">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <LocaleCountriesQueryProvider>
              {({ localeCountries }) => (
                <Form.Item name="country" label="Country">
                  <Select
                    showSearch
                    optionLabelProp="label"
                    filterOption={(input, option) => {
                      let label = "";
                      if (option?.label && isString(option.label)) label = option.label;
                      let value = option?.value;
                      return (
                        label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {localeCountries?.enumValues?.map((localeCountry) => (
                      <Select.Option
                        key={localeCountry.name}
                        value={localeCountry.name}
                        label={localeCountry.description}
                      >
                        {localeCountry.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </LocaleCountriesQueryProvider>
          </Col>
          <Col span={8}>
            <Form.Item name="contactName" label="Contact Name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="phoneNumber" label="Phone">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="faxNumber" label="Fax">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="notes" label="Notes">
              <Input.TextArea />
            </Form.Item>
          </Col>
          {action === "update" && (
            <Col span={4}>
              <Form.Item name="isEnabled" label="Is enabled?" valuePropName={"checked"}>
                <Switch />
              </Form.Item>
            </Col>
          )}
          {head && (
            <Col span={4}>
              <Form.Item name="isHead" label="Is head?" valuePropName={"checked"}>
                <Switch disabled={values.isHead} />
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    </Card>
  );
};
