import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  RenderVisitMutation as RenderVisitMutationType,
  RenderVisitMutationVariables,
} from "./__generated__/RenderVisitMutation";

const RenderVisitMutation = loader("./RenderVisitMutation.gql");

type RenderVisitMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError?: (error: ApolloError) => void;
  onCompleted: (data: RenderVisitMutationType) => void;
}) => ReactElement;

export const RenderVisitMutationProvider: RenderVisitMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<RenderVisitMutationType, RenderVisitMutationVariables>(
    RenderVisitMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
