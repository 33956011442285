import { UsersQueryProvider } from "__legacy/queries/users/UsersQueryProvider";
import { Alert, Card, Checkbox, Col, Form, Row, Select, Space } from "antd";
import { FormInstance } from "antd/lib/form";
import { isString, omit } from "lodash";

type UserFormType = {
  action: string;
  form: FormInstance<any>;
  handler: (values: any) => void;
  values: any;
  errors: string[];
};

const fields = [["userId", "userId"]];

export const onError = (form: any, setErrors: any) => ({ graphQLErrors }: any) => {
  const fieldsErrors: any[] = [];
  const errors: string[] = [];
  graphQLErrors.forEach((graphQLError: any) => {
    let found = false;
    fields.forEach(([fieldName, errorFieldName]) => {
      if (graphQLError.name === errorFieldName) {
        fieldsErrors.push({ name: fieldName, errors: (graphQLError as any).messages });
        found = true;
      }
    });
    if (!found) {
      errors.push(graphQLError.message);
    }
  });
  // console.log(fieldsErrors);
  // console.log(errors);
  form.setFields(fieldsErrors);
  setErrors(errors);
};

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const input = {
    ...omit(values, []),
    ...params,
    kind: "agency",
    authorization: {
      canManageProjects: values.authorization?.canManageProjects ?? false,
      canManageEmployees: values.authorization?.canManageEmployees ?? false,
      canManageStakeholders: values.authorization?.canManageStakeholders ?? false,
      canCreateCollaborators: values.authorization?.canCreateCollaborators ?? false,
    },
  };

  mutate({ variables: { ...variables, input } });
};

export const UserForm: React.FC<UserFormType> = (props) => {
  const { action, form, handler, values, errors } = props;

  console.log(`Globals/Agencies/Users/UserForm action: ${action}`);

  return (
    <Card>
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Form layout="vertical" form={form} initialValues={values} onFinish={handler}>
        {action === "create" && (
          <Row gutter={[24, 0]}>
            <Col span={8}>
              <UsersQueryProvider variables={{}}>
                {({ users }) => (
                  <Form.Item name="userId" label="User" rules={[{ required: true, message: "is required" }]}>
                    <Select
                      showSearch
                      optionLabelProp="label"
                      filterOption={(input, option) => {
                        let label = "";
                        if (option?.label && isString(option.label)) label = option.label;
                        let value = option?.value;
                        return (
                          label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {users.map((user) => (
                        <Select.Option key={user.id} value={user.id} label={user.email}>
                          {user.email}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </UsersQueryProvider>
            </Col>
          </Row>
        )}
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item label="Authorizations">
              <Row>
                <Col span={8}>
                  <Form.Item name={["authorization", "canManageProjects"]} valuePropName={"checked"}>
                    <Checkbox>Manage Projects</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={["authorization", "canManageEmployees"]} valuePropName={"checked"}>
                    <Checkbox>Manage Employees</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={["authorization", "canManageStakeholders"]} valuePropName={"checked"}>
                    <Checkbox>Manage Stakeholders</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={["authorization", "canCreateCollaborators"]} valuePropName={"checked"}>
                    <Checkbox>Create Collaborators</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
