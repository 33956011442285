import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  UpdateSiteMutation as UpdateSiteMutationType,
  UpdateSiteMutationVariables,
} from "./__generated__/UpdateSiteMutation";

const UpdateSiteMutation = loader("./UpdateSiteMutation.gql");

type UpdateSiteMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted: (data: UpdateSiteMutationType) => void;
}) => ReactElement;

export const UpdateSiteMutationProvider: UpdateSiteMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<UpdateSiteMutationType, UpdateSiteMutationVariables>(UpdateSiteMutation, {
    errorPolicy: "none",
    onError,
    onCompleted,
  });

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
