import { useApplicationContext } from "__legacy/components/Application";
import { ActivityTemplatesQueryProvider } from "__legacy/queries/activities/ActivityTemplatesQueryProvider";
import {
  CreateProjectActivityItemsMutationProvider,
} from "__legacy/queries/projects/CreateProjectActivityItemsMutationProvider";
import {
  UpdateProjectActivityItemMutationProvider,
} from "__legacy/queries/projects/UpdateProjectActivityItemMutationProvider";
import { Alert, Button, Card, Col, Collapse, DatePicker, Drawer, Form, InputNumber, Row, Select, Switch } from "antd";
import { isString } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router";

import { PwsActivityItemRow } from "./PwsActivityItemRow";

type PwsContractActivitiesCardType = {
  project: any;
};

export const PwsContractActivitiesCard: React.FC<PwsContractActivitiesCardType> = (props) => {
  const { project } = props;
  const { agencyId } = useParams();
  const { session } = useApplicationContext();
  const [template, setTemplate] = useState<String>();
  const [item, setItem] = useState<any>();
  const [form] = Form.useForm();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const onClose = () => {
    setItem(undefined);
    setDrawerVisible(false);
  };
  const openForm = (item: any) => {
    const dependency = project.activityItems.find((i: any) => i.item.id === item.item.dependency?.id);
    const canBeDone = dependency ? dependency.done : true;
    setItem({
      ...item,
      doneOn: item.doneOn ? moment(item.doneOn, "YYYY-MM-DD HH:mm") : null,
      canBeDone,
      dependency,
    });
    form.resetFields();
    setDrawerVisible(true);
  };

  let stages: any[] = [];
  let unstaged: any[] = [];
  project.activityItems?.forEach((o: any) => {
    if (o.item.stage) {
      const stage = stages.find((s: any) => s.id === o.item.stage.id);
      if (!stage) stages.push({ id: o.item.stage.id, title: o.item.stage.description, items: [o] });
      else stage.items.push(o);
    } else unstaged.push(o);
  });

  const employmentId = session.currentUser.employments
    .filter((e: any) => e.kind === "agency")
    ?.find((e: any) => e.agency.id === agencyId)?.id;

  return (
    <Card>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <ActivityTemplatesQueryProvider variables={{ agencyId, kind: project.scheme.mandaysKind }}>
            {({ activityTemplates }) => (
              <Row gutter={[24, 12]}>
                <Col span={24}>Template</Col>
                <Col span={24}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    optionLabelProp="label"
                    onChange={(v) => setTemplate(v as String)}
                    filterOption={(input, option) => {
                      let label = "";
                      if (option?.label && isString(option.label)) label = option.label;
                      let value = option?.value;
                      return (
                        label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {activityTemplates.map((template) => (
                      <Select.Option key={template.id} value={template.id} label={template.fullName}>
                        {template.fullName}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                {template && (
                  <Col span={12}>
                    <CreateProjectActivityItemsMutationProvider>
                      {(loading, mutate) => (
                        <Button
                          onClick={() => mutate({ variables: { id: project.id, activityTemplateId: template } })}
                          loading={loading}
                        >
                          Create Activities from Template
                        </Button>
                      )}
                    </CreateProjectActivityItemsMutationProvider>
                  </Col>
                )}
              </Row>
            )}
          </ActivityTemplatesQueryProvider>
        </Col>
        <Col span={24}>
          <Row gutter={[24, 24]}>
            {!!stages.length && (
              <Col span={24}>
                <Collapse defaultActiveKey={[0, 1, 2]}>
                  {stages.map((stage: any, i: any) => (
                    <Collapse.Panel key={i} header={stage.title}>
                      {stage.items.map((item: any, i: any) => (
                        <PwsActivityItemRow key={item.id} item={item} i={i} openForm={openForm} />
                      ))}
                    </Collapse.Panel>
                  ))}
                </Collapse>
              </Col>
            )}
            {!!unstaged.length && (
              <Col span={24}>
                {unstaged.map((item: any, i: any) => (
                  <PwsActivityItemRow key={item.id} item={item} i={i} openForm={openForm} />
                ))}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <UpdateProjectActivityItemMutationProvider
        onCompleted={() => {
          setDrawerVisible(false);
        }}
      >
        {(loading, mutate) => (
          <Drawer
            title="Edit Activity Item"
            width={680}
            onClose={onClose}
            visible={drawerVisible}
            keyboard={false}
            maskClosable={false}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <Button onClick={onClose} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button type="primary" loading={loading} onClick={() => form.submit()}>
                  Save
                </Button>
              </div>
            }
          >
            <Form
              key={item}
              layout="vertical"
              form={form}
              initialValues={item}
              onValuesChange={(changed) => {
                if (changed.done === false) form.setFieldsValue({ doneOn: null });
              }}
              onFinish={(values: any) => mutate({ variables: { id: item.id, input: { ...values, employmentId } } })}
            >
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <b>Description</b>
                  <p>{item?.item.description}</p>
                </Col>
                <Col span={12}>
                  <b>Estimated Hours</b>
                  <p>{item?.item.estimatedHours}</p>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="effortHours"
                    label="Effort Hours"
                    rules={[{ required: true, message: "is required" }]}
                  >
                    <InputNumber min={0.25} step={0.25} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="done" label="Done?" valuePropName={"checked"}>
                    <Switch disabled={!item?.canBeDone} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="doneOn" label="Done On">
                    <DatePicker
                      mode="date"
                      showTime
                      format="MM-DD-YYYY HH:mm"
                      style={{ width: "100%" }}
                      disabled={!item?.canBeDone}
                      disabledDate={(d) => d > moment().endOf("day")}
                    />
                  </Form.Item>
                </Col>
                {!item?.canBeDone && item?.dependency && (
                  <Col span={24}>
                    <Alert
                      type="warning"
                      message={`You have to complete ${item.dependency.item.name} before doing this`}
                    />
                  </Col>
                )}
              </Row>
            </Form>
          </Drawer>
        )}
      </UpdateProjectActivityItemMutationProvider>
    </Card>
  );
};
