import { LockOutlined } from "@ant-design/icons";
import { ProjectSummary } from "__legacy/frames/shareds/projects/ProjectSummary";
import { PwsActivitiesCard } from "__legacy/frames/shareds/projects/PwsActivitiesCard";
import { PwsCertificationCard } from "__legacy/frames/shareds/projects/PwsCertificationCard";
import { Button, Card, Col, Layout, PageHeader, Row } from "antd";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

export const New: React.FC<{}> = () => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <PageHeader
        ghost={true}
        title={`New Project`}
        extra={
          <Fragment>
            <Button onClick={() => navigate("../dashboard")}>Back to dashboard</Button>
          </Fragment>
        }
      />
      <Layout.Content className="content">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <ProjectSummary />
          </Col>
          <Col span={18}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <PwsCertificationCard create editable />
              </Col>
              {["Sites", "Transfer", "Assessment", "Reductions", "Increases", "Multi Standard"].map(
                (title: any, i: any) => (
                  <Col key={i} span={24}>
                    <Card
                      title={title}
                      bodyStyle={{ display: "none" }}
                      extra={<Button icon={<LockOutlined />} disabled />}
                    />
                  </Col>
                ),
              )}
            </Row>
          </Col>
          <Col span={6}>
            <PwsActivitiesCard
              activities={[
                { id: "certification" },
                { id: "sites" },
                { id: "transfer" },
                { id: "assessment" },
                { id: "reductions" },
                { id: "increases" },
                { id: "multi_standard" },
              ]}
            />
          </Col>
        </Row>
      </Layout.Content>
    </Fragment>
  );
};
