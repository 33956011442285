import { StakeholderKind } from "__legacy/queries/__generated__/globalTypes";
import { AbilitiesQueryProvider } from "__legacy/queries/abilities/AbilitiesQueryProvider";
import { CandidaciesQueryProvider } from "__legacy/queries/candidacies/CandidaciesQueryProvider";
import { StakeholdersQueryProvider } from "__legacy/queries/stakeholders/StakeholdersQueryProvider";
import { Button, Card, Col, Layout, PageHeader, Row, Typography } from "antd";
import { uniqWith } from "lodash";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

export const Dash: React.FC<{}> = () => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <PageHeader ghost={true} title="Auditors" />
      <Layout.Content className="content">
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <AbilitiesQueryProvider variables={{ applicationsOnly: true, filterAuthorities: true }}>
              {({ abilities }) => (
                <Card title="Scheme Applications">
                  <div style={{ minHeight: "100px", textAlign: "center" }}>
                    <Typography.Title level={3} type="secondary">
                      You have <Typography.Text type="warning">{abilities.length}</Typography.Text> Auditor's scheme
                      applications pending.
                    </Typography.Title>
                    <Button type="primary" onClick={() => navigate("../schemeApplications")}>
                      Go to list
                    </Button>
                  </div>
                </Card>
              )}
            </AbilitiesQueryProvider>
          </Col>
          <Col span={8}>
            <CandidaciesQueryProvider variables={{ applicationsOnly: true, filterAuthorities: true }}>
              {({ candidacies }) => (
                <Card title="Code Applications">
                  <div style={{ minHeight: "100px", textAlign: "center" }}>
                    <Typography.Title level={3} type="secondary">
                      You have <Typography.Text type="warning">{candidacies.length}</Typography.Text> Auditor's code
                      applications pending.
                    </Typography.Title>
                    <Button type="primary" onClick={() => navigate("../codeApplications")}>
                      Go to list
                    </Button>
                  </div>
                </Card>
              )}
            </CandidaciesQueryProvider>
          </Col>
          <Col span={8}>
            <StakeholdersQueryProvider variables={{ kind: "collaborator" as StakeholderKind, filterAuthorities: true }}>
              {({ stakeholders }) => (
                <Card title="All Auditors">
                  <div style={{ minHeight: "100px", textAlign: "center" }}>
                    <Typography.Title level={3} type="secondary">
                      You have <Typography.Text type="warning">{stakeholders.length}</Typography.Text> Auditors from{" "}
                      <Typography.Text type="warning">
                        {uniqWith(stakeholders, (value, other) => value.agency.id === other.agency.id).length}
                      </Typography.Text>{" "}
                      Agencies.
                    </Typography.Title>
                    <Button type="primary" onClick={() => navigate("../allAuditors")}>
                      Go to list
                    </Button>
                  </div>
                </Card>
              )}
            </StakeholdersQueryProvider>
          </Col>
        </Row>
      </Layout.Content>
    </Fragment>
  );
};
