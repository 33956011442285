import { CloudUploadOutlined } from "@ant-design/icons";
import { ProjectNotesCard } from "__legacy/frames/shareds/projects/ProjectNotesCard";
import { PwsEventsCard } from "__legacy/frames/shareds/projects/PwsEventsCard";
import { Reviews } from "__legacy/frames/shareds/projects/Reviews";
import { ProjectQueryProvider } from "__legacy/queries/projects/ProjectQueryProvider";
import { ReviewProjectMutationProvider } from "__legacy/queries/projects/ReviewProjectMutationProvider";
import { Alert, Button, Card, Col, Collapse, Divider, Form, Input, Layout, PageHeader, Row, Select, Space } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { compact, isString, startCase } from "lodash";
import { Fragment, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ReviewQuestions } from "../../shareds/reviews/ReviewQuestions";
import { ProjectOverview } from "./ProjectOverview";

const fields = [
  ["action", "action"],
  ["content", "content"],
  ["attachment", "attachment"],
];

export const onError = (form: any, setErrors: any) => ({ graphQLErrors }: any) => {
  const fieldsErrors: any[] = [];
  const errors: string[] = [];
  graphQLErrors.forEach((graphQLError: any) => {
    let found = false;
    fields.forEach(([fieldName, errorFieldName]) => {
      if (graphQLError.name === errorFieldName) {
        fieldsErrors.push({ name: fieldName, errors: (graphQLError as any).messages });
        found = true;
      }
    });
    if (!found) {
      errors.push(graphQLError.message);
    }
  });
  form.setFields(fieldsErrors);
  setErrors(errors);
};

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const input = {
    ...values,
    ...params,
    attachment: values.attachment?.file ?? null,
    questions: compact(values.questions),
  };

  mutate({ variables: { ...variables, input } });
};

export const Review: React.FC<{}> = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  const valuesChangeHandler = useCallback(
    (changedValues, allValues) => {
      const fieldValues: any[] = [];
      if (changedValues.attachment && changedValues.attachment.file.status === "removed") {
        fieldValues.push({ name: ["attachment"], value: null });
      }
      form.setFields(fieldValues);
    },
    [form],
  );

  const uploadProps = {
    maxCount: 1,
    accept: ".pdf, .doc, .xls, .xlsx",
    beforeUpload: () => {
      return false;
    },
  };

  const questions: any = {
    application: [
      {
        id: 0,
        note:
          "Reviewers also need to consider the following:\n1.   Check the client information on websites (positive & negative)\n2.   Complelixity of the process (may have an impact on mandays).\n3.  Translator required, safety concerns or other potential problems that may require additional audit time\n4.  From the Scope description, Is it possible to lower the risk level?\n5. Are there any potential conflicts of interest?\n6. Correct surveillance frequency.\n7. Audit time should be rounded up or down nearest 0.5 days.\n8.  It is unlikely that a recertification audit will be less than one (1) audit day",
      },
      {
        id: 10,
        ask: "Is the scope detailed on Questionnaire and adequately detailed to justify allocated code or codes?",
        opts: "yn",
      },
      { id: 20, ask: "Is the code selected appropriate?", opts: "yn" },
      { id: 30, ask: "Are we accredited?", opts: "yn" },
      { id: 40, ask: "Positive comments or Rejection details", opts: "free" },
      { id: 50, ask: "Single or Multisite?", opts: ["Single", "Multisite"] },
      {
        id: 51,
        ask: "If Multisite, has an MSM been uploaded and is it acceptable againist AJAEU's B/N or procedure?",
        opts: "yn",
        depends: 50,
        if: "Multisite",
      },
      {
        id: 52,
        ask: "Is the number of the sites on the Application the same as Eunet & MSM?",
        depends: 50,
        opts: "yna",
        if: "Multisite",
      },
      {
        id: 53,
        ask: "Does the MSM include reference to permanent and temporary sites?",
        depends: 50,
        opts: "yna",
        if: "Multisite",
      },
      { id: 80, ask: "Positive comments or Rejection details", opts: "free" },
      { id: 90, ask: "Transfer?", opts: "yna" },
      {
        id: 100,
        ask: "What type of transfer?",
        opts: ["Re-Audit Transfer", "Surveillance Transfer", "Validation transfer"],
        depends: 90,
        if: "yes",
      },
      {
        id: 101,
        ask:
          "Are all necessary supporting documents uploaded and do these documents demonstrate conformance with Transfer requirements in 15.1 of B/N?",
        opts: "yn",
        depends: 90,
        if: "yes",
      },
      {
        id: 102,
        ask: "Acceptable?",
        opts: "yn",
        depends: 90,
        if: "yes",
      },
      { id: 130, ask: "Positive comments or Rejection details", opts: "free" },
      {
        id: 140,
        ask:
          "If Total staff # is different from the Effective # used are the justifications for the reductions in Total # supported by details on Questionnaire or other documents and are these reductions acceptable as per the Briefing Note?",
        opts: "yna",
      },
      { id: 150, ask: "Positive comments or Rejection details", opts: "free" },
      { id: 160, ask: "Can reductions for IMS be justified?", opts: "yna" },
      {
        id: 170,
        ask: "Are such reductions detailed to demonstrate conformance with AJAEU's B/N?",
        opts: "yna",
        depends: 160,
        if: "yes",
      },
      { id: 180, ask: "Positive comments or Rejection details", opts: "free" },
      {
        id: 190,
        ask:
          "Are any mandays adjustment from required man-days supported by information from client and acceptable as per the Briefing Note?",
        opts: "yna",
      },
      { id: 200, ask: "Positive comments or Rejection details", opts: "free" },
      { id: 210, ask: "Is there any impact on mandays based on the last cycle performance?", opts: "yna" },
      { id: 211, ask: "Has a Re-Audit Review form been completed and provided?", opts: "yna" },
      { id: 220, ask: "Positive comments or Rejection details", opts: "free" },
      { id: 230, ask: "Does the office have awarded competence for the standard and code involved?", opts: "yn" },
      {
        id: 240,
        ask: "Has the office completed all sections of the entry related to Risk & Impartiality?",
        opts: "yn",
      },
      { id: 250, ask: "Details of Rejection", opts: "free" },
      { id: 260, ask: "All information completed correctly (including any offsite information)?", opts: "yn" },
      { id: 270, ask: "Positive comments or Rejection details", opts: "free" },
    ],
    contract: [
      {
        id: 0,
        note: "Reviewer also consider the following:\n1.   Site visits are requried, please confirm them",
      },
      {
        id: 10,
        ask: "Was the contract sent before the PWS was approved by Accredatated office?",
        opts: "yn",
      },
      { id: 20, ask: "Details of any issues", opts: "free", depends: 10, if: "yes" },
      {
        id: 30,
        ask: "Have there been any Change Request entered on the Proposal since time of original PWS Review acceptance?",
        opts: "yn",
      },
      { id: 40, ask: "Reference to Change Request received (provide details)", opts: "free", depends: 30, if: "yes" },
      {
        id: 50,
        ask: "Are the client details on the signed Contract identical to the Proposal Set Up information?",
        opts: "yn",
      },
      { id: 60, ask: "Details of any issues", opts: "free", depends: 50, if: "no" },
      {
        id: 70,
        ask:
          "Is the worded scope in the Contract the same as the worded scope agreed and accepted at time of Proposal?",
        opts: "yn",
      },
      { id: 80, ask: "Details of any issues", opts: "free", depends: 70, if: "no" },
      {
        id: 90,
        ask: "Is the Accreditation offered in the Standard/Scheme involve valid?",
        opts: "yn",
      },
      {
        id: 100,
        ask: "If no, does the contract clearly show that the certification is not accredited?",
        opts: "yna",
      },
      {
        id: 110,
        ask: "Details of any issues with accreditation shown",
        opts: "free",
        depends: 100,
        if: "no",
      },
      {
        id: 120,
        ask: "Is the contract signed by both the AJA office and the Client?",
        opts: "yn",
      },
      {
        id: 130,
        ask: "Details of any issues with Contract approvals",
        opts: "free",
        depends: 120,
        if: "no",
      },
    ],
  };

  return (
    <ProjectQueryProvider variables={{ id: projectId }}>
      {({ project }) => (
        <ReviewProjectMutationProvider onError={onError(form, setErrors)} onCompleted={() => navigate("../..")}>
          {(loading, mutate) => (
            <Fragment>
              <PageHeader
                ghost={true}
                title={`Review Project ${startCase(project.step)} ${startCase(project.status)}`}
                extra={
                  <Fragment>
                    <Button onClick={() => navigate("../..")}>Back to list</Button>
                    <Button type="primary" loading={loading} onClick={() => form.submit()}>
                      Save
                    </Button>
                  </Fragment>
                }
              />
              <Layout.Content className="content">
                <Card>
                  <Row gutter={[12, 0]}>
                    <Col span={12}>
                      <Collapse defaultActiveKey={["0", "2", "3"]}>
                        <Collapse.Panel key="0" header="Overview">
                          <ProjectOverview project={project} />
                        </Collapse.Panel>
                        <Collapse.Panel key="1" header="History">
                          <Reviews reviews={project.projectReviews} />
                        </Collapse.Panel>
                        <Collapse.Panel key="2" header="Notes">
                          <ProjectNotesCard projectId={project.id} notes={project.notes} noCard />
                        </Collapse.Panel>
                        <Collapse.Panel key="3" header="Events">
                          <PwsEventsCard events={project.events} noCard />
                        </Collapse.Panel>
                      </Collapse>
                    </Col>
                    <Col span={12}>
                      <Collapse defaultActiveKey={["0"]}>
                        <Collapse.Panel key="0" header="Review">
                          {errors.length > 0 && (
                            <Space direction="vertical" style={{ marginBottom: "10px" }}>
                              {errors.map((error, index) => (
                                <Alert key={index} type="error" message="Error" description={error} />
                              ))}
                            </Space>
                          )}
                          <Form
                            layout="vertical"
                            form={form}
                            onValuesChange={valuesChangeHandler}
                            onFinish={onFinish(mutate, {}, { id: projectId })}
                          >
                            {questions[project.step] && (
                              <Fragment>
                                <ReviewQuestions questions={questions[project.step]} />
                                <Divider />
                              </Fragment>
                            )}
                            <Row gutter={[24, 0]}>
                              <Col span={24}>
                                <Form.Item
                                  name="action"
                                  label="Action"
                                  rules={[{ required: true, message: "is required" }]}
                                >
                                  <Select
                                    showSearch
                                    optionLabelProp="label"
                                    filterOption={(input, option) => {
                                      let label = "";
                                      if (option?.label && isString(option.label)) label = option.label;
                                      let value = option?.value;
                                      return (
                                        label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      );
                                    }}
                                  >
                                    {[
                                      { key: "approve", value: "APPROVE" },
                                      { key: "deny", value: "OSP" },
                                      { key: "lost", value: "JOB LOST" },
                                    ].map((option) => (
                                      <Select.Option key={option.key} value={option.key} label={option.value}>
                                        {option.value}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item name="content" label="Further Details">
                                  <Input.TextArea />
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item name="attachment" valuePropName={"defaultFileList"}>
                                  <Dragger {...uploadProps}>
                                    <CloudUploadOutlined />
                                    Upload Attachment
                                  </Dragger>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form>
                        </Collapse.Panel>
                      </Collapse>
                    </Col>
                  </Row>
                </Card>
              </Layout.Content>
            </Fragment>
          )}
        </ReviewProjectMutationProvider>
      )}
    </ProjectQueryProvider>
  );
};
