import { Button, Grid, Icon, Link } from "@chakra-ui/react";
import React from "react";
import { RiFileDownloadLine } from "react-icons/ri";

import { Card } from "../ui/Card";
import { CardField } from "../ui/CardField";
import { CustomerForm } from "./CustomerForm";

type CustomerType = {
  openForm: (form: any, title: any) => void;
};

export const Customer: React.FC<CustomerType> = (props) => {
  const { openForm } = props;
  const title = "Customer";
  const form = <CustomerForm />;

  return (
    <Card
      title={title}
      actions={
        <Button colorScheme="blue" aria-label="Fill" onClick={() => openForm(form, title)}>
          Fill
        </Button>
      }
    >
      <Grid templateColumns="repeat(4, 1fr)">
        <CardField label="Customer" value="Gold Enterprise S.r.l." />
        <CardField label="Certified Sites" value="3" />
        <CardField
          label="Questionaire"
          value={
            <Link color="blue.500">
              Download <Icon as={RiFileDownloadLine} />
            </Link>
          }
        />
      </Grid>
      <CardField
        label="Scope"
        value="Costruzione di edifici civili, industriali, commerciali e di impianti sportivi. Produzione di
                conglomerati bituminosi, cementizi, inerti e inerti riciclati. Construction of civil, industrial and
                commercial buildings and sports facilities. Production of bituminous conglomerates, cement, aggregates
                and recycled aggregates."
      />
    </Card>
  );
};
