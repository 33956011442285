import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  UpdateRoleMutation as UpdateRoleMutationType,
  UpdateRoleMutationVariables,
} from "./__generated__/UpdateRoleMutation";

const UpdateRoleMutation = loader("./UpdateRoleMutation.gql");

type UpdateRoleMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted: (data: UpdateRoleMutationType) => void;
}) => ReactElement;

export const UpdateRoleMutationProvider: UpdateRoleMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<UpdateRoleMutationType, UpdateRoleMutationVariables>(UpdateRoleMutation, {
    errorPolicy: "none",
    onError,
    onCompleted,
  });

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
