import { CreateProjectDraftMutationProvider } from "__legacy/queries/projects/CreateProjectDraftMutationProvider";
import { UpdateProjectDraftMutationProvider } from "__legacy/queries/projects/UpdateProjectDraftMutationProvider";
import { Button, Card, Col, Drawer, Form, Row } from "antd";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { onFinish, PwsCertificationForm } from "./PwsCertificationForm";

type PwsCertificationCardType = {
  create?: boolean;
  project?: any;
  editable: boolean;
};

export const PwsCertificationCard: React.FC<PwsCertificationCardType> = (props) => {
  const { project, create, editable } = props;
  const { agencyId, id } = useParams();
  const title = "Certification";
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);
  const navigate = useNavigate();

  const [drawerVisible, setDrawerVisible] = useState(false);
  const onClose = () => setDrawerVisible(false);

  const [Mutation, params, variables] = create
    ? [CreateProjectDraftMutationProvider, { kind: "system", agencyId }, {}]
    : [UpdateProjectDraftMutationProvider, {}, { id }];

  const onCompleted = create
    ? ({ createProjectDraft: { project } }: any) => {
        navigate(`../application/redaction/${project.id}`);
      }
    : () => onClose();
  const onError = () => ({ graphQLErrors }: any) => {
    const errors: string[] = [];
    graphQLErrors.forEach((graphQLError: any) => {
      errors.push(graphQLError.message);
    });
    setErrors(errors);
  };

  let sitesLabel = "";
  if (project?.projectSites?.length) {
    sitesLabel = "1 Head Office ";
    if (project.projectSites.length > 1) {
      const sites = project.projectSites.length - 1;
      sitesLabel += `+ ${sites} Site${sites > 1 ? "s" : ""} `;
      sitesLabel += project.sampling ? "with" : "without";
      sitesLabel += " Sampling";
    }
  }

  return (
    <Fragment>
      <Card
        title={title}
        bodyStyle={create ? { display: "none" } : {}}
        extra={
          editable && (
            <Button type={create ? "primary" : "ghost"} onClick={() => setDrawerVisible(true)}>
              {create ? "Fill" : "Edit"}
            </Button>
          )
        }
      >
        {project && (
          <Row gutter={[24, 24]}>
            <Col span={12}>
              Scheme: <b>{project.scheme?.fullName}</b>
            </Col>
            <Col span={6}>
              Authority: <b>{project.accreditation?.authority?.name}</b>
            </Col>
            <Col span={6}>
              Pws type: <b>{project.reason}</b>
            </Col>
            <Col span={12}>
              Customer: <b>{project.subject?.companyName ?? null}</b>
            </Col>
            <Col span={12}>
              Scope: <b>{project.scope}</b>
            </Col>
            <Col span={12}>
              <b>{sitesLabel}</b>
            </Col>
            <Col span={6}>
              Questionaire:{" "}
              {project.questionaire && (
                <b>
                  <a href={project.questionaire} download>
                    Download
                  </a>
                </b>
              )}
            </Col>
            {project.scheme.mandaysKind === "fsms" && (
              <Fragment>
                <Col span={12}>
                  Number of the Extra HACCP's Plans: <b>{project.fsmsExtraPlans}</b>
                </Col>
                <Col span={12}>
                  Certified for Other management Systems with AJA:{" "}
                  <b>{project.fsmsOtherCertifications ? "Yes" : "No"}</b>
                </Col>
              </Fragment>
            )}
            {project.scheme.mandaysKind === "enms" && (
              <Fragment>
                <Col span={8}>
                  Annual Total Energy Consumption in Kwh as declared on Questionnaire:{" "}
                  <b>{project.enmsEnergyConsumption}</b>
                </Col>
                <Col span={8}>
                  Number of Energy Sources based on Questionnaire: <b>{project.enmsEnergySources}</b>
                </Col>
                <Col span={8}>
                  Number of Significant Energy Uses based on Questionnaire: <b>{project.enmsEnergyUses}</b>
                </Col>
              </Fragment>
            )}
          </Row>
        )}
      </Card>
      <Mutation onError={onError} onCompleted={onCompleted}>
        {(loading, mutate) => (
          <Drawer
            title={title}
            width={680}
            onClose={onClose}
            visible={drawerVisible}
            keyboard={false}
            maskClosable={false}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <Button onClick={onClose} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button type="primary" loading={loading} onClick={() => form.submit()}>
                  Save
                </Button>
              </div>
            }
          >
            <PwsCertificationForm
              form={form}
              project={project}
              onFinish={onFinish(mutate, params, variables)}
              errors={errors}
            />
          </Drawer>
        )}
      </Mutation>
    </Fragment>
  );
};
