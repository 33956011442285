import { CloudUploadOutlined } from "@ant-design/icons";
import { LocaleCountriesQueryProvider } from "__legacy/queries/enums/LocaleCountriesQueryProvider";
import { Alert, Card, Col, Form, Input, Row, Select, Space, Switch } from "antd";
import { FormInstance } from "antd/lib/form";
import Dragger from "antd/lib/upload/Dragger";
import { isString } from "lodash";
import { Fragment, useCallback } from "react";

type StakeholderFormType = {
  action: string;
  form: FormInstance<any>;
  handler: (values: any) => void;
  values: any;
  errors: string[];
};

const fields = [
  ["companyName", "companyName"],
  ["website", "website"],
  ["lastname", "lastname"],
  ["firstname", "firstname"],
  ["vatCode", "vatCode"],
  ["fiscalCode", "fiscalCode"],
  ["address", "address"],
  ["city", "city"],
  ["zipCode", "zipCode"],
  ["state", "state"],
  ["country", "country"],
  ["isEnabled", "isEnabled"],
  ["isCustomer", "isCustomer"],
  ["isSupplier", "isSupplier"],
  ["isCollaborator", "isCollaborator"],
];

export const onError = (form: any, setErrors: any) => ({ graphQLErrors }: any) => {
  const fieldsErrors: any[] = [];
  const errors: string[] = [];
  graphQLErrors.forEach((graphQLError: any) => {
    let found = false;
    fields.forEach(([fieldName, errorFieldName]) => {
      if (graphQLError.name === errorFieldName) {
        fieldsErrors.push({ name: fieldName, errors: (graphQLError as any).messages });
        found = true;
      }
    });
    if (!found) {
      errors.push(graphQLError.message);
    }
  });
  form.setFields(fieldsErrors);
  setErrors(errors);
};

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const input = {
    ...values,
    avatar: values.avatar?.file,
    nda: values.nda?.file,
    sca: values.sca?.file,
    cv: values.cv?.file,
    degree: values.degree?.file,
    ...params,
  };
  mutate({ variables: { ...variables, input } });
};

export const StakeholderForm: React.FC<StakeholderFormType> = (props) => {
  const { action, form, handler, values, errors } = props;

  console.log(`Agencies/Stakeholders/StakeholderForm action: ${action}`);

  const initialValues = {
    ...values,
    avatar: values.avatar ? [{ url: values.avatar, name: "Download" }] : [],
    nda: values.nda ? [{ url: values.nda, name: "Download" }] : [],
    sca: values.sca ? [{ url: values.sca, name: "Download" }] : [],
    cv: values.cv ? [{ url: values.cv, name: "Download" }] : [],
    degree: values.degree ? [{ url: values.degree, name: "Download" }] : [],
  };

  const valuesChangeHandler = useCallback(
    (changedValues) => {
      const fieldValues: any[] = [];
      if (changedValues.avatar && changedValues.avatar.file.status === "removed") {
        fieldValues.push({ name: ["avatar"], value: null });
      }
      if (changedValues.nda && changedValues.nda.file.status0 === "removed") {
        fieldValues.push({ name: ["nda"], value: null });
      }
      if (changedValues.sca && changedValues.sca.file.status === "removed") {
        fieldValues.push({ name: ["sca"], value: null });
      }
      if (changedValues.cv && changedValues.cv.file.status === "removed") {
        fieldValues.push({ name: ["cv"], value: null });
      }
      if (changedValues.degree && changedValues.degree.file.status === "removed") {
        fieldValues.push({ name: ["degree"], value: null });
      }
      form.setFields(fieldValues);
    },
    [form],
  );

  const uploadProps = {
    maxCount: 1,
    accept: ".pdf, .doc, .xls, .xlsx",
    beforeUpload: () => {
      return false;
    },
  };

  return (
    <Card>
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Form
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onValuesChange={valuesChangeHandler}
        onFinish={handler}
      >
        <Row gutter={[24, 0]}>
          <Col span={3}>
            <Form.Item name="isCustomer" label="Customer" valuePropName={"checked"}>
              <Switch />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="isSupplier" label="Supplier" valuePropName={"checked"}>
              <Switch />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="isCollaborator" label="Auditor" valuePropName={"checked"}>
              <Switch />
            </Form.Item>
          </Col>
          <Col span={3}>
            {action === "update" && (
              <Form.Item name="isEnabled" label="Enabled" valuePropName={"checked"}>
                <Switch />
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            <LocaleCountriesQueryProvider>
              {({ localeCountries }) => (
                <Form.Item name="country" label="Country">
                  <Select
                    showSearch
                    optionLabelProp="label"
                    filterOption={(input, option) => {
                      let label = "";
                      if (option?.label && isString(option.label)) label = option.label;
                      let value = option?.value;
                      return (
                        label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {localeCountries?.enumValues?.map((localeCountry) => (
                      <Select.Option
                        key={localeCountry.name}
                        value={localeCountry.name}
                        label={localeCountry.description}
                      >
                        {localeCountry.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </LocaleCountriesQueryProvider>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={12}>
            <Form.Item name="companyName" label="Company Name" rules={[{ required: true, message: "is required" }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="website" label="Website" rules={[{ type: "url", message: "not a valid url" }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="lastname" label="Lastname">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="firstname" label="Firstname">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="email" label="Email">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="phoneNumber" label="Phone">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item dependencies={["isCustomer", "isSupplier", "country"]} noStyle>
          {(form: FormInstance) => (
            <Fragment>
              {form.getFieldValue("country") === "IT" &&
                (form.getFieldValue("isCustomer") || form.getFieldValue("isSupplier")) && (
                  <Row gutter={[24, 0]}>
                    <Col span={12}>
                      <Form.Item name="pec" label="PEC">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="sdi" label="SDI">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
            </Fragment>
          )}
        </Form.Item>
        <Row gutter={[24, 0]}>
          <Col span={8}>
            <Form.Item name="vatCode" label="Vat Code">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="vatCodeEu" label="Vat Code EU">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="fiscalCode" label="Fiscal Code">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="ibanCode" label="IBAN">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="faxNumber" label="Fax">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="referentRole" label="Referent Role">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="consultantName" label="Consultant Name">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={12}>
            <Form.Item name="address" label="Address">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="city" label="City">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={12}>
            <Form.Item name="zipCode" label="Zip Code">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="state" label="State/County/Province">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item name="notes" label="Notes">
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item dependencies={["isCollaborator"]} noStyle>
          {(form: FormInstance) => (
            <Fragment>
              {form.getFieldValue("isCollaborator") && (
                <Row gutter={[24, 0]}>
                  <Col span={4}>
                    <Form.Item name="avatar" label="Upload Avatar" valuePropName={"defaultFileList"}>
                      <Dragger {...uploadProps} accept=".jpg, .png" listType="picture-card">
                        <CloudUploadOutlined />
                        Click or drop here
                      </Dragger>
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item name="nda" label="Upload NdA" valuePropName={"defaultFileList"}>
                      <Dragger {...uploadProps}>
                        <CloudUploadOutlined />
                        Click or drop here
                      </Dragger>
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item name="sca" label="Upload SCA" valuePropName={"defaultFileList"}>
                      <Dragger {...uploadProps}>
                        <CloudUploadOutlined />
                        Click or drop here
                      </Dragger>
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item name="cv" label="Upload CV" valuePropName={"defaultFileList"}>
                      <Dragger {...uploadProps}>
                        <CloudUploadOutlined />
                        Click or drop here
                      </Dragger>
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item name="degree" label="Upload Degree" valuePropName={"defaultFileList"}>
                      <Dragger {...uploadProps}>
                        <CloudUploadOutlined />
                        Click or drop here
                      </Dragger>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Fragment>
          )}
        </Form.Item>
      </Form>
    </Card>
  );
};
