import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  UpdateTransferMutation as UpdateTransferMutationType,
  UpdateTransferMutationVariables,
} from "./__generated__/UpdateTransferMutation";

const UpdateTransferMutation = loader("./UpdateTransferMutation.gql");

type UpdateTransferMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted?: (data: UpdateTransferMutationType) => void;
}) => ReactElement;

export const UpdateTransferMutationProvider: UpdateTransferMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<UpdateTransferMutationType, UpdateTransferMutationVariables>(
    UpdateTransferMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
