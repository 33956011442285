import { Reviews } from "__legacy/frames/shareds/collaborators/Reviews";
import { CandidacyQueryProvider } from "__legacy/queries/candidacies/CandidacyQueryProvider";
import { UpdateCandidacyMutationProvider } from "__legacy/queries/candidacies/UpdateCandidacyMutationProvider";
import { Button, Divider, Form, Layout, PageHeader } from "antd";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CandidacyForm, onError, onFinish } from "./CandidacyForm";

export const Edit: React.FC<{}> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <CandidacyQueryProvider variables={{ id: id }}>
      {({ candidacy }) => (
        <UpdateCandidacyMutationProvider
          onError={onError(form, setErrors)}
          onCompleted={() => navigate("../../../../")}
        >
          {(loading, mutate) => (
            <Fragment>
              <PageHeader
                ghost={true}
                title={`Edit candidacy for stakeholder ${candidacy.ability.stakeholder.companyName} on ${candidacy.ability.scheme.name}`}
                extra={
                  <Fragment>
                    <Button onClick={() => navigate("../../../../")}>Back to abilities</Button>
                    <Button type="primary" loading={loading} onClick={() => form.submit()}>
                      Save
                    </Button>
                  </Fragment>
                }
              />
              <Layout.Content className="content">
                <CandidacyForm
                  action="update"
                  form={form}
                  handler={onFinish(mutate, { status: "for_review" }, { id })}
                  values={{
                    ...candidacy,
                    scheme: candidacy.ability.scheme,
                  }}
                  errors={errors}
                />
                <Divider />
                <Reviews reviews={candidacy.candidacyReviews} />
              </Layout.Content>
            </Fragment>
          )}
        </UpdateCandidacyMutationProvider>
      )}
    </CandidacyQueryProvider>
  );
};
