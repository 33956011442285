import { ClusterOutlined, FileDoneOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { StakeholderQueryProvider } from "__legacy/queries/stakeholders/StakeholderQueryProvider";
import { Button, Layout, Menu, PageHeader } from "antd";
import { Fragment, useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

export const Show: React.FC<{ agency?: true }> = (props) => {
  const { agency } = props;
  const { stakeholderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState<string[]>(["stakeholder"]);

  useEffect(() => {
    if (location.pathname.includes("/experiences")) setSelectedKey(["experiences"]);
    else if (location.pathname.includes("/trainings")) setSelectedKey(["trainings"]);
    else if (location.pathname.includes("/abilities")) setSelectedKey(["abilities"]);
    else setSelectedKey(["stakeholder"]);
  }, [location.pathname]);

  return (
    <StakeholderQueryProvider variables={{ id: stakeholderId }}>
      {({ stakeholder }) => (
        <Fragment>
          <PageHeader
            ghost={true}
            title={`Auditor ${stakeholder.companyName}`}
            extra={
              <Fragment>
                <Button onClick={() => navigate(agency ? "../" : "../../")}>Back to list</Button>
              </Fragment>
            }
          />
          <Layout.Content className="content">
            <Layout className="show-sidebar-bg" style={{ padding: "24px 0" }}>
              <Layout.Sider className="show-sidebar-bg" width={200}>
                <Menu mode="inline" key={`${selectedKey}`} defaultSelectedKeys={selectedKey} style={{ height: "100%" }}>
                  <Menu.Item key="stakeholder" icon={<InfoCircleOutlined />}>
                    <NavLink to="./">
                      <span>Stakeholder</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="experiences" icon={<ClusterOutlined />}>
                    <NavLink to="./experiences">
                      <span>Experiences</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="trainings" icon={<FileDoneOutlined />}>
                    <NavLink to="./trainings">
                      <span>Trainings</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="abilities" icon={<FileDoneOutlined />}>
                    <NavLink to="./abilities">
                      <span>Abilities</span>
                    </NavLink>
                  </Menu.Item>
                </Menu>
              </Layout.Sider>
              <Layout.Content>
                <Outlet />
              </Layout.Content>
            </Layout>
          </Layout.Content>
        </Fragment>
      )}
    </StakeholderQueryProvider>
  );
};
