import { Button, Tag } from "@chakra-ui/react";
import React from "react";

import { Card } from "../ui/Card";
import { CardField } from "../ui/CardField";

export const Assessment: React.FC<{}> = () => {
  return (
    <Card
      title="Assessment"
      actions={
        <Button variant="outline" aria-label="Edit" colorScheme="blue">
          Edit
        </Button>
      }
    >
      <CardField label="Stage 1" value={<Tag>Off Site</Tag>} />
      <CardField
        label="Reason"
        value="Gestione impianto di compostaggio, di trattamento e valorizzazione frazione secca ed umida dei rifiuti solidi urbani. Gestione discarica per rifiuti non pericoloscompostaggio, di trattamento e valorizzazione frazione secca ed umida dei rifiuti solidi urbani. Gestione discarica per rifiuti non pericolosi."
      />
    </Card>
  );
};
