import { UpdateUserMutationProvider } from "__legacy/queries/users/UpdateUserMutationProvider";
import { UserQueryProvider } from "__legacy/queries/users/UserQueryProvider";
import { Button, Form, Layout, PageHeader } from "antd";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { onError, onFinish, UserForm } from "./UserForm";

export const Edit: React.FC<{}> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <UserQueryProvider variables={{ id }}>
      {({ user }) => (
        <UpdateUserMutationProvider onError={onError(form, setErrors)} onCompleted={() => navigate("../")}>
          {(loading, mutate) => (
            <Fragment>
              <PageHeader
                ghost={true}
                title={`Edit user ${user.email}`}
                extra={
                  <Fragment>
                    <Button onClick={() => navigate("../../")}>Back to list</Button>
                    <Button onClick={() => navigate("../")}>Back to details</Button>
                    <Button type="primary" loading={loading} onClick={() => form.submit()}>
                      Save
                    </Button>
                  </Fragment>
                }
              />
              <Layout.Content className="content">
                <UserForm
                  action="update"
                  form={form}
                  handler={onFinish(mutate, {}, { id })}
                  values={user}
                  errors={errors}
                />
              </Layout.Content>
            </Fragment>
          )}
        </UpdateUserMutationProvider>
      )}
    </UserQueryProvider>
  );
};
