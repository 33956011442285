import { useQuery } from "@apollo/client";
import { CenterResult } from "__legacy/components/CenterResult";
import { CenterSpinner } from "__legacy/components/CenterSpinner";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import { ProjectReasonsQuery as ProjectReasonsQueryType } from "./__generated__/ProjectReasonsQuery";

const ProjectReasonsQuery = loader("./ProjectReasonsQuery.gql");

type ProjectReasonsQueryProviderType = (props: {
  children: (data: ProjectReasonsQueryType) => ReactNode;
}) => ReactElement;

export const ProjectReasonsQueryProvider: ProjectReasonsQueryProviderType = (props) => {
  const { children } = props;

  const { error, loading, data } = useQuery<ProjectReasonsQueryType>(ProjectReasonsQuery, {
    fetchPolicy: "cache-and-network",
  });

  if (loading) return <CenterSpinner size="large" />;
  if (error || !data)
    return <CenterResult status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;

  return <Fragment>{children(data)}</Fragment>;
};
