import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Fragment, ReactElement, ReactNode } from "react";

import {
  UpdateAbilityMutation as UpdateAbilityMutationType,
  UpdateAbilityMutationVariables,
} from "./__generated__/UpdateAbilityMutation";

const UpdateAbilityMutation = loader("./UpdateAbilityMutation.gql");

type UpdateAbilityMutationProviderType = (props: {
  children: (loading: boolean, mutate: any) => ReactNode;
  onError: (error: ApolloError) => void;
  onCompleted: (data: UpdateAbilityMutationType) => void;
}) => ReactElement;

export const UpdateAbilityMutationProvider: UpdateAbilityMutationProviderType = (props) => {
  const { children, onError, onCompleted } = props;

  const [mutate, { loading }] = useMutation<UpdateAbilityMutationType, UpdateAbilityMutationVariables>(
    UpdateAbilityMutation,
    {
      errorPolicy: "none",
      onError,
      onCompleted,
    },
  );

  return <Fragment>{children(loading, mutate)}</Fragment>;
};
