import { QuestionCircleOutlined } from "@ant-design/icons";
import { ApplyProjectMutationProvider } from "__legacy/queries/projects/ApplyProjectMutationProvider";
import { LoseProjectMutationProvider } from "__legacy/queries/projects/LoseProjectMutationProvider";
import { ProjectQueryProvider } from "__legacy/queries/projects/ProjectQueryProvider";
import { Button, Card, Col, Collapse, Layout, PageHeader, Popconfirm, Row, Space } from "antd";
import { compact, find, includes, isEmpty, remove, toNumber } from "lodash";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ProjectNotesCard } from "./ProjectNotesCard";
import { ProjectSummary } from "./ProjectSummary";
import { PwsActivitiesCard } from "./PwsActivitiesCard";
import { PwsAssessmentCard } from "./PwsAssessmentCard";
import { PwsCertificationCard } from "./PwsCertificationCard";
import { PwsContractActivitiesCard } from "./PwsContractActivitiesCard";
import { PwsContractCard } from "./PwsContractCard";
import { PwsEventsCard } from "./PwsEventsCard";
import { PwsFilesCard } from "./PwsFilesCard";
import { PwsIncreasesCard } from "./PwsIncreasesCard";
import { PwsMainAuditCard } from "./PwsMainAuditCard";
import { PwsMultiStandardCard } from "./PwsMultiStandardCard";
import { PwsProjectSiteCard } from "./PwsProjectSiteCard";
import { PwsProposalCard } from "./PwsProposalCard";
import { PwsReductionsCard } from "./PwsReductionsCard";
import { PwsSurveillanceCard } from "./PwsSurveillanceCard";
import { PwsTransferCard } from "./PwsTransferCard";
import { Reviews } from "./Reviews";
import { VisitBookingCard } from "./VisitBookingCard";

export const Show: React.FC<{ agency?: true }> = (props) => {
  const { agency } = props;
  const { id, step, status } = useParams();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(
    status === "booking" ? (step === "surveillance" ? "surveillance1" : "mainAudit") : "pws",
  );
  const tabChange = (tab: any) => setCurrentTab(tab);

  return (
    <ProjectQueryProvider variables={{ id }}>
      {({ project }) => {
        const archived = project && project.status === "archived";
        const booking = project && project.status === "booking";
        const certified = project && project.status === "certified";
        const packageReviews: any[] = [];
        const tabs = [{ key: "pws", tab: "Pws" }];
        if (booking || certified || archived) {
          project.audits.forEach((audit: any) => {
            audit.visits.forEach((visit: any) => {
              if (visit.visitReviews.length)
                packageReviews.push({
                  title: (
                    <Space size="middle">
                      {audit.kind === "main" ? "Main Audit" : `Surveillance ${audit.step}`}
                      {audit.kind === "main" && `STAGE ${audit.step}`}
                      {visit.site.name}
                    </Space>
                  ),
                  reviews: visit.visitReviews,
                });
            });
            if (audit.kind === "main" && audit.step === 2) return;
            tabs.push({
              key: audit.kind === "main" ? "mainAudit" : `surveillance${audit.step}`,
              tab: audit.kind === "main" ? "Main Audit" : `Surveillance ${audit.step}`,
            });
          });
        }
        if (project.projectReviews.length) tabs.push({ key: "reviews", tab: "Reviews" });
        if (project.certificationFilled) tabs.push({ key: "contractActivities", tab: "Contract Activities" });

        const mainAudits = project.audits?.filter((audit: any) => audit.kind === "main" && !isEmpty(audit.visits));
        const surveillances = project.audits?.filter(
          (audit: any) => audit.kind === "surveillance" && !isEmpty(audit.visits),
        );

        const auditsActivities = compact(project.mandays).map((minute: any) => ({
          ...minute,
          id: minute.audit === "main" ? "main audit" : minute.audit,
        }));
        const applicationActivities = [...project.activities, ...auditsActivities];
        const editable =
          !!agency && project.step === "application" && ["redaction", "pending"].includes(project.status);
        const applyable =
          !!agency &&
          project.step === "application" &&
          project.status === "redaction" &&
          !find(applicationActivities, { filled: false });
        const apply = {
          id: "application",
          main: true,
          locked: !applyable,
          filled: ["pending", "booking"].includes(project.status) || ["proposal", "contract"].includes(project.step),
        };
        const proposable = !!agency && project.step === "proposal" && project.status === "redaction";
        const proposal = {
          id: "proposal",
          main: true,
          locked: !proposable,
          filled: (!proposable && project.proposalSent) || project.status === "approved",
        };
        const contractable = !!agency && project.step === "contract" && project.status === "redaction";
        const contract = { id: "contract", main: true, locked: !contractable, filled: project.status === "booking" };
        const activities = [...applicationActivities, apply, proposal, contract];
        if (project.reason !== "transfer") remove(activities, { id: "transfer" });
        if (project.reason === "renewal") remove(activities, { id: "assessment" });
        if (booking || certified)
          tabs.forEach((tab: any) => {
            if (!includes(["pws", "reviews"], tab.key))
              activities.push({ id: tab.tab, main: true, locked: tab.key !== "mainAudit" });
          });

        return (
          <Fragment>
            <PageHeader
              ghost={true}
              title={`Project`}
              extra={
                <Fragment>
                  <Button onClick={() => window.history.back()}>Back to list</Button>
                </Fragment>
              }
            />
            <Layout.Content className="content">
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <ProjectSummary project={project} tabs={tabs} currentTab={currentTab} tabChange={tabChange} />
                </Col>
                <Col span={18}>
                  <Row gutter={[16, 16]}>
                    {currentTab === "pws" && (
                      <Fragment>
                        <Col span={24}>
                          <PwsCertificationCard project={project} editable={editable} />
                        </Col>
                        {project.projectSites.length > 0 && (
                          <Col span={24}>
                            <Card title="Sites">
                              {project.projectSites.map((projectSite: any, i: any) => (
                                <PwsProjectSiteCard
                                  key={projectSite.id}
                                  project={project}
                                  projectSite={projectSite}
                                  i={i}
                                  editable={editable}
                                />
                              ))}
                            </Card>
                          </Col>
                        )}
                        {project.reason === "transfer" && (
                          <Col span={24}>
                            <PwsTransferCard project={project} editable={editable} />
                          </Col>
                        )}
                        {project.reason !== "renewal" && (
                          <Col span={24}>
                            <PwsAssessmentCard project={project} editable={editable} />
                          </Col>
                        )}
                        <Col span={24}>
                          <PwsReductionsCard project={project} editable={editable} />
                        </Col>
                        <Col span={24}>
                          <PwsIncreasesCard project={project} editable={editable} />
                        </Col>
                        <Col span={24}>
                          <PwsMultiStandardCard project={project} editable={editable} />
                        </Col>
                        {isEmpty(mainAudits) || (
                          <Col span={24}>
                            <PwsMainAuditCard
                              audits={mainAudits}
                              mandays={find(project.mandays, { audit: "main" })}
                              projectSites={project.projectSites}
                              editable={editable}
                            />
                          </Col>
                        )}
                        {surveillances.map((audit: any, i: any) => (
                          <Col span={24} key={i}>
                            <PwsSurveillanceCard
                              audit={audit}
                              mandays={find(project.mandays, { audit: audit.kind + audit.step })}
                              projectSites={project.projectSites}
                              editable={editable}
                            />
                          </Col>
                        ))}
                        {applyable && (
                          <Col span={24}>
                            <Card
                              title="Application"
                              bodyStyle={{ display: "none" }}
                              extra={
                                <ApplyProjectMutationProvider
                                  onError={() => null}
                                  onCompleted={() => navigate("../../../dashboard")}
                                >
                                  {(loading, mutate) => (
                                    <Popconfirm
                                      title="Are you sure?"
                                      placement="right"
                                      icon={<QuestionCircleOutlined />}
                                      onConfirm={() => mutate({ variables: { id } })}
                                    >
                                      <Button type="primary" loading={loading}>
                                        Apply
                                      </Button>
                                    </Popconfirm>
                                  )}
                                </ApplyProjectMutationProvider>
                              }
                            />
                          </Col>
                        )}
                        {(proposable || project.proposal) && (
                          <Col span={24}>
                            <PwsProposalCard project={project} editable={proposable} />
                          </Col>
                        )}
                        {(contractable || project.contract) && (
                          <Col span={24}>
                            <PwsContractCard project={project} editable={contractable} />
                          </Col>
                        )}
                      </Fragment>
                    )}
                    {currentTab === "mainAudit" && (
                      <Fragment>
                        {mainAudits.map((audit: any, i: any) => {
                          return audit.visits.map((visit: any, j: any) => (
                            <Fragment key={visit.id}>
                              <Col span={24}>
                                <VisitBookingCard
                                  visit={visit}
                                  audit={audit}
                                  project={project}
                                  editable={
                                    !!agency &&
                                    !archived &&
                                    !certified &&
                                    (i === 0 ||
                                      (mainAudits[i - 1] ? mainAudits[i - 1].visits[j].status === "approved" : false))
                                  }
                                />
                              </Col>
                            </Fragment>
                          ));
                        })}
                      </Fragment>
                    )}
                    {currentTab.match("surveillance") && (
                      <Fragment>
                        {surveillances
                          .filter((audit: any) => audit.step === toNumber(currentTab.split("surveillance")[1]))
                          .map((audit: any) => {
                            const i = audit.step;
                            return audit.visits.map((visit: any, j: any) => (
                              <Fragment key={visit.id}>
                                <Col span={24}>
                                  <VisitBookingCard
                                    visit={visit}
                                    audit={audit}
                                    project={project}
                                    editable={
                                      !!agency &&
                                      !archived &&
                                      !certified &&
                                      (i === 1
                                        ? mainAudits[1].visits[j]
                                          ? mainAudits[1].visits[j].status === "approved"
                                          : true
                                        : surveillances[i - 2].visits[j]
                                        ? surveillances[i - 2].visits[j].status === "approved"
                                        : true)
                                    }
                                  />
                                </Col>
                              </Fragment>
                            ));
                          })}
                      </Fragment>
                    )}
                    {currentTab === "reviews" && (
                      <Col span={24}>
                        <Card title="Reviews">
                          <Collapse defaultActiveKey={[0, 1]}>
                            <Collapse.Panel key="0" header="PWS Reviews">
                              <Reviews reviews={project.projectReviews} />
                            </Collapse.Panel>
                            {packageReviews.length > 0 && (
                              <Collapse.Panel key="1" header="Package Reviews">
                                {packageReviews.map((r, i) => (
                                  <Space key={i} direction="vertical" style={{ width: "100%" }}>
                                    {r.title}
                                    <Reviews reviews={r.reviews} />
                                  </Space>
                                ))}
                              </Collapse.Panel>
                            )}
                          </Collapse>
                        </Card>
                      </Col>
                    )}
                    {currentTab === "contractActivities" && (
                      <Col span={24}>
                        <PwsContractActivitiesCard project={project} />
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col span={6}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <PwsActivitiesCard project={project} activities={activities} />
                    </Col>
                    <Col span={24}>
                      <PwsFilesCard project={project} />
                    </Col>
                    <Col span={24}>
                      <ProjectNotesCard projectId={project.id} notes={project.notes} />
                    </Col>
                    <Col span={24}>
                      <PwsEventsCard events={project.events} />
                    </Col>
                    {project.originId && (
                      <Col span={24}>
                        <Card>
                          <Button
                            style={{ width: "100%" }}
                            onClick={() => navigate(`../../../surveillance/booking/${project.originId}`)}
                          >
                            Old Certification
                          </Button>
                        </Card>
                      </Col>
                    )}
                    {(applyable || contractable) && (
                      <Col span={24}>
                        <Card>
                          <LoseProjectMutationProvider onError={() => null} onCompleted={() => navigate("..")}>
                            {(loading, mutate) => (
                              <Popconfirm
                                title="Are you sure?"
                                placement="bottom"
                                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                                onConfirm={() => mutate({ variables: { id } })}
                              >
                                <Button danger style={{ width: "100%" }} loading={loading}>
                                  Job Lost
                                </Button>
                              </Popconfirm>
                            )}
                          </LoseProjectMutationProvider>
                        </Card>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Layout.Content>
          </Fragment>
        );
      }}
    </ProjectQueryProvider>
  );
};
