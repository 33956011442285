import { useApplicationContext } from "__legacy/components/Application";
import { CreateCarMutationProvider } from "__legacy/queries/projects/CreateCarMutationProvider";
import { UpdateCarMutationProvider } from "__legacy/queries/projects/UpdateCarMutationProvider";
import { Alert, Button, Col, DatePicker, Drawer, Form, Row, Select, Space, Switch } from "antd";
import { FormInstance } from "antd/lib/form";
import { isString } from "lodash";
import moment from "moment";
import { Fragment, useState } from "react";

const formatDate = (date: any) => {
  return moment(date, "MM-DD-YYYY").isValid() ? moment(date, "MM-DD-YYYY").format("YYYY-MM-DD") : null;
};

export const CarForm: React.FC<{
  visible: any;
  onClose: () => void;
  car: any;
  visitPackage: any;
}> = (props) => {
  const { visible, onClose, visitPackage, car } = props;
  const [form] = Form.useForm();
  const { getEnumOptions } = useApplicationContext();

  const [Mutation, params, variables] = car
    ? [UpdateCarMutationProvider, {}, { id: car.id }]
    : [CreateCarMutationProvider, { packageId: visitPackage?.id }, {}];

  const initialValues = car
    ? {
        ...car,
        clientResponseOn: car.clientResponseOn ? moment(car.clientResponseOn, "YYYY-MM-DD") : null,
        auditorAcceptanceOn: car.auditorAcceptanceOn ? moment(car.auditorAcceptanceOn, "YYYY-MM-DD") : null,
      }
    : {};

  const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
    const input = {
      ...values,
      clientResponseOn: formatDate(values.clientResponseOn),
      auditorAcceptanceOn: formatDate(values.auditorAcceptanceOn),
      ...params,
    };
    mutate({ variables: { ...variables, input } });
  };

  const [errors, setErrors] = useState<string[]>([]);
  const onError = () => ({ graphQLErrors }: any) => {
    const errors: string[] = [];
    graphQLErrors.forEach((graphQLError: any) => {
      errors.push(graphQLError.message);
    });
    setErrors(errors);
  };

  return (
    <Mutation onError={onError}>
      {(loading, mutate) => (
        <Drawer
          title={(car ? "" : "New ") + "CAR Details"}
          width={680}
          onClose={onClose}
          visible={visible}
          keyboard={false}
          maskClosable={false}
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button onClick={onClose} style={{ marginRight: 8 }}>
                Cancel
              </Button>
              <Button type="primary" loading={loading} onClick={() => form.submit()}>
                Save
              </Button>
            </div>
          }
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish(mutate, params, variables)}
            initialValues={initialValues}
          >
            {errors.length > 0 && (
              <Space direction="vertical" style={{ marginBottom: "10px" }}>
                {errors.map((error, index) => (
                  <Alert key={index} type="error" message="Error" description={error} />
                ))}
              </Space>
            )}
            <Row gutter={[24, 0]}>
              <Col span={12}>
                <Form.Item name="kind" label="Kind" rules={[{ required: true, message: "is required" }]}>
                  <Select
                    showSearch
                    optionLabelProp="label"
                    filterOption={(input, option) => {
                      let label = "";
                      if (option?.label && isString(option.label)) label = option.label;
                      let value = option?.value;
                      return (
                        label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {getEnumOptions("carKinds")}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="clientResponseOn"
                  label="Client Response Date"
                  rules={[{ required: true, message: "is required" }]}
                >
                  <DatePicker mode="date" format="MM-DD-YYYY" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="auditorAcceptance" label="Auditor Acceptance" valuePropName={"checked"}>
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item dependencies={["auditorAcceptance"]}>
                  {(form: FormInstance) => (
                    <Fragment>
                      {form.getFieldValue("auditorAcceptance") && (
                        <Form.Item
                          name="auditorAcceptanceOn"
                          label="Auditor Acceptance Date"
                          rules={[{ required: true, message: "is required" }]}
                        >
                          <DatePicker mode="date" format="MM-DD-YYYY" style={{ width: "100%" }} />
                        </Form.Item>
                      )}
                    </Fragment>
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="status" label="Status" rules={[{ required: true, message: "is required" }]}>
                  <Select
                    showSearch
                    optionLabelProp="label"
                    filterOption={(input, option) => {
                      let label = "";
                      if (option?.label && isString(option.label)) label = option.label;
                      let value = option?.value;
                      return (
                        label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {getEnumOptions("carStatuses")}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      )}
    </Mutation>
  );
};
