import { Alert, Col, Form, Input, Row, Space, Switch } from "antd";
import { FormInstance } from "antd/lib/form";
import { useCallback } from "react";

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const input = {
    ...values,
    ...params,
  };
  mutate({ variables: { ...variables, input } });
};

export const PwsAssessmentForm: React.FC<{
  form: FormInstance<any>;
  onFinish: (values: any) => void;
  project: any;
  errors: string[];
}> = (props) => {
  const { form, project, onFinish, errors } = props;

  const onValuesChange = useCallback(
    (changedValues, allValues) => {
      const fieldValues: any[] = [];
      if (changedValues.initialAssessmentOffsite === false) {
        fieldValues.push({ name: ["initialAssessmentReason"], value: null });
      }
      form.setFields(fieldValues);
    },
    [form],
  );

  return (
    <Form layout="vertical" form={form} initialValues={project} onValuesChange={onValuesChange} onFinish={onFinish}>
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Form.Item label="Stage1 Off Site">
            <Form.Item name="initialAssessmentOffsite" valuePropName={"checked"}>
              <Switch />
            </Form.Item>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Stage1 Off Site Reason" dependencies={["initialAssessmentOffsite"]}>
            {(form: FormInstance) => (
              <Form.Item name="initialAssessmentReason">
                <Input.TextArea disabled={!form.getFieldValue("initialAssessmentOffsite")} rows={2} />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
