import { Box, Button, Divider, Grid, Heading, HStack, Icon, Stack, Text, Tooltip } from "@chakra-ui/react";
import { FiAlertCircle } from "react-icons/fi";

import { Card } from "../ui/Card";
import { CardField } from "../ui/CardField";

type SurveillanceType = {
  n: string;
};

export const Surveillance: React.FC<SurveillanceType> = (props) => {
  const { n } = props;
  const sites = ["Main Office", "Commercial Office"];
  return (
    <Card
      title={`Surveillance ${n}`}
      actions={
        <HStack>
          <Text>Mandays: 0/10</Text>
          <Tooltip hasArrow label="Missing 10 mandays" placement="top">
            <Box>
              <Icon as={FiAlertCircle} boxSize="4" color="amber.500" />
            </Box>
          </Tooltip>
        </HStack>
      }
    >
      <Stack w="full" spacing="4">
        {sites.map((site, i) => (
          <Stack>
            <HStack justify="space-between">
              <Heading size="xs">{site}</Heading>
              <Button colorScheme="blue" aria-label="Fill">
                Fill
              </Button>
            </HStack>
            <Grid templateColumns="repeat(2, 1fr)">
              <Grid templateColumns="repeat(3, 1fr)">
                <CardField label="Mandays" value="0" />
                <CardField label="Fee" value="0%" />
                <CardField label="Report" value="0" />
              </Grid>
              <HStack></HStack>
            </Grid>
            {i < sites.length - 1 && <Divider />}
          </Stack>
        ))}
      </Stack>
    </Card>
  );
};
