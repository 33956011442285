import { HStack, IconButton, Stack, Tab, Table, TabList, Tabs, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { RiDeleteBinLine, RiEdit2Line } from "react-icons/ri";

export const ListTable: React.FC<{}> = () => {
  return (
    <Stack>
      <Tabs variant="unstyled">
        <TabList>
          <Tab _selected={{ color: "blue.500", borderBottom: "1px", borderColor: "blue.500" }}>All</Tab>
          <Tab _selected={{ color: "blue.500", borderBottom: "1px", borderColor: "blue.500" }}>For Review</Tab>
          <Tab _selected={{ color: "blue.500", borderBottom: "1px", borderColor: "blue.500" }}>Need Correction</Tab>
        </TabList>
      </Tabs>
      <Table boxShadow="sm" bg="white">
        <Thead bg="coolGray.200">
          <Tr>
            <Th py="4">Status</Th>
            <Th>Kind</Th>
            <Th>Code</Th>
            <Th isNumeric>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>ok</Td>
            <Td>new</Td>
            <Td>XXX</Td>
            <Td isNumeric>
              <HStack justify="flex-end">
                <IconButton variant="outline" aria-label="Edit" icon={<RiEdit2Line />}>
                  Edit
                </IconButton>
                <IconButton variant="outline" colorScheme="red" aria-label="Edit" icon={<RiDeleteBinLine />}>
                  Edit
                </IconButton>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>ok</Td>
            <Td>new</Td>
            <Td>XXX</Td>
            <Td isNumeric>
              <HStack justify="flex-end">
                <IconButton variant="outline" aria-label="Edit" icon={<RiEdit2Line />}>
                  Edit
                </IconButton>
                <IconButton variant="outline" colorScheme="red" aria-label="Edit" icon={<RiDeleteBinLine />}>
                  Edit
                </IconButton>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>ok</Td>
            <Td>new</Td>
            <Td>XXX</Td>
            <Td isNumeric>
              <HStack justify="flex-end">
                <IconButton variant="outline" aria-label="Edit" icon={<RiEdit2Line />}>
                  Edit
                </IconButton>
                <IconButton variant="outline" colorScheme="red" aria-label="Edit" icon={<RiDeleteBinLine />}>
                  Edit
                </IconButton>
              </HStack>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Stack>
  );
};
