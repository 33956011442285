import { Checkbox, Divider, Form, Input, Radio, Space } from "antd";
import { FormInstance } from "antd/lib/form";
import { isArray, isString } from "lodash";
import { Fragment } from "react";

type ReviewQuestionsType = {
  questions?: any[];
};

export const ReviewQuestions: React.FC<ReviewQuestionsType> = (props) => {
  const { questions } = props;

  const renderQuestion = (question: any) => {
    if (!question.opts) return null;
    const ask = [question.id, "ask"];
    const reply = [question.id, "reply"];
    return (
      <Fragment>
        <Form.Item hidden name={ask} initialValue={question.ask}>
          <Input hidden />
        </Form.Item>
        {/^yn/.test(question.opts) && (
          <Form.Item name={reply} label={question.ask} rules={[{ required: true, message: "is required" }]}>
            <Radio.Group>
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
              {question.opts === "yna" && <Radio value="n/a">N/A</Radio>}
            </Radio.Group>
          </Form.Item>
        )}
        {question.opts === "check" && (
          <Form.Item name={reply} valuePropName="checked">
            <Checkbox>{question.ask}</Checkbox>
          </Form.Item>
        )}
        {isArray(question.opts) && (
          <Form.Item name={reply} label={question.ask} rules={[{ required: true, message: "is required" }]}>
            <Radio.Group>
              {question.opts.map((o: any, i: any) => (
                <Radio key={i} value={o.toString()}>
                  {o}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        )}
        {question.opts === "free" && (
          <Form.Item name={reply} label={question.ask}>
            <Input.TextArea rows={1} />
          </Form.Item>
        )}
      </Fragment>
    );
  };

  return (
    <Form.List name="questions">
      {(fields) => (
        <Space direction="vertical">
          {questions?.map((question: any) => {
            const dependency = question.depends
              ? isString(question.depends)
                ? question.depends
                : ["questions", question.depends, "reply"]
              : null;

            return (
              <Fragment key={question.id}>
                {question.note && (
                  <Fragment>
                    {question.note.split("\n").map((str: any, i: any) => (
                      <div key={i}>{str}</div>
                    ))}
                    <Divider />
                  </Fragment>
                )}
                {dependency && (
                  <Form.Item dependencies={[dependency]} noStyle>
                    {(form: FormInstance) => form.getFieldValue(dependency) === question.if && renderQuestion(question)}
                  </Form.Item>
                )}
                {!dependency && renderQuestion(question)}
              </Fragment>
            );
          })}
        </Space>
      )}
    </Form.List>
  );
};
