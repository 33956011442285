import { CheckOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import moment from "moment";
import { Fragment } from "react";
import { Link } from "react-router-dom";

type PwsActivityItemRowType = {
  item: any;
  i: any;
  openForm: (item: any) => void;
};

export const PwsActivityItemRow: React.FC<PwsActivityItemRowType> = (props) => {
  const { item, i, openForm } = props;

  return (
    <Row gutter={[0, 5]} key={item.id}>
      {i === 0 && (
        <Fragment>
          <Col span={1}>
            <b>Done</b>
          </Col>
          <Col span={4}>{item.item.stage ? <b>Stage</b> : null}</Col>
          <Col span={5}>
            <b>Item</b>
          </Col>
          <Col span={7}>
            <b>Description</b>
          </Col>
          <Col span={2}>
            <b>Estimated Hours</b>
          </Col>
          <Col span={2}>
            <b>Effort Hours</b>
          </Col>
          <Col span={3}>
            <b>Done On</b>
          </Col>
        </Fragment>
      )}
      <Col span={1}>{item.done && <CheckOutlined style={{ color: "#52c41a" }} />}</Col>
      <Col span={4}>{item.item.stage?.name}</Col>
      <Col span={5}>
        <Link to="#" onClick={() => openForm(item)}>
          {item.item.name}
        </Link>
      </Col>
      <Col span={7}>{item.item.description}</Col>
      <Col span={2}>{item.item.estimatedHours}</Col>
      <Col span={2}>{item.effortHours}</Col>
      <Col span={3}>{item.doneOn ? moment(item.doneOn).format("MM-DD-YYYY HH:mm") : null}</Col>
    </Row>
  );
};
