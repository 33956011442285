import { Tab, TabList, Tabs } from "@chakra-ui/react";

export const CustomerSubTabs: React.FC<{}> = () => {
  return (
    <Tabs variant="unstyled">
      <TabList>
        <Tab
          fontSize="xs"
          fontWeight="bold"
          p="2"
          mx="px"
          borderBottom="1px"
          borderBottomColor="transparent"
          color="coolGray.400"
          _selected={{ color: "coolGray.700", borderBottomColor: "coolGray.700" }}
          _hover={{ color: "coolGray.600", borderBottomColor: "coolGray.600" }}
        >
          SITES
        </Tab>
        <Tab
          fontSize="xs"
          fontWeight="bold"
          p="2"
          mx="px"
          borderBottom="1px"
          borderBottomColor="transparent"
          color="coolGray.400"
          _selected={{ color: "coolGray.700", borderBottomColor: "coolGray.700" }}
          _hover={{ color: "coolGray.600", borderBottomColor: "coolGray.600" }}
        >
          PROJECTS
        </Tab>
      </TabList>
    </Tabs>
  );
};
