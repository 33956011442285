import { Button, HStack, Tag } from "@chakra-ui/react";
import React from "react";

import { Card } from "../ui/Card";
import { CardField } from "../ui/CardField";

export const Reductions: React.FC<{}> = () => {
  return (
    <Card
      title="Reductions"
      actions={
        <Button variant="outline" aria-label="Edit" colorScheme="blue">
          Edit
        </Button>
      }
    >
      <HStack>
        <CardField label="Applied" value="" />
        <Tag>Certified</Tag>
        <Tag>Automation</Tag>
        <Tag>MS Maturity</Tag>
      </HStack>
      <CardField label="Certified by" value="AJA" />
      <CardField label="Automation info" value="---" />
      <CardField label="MS Maturity" value="---" />
      <CardField label="Other" value="4% - other reductions" />
    </Card>
  );
};
