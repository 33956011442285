import { Alert, Card, Col, Divider, Row, Space, Tooltip } from "antd";
import { Fragment } from "react";

import { PwsVisitCard } from "./PwsVisitCard";

type PwsSurveillanceCardType = {
  audit: any;
  mandays: any;
  projectSites: any[];
  editable: boolean;
};

export const PwsSurveillanceCard: React.FC<PwsSurveillanceCardType> = (props) => {
  const { audit, mandays, projectSites, editable } = props;
  const { visits } = audit;

  return (
    <Card
      title={`Surveillance ${audit.step}`}
      extra={
        <Row gutter={[10, 0]}>
          <Col span={8}>
            <Tooltip
              title={
                <Space direction="vertical">
                  {"STARTING MANDAYS: " + mandays.start}
                  {"REDUCTIONS MANDAYS: " + mandays.reduction}
                  {/* {"INTEGRATED MANDAYS: " + mandays.integrated} */}
                  {"INCREASES MANDAYS: " + mandays.increase}
                  {"MULTI STANDARD MANDAYS: " + mandays.multiStandard}
                  {"MINIMUM MANDAYS: " + mandays.minimum}
                </Space>
              }
            >
              <Alert
                style={{ width: "220px" }}
                message={`Min. Mandays ${mandays.minimum}`}
                type={mandays.status}
                showIcon
              />
            </Tooltip>
          </Col>
          <Col span={8}>
            <Alert
              style={{ width: "220px" }}
              message={`Alloc. Mandays ${mandays.current}`}
              type={mandays.status}
              showIcon
            />
          </Col>
          <Col span={8}>
            <Alert
              style={{ width: "220px" }}
              message={`Miss. Mandays ${mandays.missing}`}
              type={mandays.status}
              showIcon
            />
          </Col>
        </Row>
      }
    >
      {visits.map((visit: any, i: any) => (
        <Fragment key={visit.id}>
          <Row gutter={[24, 0]}>
            <PwsVisitCard
              visit={visit}
              audit={audit}
              mandays={mandays}
              projectSite={projectSites.find((ps: any) => ps.site?.id === visit.site.id)}
              editable={editable}
            />
          </Row>
          {i < visits.length - 1 && <Divider />}
        </Fragment>
      ))}
    </Card>
  );
};
