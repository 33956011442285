import { SchemeQueryProvider } from "__legacy/queries/schemes/SchemeQueryProvider";
import { Checkbox, Layout, Typography } from "antd";
import { useParams } from "react-router-dom";

export const Show: React.FC<{}> = () => {
  const { schemeId } = useParams();

  return (
    <SchemeQueryProvider variables={{ id: schemeId }}>
      {({ scheme }) => (
        <Layout.Content className="content">
          <Typography.Title level={2}>{scheme.name}</Typography.Title>
          <Typography.Title level={5}>Kind: {scheme.kind}</Typography.Title>
          <Typography.Title level={5}>Duration: {scheme.duration}</Typography.Title>
          <Typography.Title level={5}>Frequency: {scheme.frequency}</Typography.Title>
          {!!scheme.samplingThreshold && (
            <Typography.Title level={5}>Sampling Threshold: {scheme.samplingThreshold}%</Typography.Title>
          )}
          <Typography.Title level={5}>Mandays Kind: {scheme.mandaysKind?.toUpperCase()}</Typography.Title>
          <Typography.Title level={5}>Project Code Suffix: {scheme.projectCodeSuffix}</Typography.Title>
          <Checkbox disabled checked={scheme.isEnabled}>
            Enabled
          </Checkbox>
        </Layout.Content>
      )}
    </SchemeQueryProvider>
  );
};
