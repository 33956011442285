import { FileAddOutlined } from "@ant-design/icons";
import { AbilityKindsQueryProvider } from "__legacy/queries/enums/AbilityKindsQueryProvider";
import { AbilityLevelsQueryProvider } from "__legacy/queries/enums/AbilityLevelsQueryProvider";
import { SchemesQueryProvider } from "__legacy/queries/schemes/SchemesQueryProvider";
import { Alert, Card, Col, DatePicker, Form, Row, Select, Space } from "antd";
import { FormInstance } from "antd/lib/form";
import Dragger from "antd/lib/upload/Dragger";
import { isString } from "lodash";
import moment from "moment";
import { useCallback } from "react";

type CodeFormType = {
  action: string;
  form: FormInstance<any>;
  handler: (values: any) => void;
  values: any;
  errors: string[];
};

const fields = [
  ["schemeId", "schemeId"],
  ["status", "status"],
  ["level", "level"],
  ["kind", "kind"],
  ["journeyDate", "journeyDate"],
  ["startDate", "startDate"],
  ["endDate", "endDate"],
  ["certificate", "certificate"],
];

export const onError = (form: any, setErrors: any) => ({ graphQLErrors }: any) => {
  const fieldsErrors: any[] = [];
  const errors: string[] = [];
  graphQLErrors.forEach((graphQLError: any) => {
    let found = false;
    fields.forEach(([fieldName, errorFieldName]) => {
      if (graphQLError.name === errorFieldName) {
        fieldsErrors.push({ name: fieldName, errors: (graphQLError as any).messages });
        found = true;
      }
    });
    if (!found) {
      errors.push(graphQLError.message);
    }
  });
  // console.log(fieldsErrors);
  // console.log(errors);
  form.setFields(fieldsErrors);
  setErrors(errors);
};

export const onFinish = (mutate: any, params: any, variables: any) => (values: any) => {
  const input = {
    ...values,
    ...params,
    journeyDate: moment(values["journeyDate"], "MM-DD-YYYY").format("YYYY-MM-DD"),
    startDate: moment(values["startDate"], "MM-DD-YYYY").format("YYYY-MM-DD"),
    endDate: moment(values["endDate"], "MM-DD-YYYY").format("YYYY-MM-DD"),
    certificate: values.certificate?.file ?? null,
  };

  mutate({ variables: { ...variables, input } });
};

export const AbilityForm: React.FC<CodeFormType> = (props) => {
  const { action, form, handler, values, errors } = props;

  console.log(`Agencies/Collaborators/Abilities/AbilityForm action: ${action}`);

  const valuesChangeHandler = useCallback(
    (changedValues, allValues) => {
      const fieldValues: any[] = [];
      if (changedValues.certificate && changedValues.certificate.file.status === "removed") {
        fieldValues.push({ name: ["certificate"], value: null });
      }
      form.setFields(fieldValues);
    },
    [form],
  );

  const initialValues = {
    ...values,
    certificate: values.certificate ? [{ url: values.certificate, name: "Download" }] : [],
  };

  const uploadProps = {
    maxCount: 1,
    accept: ".pdf, .doc, .xls, .xlsx",
    beforeUpload: () => {
      return false;
    },
  };

  return (
    <Card>
      {errors.length > 0 && (
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          {errors.map((error, index) => (
            <Alert key={index} type="error" message="Error" description={error} />
          ))}
        </Space>
      )}
      <Form
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onValuesChange={valuesChangeHandler}
        onFinish={handler}
      >
        <Row gutter={[24, 0]}>
          <Col span={8}>
            <SchemesQueryProvider>
              {({ schemes }) => (
                <Form.Item name="schemeId" label="Scheme" rules={[{ required: true, message: "is required" }]}>
                  <Select
                    showSearch
                    optionLabelProp="label"
                    filterOption={(input, option) => {
                      let label = "";
                      if (option?.label && isString(option.label)) label = option.label;
                      let value = option?.value;
                      return (
                        label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {schemes.map((scheme) => (
                      <Select.Option key={scheme.id} value={scheme.id} label={scheme.name}>
                        {scheme.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </SchemesQueryProvider>
          </Col>
          <Col span={8}>
            <AbilityLevelsQueryProvider>
              {({ abilityLevels }) => (
                <Form.Item name="level" label="Level" rules={[{ required: true, message: "is required" }]}>
                  <Select
                    showSearch
                    optionLabelProp="label"
                    filterOption={(input, option) => {
                      let label = "";
                      if (option?.label && isString(option.label)) label = option.label;
                      let value = option?.value;
                      return (
                        label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {abilityLevels?.enumValues?.map((abilityLevel) => (
                      <Select.Option key={abilityLevel.name} value={abilityLevel.name} label={abilityLevel.description}>
                        {abilityLevel.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </AbilityLevelsQueryProvider>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={8}>
            <AbilityKindsQueryProvider>
              {({ abilityKinds }) => (
                <Form.Item name="kind" label="Course Type" rules={[{ required: true, message: "is required" }]}>
                  <Select
                    showSearch
                    optionLabelProp="label"
                    filterOption={(input, option) => {
                      let label = "";
                      if (option?.label && isString(option.label)) label = option.label;
                      let value = option?.value;
                      return (
                        label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {abilityKinds?.enumValues?.map((abilityKind) => (
                      <Select.Option key={abilityKind.name} value={abilityKind.name} label={abilityKind.description}>
                        {abilityKind.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </AbilityKindsQueryProvider>
          </Col>
          <Col span={8}>
            <Form.Item name="journeyDate" label="Course Date" rules={[{ required: true, message: "is required" }]}>
              <DatePicker mode="date" format="MM-DD-YYYY" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={8}>
            <Form.Item name="startDate" label="Start Date" rules={[{ required: true, message: "is required" }]}>
              <DatePicker mode="date" format="MM-DD-YYYY" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="endDate" label="End Date" rules={[{ required: true, message: "is required" }]}>
              <DatePicker mode="date" format="MM-DD-YYYY" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={8}>
            <Form.Item name="certificate" label="Certificate" valuePropName={"defaultFileList"}>
              <Dragger {...uploadProps}>
                <FileAddOutlined />
                Click or drop here
              </Dragger>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
