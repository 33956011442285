import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Stack,
} from "@chakra-ui/react";
import React from "react";

type FormDrawerType = {
  isOpen: boolean;
  onClose: any;
  size?: string;
  title?: string;
};

export const FormDrawer: React.FC<FormDrawerType> = (props) => {
  const { children, isOpen, onClose, size, title } = props;

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size={size}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            <Heading size="sm">{title}</Heading>
          </DrawerHeader>
          <DrawerBody py="4">
            <Stack spacing="4">{children}</Stack>
          </DrawerBody>
          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue">Save</Button>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};
